export const siteMap = {
    data: {
        linkList: {
            sitemapCustomerService: {
                id: 'sitemapCustomerService',
                tags: ['auto/oem/lrfg/site-map'],
                version: '20240606',
                title: {
                    text: 'CUSTOMER SERVICE',
                },
                subTitle: {
                    text: null,
                },
                links: {
                    sitemapContact: {
                        headerText: null,
                        value: 'Contact us',
                        type: null,
                        presentationType: null,
                        url: '/contact-us',
                        esUrl: null,
                        id: 'sitemapContact',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    sitemapFaqs: {
                        headerText: null,
                        value: 'FAQs',
                        type: null,
                        presentationType: null,
                        url: '/faq',
                        esUrl: null,
                        id: 'sitemapFaqs',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    sitemapGiveFeedback: {
                        headerText: null,
                        value: 'Give feedback',
                        type: null,
                        presentationType: null,
                        url: 'https://jpmc.surveymonkey.com/r/YC7JSHP',
                        esUrl: null,
                        id: 'sitemapGiveFeedback',
                        hat: null,
                        navigationKeyId: 'Untimed Modal',
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: true,
                    },
                },
            },
            sitemapFinancingAndOffers: {
                id: 'sitemapFinancingAndOffers',
                tags: ['auto/oem/lrfg/site-map'],
                version: '20240606',
                title: {
                    text: 'FINANCING & OFFERS',
                },
                subTitle: {
                    text: null,
                },
                links: {
                    sitemapFinancingOptions: {
                        headerText: null,
                        value: 'Financing options',
                        type: null,
                        presentationType: null,
                        url: '/financing',
                        esUrl: null,
                        id: 'sitemapFinancingOptions',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    sitemapLeaseVsPurchase: {
                        headerText: null,
                        value: 'Lease vs. purchase',
                        type: null,
                        presentationType: null,
                        url: '/financing#lease-vs-purchase',
                        esUrl: null,
                        id: 'sitemapLeaseVsPurchase',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    sitemapLeaseLoyaltyProgram: {
                        headerText: null,
                        value: 'Lease loyalty program',
                        type: null,
                        presentationType: null,
                        url: '/lease-end/return#loyaltyprogram',
                        esUrl: null,
                        id: 'sitemapLeaseLoyaltyProgram',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    sitemapCurrentOffers: {
                        headerText: null,
                        value: 'Current offers',
                        type: null,
                        presentationType: null,
                        url: 'https://www.landroverusa.com/current-offers/index.html',
                        esUrl: null,
                        id: 'sitemapCurrentOffers',
                        hat: null,
                        navigationKeyId: 'Untimed Modal',
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: true,
                    },
                },
            },
            sitemapLease: {
                id: 'sitemapLease',
                tags: ['auto/oem/lrfg/site-map'],
                version: '20240606',
                title: {
                    text: 'LEASE',
                },
                subTitle: {
                    text: null,
                },
                links: {
                    sitemapLeaseEnd: {
                        headerText: null,
                        value: 'Lease end',
                        type: null,
                        presentationType: null,
                        url: '/lease-end',
                        esUrl: null,
                        id: 'sitemapLeaseEnd',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    sitemapGetANewLandRover: {
                        headerText: null,
                        value: 'Get a new Land Rover',
                        type: null,
                        presentationType: null,
                        url: '/lease-end/new',
                        esUrl: null,
                        id: 'sitemapGetANewLandRover',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    sitemapReturnLeaseVehicle: {
                        headerText: null,
                        value: 'Return lease vehicle',
                        type: null,
                        presentationType: null,
                        url: '/lease-end/return',
                        esUrl: null,
                        id: 'sitemapReturnLeaseVehicle',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    sitemapExtendLease: {
                        headerText: null,
                        value: 'Extend lease',
                        type: null,
                        presentationType: null,
                        url: '/lease-end/other',
                        esUrl: null,
                        id: 'sitemapExtendLease',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    sitemapPurchaseYourVehicle: {
                        headerText: null,
                        value: 'Purchase your vehicle',
                        type: null,
                        presentationType: null,
                        url: '/lease-end/other#purchase-current-land-rover',
                        esUrl: null,
                        id: 'sitemapPurchaseYourVehicle',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    sitemapWearAndUse: {
                        headerText: null,
                        value: 'Wear and use',
                        type: null,
                        presentationType: null,
                        url: '/lease-end/return#wearanduse',
                        esUrl: null,
                        id: 'sitemapWearAndUse',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    sitemapEarlyTurnIn: {
                        headerText: null,
                        value: 'Early turn in',
                        type: null,
                        presentationType: null,
                        url: '/lease-end/other#early-turn-in',
                        esUrl: null,
                        id: 'sitemapEarlyTurnIn',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                },
            },
            sitemapLoan: {
                id: 'sitemapLoan',
                tags: ['auto/oem/lrfg/site-map'],
                version: '20240606',
                title: {
                    text: 'LOAN',
                },
                subTitle: {
                    text: null,
                },
                links: {
                    sitemapLeaseEnd: {
                        headerText: null,
                        value: 'Loan end',
                        type: null,
                        presentationType: null,
                        url: '/loan-end',
                        esUrl: null,
                        id: 'sitemapLeaseEnd',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                },
            },
            sitemapNavList: {
                id: 'sitemapNavList',
                tags: ['auto/oem/lrfg/site-map'],
                version: '20240606',
                title: {
                    text: null,
                },
                subTitle: {
                    text: null,
                },
                links: {
                    sitemapPayAndManage: {
                        headerText: null,
                        value: 'PAY & MANAGE',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'sitemapPayAndManage',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    sitemapFinancingAndOffers: {
                        headerText: null,
                        value: 'FINANCING & OFFERS',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'sitemapFinancingAndOffers',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    sitemapShoppingTools: {
                        headerText: null,
                        value: 'SHOP & BUILD',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'sitemapShoppingTools',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    sitemapLoan: {
                        headerText: null,
                        value: 'LOAN',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'sitemapLoan',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    sitemapLease: {
                        headerText: null,
                        value: 'LEASE',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'sitemapLease',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    sitemapCustomerService: {
                        headerText: null,
                        value: 'CUSTOMER SERVICE',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'sitemapCustomerService',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                },
            },
            sitemapPayAndManage: {
                id: 'sitemapPayAndManage',
                tags: ['auto/oem/lrfg/site-map'],
                version: '20240606',
                title: {
                    text: 'PAY & MANAGE',
                },
                subTitle: {
                    text: null,
                },
                links: {
                    sitemapCreateAccount: {
                        headerText: null,
                        value: 'Create account',
                        type: null,
                        presentationType: null,
                        url: 'https://secure.chase.com/web/auth/enrollment#/enroll/onlineEnrollment/gettingStarted/index?LOB=&jp_cdf=LRFG',
                        esUrl: null,
                        id: 'sitemapCreateAccount',
                        hat: null,
                        navigationKeyId: 'Timed Modal',
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: true,
                    },
                    sitemapMyAccount: {
                        headerText: null,
                        value: 'My Account',
                        type: null,
                        presentationType: null,
                        url: '/myaccount',
                        esUrl: null,
                        id: 'sitemapMyAccount',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    sitemapMakeAPayment: {
                        headerText: null,
                        value: 'Make a payment',
                        type: null,
                        presentationType: null,
                        url: '/myaccount',
                        esUrl: null,
                        id: 'sitemapMakeAPayment',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    sitemapAccountSetup: {
                        headerText: null,
                        value: 'Account setup',
                        type: null,
                        presentationType: null,
                        url: '/welcome',
                        esUrl: null,
                        id: 'sitemapAccountSetup',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    sitemapAutomaticPayments: {
                        headerText: null,
                        value: 'Automatic payments',
                        type: null,
                        presentationType: null,
                        url: '/myaccount',
                        esUrl: null,
                        id: 'sitemapAutomaticPayments',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    sitemapPaperlessBilling: {
                        headerText: null,
                        value: 'Paperless billing',
                        type: null,
                        presentationType: null,
                        url: 'https://www.chase.com/personal/mobile-online-banking/login-paperless?jp_cdf=LRFG',
                        esUrl: null,
                        id: 'sitemapPaperlessBilling',
                        hat: null,
                        navigationKeyId: 'Timed Modal',
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: true,
                    },
                },
            },
            sitemapShoppingTools: {
                id: 'sitemapShoppingTools',
                tags: ['auto/oem/lrfg/site-map'],
                version: '20240606',
                title: {
                    text: 'SHOP & BUILD',
                },
                subTitle: {
                    text: null,
                },
                links: {
                    sitemapBuildAndPrice: {
                        headerText: null,
                        value: 'Build and price',
                        type: null,
                        presentationType: null,
                        url: 'https://www.landroverusa.com/build-your-own/index.html',
                        esUrl: null,
                        id: 'sitemapBuildAndPrice',
                        hat: null,
                        navigationKeyId: 'Untimed Modal',
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: true,
                    },
                    sitemapSearchInventory: {
                        headerText: null,
                        value: 'Search inventory',
                        type: null,
                        presentationType: null,
                        url: 'https://inventory.landroverusa.com/new',
                        esUrl: null,
                        id: 'sitemapSearchInventory',
                        hat: null,
                        navigationKeyId: 'Untimed Modal',
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: true,
                    },
                    sitemapCertifiedPreOwned: {
                        headerText: null,
                        value: 'Certified pre-owned',
                        type: null,
                        presentationType: null,
                        url: 'https://www.landroverusa.com/certified-pre-owned/index.html',
                        esUrl: null,
                        id: 'sitemapCertifiedPreOwned',
                        hat: null,
                        navigationKeyId: 'Untimed Modal',
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: true,
                    },
                    sitemapFindARetailer: {
                        headerText: null,
                        value: 'Find a retailer',
                        type: null,
                        presentationType: null,
                        url: 'https://www.landroverusa.com/national-dealer-locator.html',
                        esUrl: null,
                        id: 'sitemapFindARetailer',
                        hat: null,
                        navigationKeyId: 'Untimed Modal',
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: true,
                    },
                    sitemapEstimateTradeInValue: {
                        headerText: null,
                        value: 'Estimate trade-in value',
                        type: null,
                        presentationType: null,
                        url: 'https://www.landroverusa.com/shopping-tools/calculate-trade-in-value.html',
                        esUrl: null,
                        id: 'sitemapEstimateTradeInValue',
                        hat: null,
                        navigationKeyId: 'Untimed Modal',
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: true,
                    },
                },
            },
        },
        carousel: {},
        iconList: {},
        legalContainer: {},
        dialog: {},
        contentList: {},
        tile: {
            siteMap: {
                tags: ['auto/oem/lrfg/site-map'],
                id: 'siteMap',
                variationId: null,
                title: {
                    html: '<p>SITE MAP</p>\n',
                    text: 'SITE MAP',
                    markdown: 'SITE MAP\n',
                },
                subTitle: {
                    html: null,
                    text: null,
                    markdown: null,
                },
                version: '20240502',
                body: {
                    html: null,
                    text: null,
                    markdown: null,
                },
                additionalText: {
                    html: null,
                    text: null,
                    markdown: null,
                },
                hat: null,
                iconImage: {
                    altText: null,
                    id: 'siteMapImage',
                    source: null,
                    url: null,
                },
                primaryImage: {
                    altText: 'siteMapImage',
                    id: null,
                    source: null,
                    url: null,
                },
                primaryImageWithRendition: null,
                secondaryImageWithRendition: null,
                links: [],
            },
        },
        structuredContentList: {},
    },
};
