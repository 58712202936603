export const faqsPage = {
    data: {
        linkList: {
            allLinks: {
                id: 'allLinks',
                tags: ['auto/oem/lrfg/faqs'],
                version: '20240606',
                title: {
                    text: null,
                },
                subTitle: {
                    text: null,
                },
                links: {
                    callUsNumber: {
                        headerText: 'anquer',
                        value: '1-800-336-6675',
                        type: null,
                        presentationType: null,
                        url: '1-800-336-6675',
                        esUrl: null,
                        id: 'callUsNumber',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    contactUs: {
                        headerText: 'anquer',
                        value: 'contact us',
                        type: null,
                        presentationType: null,
                        url: '/contact-us',
                        esUrl: null,
                        id: 'contactUs',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    chaseDotCom: {
                        headerText: 'anquer',
                        value: 'chase.com',
                        type: null,
                        presentationType: null,
                        url: 'https://www.chase.com/personal/auto-loans/servicing?jp_cdf=LRFG',
                        esUrl: null,
                        id: 'chaseDotCom',
                        hat: null,
                        navigationKeyId: 'Timed Modal',
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: true,
                    },
                    odoMeterDisclosure: {
                        headerText: 'anquer',
                        value: 'Odometer Disclosure Statement',
                        type: null,
                        presentationType: null,
                        url: 'https://www.chase.com/content/dam/chase-ux/documents/personal/auto-loans/odometer_statement_lease.pdf?jp_cdf=LRFG',
                        esUrl: null,
                        id: 'odoMeterDisclosure',
                        hat: null,
                        navigationKeyId: 'Timed Modal',
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: true,
                    },
                    wearAnduse: {
                        headerText: 'anquer',
                        value: 'Wear and use',
                        type: null,
                        presentationType: null,
                        url: '/lease-end/return#wearanduse',
                        esUrl: null,
                        id: 'wearAnduse',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    return: {
                        headerText: 'anquer',
                        value: 'Return',
                        type: null,
                        presentationType: null,
                        url: '/lease-end/return',
                        esUrl: null,
                        id: 'return',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    chaseLeaseBuyOut: {
                        headerText: 'anquer',
                        value: 'chase.com lease buy out',
                        type: null,
                        presentationType: null,
                        url: 'https://www.chase.com/leasebuyout',
                        esUrl: null,
                        id: 'chaseLeaseBuyOut',
                        hat: null,
                        navigationKeyId: 'Timed Modal',
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: true,
                    },
                    chaseLoanStatus: {
                        headerText: 'anquer',
                        value: 'chase Loan Status',
                        type: null,
                        presentationType: null,
                        url: 'https://www.chase.com/loanstatus',
                        esUrl: null,
                        id: 'chaseLoanStatus',
                        hat: null,
                        navigationKeyId: 'Timed Modal',
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: true,
                    },
                    landRoverFinGroup: {
                        headerText: 'anquer',
                        value: 'www.LandRoverFinancialGroup.com',
                        type: null,
                        presentationType: null,
                        url: '/',
                        esUrl: null,
                        id: 'landRoverFinGroup',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    chaseDotComPaperLess: {
                        headerText: 'anquer',
                        value: 'chase.com/Paperless',
                        type: null,
                        presentationType: null,
                        url: 'https://www.chase.com/personal/mobile-online-banking/login-paperless',
                        esUrl: null,
                        id: 'chaseDotComPaperLess',
                        hat: null,
                        navigationKeyId: 'Timed Modal',
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: true,
                    },
                    loanPayOffFaqs: {
                        headerText: 'anquer',
                        value: 'loan payoff FAQs',
                        type: null,
                        presentationType: null,
                        url: 'https://chase.com/personal/auto-loans/servicing/faqs/loan-payoff?jp_cdf=LRFG',
                        esUrl: null,
                        id: 'loanPayOffFaqs',
                        hat: null,
                        navigationKeyId: 'Timed Modal',
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: true,
                    },
                },
            },
            faqsLinkList: {
                id: 'faqsLinkList',
                tags: ['auto/oem/lrfg/faqs'],
                version: '20240606',
                title: {
                    text: null,
                },
                subTitle: {
                    text: null,
                },
                links: {
                    onlineAccountEnrollment: {
                        headerText: null,
                        value: 'ONLINE ACCOUNT ENROLLMENT',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'onlineAccountEnrollment',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: 'pl_faq_online_acct',
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    payments: {
                        headerText: null,
                        value: 'PAYMENTS',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'payments',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: 'pl_faq_payments',
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    loanPayOff: {
                        headerText: null,
                        value: 'LOAN PAYOFF',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'loanPayOff',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: 'pl_faq_loan_payoff',
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    leaseEnd: {
                        headerText: null,
                        value: 'LEASE END',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'leaseEnd',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: 'pl_faq_lease_end',
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    leaseToLoan: {
                        headerText: null,
                        value: 'LEASE TO LOAN',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'leaseToLoan',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    titleAndLienRelease: {
                        headerText: null,
                        value: 'TITLE & LIEN RELEASE',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'titleAndLienRelease',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: 'pl_faq_title_lien',
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    financialDifficulty: {
                        headerText: null,
                        value: 'FINANCIAL DIFFICULTY',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'financialDifficulty',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: 'pl_faq_finan_difficulty',
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    otherTopics: {
                        headerText: null,
                        value: 'OTHER TOPICS',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'otherTopics',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: 'pl_faq_other_topics',
                        trackingType: null,
                        showSpeedBump: false,
                    },
                },
            },
            financialDifficulty: {
                id: 'financialDifficulty',
                tags: ['auto/oem/lrfg/faqs'],
                version: '20240606',
                title: {
                    text: 'FINANCIAL DIFFICULTY',
                },
                subTitle: {
                    text: null,
                },
                links: {
                    financialDifficultyOption1: {
                        headerText: null,
                        value: "What if I can't make my next payment or miss a payment?",
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'financialDifficultyOption1',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: 'pl_faq_fd_miss_payment',
                        trackingType: null,
                        showSpeedBump: false,
                    },
                },
            },
            financialDifficultyOption1: {
                id: 'financialDifficultyOption1',
                tags: ['auto/oem/lrfg/faqs'],
                version: '20240606',
                title: {
                    text: null,
                },
                subTitle: {
                    text: null,
                },
                links: {
                    financialDifficultyOption1Item1: {
                        headerText: null,
                        value: "Sometimes unexpected issues come up. If you can't make your next payment or you missed your payment, we may be able to help you. Call us at <1>{{callUsNumber}}</1> so we can discuss possible options that may work for you. We accept operator relay calls.",
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: 'callUsNumber',
                        id: 'financialDifficultyOption1Item1',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: 'allLinks',
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                },
            },
            leaseEnd: {
                id: 'leaseEnd',
                tags: ['auto/oem/lrfg/faqs'],
                version: '20240606',
                title: {
                    text: null,
                },
                subTitle: {
                    text: null,
                },
                links: {
                    leaseEndOption1: {
                        headerText: null,
                        value: 'Where do I find the terms for my lease?',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'leaseEndOption1',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: 'pl_faq_le_terms',
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    leaseEndOption2: {
                        headerText: null,
                        value: 'How can I terminate my lease early?',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'leaseEndOption2',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: 'pl_faq_le_early_term',
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    leaseEndOption3: {
                        headerText: null,
                        value: 'Can I purchase my leased vehicle?',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'leaseEndOption3',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: 'pl_faq_le_purchase',
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    leaseEndOption4: {
                        headerText: null,
                        value: 'Can I negotiate the purchase price of my vehicle?',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'leaseEndOption4',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: 'pl_faq_le_negotiate',
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    leaseEndOption5: {
                        headerText: null,
                        value: 'How do I get my lease purchase balance?',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'leaseEndOption5',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: 'pl_faq_le_lease_balance',
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    leaseEndOption6: {
                        headerText: null,
                        value: 'When does my payoff quote expire?',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'leaseEndOption6',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: 'pl_faq_le_payoff_expire',
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    leaseEndOption7: {
                        headerText: null,
                        value: 'What is included in the purchase price?',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'leaseEndOption7',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: 'pl_faq_le_purchase_incl',
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    leaseEndOption8: {
                        headerText: null,
                        value: 'I received my purchase documents and they include a Monthly Payment Due. Why am I paying an extra monthly payment when I am buying the car?',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'leaseEndOption8',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: 'pl_faq_le_future_rent',
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    leaseEndOption9: {
                        headerText: null,
                        value: 'How long will it take to process the title after purchase?',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'leaseEndOption9',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: 'pl_faq_le_process_purch',
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    leaseEndOption10: {
                        headerText: null,
                        value: null,
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'leaseEndOption10',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: 'pl_faq_le_preinsp_req',
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    leaseEndOption11: {
                        headerText: null,
                        value: 'I purchased a tire replacement product when I signed my lease. Do I need to use it before I turn in my vehicle?',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'leaseEndOption11',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: 'pl_faq_le_replace_tire',
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    leaseEndOption12: {
                        headerText: null,
                        value: 'Who do I contact about the products and services I purchased with my lease?',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'leaseEndOption12',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: 'pl_faq_le_retailer_prod',
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    leaseEndOption13: {
                        headerText: null,
                        value: 'How do you know when my vehicle was returned?',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'leaseEndOption13',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: 'pl_faq_le_return_lease',
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    leaseEndOption14: {
                        headerText: null,
                        value: 'What is included on the Lease-End Bill?',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'leaseEndOption14',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: 'pl_faq_le_lease_bill',
                        trackingType: null,
                        showSpeedBump: false,
                    },
                },
            },
            leaseEndOption1: {
                id: 'leaseEndOption1',
                tags: ['auto/oem/lrfg/faqs'],
                version: '20240606',
                title: {
                    text: null,
                },
                subTitle: {
                    text: null,
                },
                links: {
                    leaseEndOption1Item1: {
                        headerText: null,
                        value: 'Review the Lease Agreement that you signed at the retailer. You may<1> {{contactUs}} </1>to request a copy.',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: 'contactUs',
                        id: 'leaseEndOption1Item1',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: 'allLinks',
                        icon: null,
                        trackingId: 'pl_faq_le_lnk_Chase_terms',
                        trackingType: null,
                        showSpeedBump: false,
                    },
                },
            },
            leaseEndOption10: {
                id: 'leaseEndOption10',
                tags: ['auto/oem/lrfg/faqs'],
                version: '20240606',
                title: {
                    text: null,
                },
                subTitle: {
                    text: null,
                },
                links: {
                    leaseEndOption10Item1: {
                        headerText: null,
                        value: 'No, the pre-inspection is optional. See <1>{{wearAnduse}}</1> for additional information.',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: 'wearAnduse',
                        id: 'leaseEndOption10Item1',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: 'allLinks',
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                },
            },
            leaseEndOption11: {
                id: 'leaseEndOption11',
                tags: ['auto/oem/lrfg/faqs'],
                version: '20240606',
                title: {
                    text: null,
                },
                subTitle: {
                    text: null,
                },
                links: {
                    leaseEndOption11Item1: {
                        headerText: null,
                        value: "Yes. If you purchased an extended warranty or other optional products from the retailer at time of lease, it's your responsibility to use the benefits before you return the vehicle.",
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'leaseEndOption11Item1',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    leaseEndOption11Item2: {
                        headerText: null,
                        value: 'Since they are purchased directly from the retailer, we may be unable to determine which products you purchased.',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'leaseEndOption11Item2',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                },
            },
            leaseEndOption12: {
                id: 'leaseEndOption12',
                tags: ['auto/oem/lrfg/faqs'],
                version: '20240606',
                title: {
                    text: null,
                },
                subTitle: {
                    text: null,
                },
                links: {
                    leaseEndOption12Item1: {
                        headerText: null,
                        value: 'The retailer should have provided you with documentation about the products and services you purchased.',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'leaseEndOption12Item1',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    leaseEndOption12Item2: {
                        headerText: null,
                        value: 'These documents explain terms and conditions, coverage, cancellation provisions, how to file a claim and how to contact your service provider. Contact your retailer for additional information.',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'leaseEndOption12Item2',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                },
            },
            leaseEndOption13: {
                id: 'leaseEndOption13',
                tags: ['auto/oem/lrfg/faqs'],
                version: '20240606',
                title: {
                    text: null,
                },
                subTitle: {
                    text: null,
                },
                links: {
                    leaseEndOption13Item1: {
                        headerText: null,
                        value: 'In most cases, the retailer will let us know when you returned the vehicle,',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'leaseEndOption13Item1',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    leaseEndOption13Item2: {
                        headerText: null,
                        value: "but it's a good idea to <1> {{contactUs}}</1> within 1 business day of vehicle turn-in to ensure that we have the correct date and information to calculate your lease-end bill, if applicable.",
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: 'contactUs',
                        id: 'leaseEndOption13Item2',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: 'allLinks',
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                },
            },
            leaseEndOption14: {
                id: 'leaseEndOption14',
                tags: ['auto/oem/lrfg/faqs'],
                version: '20240606',
                title: {
                    text: null,
                },
                subTitle: {
                    text: null,
                },
                links: {
                    leaseEndOption14Item1: {
                        headerText: 'para',
                        value: 'The Lease-End Bill may include the following:',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'leaseEndOption14Item1',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    leaseEndOption14Item2: {
                        headerText: 'list',
                        value: 'Early termination charge: An early termination charge may apply, including remaining payments, as outlined in the Early Termination section of your Lease Agreement.',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'leaseEndOption14Item2',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    leaseEndOption14Item3: {
                        headerText: 'list',
                        value: 'Deferred payments/Payment balance: If you have any unpaid deferred payments or balances remaining, we will add it to your final Lease-End Bill.',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'leaseEndOption14Item3',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    leaseEndOption14Item4: {
                        headerText: 'list',
                        value: 'Excess Wear and Use Charge: If the vehicle is subject to more than normal wear and use, you will owe us for the actual or estimated cost to repair or replace any excess wear and use items.',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'leaseEndOption14Item4',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    leaseEndOption14Item5: {
                        headerText: 'list',
                        value: 'Please see the Excess Wear and Use section of your Lease Agreement for complete details, and the lease-end <1>{{return}}</1? page for additional information,',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: 'return',
                        id: 'leaseEndOption14Item5',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: 'allLinks',
                        icon: null,
                        trackingId: 'pl_faq_le_bill_lnk_retrn',
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    leaseEndOption14Item6: {
                        headerText: 'list',
                        value: 'including how to schedule a pre-inspection.',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'leaseEndOption14Item6',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    leaseEndOption14Item7: {
                        headerText: 'list',
                        value: 'Excess mileage charge: You can estimate your excess mileage charges by multiplying your excess miles by the per mile charge in your Lease Agreement.',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'leaseEndOption14Item7',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    leaseEndOption14Item8: {
                        headerText: 'list',
                        value: 'Turn–in fee (Disposition fee).',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'leaseEndOption14Item8',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    leaseEndOption14Item9: {
                        headerText: 'list',
                        value: "Tickets and citations: You are responsible for all tickets, citations, penalties and fines. At our option, we may pay on your behalf any fine that you haven't paid.",
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'leaseEndOption14Item9',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    leaseEndOption14Item10: {
                        headerText: 'list',
                        value: 'You must reimburse us for any such amount immediately upon demand. You should contact the issuing authority for questions regarding the ticket, citation, penalty or fee or, if needed, a copy of the ticket or citation.',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'leaseEndOption14Item10',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                },
            },
            leaseEndOption3: {
                id: 'leaseEndOption3',
                tags: ['auto/oem/lrfg/faqs'],
                version: '20240606',
                title: {
                    text: null,
                },
                subTitle: {
                    text: null,
                },
                links: {
                    leaseEndOption3Item1: {
                        headerText: null,
                        value: "Yes, you can purchase your leased vehicle at any time during the lease. <1>{{contactUs}}</1> for forms, instructions, and the purchase amount. We can't accept purchases from third parties (such as friends and family).",
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: 'contactUs',
                        id: 'leaseEndOption3Item1',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: 'allLinks',
                        icon: null,
                        trackingId: 'pl_faq_le_lnk_pur_contact',
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    leaseEndOption3Item2: {
                        headerText: null,
                        value: 'Please note that even after you purchase your lease, you may be billed for remaining property taxes, parking tickets, toll charges, and other state or local fees due on your account.',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'leaseEndOption3Item2',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                },
            },
            leaseEndOption4: {
                id: 'leaseEndOption4',
                tags: ['auto/oem/lrfg/faqs'],
                version: '20240606',
                title: {
                    text: null,
                },
                subTitle: {
                    text: null,
                },
                links: {
                    leaseEndOption4Item1: {
                        headerText: null,
                        value: "We don't negotiate the purchase price as you are bound by your signed Lease Agreement. Any negotiations regarding the purchase would need to take place at the retailer prior to signing your Lease Agreement.",
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'leaseEndOption4Item1',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                },
            },
            leaseEndOption5: {
                id: 'leaseEndOption5',
                tags: ['auto/oem/lrfg/faqs'],
                version: '20240606',
                title: {
                    text: null,
                },
                subTitle: {
                    text: null,
                },
                links: {
                    leaseEndOption5Item1: {
                        headerText: null,
                        value: 'If you are more than 6 months prior to your maturity date, you can go to <1>{{chaseDotCom}}</1>.',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: 'chaseDotCom',
                        id: 'leaseEndOption5Item1',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: 'allLinks',
                        icon: null,
                        trackingId: 'pl_faq_le_lnk_bal_chase',
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    leaseEndOption5Item2: {
                        headerText: null,
                        value: 'If you are less than 6 months prior to your maturity date, <1>{{contactUs}}</1>',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: 'contactUs',
                        id: 'leaseEndOption5Item2',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: 'allLinks',
                        icon: null,
                        trackingId: 'pl_faq_le_lnk_bal_contact',
                        trackingType: null,
                        showSpeedBump: false,
                    },
                },
            },
            leaseEndOption6: {
                id: 'leaseEndOption6',
                tags: ['auto/oem/lrfg/faqs'],
                version: '20240606',
                title: {
                    text: null,
                },
                subTitle: {
                    text: null,
                },
                links: {
                    leaseEndOption6Item1: {
                        headerText: null,
                        value: 'Refer to the documentation provided when the quote was received to confirm the \\"good through\\" date.',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'leaseEndOption6Item1',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                },
            },
            leaseEndOption8: {
                id: 'leaseEndOption8',
                tags: ['auto/oem/lrfg/faqs'],
                version: '20240606',
                title: {
                    text: null,
                },
                subTitle: {
                    text: null,
                },
                links: {
                    leaseEndOption8Item1: {
                        headerText: null,
                        value: 'If your payoff quote overlaps your payment due date, you will see an amount for Monthly Payment Due.',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'leaseEndOption8Item1',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    leaseEndOption8Item2: {
                        headerText: null,
                        value: "This isn't an extra payment, as the Monthly Payment Due was subtracted from the Purchase Price and includes a rent charge and tax.",
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'leaseEndOption8Item2',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    leaseEndOption8Item3: {
                        headerText: null,
                        value: 'If the quote had not overlapped your normal payment due date, the amount in the Monthly Payment Due section would have remained in the Purchase Price amount.',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'leaseEndOption8Item3',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    leaseEndOption8Item4: {
                        headerText: null,
                        value: 'The Total Purchase Price must be paid to meet the terms of your Lease Agreement and close your account.',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'leaseEndOption8Item4',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    leaseEndOption8Item5: {
                        headerText: null,
                        value: 'If the payoff check is posted before the payment due date, you may be entitled to a refund, which may include the rent charge and tax.',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'leaseEndOption8Item5',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    leaseEndOption8Item6: {
                        headerText: null,
                        value: 'We recommend sending the payoff check via overnight delivery if this is a concern.',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'leaseEndOption8Item6',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                },
            },
            leaseEndOption9: {
                id: 'leaseEndOption9',
                tags: ['auto/oem/lrfg/faqs'],
                version: '20240606',
                title: {
                    text: null,
                },
                subTitle: {
                    text: null,
                },
                links: {
                    leaseEndOption9Item1: {
                        headerText: null,
                        value: 'To release your title, your account must be paid in full with a $0 balance and we must receive a properly completed, federally required <1>{{odoMeterDisclosure}} </1>.',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: 'odoMeterDisclosure',
                        id: 'leaseEndOption9Item1',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: 'allLinks',
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    leaseEndOption9Item2: {
                        headerText: null,
                        value: 'Following processing of both items, the title will be sent to the address that appears on your auto account statements generally in 2-10 business days or based on State requirements.',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'leaseEndOption9Item2',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    leaseEndOption9Item3: {
                        headerText: null,
                        value: '<2>{{contactUs}}</2> directly if you need to update your information. Although we release the title typically within 2-10 business days,',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: 'contactUs',
                        id: 'leaseEndOption9Item3',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: 'allLinks',
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    leaseEndOption9Item4: {
                        headerText: null,
                        value: null,
                        type: null,
                        presentationType: null,
                        url: 'Please allow up to 30 days to receive your title after the final payment has posted based on mail time and/or state DMV processing.',
                        esUrl: null,
                        id: 'leaseEndOption9Item4',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                },
            },
            leaseToLoan: {
                id: 'leaseToLoan',
                tags: ['auto/oem/lrfg/faqs'],
                version: '20240606',
                title: {
                    text: 'LEASE TO LOAN',
                },
                subTitle: {
                    text: null,
                },
                links: {
                    leaseToLoanOption1: {
                        headerText: null,
                        value: 'How can I apply for lease purchase financing with Chase?',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'leaseToLoanOption1',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    leaseToLoanOption2: {
                        headerText: null,
                        value: 'How soon will I receive a credit decision after I submit my application?',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'leaseToLoanOption2',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    leaseToLoanOption3: {
                        headerText: null,
                        value: 'Can I make a payment towards the lease purchase balance?',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'leaseToLoanOption3',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    leaseToLoanOption4: {
                        headerText: null,
                        value: 'How does applying for a loan affect my credit score?',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'leaseToLoanOption4',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    leaseToLoanOption5: {
                        headerText: null,
                        value: 'Is there a minimum loan amount required for lease purchase financing?',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'leaseToLoanOption5',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    leaseToLoanOption6: {
                        headerText: null,
                        value: 'What loan terms are available?',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'leaseToLoanOption6',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    leaseToLoanOption7: {
                        headerText: null,
                        value: 'How long are my approval and/or interest rate good?',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'leaseToLoanOption7',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    leaseToLoanOption8: {
                        headerText: null,
                        value: 'How do I sign my loan closing documents?',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'leaseToLoanOption8',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                },
            },
            leaseToLoanOption1: {
                id: 'leaseToLoanOption1',
                tags: ['auto/oem/lrfg/faqs'],
                version: '20240606',
                title: {
                    text: null,
                },
                subTitle: {
                    text: null,
                },
                links: {
                    leaseToLoanOption1Item1: {
                        headerText: null,
                        value: 'Visit <1>{{chaseLeaseBuyOut}}</1> to complete an application for lease purchase financing from Chase.',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: 'chaseLeaseBuyOut',
                        id: 'leaseToLoanOption1Item1',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: 'allLinks',
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    leaseToLoanOption1Item2: {
                        headerText: null,
                        value: 'Lease purchase financing is only available for named lessees on the Lease Agreement, and cannot be transferred to third parties, such as friends or family members.',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'leaseToLoanOption1Item2',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    leaseToLoanOption1Item3: {
                        headerText: null,
                        value: 'Adding or removing an individual from the lease agreement on the finance application will cause an automatic rejection of the application. You will need to complete a new application with all named parties.',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'leaseToLoanOption1Item3',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                },
            },
            leaseToLoanOption2: {
                id: 'leaseToLoanOption2',
                tags: ['auto/oem/lrfg/faqs'],
                version: '20240606',
                title: {
                    text: null,
                },
                subTitle: {
                    text: null,
                },
                links: {
                    leaseToLoanOption2Item1: {
                        headerText: null,
                        value: 'A decision is typically made within 1-3 business days. If you entered your email address during the application process,',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'leaseToLoanOption2Item1',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    leaseToLoanOption2Item2: {
                        headerText: null,
                        value: "we'll send you a follow-up email. If you applied by phone, a decision letter will be mailed to you.",
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'leaseToLoanOption2Item2',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    leaseToLoanOption2Item3: {
                        headerText: null,
                        value: 'Check the status of your application about 24 hours after submission at <1>{{chaseLoanStatus}}</1>.',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: 'chaseLoanStatus',
                        id: 'leaseToLoanOption2Item3',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: 'allLinks',
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                },
            },
            leaseToLoanOption3: {
                id: 'leaseToLoanOption3',
                tags: ['auto/oem/lrfg/faqs'],
                version: '20240606',
                title: {
                    text: null,
                },
                subTitle: {
                    text: null,
                },
                links: {
                    leaseToLoanOption3Item1: {
                        headerText: null,
                        value: 'Payments or paydowns may be made using the same payment information as your regular monthly payments. View our <1>{{contactUs}}</1> page for additional information about making payments.',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: 'contactUs',
                        id: 'leaseToLoanOption3Item1',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: 'allLinks',
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    leaseToLoanOption3Item2: {
                        headerText: null,
                        value: 'Depending on the payment amount, there may be a hold for up to 10 business days which may go into a new statement period.',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'leaseToLoanOption3Item2',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    leaseToLoanOption3Item3: {
                        headerText: null,
                        value: 'The closing process can continue once the payment has posted and an updated purchase quote is available.',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'leaseToLoanOption3Item3',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                },
            },
            leaseToLoanOption4: {
                id: 'leaseToLoanOption4',
                tags: ['auto/oem/lrfg/faqs'],
                version: '20240606',
                title: {
                    text: null,
                },
                subTitle: {
                    text: null,
                },
                links: {
                    leaseToLoanOption4Item1: {
                        headerText: null,
                        value: "When you apply for a loan, we make a credit report hard inquiry. Each credit reporting agency creates your credit scores. We're unable to provide details how the inquiry could affect your credit scores.",
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'leaseToLoanOption4Item1',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                },
            },
            leaseToLoanOption5: {
                id: 'leaseToLoanOption5',
                tags: ['auto/oem/lrfg/faqs'],
                version: '20240606',
                title: {
                    text: null,
                },
                subTitle: {
                    text: null,
                },
                links: {
                    leaseToLoanOption5Item1: {
                        headerText: null,
                        value: 'When financing your vehicle with Chase, the minimum loan amount required is $4,000.',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'leaseToLoanOption5Item1',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                },
            },
            leaseToLoanOption6: {
                id: 'leaseToLoanOption6',
                tags: ['auto/oem/lrfg/faqs'],
                version: '20240606',
                title: {
                    text: null,
                },
                subTitle: {
                    text: null,
                },
                links: {
                    leaseToLoanOption6Item1: {
                        headerText: null,
                        value: 'We offer loan terms from 12 to 72 months. This may vary depending on your credit score, age of the vehicle and vehicle mileage.',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'leaseToLoanOption6Item1',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                },
            },
            leaseToLoanOption7: {
                id: 'leaseToLoanOption7',
                tags: ['auto/oem/lrfg/faqs'],
                version: '20240606',
                title: {
                    text: null,
                },
                subTitle: {
                    text: null,
                },
                links: {
                    leaseToLoanOption7Item1: {
                        headerText: null,
                        value: 'Approval decisions are valid for 30 days from the date of approval.',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'leaseToLoanOption7Item1',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                },
            },
            leaseToLoanOption8: {
                id: 'leaseToLoanOption8',
                tags: ['auto/oem/lrfg/faqs'],
                version: '20240606',
                title: {
                    text: null,
                },
                subTitle: {
                    text: null,
                },
                links: {
                    leaseToLoanOption8Item1: {
                        headerText: null,
                        value: 'We’ll send all necessary closing documents to you. The package will include easy-to-follow instructions and a phone number that you can use to call us if you have any questions.',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'leaseToLoanOption8Item1',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    leaseToLoanOption8Item2: {
                        headerText: null,
                        value: 'To speed up the process of closing your loan, we’ll include a postage-paid overnight return envelope and label to send the completed documents back to us.',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'leaseToLoanOption8Item2',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    leaseToLoanOption8Item3: {
                        headerText: null,
                        value: 'Some documents may require notarization.',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'leaseToLoanOption8Item3',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                },
            },
            loanPayOff: {
                id: 'loanPayOff',
                tags: ['auto/oem/lrfg/faqs'],
                version: '20240606',
                title: {
                    text: 'LOAN PAYOFF',
                },
                subTitle: {
                    text: null,
                },
                links: {
                    loanPayOffOption1: {
                        headerText: null,
                        value: 'How do I get an auto loan payoff quote?',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'loanPayOffOption1',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: 'pl_faq_lp_payoff_quote',
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    loanPayOffOption2: {
                        headerText: null,
                        value: 'Can I pay off my loan early? Will I incur a fee if I do?',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'loanPayOffOption2',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: 'pl_faq_lp_payoff_early',
                        trackingType: null,
                        showSpeedBump: false,
                    },
                },
            },
            loanPayOffOption1: {
                id: 'loanPayOffOption1',
                tags: ['auto/oem/lrfg/faqs'],
                version: '20240606',
                title: {
                    text: null,
                },
                subTitle: {
                    text: null,
                },
                links: {
                    loanPayOffOption1Item1: {
                        headerText: null,
                        value: '1. Sign in to <1>{{chaseDotCom}}</1> and choose “See a payoff quote” within the “More” menu to see your payoff quote and verify your address. Follow the instructions to submit your payoff by mail or online.',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: 'chaseDotCom',
                        id: 'loanPayOffOption1Item1',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: 'allLinks',
                        icon: null,
                        trackingId: 'pl_faq_lp_payoff_quote_chase',
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    loanPayOffOption1Item2: {
                        headerText: null,
                        value: '2. Make sure to confirm your address before completing your payoff because we will mail your title or lien release to the address that appears on your auto account statements — unless you live in an electronic (paperless) state.',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'loanPayOffOption1Item2',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    loanPayOffOption1Item3: {
                        headerText: null,
                        value: "Florida doesn't generate a lien-free title when the electronic lien is released. You should contact the state's DMV to request a lien-free title.",
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'loanPayOffOption1Item3',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    loanPayOffOption1Item4: {
                        headerText: null,
                        value: '3. Be sure to cancel any automatic payments when you pay off your loan.',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'loanPayOffOption1Item4',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                },
            },
            loanPayOffOption2: {
                id: 'loanPayOffOption2',
                tags: ['auto/oem/lrfg/faqs'],
                version: '20240606',
                title: {
                    text: null,
                },
                subTitle: {
                    text: null,
                },
                links: {
                    loanPayOffOption2Item1: {
                        headerText: null,
                        value: "You can pay off your loan whenever you're ready to and there's no pre-payment penalty for doing so. We don't charge pre-payment fees, even if your contract lists a fee.",
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'loanPayOffOption2Item1',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                },
            },
            onlineAccountEnrollment: {
                id: 'onlineAccountEnrollment',
                tags: ['auto/oem/lrfg/faqs'],
                version: '20240606',
                title: {
                    text: 'ONLINE ACCOUNT ENROLLMENT',
                },
                subTitle: {
                    text: null,
                },
                links: {
                    onlineAccountEnrollmentOption1: {
                        headerText: null,
                        value: 'How do I sign up to manage my Land Rover Financial Group account online?',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'onlineAccountEnrollmentOption1',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: 'pl_faq_oa_sign_up',
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    onlineAccountEnrollmentOption2: {
                        headerText: null,
                        value: 'How can I update my mailing address?',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'onlineAccountEnrollmentOption2',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: 'pl_faq_update_address',
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    onlineAccountEnrollmentOption3: {
                        headerText: null,
                        value: "Why isn't my username and password working?",
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'onlineAccountEnrollmentOption3',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: 'pl_faq_oa_un_password',
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    onlineAccountEnrollmentOption4: {
                        headerText: null,
                        value: 'How do I manage my paperless preferences and other communications?',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'onlineAccountEnrollmentOption4',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: 'pl_faq_oa_paperless',
                        trackingType: null,
                        showSpeedBump: false,
                    },
                },
            },
            onlineAccountEnrollmentOption1: {
                id: 'onlineAccountEnrollmentOption1',
                tags: ['auto/oem/lrfg/faqs'],
                version: '20240606',
                title: {
                    text: null,
                },
                subTitle: {
                    text: null,
                },
                links: {
                    onlineAccountEnrollmentOption1Item1: {
                        headerText: null,
                        value: 'Chase manages your Land Rover Financial Group account. Go to <1>{{chaseDotCom}}</1> to create your username and password.',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: 'chaseDotCom',
                        id: 'onlineAccountEnrollmentOption1Item1',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: 'allLinks',
                        icon: null,
                        trackingId: 'pl_faq_oa_lnk_sign_Chase',
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    onlineAccountEnrollmentOption1Item2: {
                        headerText: null,
                        value: "You'll need your auto account number at the top of your statement. You'll also need your Social Security or Tax ID number.",
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'onlineAccountEnrollmentOption1Item2',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    onlineAccountEnrollmentOption1Item3: {
                        headerText: null,
                        value: 'After you create your username and password at <1>{{chaseDotCom}}</1>, you can sign in securely at <2>{{landRoverFinGroup}}</2>.',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: 'chaseDotCom,landRoverFinGroup',
                        id: 'onlineAccountEnrollmentOption1Item3',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: 'allLinks',
                        icon: null,
                        trackingId: 'pl_faq_oa_lnk_sign_lrfg',
                        trackingType: null,
                        showSpeedBump: false,
                    },
                },
            },
            onlineAccountEnrollmentOption2: {
                id: 'onlineAccountEnrollmentOption2',
                tags: ['auto/oem/lrfg/faqs'],
                version: '20240606',
                title: {
                    text: null,
                },
                subTitle: {
                    text: null,
                },
                links: {
                    onlineAccountEnrollmentOption2Item1: {
                        headerText: null,
                        value: "If you're a loan customer, you can update your address by choosing “Profile & settings” on <1>{{chaseDotCom}}</1>.",
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: 'chaseDotCom',
                        id: 'onlineAccountEnrollmentOption2Item1',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: 'allLinks',
                        icon: null,
                        trackingId: 'pl_faq_lnk_update_chase',
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    onlineAccountEnrollmentOption2Item2: {
                        headerText: null,
                        value: "If you're a lease customer, <1>{{contactUs}}</1> to update your address. We'll let you know how the address change may impact your lease account.",
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: 'contactUs',
                        id: 'onlineAccountEnrollmentOption2Item2',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: 'allLinks',
                        icon: null,
                        trackingId: 'pl_faq_lnk_update_contact',
                        trackingType: null,
                        showSpeedBump: false,
                    },
                },
            },
            onlineAccountEnrollmentOption3: {
                id: 'onlineAccountEnrollmentOption3',
                tags: ['auto/oem/lrfg/faqs'],
                version: '20240606',
                title: {
                    text: null,
                },
                subTitle: {
                    text: null,
                },
                links: {
                    onlineAccountEnrollmentOption3Item1: {
                        headerText: null,
                        value: 'When you get a new vehicle, you may have several online accounts each with a different username and password.',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'onlineAccountEnrollmentOption3Item1',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    onlineAccountEnrollmentOption3Item2: {
                        headerText: null,
                        value: 'For example, in addition to your financial services account,',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'onlineAccountEnrollmentOption3Item2',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    onlineAccountEnrollmentOption3Item3: {
                        headerText: null,
                        value: 'you may have an account through your manufacturer or retailer that gives you access to vehicle information, service appointment scheduling and other items related to your vehicle.',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'onlineAccountEnrollmentOption3Item3',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    onlineAccountEnrollmentOption3Item4: {
                        headerText: null,
                        value: "Make sure the credentials you are using are the ones for <1>{{chaseDotCom}}</1> if you're still having issues,",
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: 'chaseDotCom',
                        id: 'onlineAccountEnrollmentOption3Item4',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: 'allLinks',
                        icon: null,
                        trackingId: 'pl_faq_lnk_update_chase',
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    onlineAccountEnrollmentOption3Item5: {
                        headerText: null,
                        value: 'you can <2>{{contactUs}}</2>',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: 'contactUs',
                        id: 'onlineAccountEnrollmentOption3Item5',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: 'allLinks',
                        icon: null,
                        trackingId: 'pl_faq_lnk_update_contact',
                        trackingType: null,
                        showSpeedBump: false,
                    },
                },
            },
            onlineAccountEnrollmentOption4: {
                id: 'onlineAccountEnrollmentOption4',
                tags: ['auto/oem/lrfg/faqs'],
                version: '20240606',
                title: {
                    text: null,
                },
                subTitle: {
                    text: null,
                },
                links: {
                    onlineAccountEnrollmentOption4Item1: {
                        headerText: null,
                        value: "If you're the primary account holder you can manage your paperless preferences by signing in to <1> {{chaseDotComPaperLess}}</1>. You can then turn paperless statements and letters on or off.",
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: 'chaseDotComPaperLess',
                        id: 'onlineAccountEnrollmentOption4Item1',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: 'allLinks',
                        icon: null,
                        trackingId: 'pl_faq_oa_paperless_chase',
                        trackingType: null,
                        showSpeedBump: false,
                    },
                },
            },
            otherTopics: {
                id: 'otherTopics',
                tags: ['auto/oem/lrfg/faqs'],
                version: '20240606',
                title: {
                    text: 'OTHER TOPICS',
                },
                subTitle: {
                    text: null,
                },
                links: {
                    otherTopicsOption1: {
                        headerText: null,
                        value: 'Is Land Rover Financial Group part of Chase?',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'otherTopicsOption1',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: 'pl_faq_ot_pl_fin_serv',
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    otherTopicsOption2: {
                        headerText: null,
                        value: 'My name is misspelled on my documentation. How do I get it corrected?',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'otherTopicsOption2',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: 'pl_faq_ot_name_correction',
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    otherTopicsOption3: {
                        headerText: null,
                        value: 'can I take the vehicle out of the country?',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'otherTopicsOption3',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: 'pl_faq_ot_out_country',
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    otherTopicsOption4: {
                        headerText: null,
                        value: 'How are tickets and citations handled for my lease account?',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'otherTopicsOption4',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: 'pl_faq_ot_tickets',
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    otherTopicsOption5: {
                        headerText: null,
                        value: 'Why did I receive a billing statement after I turned in my vehicle?',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'otherTopicsOption5',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: 'pl_faq_ot_close_acct',
                        trackingType: null,
                        showSpeedBump: false,
                    },
                },
            },
            otherTopicsOption1: {
                id: 'otherTopicsOption1',
                tags: ['auto/oem/lrfg/faqs'],
                version: '20240606',
                title: {
                    text: null,
                },
                subTitle: {
                    text: null,
                },
                links: {
                    otherTopicsOption1Item1: {
                        headerText: null,
                        value: 'Land Rover Financial Group is the program under which JPMorgan Chase Bank, N.A. (\\"Chase\\") provides auto financing to Land Rover customers, through participating Land Rover retailers.',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'otherTopicsOption1Item1',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    otherTopicsOption1Item2: {
                        headerText: null,
                        value: 'The tradename \\"Land Rover Financial Group\\" and the Land Rover logo are owned by Jaguar Land Rover North America, LLC (JLR) or its affiliates and are licensed to Chase.',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'otherTopicsOption1Item2',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    otherTopicsOption1Item3: {
                        headerText: null,
                        value: 'Chase is not affiliated with JLR or any Land Rover retailer. Neither JLR nor any Land Rover retailer is an agent of Chase. All vehicle sales are through Land Rover retailers.',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'otherTopicsOption1Item3',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                },
            },
            otherTopicsOption2: {
                id: 'otherTopicsOption2',
                tags: ['auto/oem/lrfg/faqs'],
                version: '20240606',
                title: {
                    text: null,
                },
                subTitle: {
                    text: null,
                },
                links: {
                    otherTopicsOption2Item1: {
                        headerText: null,
                        value: '<1>{{contactUs}}</1> to complete the update. Your name will need to be correct if you plan to purchase the vehicle. Please note that address changes cannot be made online.',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: 'contactUs',
                        id: 'otherTopicsOption2Item1',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: 'allLinks',
                        icon: null,
                        trackingId: 'pl_faq_ot_lnk_name_cont',
                        trackingType: null,
                        showSpeedBump: false,
                    },
                },
            },
            otherTopicsOption3: {
                id: 'otherTopicsOption3',
                tags: ['auto/oem/lrfg/faqs'],
                version: '20240606',
                title: {
                    text: null,
                },
                subTitle: {
                    text: null,
                },
                links: {
                    otherTopicsOption3Item1: {
                        headerText: null,
                        value: 'To request permission, call Customer Service at <1>{{callUsNumber}}</1>. We accept operator relay calls. Note: Please <2>{{contactUs}}</2> at least 2 weeks prior to your scheduled departure date.',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: 'callUsNumber,contactUs',
                        id: 'otherTopicsOption3Item1',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: 'allLinks',
                        icon: null,
                        trackingId: 'pl_faq_ot_out_country_cont',
                        trackingType: null,
                        showSpeedBump: false,
                    },
                },
            },
            otherTopicsOption4: {
                id: 'otherTopicsOption4',
                tags: ['auto/oem/lrfg/faqs'],
                version: '20240606',
                title: {
                    text: null,
                },
                subTitle: {
                    text: null,
                },
                links: {
                    otherTopicsOption4Item1: {
                        headerText: null,
                        value: 'You are responsible for all tickets, citations, penalties and fines. At our option, we may pay on your behalf any fine that you have not paid. You must reimburse us for any such amount immediately upon demand.',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'otherTopicsOption4Item1',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    otherTopicsOption4Item2: {
                        headerText: null,
                        value: 'You should contact the issuing authority for questions regarding the ticket, citation, penalty or fee or, if needed, a copy of the ticket or citation.',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'otherTopicsOption4Item2',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                },
            },
            otherTopicsOption5: {
                id: 'otherTopicsOption5',
                tags: ['auto/oem/lrfg/faqs'],
                version: '20240606',
                title: {
                    text: null,
                },
                subTitle: {
                    text: null,
                },
                links: {
                    otherTopicsOption5Item1: {
                        headerText: null,
                        value: 'Billing statements are generated about 2 weeks prior to the due date if the vehicle has not yet been returned and the account closed.',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'otherTopicsOption5Item1',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    otherTopicsOption5Item2: {
                        headerText: null,
                        value: 'If you returned your vehicle, you can disregard the monthly billing statement.',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'otherTopicsOption5Item2',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                },
            },
            paymentOption1: {
                id: 'paymentOption1',
                tags: ['auto/oem/lrfg/faqs'],
                version: '20240606',
                title: {
                    text: null,
                },
                subTitle: {
                    text: null,
                },
                links: {
                    paymentOption1Item1: {
                        headerText: null,
                        value: 'See our<1> {{contactUs}}</1> page for payment addresses.',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: 'contactUs',
                        id: 'paymentOption1Item1',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: 'allLinks',
                        icon: null,
                        trackingId: 'pl_faq_pay_lnk_send_cont',
                        trackingType: null,
                        showSpeedBump: false,
                    },
                },
            },
            paymentOption2: {
                id: 'paymentOption2',
                tags: ['auto/oem/lrfg/faqs'],
                version: '20240606',
                title: {
                    text: null,
                },
                subTitle: {
                    text: null,
                },
                links: {
                    paymentOption2Item1: {
                        headerText: null,
                        value: "After you sign in, choose Make a Payment on your <1>{{accountPage}}</1>. You'll be transferred to <2>{{chaseDotCom}}</2> to make one-time or automatic payments.",
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: 'accountPage,chaseDotCom',
                        id: 'paymentOption2Item1',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: 'allLinks',
                        icon: null,
                        trackingId: 'pl_faq_pay_lnk_acct',
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    paymentOption2Item2: {
                        headerText: null,
                        value: "Keep in mind: Don't use the one-time payment option to make a payoff. This will cause delays in posting the payoff. Refer to the <1>{{loanPayOffFaqs}}</1> for more information on how to make a payoff online.",
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: 'loanPayOffFaqs',
                        id: 'paymentOption2Item2',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: 'allLinks',
                        icon: null,
                        trackingId: 'pl_faq_pay_lnk_payoff_faq',
                        trackingType: null,
                        showSpeedBump: false,
                    },
                },
            },
            paymentOption3: {
                id: 'paymentOption3',
                tags: ['auto/oem/lrfg/faqs'],
                version: '20240606',
                title: {
                    text: null,
                },
                subTitle: {
                    text: null,
                },
                links: {
                    paymentOption3Item1: {
                        headerText: null,
                        value: 'No, you can add a non-Chase or external account. From the Payment page on <1> {{chaseDotCom}}</1>, choose “Add an account” and follow the instructions.',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: 'chaseDotCom',
                        id: 'paymentOption3Item1',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: 'allLinks',
                        icon: null,
                        trackingId: 'pl_faq_pay_lnk_ext_acct',
                        trackingType: null,
                        showSpeedBump: false,
                    },
                },
            },
            paymentOption4: {
                id: 'paymentOption4',
                tags: ['auto/oem/lrfg/faqs'],
                version: '20240606',
                title: {
                    text: null,
                },
                subTitle: {
                    text: null,
                },
                links: {
                    paymentOption4Item1: {
                        headerText: 'para',
                        value: 'Yes, payments and payoffs can be made by wire transfer using the information below. You may be charged a fee by the wire transfer provider for this service.',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'paymentOption4Item1',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    paymentOption4Item2: {
                        headerText: 'datalist',
                        value: 'Bank name: JP Morgan Chase Bank',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'paymentOption4Item2',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    paymentOption4Item3: {
                        headerText: 'datalist',
                        value: 'Routing number: 021000021',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'paymentOption4Item3',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    paymentOption4Item4: {
                        headerText: 'datalist',
                        value: 'Bank entity: CMS',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'paymentOption4Item4',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    paymentOption4Item5: {
                        headerText: 'datalist',
                        value: 'DDA number: 910-2-715928',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'paymentOption4Item5',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    paymentOption4Item6: {
                        headerText: 'datalist',
                        value: 'Account name: CAF EFT Processing',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'paymentOption4Item6',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    paymentOption4Item7: {
                        headerText: 'datalist',
                        value: 'Beneficiary: Loan/Lease account number',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'paymentOption4Item7',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    paymentOption4Item8: {
                        headerText: 'para',
                        value: 'Any additional information the wire transfer provider wants to include may be included in the Beneficiary field.',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'paymentOption4Item8',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    paymentOption4Item9: {
                        headerText: 'para',
                        value: 'If the sending bank requests physical address information for wire',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'paymentOption4Item9',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    paymentOption4Item10: {
                        headerText: 'datalist',
                        value: 'Street: 270 Park Avenue',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'paymentOption4Item10',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    paymentOption4Item11: {
                        headerText: 'datalist',
                        value: 'City: New York',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'paymentOption4Item11',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    paymentOption4Item12: {
                        headerText: 'datalist',
                        value: 'State: NY',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'paymentOption4Item12',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    paymentOption4Item13: {
                        headerText: 'datalist',
                        value: 'Zip Code: 10017',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'paymentOption4Item13',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    paymentOption4Item14: {
                        headerText: 'para',
                        value: 'If sending payoff:',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'paymentOption4Item14',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    paymentOption4Item15: {
                        headerText: 'datalist',
                        value: 'Remitter Section: Include your name and address.',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'paymentOption4Item15',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    paymentOption4Item16: {
                        headerText: 'datalist',
                        value: 'Reference Section: Include your account number, name and note to release title to <name> and <address>.',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'paymentOption4Item16',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                },
            },
            paymentOption5: {
                id: 'paymentOption5',
                tags: ['auto/oem/lrfg/faqs'],
                version: '20240606',
                title: {
                    text: null,
                },
                subTitle: {
                    text: null,
                },
                links: {
                    paymentOption5Item1: {
                        headerText: null,
                        value: 'It depends on how the payment was submitted to us.',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'paymentOption5Item1',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    paymentOption5Item2: {
                        headerText: null,
                        value: "In general, payments will be credited to the account as of the day they're received by us, as long as they're received during business hours or for online payments, prior to the processing cut-off time.",
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'paymentOption5Item2',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    paymentOption5Item3: {
                        headerText: null,
                        value: 'In some cases, missing or incomplete information may cause delays.',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'paymentOption5Item3',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                },
            },
            paymentOption6: {
                id: 'paymentOption6',
                tags: ['auto/oem/lrfg/faqs'],
                version: '20240606',
                title: {
                    text: null,
                },
                subTitle: {
                    text: null,
                },
                links: {
                    paymentOption6Item1: {
                        headerText: null,
                        value: 'Posting overnight payments usually takes up to 3 business days if all the account information needed to post the payment is included.',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'paymentOption6Item1',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    paymentOption6Item2: {
                        headerText: null,
                        value: 'There may be delays if your payment is missing information, such as your account number or name.',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'paymentOption6Item2',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    paymentOption6Item3: {
                        headerText: null,
                        value: 'Keep in mind: Even though the posting may be delayed, the payment will be applied the date it was received for processing.',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'paymentOption6Item3',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                },
            },
            paymentOption7: {
                id: 'paymentOption7',
                tags: ['auto/oem/lrfg/faqs'],
                version: '20240606',
                title: {
                    text: null,
                },
                subTitle: {
                    text: null,
                },
                links: {
                    paymentOption7Item1: {
                        headerText: null,
                        value: 'For loans: Any payment received is first applied to any interest due, then to any principal due and then to any outstanding fees.',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'paymentOption7Item1',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    paymentOption7Item2: {
                        headerText: null,
                        value: 'After paying the amount due and fees, any remaining amount is applied to reduce the principal balance.',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'paymentOption7Item2',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    paymentOption7Item3: {
                        headerText: null,
                        value: 'For lease: The payment is first applied to any amount due on the account, then any outstanding fees. Any amount remaining will be applied to the next payment due.',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'paymentOption7Item3',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    paymentOption7Item4: {
                        headerText: null,
                        value: "Keep in mind: Paying more than the amount due won't reduce the amount you're required to pay over the life of the lease.",
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'paymentOption7Item4',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                },
            },
            paymentOption8: {
                id: 'paymentOption8',
                tags: ['auto/oem/lrfg/faqs'],
                version: '20240606',
                title: {
                    text: null,
                },
                subTitle: {
                    text: null,
                },
                links: {
                    paymentOption8Item1: {
                        headerText: null,
                        value: 'You can cancel a payment you\'ve scheduled online as long as the payment isn\'t already in a \\"Sent\\" or \\"Complete\\" status.',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'paymentOption8Item1',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    paymentOption8Item2: {
                        headerText: null,
                        value: 'Sign in to <1>{{chaseDotCom}}</1> and view your “Payment activity” under “Pay & Transfer” to cancel.',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: 'chaseDotCom',
                        id: 'paymentOption8Item2',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: 'allLinks',
                        icon: null,
                        trackingId: 'pl_faq_pay_lnk_chase_cncl',
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    paymentOption8Item3: {
                        headerText: null,
                        value: 'Keep in mind: The cutoff time to cancel a payment is: 11:00 PM Eastern time on any business day for payments from a Chase checking or prepaid account',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'paymentOption8Item3',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    paymentOption8Item4: {
                        headerText: null,
                        value: '8:00 PM Eastern time on any business day for payments from a non-Chase checking account',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'paymentOption8Item4',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                },
            },
            paymentOption9: {
                id: 'paymentOption9',
                tags: ['auto/oem/lrfg/faqs'],
                version: '20240606',
                title: {
                    text: null,
                },
                subTitle: {
                    text: null,
                },
                links: {
                    paymentOption9Item1: {
                        headerText: null,
                        value: 'Sign in to <1>{{chaseDotCom}}</1> and go the \\"More\\" menu, then choose \\"Update settings and preferences\\", and then \\"Payment due date\\" and follow the instructions.',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: 'chaseDotCom',
                        id: 'paymentOption9Item1',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: 'allLinks',
                        icon: null,
                        trackingId: 'pl_faq_pay_lnk_chase_date',
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    paymentOption9Item2: {
                        headerText: null,
                        value: 'Keep in mind: You can only change your due date 2 times during the life of the loan and only once during the life of a lease. Additional restrictions apply.',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'paymentOption9Item2',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                },
            },
            payments: {
                id: 'payments',
                tags: ['auto/oem/lrfg/faqs'],
                version: '20240606',
                title: {
                    text: 'PAYMENTS',
                },
                subTitle: {
                    text: null,
                },
                links: {
                    paymentOption1: {
                        headerText: null,
                        value: 'Where do I send my monthly payment?',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'paymentOption1',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: 'pl_faq_pay_send',
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    paymentOption2: {
                        headerText: null,
                        value: 'How can I make a payment online?',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'paymentOption2',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: 'pl_faq_pay_online',
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    paymentOption3: {
                        headerText: null,
                        value: 'Am I required to have a Chase checking account to make an online payment?',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'paymentOption3',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: 'pl_faq_pay_chase_acct',
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    paymentOption4: {
                        headerText: null,
                        value: 'Can I make payments and payoffs by wire transfer?',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'paymentOption4',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: 'pl_faq_pay_wire',
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    paymentOption5: {
                        headerText: null,
                        value: 'How long does it take to post my payment to my auto account?',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'paymentOption5',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: 'pl_faq_pay_post',
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    paymentOption6: {
                        headerText: null,
                        value: "Why hasn't my overnight payment posted to my account yet?",
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'paymentOption6',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: 'pl_faq_pay_overnight',
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    paymentOption7: {
                        headerText: null,
                        value: 'How is my payment allocated to my account?',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'paymentOption7',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: 'pl_faq_pay_allocation',
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    paymentOption8: {
                        headerText: null,
                        value: 'Can I cancel a payment that was previously scheduled online?',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'paymentOption8',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: 'pl_faq_pay_cancel',
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    paymentOption9: {
                        headerText: null,
                        value: 'How can I change the payment due date?',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'paymentOption9',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: 'pl_faq_pay_change_date',
                        trackingType: null,
                        showSpeedBump: false,
                    },
                },
            },
            searchResultText: {
                id: 'searchResultText',
                tags: ['auto/oem/lrfg/faqs'],
                version: '20240606',
                title: {
                    text: null,
                },
                subTitle: {
                    text: null,
                },
                links: {
                    searchResultText1: {
                        headerText: null,
                        value: 'Showing <1>{{searchCounts}}</1> result for <2>{{searchValue }}</2>',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'searchResultText1',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    searchResultText2: {
                        headerText: null,
                        value: 'No results found. Try a different search or <1>{{contactUs}}</1>',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: 'contactUs',
                        id: 'searchResultText2',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: 'allLinks',
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                },
            },
            titleAndLienRelease: {
                id: 'titleAndLienRelease',
                tags: ['auto/oem/lrfg/faqs'],
                version: '20240606',
                title: {
                    text: 'TITLE & LIEN RELEASE',
                },
                subTitle: {
                    text: null,
                },
                links: {
                    titleAndLienReleaseOption1: {
                        headerText: null,
                        value: 'Where can I find more information about the payoff process and the title/lien release?',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'titleAndLienReleaseOption1',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: 'pl_faq_tlr_info',
                        trackingType: null,
                        showSpeedBump: false,
                    },
                },
            },
            titleAndLienReleaseOption1: {
                id: 'titleAndLienReleaseOption1',
                tags: ['auto/oem/lrfg/faqs'],
                version: '20240606',
                title: {
                    text: null,
                },
                subTitle: {
                    text: null,
                },
                links: {
                    titleAndLienReleaseOption1Item1: {
                        headerText: null,
                        value: 'Visit<1>{{chaseDotCom}}</1> to learn more.',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: 'chaseDotCom',
                        id: 'titleAndLienReleaseOption1Item1',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: 'allLinks',
                        icon: null,
                        trackingId: 'pl_faq_tlr_lnk_info_chase',
                        trackingType: null,
                        showSpeedBump: false,
                    },
                },
            },
        },
        carousel: {},
        iconList: {
            FooterList: {
                tags: ['auto/oem/lrfg/faqs'],
                id: 'FooterList',
                title: {
                    html: null,
                    text: null,
                    markdown: null,
                },
                subTitle: {
                    html: null,
                    text: null,
                    markdown: null,
                },
                version: '20240606',
                icons: [],
            },
        },
        legalContainer: {},
        dialog: {},
        contentList: {
            leaseEndContentList2: {
                id: 'leaseEndContentList2',
                title: {
                    html: '<p>Some states assess sales tax on the purchase price, and you may be required to pay the sales tax to the state motor vehicle titling agency when you transfer the title. Please check with your state motor vehicle titling agency for more information.</p>\n',
                    text: 'Some states assess sales tax on the purchase price, and you may be required to pay the sales tax to the state motor vehicle titling agency when you transfer the title. Please check with your state motor vehicle titling agency for more information.',
                    markdown: 'Some states assess sales tax on the purchase price, and you may be required to pay the sales tax to the state motor vehicle titling agency when you transfer the title. Please check with your state motor vehicle titling agency for more information.\n',
                },
                tags: ['auto/oem/lrfg/faqs'],
                version: '20240606',
                items: {},
            },
        },
        tile: {
            needAdditionalHelp: {
                tags: ['auto/oem/lrfg/faqs'],
                id: 'needAdditionalHelp',
                variationId: null,
                title: {
                    html: '<p>NEED ADDITIONAL HELP?</p>\n',
                    text: 'NEED ADDITIONAL HELP?',
                    markdown: 'NEED ADDITIONAL HELP?\n',
                },
                subTitle: {
                    html: null,
                    text: null,
                    markdown: null,
                },
                version: '20240606',
                body: {
                    html: null,
                    text: null,
                    markdown: null,
                },
                additionalText: {
                    html: null,
                    text: null,
                    markdown: null,
                },
                hat: null,
                iconImage: {
                    altText: null,
                    id: null,
                    source: '/content/dam/unified-assets/iconography/auto/ico_auto_people_group.svg',
                    url: 'https://static2-qa2.chasecdn.com/content/dam/unified-assets/iconography/auto/ico_auto_people_group.svg',
                },
                primaryImage: null,
                primaryImageWithRendition: null,
                secondaryImageWithRendition: null,
                links: {
                    contactUS: {
                        value: 'CONTACT US',
                        type: null,
                        languageCode: null,
                        disabled: null,
                        active: null,
                        underLined: null,
                        inversed: null,
                        presentationType: null,
                        url: '/contact-us',
                        esUrl: null,
                        id: 'contactUS',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                        headerText: null,
                        visibleIn: null,
                        variant: null,
                    },
                },
            },
        },
        structuredContentList: {},
        header: {
            faqHeader: {
                tags: ['auto/oem/lrfg/faqs'],
                id: 'faqHeader',
                title: {
                    html: '<p>FAQS</p>\n',
                    text: 'FAQS',
                },
                subTitle: {
                    html: null,
                    text: null,
                },
                version: '20240606',
                search: {
                    label: 'Search frequently asked questions',
                    placeHolder: 'Search',
                    hat: null,
                    helpText: null,
                },
                logo: {
                    desktop: null,
                    mobile: null,
                    tablet: null,
                },
            },
        },
    },
};
