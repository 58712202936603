// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { http, HttpResponse } from 'msw';
import userList from './mock_files/userList.json';
import { AEM_TAGS, API_URLS } from '../src/constants/constants';
import { extendYourLease } from './mock_files/aem/extendYourLease_20240606';
import { commonPage } from './mock_files/aem/commonPage-20240606';
import { homePage } from './mock_files/aem/homePage-20240606';
import { leaseEnd } from './mock_files/aem/leaseEndPage-20240606';
import { loanEnd } from './mock_files/aem/loanEnd-20240606';
import { siteMap } from './mock_files/aem/siteMap-20240606';
import { digitalTurnIn } from './mock_files/aem/digitalTurnIn-20240606';
import { financingOffersPage } from './mock_files/aem/financingOffersPage-20240606';
import { errorPage } from './mock_files/aem/errorPage-20240606';
import { leaseExplore } from './mock_files/aem/leaseExplorePage-20240606';
import { contactUs } from './mock_files/aem/contactUs-20240606';
import { preApprovalData } from './mock_files/aem/preApproval-20240606';
import { newCustomer } from './mock_files/aem/newCustomer-20240606';
import { faqsPage } from './mock_files/aem/faqsPage-20240606';
import { returnPage } from './mock_files/aem/returnPage-20240606';
import { accounts } from './mock_files/aem/accountsPage-20240606';
export const handlers = [
    http.get(API_URLS.MyAccount, () => {
        console.log('Mocked an authentication call.');
        return HttpResponse.json(userList);
    }),
    http.post(`/userList`, ({ request }) => {
        return HttpResponse.json(userList);
    }),
    http.post('/error', ({ request }) => {
        if (!request.headers.has('cookie')) {
            throw new HttpResponse(null, { status: 400 });
        }
    }),
    http.post(API_URLS.Logger, ({ request }) => {
        return HttpResponse.json({ status: 'ok' });
    }),
    http.post(API_URLS.Header, ({ request }) => {
        return HttpResponse.json(false);
    }),
    http.get('https://cms.test.mock/content/pq/auto/lrfg', (info) => {
        const { request } = info;
        const url = new URL(request.url);
        const tag = url.searchParams.get('tag');
        switch (tag) {
            case AEM_TAGS.EXTENDLEASEPAGE_TAG:
                return HttpResponse.json(extendYourLease);
            case AEM_TAGS.COMMON_TAG:
                return HttpResponse.json(commonPage);
            case AEM_TAGS.HOMEPAGE_TAG:
                return HttpResponse.json(homePage);
            case AEM_TAGS.LEASEPAGE_TAG:
                return HttpResponse.json(leaseEnd);
            case AEM_TAGS.LOANPAGE_TAG:
                return HttpResponse.json(loanEnd);
            case AEM_TAGS.DIGITAL_LEASE_TURNIN_TAG:
                return HttpResponse.json(digitalTurnIn);
            case AEM_TAGS.SITEMAPPAGE_TAG:
                return HttpResponse.json(siteMap);
            case AEM_TAGS.FINANCING_AND_OFFERS_TAG:
                return HttpResponse.json(financingOffersPage);
            case AEM_TAGS.ERROR_PAGE_TAG:
                return HttpResponse.json(errorPage);
            case AEM_TAGS.LEASE_EXPLORE_NEW_LANDROVER_TAG:
                return HttpResponse.json(leaseExplore);
            case AEM_TAGS.CONTACTUS_TAG:
                return HttpResponse.json(contactUs);
            case AEM_TAGS.PREAPPROVALPAGE_TAG:
                return HttpResponse.json(preApprovalData);
            case AEM_TAGS.NEWCUSTOMERPAGE_TAG:
                return HttpResponse.json(newCustomer);
            case AEM_TAGS.FAQSPAGE_TAG:
                return HttpResponse.json(faqsPage);
            case AEM_TAGS.RETURNPAGE_TAG:
                return HttpResponse.json(returnPage);
            case AEM_TAGS.ACCOUNTS_TAG:
                return HttpResponse.json(accounts);
            default:
                return HttpResponse.json([]);
        }
    }),
];
