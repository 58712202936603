export const loanEnd = {
    data: {
        linkList: {
            checkYourMailingAddress: {
                id: 'checkYourMailingAddress',
                tags: ['auto/oem/lrfg/loanend'],
                version: '20240606',
                title: {
                    text: '03',
                },
                subTitle: {
                    text: 'CHECK YOUR MAILING ADDRESS',
                },
                links: {
                    getPayoffQuote1Option1: {
                        headerText: null,
                        value: 'We’ll mail your title or lien release to the mailing address that appears on your auto billing statements. Make sure to review your address with us and the DMV, and make any necessary updates before completing your payoff.',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'getPayoffQuote1Option1',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                },
            },
            endOfLoanLinkList: {
                id: 'endOfLoanLinkList',
                tags: ['auto/oem/lrfg/loanend'],
                version: '20240606',
                title: {
                    text: null,
                },
                subTitle: {
                    text: null,
                },
                links: {
                    chaseDotCom: {
                        headerText: 'anquer',
                        value: 'chase.com',
                        type: null,
                        presentationType: null,
                        url: 'https://www.chase.com/personal/auto-loans/servicing?jp_cdf=LRFG',
                        esUrl: null,
                        id: 'chaseDotCom',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: true,
                    },
                    faqs: {
                        headerText: 'anquer',
                        value: 'FAQS',
                        type: null,
                        presentationType: null,
                        url: '/faq',
                        esUrl: null,
                        id: 'faqs',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                },
            },
            getPayoffQuote: {
                id: 'getPayoffQuote',
                tags: ['auto/oem/lrfg/loanend'],
                version: '20240606',
                title: {
                    text: null,
                },
                subTitle: {
                    text: null,
                },
                links: {
                    getPayoffQuote1: {
                        headerText: null,
                        value: 'If you haven’t received a maturity notice from us, or you want to pay off your loan early, you’ll want to request a payoff quote.',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'getPayoffQuote1',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    getPayoffQuote2: {
                        headerText: null,
                        value: 'You won’t be charged a penalty for paying off your loan early, even if your contract lists a fee.',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'getPayoffQuote2',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                },
            },
            getPayoffQuote1: {
                id: 'getPayoffQuote1',
                tags: ['auto/oem/lrfg/loanend'],
                version: '20240606',
                title: {
                    text: null,
                },
                subTitle: {
                    text: null,
                },
                links: {
                    getPayoffQuote1Option1: {
                        headerText: null,
                        value: 'Sign in to <1>{{chaseDotCom}}</1> and choose your auto account.',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: 'chaseDotCom',
                        id: 'getPayoffQuote1Option1',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: 'endOfLoanLinkList',
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    getPayoffQuote1Option2: {
                        headerText: null,
                        value: 'Go to the “More” menu and choose “See a payoff quote.”',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'getPayoffQuote1Option2',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                },
            },
            loanEndQuickLinksList: {
                id: 'loanEndQuickLinksList',
                tags: ['auto/oem/lrfg/loanend'],
                version: '20240606',
                title: {
                    text: 'QUICK LINKS',
                },
                subTitle: {
                    text: null,
                },
                links: {
                    quickLinks1: {
                        headerText: null,
                        value: 'EXPLORE A NEW LAND ROVER',
                        type: null,
                        presentationType: null,
                        url: 'https://inventory.landroverusa.com/new/',
                        esUrl: null,
                        id: 'quickLinks1',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    quickLinks2: {
                        headerText: null,
                        value: 'MANAGE YOUR ACCOUNT',
                        type: null,
                        presentationType: null,
                        url: '/myaccount',
                        esUrl: null,
                        id: 'quickLinks2',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                },
            },
            stepsForEndofLoan: {
                id: 'stepsForEndofLoan',
                tags: ['auto/oem/lrfg/loanend'],
                version: '20240606',
                title: {
                    text: null,
                },
                subTitle: {
                    text: null,
                },
                links: {
                    turnOffYourAutoPayments: {
                        headerText: null,
                        value: 'TURN OFF YOUR AUTOMATIC PAYMENTS',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'turnOffYourAutoPayments',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    getPayoffQuote: {
                        headerText: null,
                        value: 'GET A PAYOFF QUOTE',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'getPayoffQuote',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    checkYourMailingAddress: {
                        headerText: null,
                        value: 'CHECK YOUR MAILING ADDRESS',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'checkYourMailingAddress',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    receiveYourTitile: {
                        headerText: null,
                        value: 'RECEIVE YOUR TITLE OR LIEN RELEASE',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'receiveYourTitile',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                },
            },
            turnOffYourAutoPayments: {
                id: 'turnOffYourAutoPayments',
                tags: ['auto/oem/lrfg/loanend'],
                version: '20240606',
                title: {
                    text: null,
                },
                subTitle: {
                    text: null,
                },
                links: {
                    turnOffYourAutoPayments1: {
                        headerText: null,
                        value: 'If you have automatic payments through Chase, go to your account at <1>{{chaseDotCom}}</>, choose “Pay loan” and turn them off.',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: 'chaseDotCom',
                        id: 'turnOffYourAutoPayments1',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: 'endOfLoanLinkList',
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    turnOffAutomaticPayments2: {
                        headerText: null,
                        value: 'If you have automatic payments through another bank, go there to turn them off.',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'turnOffAutomaticPayments2',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    turnOffAutomaticPayments3: {
                        headerText: null,
                        value: 'Keep in mind: We can’t turn them off for you. If you don’t turn off your automatic payments, you may be overcharged.',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'turnOffAutomaticPayments3',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                },
            },
        },
        carousel: {
            loanEndExploreMoreTiles: {
                brands: null,
                segments: null,
                tags: ['auto/oem/lrfg/loanend'],
                id: 'loanEndExploreMoreTiles',
                title: {
                    html: '<p>READY FOR A NEW LAND ROVER EXPERIENCE?</p>\n',
                    text: 'READY FOR A NEW LAND ROVER EXPERIENCE?',
                    markdown: 'READY FOR A NEW LAND ROVER EXPERIENCE?\n',
                },
                subTitle: {
                    html: null,
                    text: null,
                    markdown: null,
                },
                version: '20240606',
                heroes: [],
                tiles: {
                    exploreMoreTile1: {
                        brands: null,
                        segments: null,
                        tags: ['ccb-cf:auto/oem/lrfg/loanend'],
                        id: 'exploreMoreTile1',
                        variationId: null,
                        title: {
                            html: '<p>EXPLORE A NEW LAND ROVER</p>\n',
                            text: 'EXPLORE A NEW LAND ROVER',
                            markdown: 'EXPLORE A NEW LAND ROVER\n',
                        },
                        subTitle: {
                            html: '<p>Let Land Rover exceed your expectations. Learn what you need to know to purchase or lease a Land Rover.</p>\n',
                            text: 'Let Land Rover exceed your expectations. Learn what you need to know to purchase or lease a Land Rover.',
                            markdown: 'Let Land Rover exceed your expectations. Learn what you need to know to purchase or lease a Land Rover.\n',
                        },
                        version: '20240606',
                        body: {
                            html: null,
                            text: null,
                            markdown: null,
                        },
                        additionalText: {
                            html: null,
                            text: null,
                            markdown: null,
                        },
                        hat: null,
                        iconImage: {
                            altText: null,
                            id: 'exploreMoreTile1Image',
                            source: null,
                            url: null,
                        },
                        primaryImage: {
                            altText: 'exploreNewLandRoverText',
                            id: null,
                            source: '/content/dam/unified-assets/photography/partner/land-rover/optimized/LoanEnd_ExploreANewLandRover.jpg',
                            url: 'https://static2-qa2.chasecdn.com/content/dam/unified-assets/photography/partner/land-rover/optimized/LoanEnd_ExploreANewLandRover.jpg',
                        },
                        primaryImageWithRendition: null,
                        secondaryImageWithRendition: null,
                        links: {
                            exploreMoreLink: {
                                value: 'GET STARTED',
                                type: null,
                                languageCode: null,
                                disabled: null,
                                active: null,
                                underLined: null,
                                inversed: null,
                                presentationType: null,
                                url: '/financing',
                                esUrl: null,
                                id: 'exploreMoreLink',
                                hat: null,
                                navigationKeyId: null,
                                target: null,
                                iconId: null,
                                icon: null,
                                trackingId: null,
                                trackingType: null,
                                showSpeedBump: null,
                                headerText: null,
                            },
                        },
                    },
                    exploreMoreTile2: {
                        brands: null,
                        segments: null,
                        tags: ['ccb-cf:auto/oem/lrfg/loanend'],
                        id: 'exploreMoreTile2',
                        variationId: null,
                        title: {
                            html: '<p>LAND ROVER FINANCE OFFERS</p>\n',
                            text: 'LAND ROVER FINANCE OFFERS',
                            markdown: 'LAND ROVER FINANCE OFFERS\n',
                        },
                        subTitle: {
                            html: "<p>Find the land rover vehicle that's perfect for you with our latest special offers.</p>\n",
                            text: "Find the land rover vehicle that's perfect for you with our latest special offers.",
                            markdown: "Find the land rover vehicle that's perfect for you with our latest special offers.\n",
                        },
                        version: '20240606',
                        body: {
                            html: null,
                            text: null,
                            markdown: null,
                        },
                        additionalText: {
                            html: null,
                            text: null,
                            markdown: null,
                        },
                        hat: null,
                        iconImage: {
                            altText: null,
                            id: 'exploreMoreTile2Image',
                            source: null,
                            url: null,
                        },
                        primaryImage: {
                            altText: 'landRoverFinanceText',
                            id: null,
                            source: '/content/dam/unified-assets/photography/partner/land-rover/optimized/LoanEnd_LandRoverFinanceOffers.jpg',
                            url: 'https://static2-qa2.chasecdn.com/content/dam/unified-assets/photography/partner/land-rover/optimized/LoanEnd_LandRoverFinanceOffers.jpg',
                        },
                        primaryImageWithRendition: null,
                        secondaryImageWithRendition: null,
                        links: {
                            exploreMoreLink: {
                                value: 'LEARN MORE',
                                type: null,
                                languageCode: null,
                                disabled: null,
                                active: null,
                                underLined: null,
                                inversed: null,
                                presentationType: null,
                                url: 'https://www.landroverusa.com/current-offers/index.html',
                                esUrl: null,
                                id: 'exploreMoreLink',
                                hat: null,
                                navigationKeyId: 'Untimed Modal',
                                target: null,
                                iconId: null,
                                icon: null,
                                trackingId: null,
                                trackingType: null,
                                showSpeedBump: null,
                                headerText: null,
                            },
                        },
                    },
                },
            },
        },
        iconList: {},
        legalContainer: {},
        dialog: {},
        contentList: {},
        tile: {
            loadEnd: {
                tags: ['auto/oem/lrfg/loanend'],
                id: 'loadEnd',
                variationId: null,
                title: {
                    html: '<p>END OF LOAN OR EARLY PAYOFF</p>\n',
                    text: 'END OF LOAN OR EARLY PAYOFF',
                    markdown: 'END OF LOAN OR EARLY PAYOFF\n',
                },
                subTitle: {
                    html: null,
                    text: null,
                    markdown: null,
                },
                version: '20240502',
                body: {
                    html: null,
                    text: null,
                    markdown: null,
                },
                additionalText: {
                    html: null,
                    text: null,
                    markdown: null,
                },
                hat: null,
                iconImage: null,
                primaryImage: null,
                primaryImageWithRendition: null,
                secondaryImageWithRendition: null,
                links: [],
            },
        },
        structuredContentList: {},
    },
};
