export const returnPage = {
    data: {
        linkList: {
            completeYourLandRoverReturn: {
                id: 'completeYourLandRoverReturn',
                tags: ['auto/oem/lrfg/returnyourlandrover'],
                version: '20240606',
                title: {
                    text: 'COMPLETE YOUR LAND ROVER RETURN',
                },
                subTitle: {
                    text: null,
                },
                links: {
                    finishStepsToCompleteReturnOptional: {
                        headerText: null,
                        value: null,
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'finishStepsToCompleteReturnOptional',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                },
            },
            daysBeforeYouReturnVehicle: {
                id: 'daysBeforeYouReturnVehicle',
                tags: ['auto/oem/lrfg/returnyourlandrover'],
                version: '20240606',
                title: {
                    text: '14 DAYS BEFORE YOU TURN IN YOUR VEHICLE',
                },
                subTitle: {
                    text: null,
                },
                links: {
                    turnOffAutomaticPayments: {
                        headerText: null,
                        value: 'TURN OFF AUTOMATIC PAYMENTS',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'turnOffAutomaticPayments',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    scheduleTheReturnOfVehicle: {
                        headerText: null,
                        value: 'SCHEDULE THE RETURN OF YOUR VEHICLE',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'scheduleTheReturnOfVehicle',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    findLocalDmvRequirements: {
                        headerText: null,
                        value: 'FIND OUT LOCAL DMV REQUIREMENTS FOR TURN-IN',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'findLocalDmvRequirements',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                },
            },
            evaluateWearAndUse: {
                id: 'evaluateWearAndUse',
                tags: ['auto/oem/lrfg/returnyourlandrover'],
                version: '20240606',
                title: {
                    text: 'EVALUATE WEAR AND USE',
                },
                subTitle: {
                    text: 'As you get to the end of your lease, we want to help you make your turn-in as simple as possible. As detailed in your Lease Agreement, we may bill you for the following excess wear and use items:',
                },
                links: {
                    evaluateWearAndUseOption1: {
                        headerText: null,
                        value: 'Holes, tears, burns, stains, strong odors or excessively worn areas in the carpet, other interior surfaces or convertible top.',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'evaluateWearAndUseOption1',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    evaluateWearAndUseOption2: {
                        headerText: null,
                        value: 'Scratches, chips, dents, pits, rust, holes or cracks in the wheels or exterior surfaces, windshield or other glass surfaces or metal work.',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'evaluateWearAndUseOption2',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    evaluateWearAndUseOption3: {
                        headerText: null,
                        value: 'Cracked, broken, or missing windows, doors, lights, trim, mirrors, or antennae.',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'evaluateWearAndUseOption3',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    evaluateWearAndUseOption4: {
                        headerText: null,
                        value: 'Missing, damaged or nonworking mechanical equipment, safety or emissions control equipment, electrical or other parts or accessories.',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'evaluateWearAndUseOption4',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    evaluateWearAndUseOption5: {
                        headerText: null,
                        value: "Damage to the vehicle's frame or alignment.",
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'evaluateWearAndUseOption5',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    evaluateWearAndUseOption6: {
                        headerText: null,
                        value: 'Damage due to improper, poor quality or incomplete repairs or paint work. You also will remain liable to us for the full cost of any such items resulting from intentional or willful abuse or misuse.',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'evaluateWearAndUseOption6',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    evaluateWearAndUseOption7: {
                        headerText: null,
                        value: 'Any wear and use related to tires. Tire tread must be at least 1/8” (4/32”) at the lowest point and may not contain any other damages. Make sure that all tires match and are comparable quality to the original equipment. For tires to be considered properly',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'evaluateWearAndUseOption7',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    evaluateWearAndUseOption8: {
                        headerText: null,
                        value: 'Any missing manuals or missing or nonworking keys or remote entry devices.',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'evaluateWearAndUseOption8',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                },
            },
            findLocalDmvRequirements: {
                id: 'findLocalDmvRequirements',
                tags: ['auto/oem/lrfg/returnyourlandrover'],
                version: '20240606',
                title: {
                    text: 'FIND OUT LOCAL DMV REQUIREMENTS FOR TURN-IN',
                },
                subTitle: {
                    text: null,
                },
                links: {
                    findLocalDmvRequirementsOption1: {
                        headerText: null,
                        value: 'Contact your local Department of Motor Vehicles to see if there are specific turn-in requirements for your area. Your retailer may assist during your turn-in appointment, but it’s your responsibility to complete the state requirements.',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'findLocalDmvRequirementsOption1',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                },
            },
            finishStepsToCompleteReturn: {
                id: 'finishStepsToCompleteReturn',
                tags: ['auto/oem/lrfg/returnyourlandrover'],
                version: '20240606',
                title: {
                    text: 'FINISH THESE STEPS TO COMPLETE YOUR RETURN',
                },
                subTitle: {
                    text: null,
                },
                links: {
                    finishStepsToCompleteReturnOption1: {
                        headerText: null,
                        value: 'Complete the <1>{{digitalLeaseTurnInForm}}</1> or <2>{{contactUs}}</2> within 1 business day of vehicle turn-in to ensure that we have the correct date and information to calculate your lease-end bill, if you have one.',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: 'digitalLeaseTurnInForm,contactUs',
                        id: 'finishStepsToCompleteReturnOption1',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: 'returnYourLandRoverLinkList',
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    finishStepsToCompleteReturnOption2: {
                        headerText: null,
                        value: 'Pay your lease-end bill. If you have charges at the end of your lease, your bill will arrive 30–60 days after the vehicle is returned. If you turn-in your vehicle early, or there’s a situation where the vehicle has to be sold, expect your bill within 90–1',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'finishStepsToCompleteReturnOption2',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                },
            },
            finishStepsToCompleteReturnOptional: {
                id: 'finishStepsToCompleteReturnOptional',
                tags: ['auto/oem/lrfg/returnyourlandrover'],
                version: '20240606',
                title: {
                    text: null,
                },
                subTitle: {
                    text: null,
                },
                links: {
                    finishStepsToCompleteReturn: {
                        headerText: null,
                        value: 'FINISH THESE STEPS TO COMPLETE YOUR RETURN',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'finishStepsToCompleteReturn',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                },
            },
            forms: {
                id: 'forms',
                tags: ['auto/oem/lrfg/returnyourlandrover'],
                version: '20240606',
                title: {
                    text: 'FORMS',
                },
                subTitle: {
                    text: null,
                },
                links: {
                    formsOption1: {
                        headerText: null,
                        value: 'DIGITAL LEASE TURN-IN FORM ',
                        type: null,
                        presentationType: null,
                        url: '/lease-end-turnin',
                        esUrl: null,
                        id: 'formsOption1',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: 'pl_le_ql_turnin',
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    formsOption2: {
                        headerText: null,
                        value: 'ODOMETER STATEMENT',
                        type: null,
                        presentationType: null,
                        url: 'https://www.chase.com/content/dam/chase-ux/documents/personal/auto-loans/odometer_statement_lease.pdf?jp_cdf=LRFG',
                        esUrl: null,
                        id: 'formsOption2',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: 'pl_le_ql_odo_Stm',
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    formsOption3: {
                        headerText: null,
                        value: 'PRINTABLE LAND ROVER RETURN CHECKLIST',
                        type: null,
                        presentationType: null,
                        url: 'https://lrfg-uat.chase.com/oem/land-rover/Land-Rover-Lease-End-Checklist-2022.pdf',
                        esUrl: null,
                        id: 'formsOption3',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: 'pl_le_ql_stn_chklst',
                        trackingType: null,
                        showSpeedBump: false,
                    },
                },
            },
            getYourVehicleReady: {
                id: 'getYourVehicleReady',
                tags: ['auto/oem/lrfg/returnyourlandrover'],
                version: '20240606',
                title: {
                    text: 'GET YOUR VEHICLE READY TO TURN IN',
                },
                subTitle: {
                    text: null,
                },
                links: {
                    getYourVehicleReadyOption1: {
                        headerText: null,
                        value: 'Remove all of your personal items from the vehicle.',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'getYourVehicleReadyOption1',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    getYourVehicleReadyOption2: {
                        headerText: null,
                        value: 'Clear any personal information from the technology in the vehicle.',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'getYourVehicleReadyOption2',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                },
            },
            landRoverLoayalityProgramContentList: {
                id: 'landRoverLoayalityProgramContentList',
                tags: ['auto/oem/lrfg/returnyourlandrover'],
                version: '20240606',
                title: {
                    text: null,
                },
                subTitle: {
                    text: null,
                },
                links: {
                    toTakePartOfTheProgramOption1: {
                        headerText: null,
                        value: 'Return your current leased vehicle.',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'toTakePartOfTheProgramOption1',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    toTakePartOfTheProgramOption2: {
                        headerText: null,
                        value: 'Finance or lease a new vehicle through Land Rover Financial Group within 30 days of turning in your leased vehicle.',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'toTakePartOfTheProgramOption2',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                },
            },
            landRoverLoayalityProgramList: {
                id: 'landRoverLoayalityProgramList',
                tags: ['auto/oem/lrfg/returnyourlandrover'],
                version: '20240606',
                title: {
                    text: 'LAND ROVER LOYALTY PROGRAM',
                },
                subTitle: {
                    text: null,
                },
                links: {
                    landRoverLoayalityProgramOption1: {
                        headerText: null,
                        value: 'If you currently lease your Land Rover, you may qualify for the Loyalty Disposition Fee Waiver Program.<1>{{royaltyProgramSup}}</1>',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: 'royaltyProgramSup',
                        id: 'landRoverLoayalityProgramOption1',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: 'returnYourLandRoverLinkList',
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    landRoverLoayalityProgramOption2: {
                        headerText: null,
                        value: "The disposition fee is commonly charged if you don't purchase the vehicle—it covers the vehicle’s transport and sale costs. With this program, any disposition fee you may owe under your current lease may be waived.",
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'landRoverLoayalityProgramOption2',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    toTakePartOfTheProgram: {
                        headerText: null,
                        value: 'To take part of the program, you’ll need to:',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'toTakePartOfTheProgram',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                },
            },
            notifyRecentRepairs: {
                id: 'notifyRecentRepairs',
                tags: ['auto/oem/lrfg/returnyourlandrover'],
                version: '20240606',
                title: {
                    text: 'EVALUATE WEAR AND USE',
                },
                subTitle: {
                    text: null,
                },
                links: {
                    notifyRecentRepairsOption1: {
                        headerText: null,
                        value: 'If you make repairs to reduce your end-of-term liability, <1>{{notifyRecentRepairsSup}}</1> let us know before you return your vehicle by sending a <2>{{secureMessage}}</2> at chase.com.',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: 'notifyRecentRepairsSup,secureMessage',
                        id: 'notifyRecentRepairsOption1',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: 'returnYourLandRoverLinkList',
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    notifyRecentRepairsOption2: {
                        headerText: null,
                        value: 'All repairs must be completed by a licensed repair facility. Please include receipts for all repairs.',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'notifyRecentRepairsOption2',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                },
            },
            prepareToReturnYourLandRoverList: {
                id: 'prepareToReturnYourLandRoverList',
                tags: ['auto/oem/lrfg/returnyourlandrover'],
                version: '20240606',
                title: {
                    text: 'PREPARE TO RETURN YOUR LAND ROVER',
                },
                subTitle: {
                    text: null,
                },
                links: {
                    sixtydaysBeforeYouReturn: {
                        headerText: 'accordian',
                        value: '60 days before you turn in your vehicle',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'sixtydaysBeforeYouReturn',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    daysBeforeYouReturnVehicle: {
                        headerText: 'accordian',
                        value: '14 DAYS BEFORE YOU TURN IN YOUR VEHICLE',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'daysBeforeYouReturnVehicle',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                },
            },
            quickLinks: {
                id: 'quickLinks',
                tags: ['auto/oem/lrfg/returnyourlandrover'],
                version: '20240606',
                title: {
                    text: 'QUICK LINKS',
                },
                subTitle: {
                    text: null,
                },
                links: {
                    quickLinksOption1: {
                        headerText: null,
                        value: 'SCHEDULE A PRE-INSPECTION',
                        type: null,
                        presentationType: null,
                        url: 'https://autovinlive.com/Login/EntryGet',
                        esUrl: null,
                        id: 'quickLinksOption1',
                        hat: null,
                        navigationKeyId: 'Untimed Modal',
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: 'pl_le_ql_schedule',
                        trackingType: null,
                        showSpeedBump: true,
                    },
                    quickLinksOption2: {
                        headerText: null,
                        value: 'REVIEW YOUR LEASE-END OPTIONS',
                        type: null,
                        presentationType: null,
                        url: '/lease-end',
                        esUrl: null,
                        id: 'quickLinksOption2',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: 'pl_le_ql_options',
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    quickLinksOption3: {
                        headerText: null,
                        value: 'FAQs',
                        type: null,
                        presentationType: null,
                        url: '/faq',
                        esUrl: null,
                        id: 'quickLinksOption3',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: 'pl_le_ql_faq',
                        trackingType: null,
                        showSpeedBump: false,
                    },
                },
            },
            returnBodyContent: {
                id: 'returnBodyContent',
                tags: ['auto/oem/lrfg/returnyourlandrover'],
                version: '20240606',
                title: {
                    text: null,
                },
                subTitle: {
                    text: null,
                },
                links: {
                    prepareToReturnYourLandRoverList: {
                        headerText: 'collapse',
                        value: 'PREPARE TO RETURN YOUR LAND ROVER',
                        type: null,
                        presentationType: null,
                        url: 'prepare-to-return-your-land-rover',
                        esUrl: null,
                        id: 'prepareToReturnYourLandRoverList',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    returnYourLandRover: {
                        headerText: 'default',
                        value: 'RETURN YOUR LAND ROVER',
                        type: null,
                        presentationType: null,
                        url: 'return-your-land-rover',
                        esUrl: null,
                        id: 'returnYourLandRover',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    completeYourLandRoverReturn: {
                        headerText: 'default',
                        value: 'COMPLETE YOUR LAND ROVER RETURN',
                        type: null,
                        presentationType: null,
                        url: 'complete-your-land-rover-return',
                        esUrl: null,
                        id: 'completeYourLandRoverReturn',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                },
            },
            returnYourLandRover: {
                id: 'returnYourLandRover',
                tags: ['auto/oem/lrfg/returnyourlandrover'],
                version: '20240606',
                title: {
                    text: 'RETURN YOUR LAND ROVER',
                },
                subTitle: {
                    text: null,
                },
                links: {
                    returnYourLandRoverOptional: {
                        headerText: null,
                        value: null,
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'returnYourLandRoverOptional',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                },
            },
            returnYourLandRoverLinkList: {
                id: 'returnYourLandRoverLinkList',
                tags: ['auto/oem/lrfg/returnyourlandrover'],
                version: '20240606',
                title: {
                    text: null,
                },
                subTitle: {
                    text: null,
                },
                links: {
                    legalContainerSupOption1: {
                        headerText: 'sup',
                        value: '1',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'legalContainerSupOption1',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    legalContainerSupOption2: {
                        headerText: 'sup',
                        value: '2',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'legalContainerSupOption2',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    legalContainerSupOption3: {
                        headerText: 'sup',
                        value: '3',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'legalContainerSupOption3',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    odometerStatement: {
                        headerText: 'anquer',
                        value: 'odometer statement',
                        type: null,
                        presentationType: null,
                        url: 'https://www.chase.com/content/dam/chase-ux/documents/personal/auto-loans/odometer_statement_lease.pdf?jp_cdf=LRFG',
                        esUrl: null,
                        id: 'odometerStatement',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    schedulePreInspectionsup: {
                        headerText: 'sup',
                        value: '1',
                        type: null,
                        presentationType: null,
                        url: '#pre-inspection-disclosure',
                        esUrl: null,
                        id: 'schedulePreInspectionsup',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    preinspectionOnline: {
                        headerText: 'anquer',
                        value: 'pre-inspection online',
                        type: null,
                        presentationType: null,
                        url: 'https://autovinlive.com/Login/EntryGet',
                        esUrl: null,
                        id: 'preinspectionOnline',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: 'pl_le_schedule',
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    contactUs: {
                        headerText: 'anquer',
                        value: 'contact us',
                        type: null,
                        presentationType: null,
                        url: '/contact-us',
                        esUrl: null,
                        id: 'contactUs',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: 'pl_le_contactus2',
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    secureMessage: {
                        headerText: 'anquer',
                        value: 'secure message',
                        type: null,
                        presentationType: null,
                        url: 'https://www.chase.com/digital/login-secure-message?jp_cdf=LRFG',
                        esUrl: null,
                        id: 'secureMessage',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: 'pl_le_secure',
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    chaseDotcom: {
                        headerText: 'anquer',
                        value: 'chase.com',
                        type: null,
                        presentationType: null,
                        url: 'http://chase.com',
                        esUrl: null,
                        id: 'chaseDotcom',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    landRoverUsaDotCom: {
                        headerText: 'anquer',
                        value: 'LandRoverUSA.com',
                        type: null,
                        presentationType: null,
                        url: 'https://www.landroverusa.com/index.html',
                        esUrl: null,
                        id: 'landRoverUsaDotCom',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: 'pl_le_14_dealer_loc',
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    digitalLeaseTurnInForm: {
                        headerText: 'anquer',
                        value: 'Digital Lease Turn-in form',
                        type: null,
                        presentationType: null,
                        url: '/lease-end-turnin',
                        esUrl: null,
                        id: 'digitalLeaseTurnInForm',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: 'pl_le_turnin',
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    scheduleContactUs: {
                        headerText: 'anquer',
                        value: null,
                        type: null,
                        presentationType: null,
                        url: '/contact-us',
                        esUrl: null,
                        id: 'scheduleContactUs',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: 'pl_le_contactus',
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    notifyRecentRepairsSup: {
                        headerText: 'sup',
                        value: '2',
                        type: null,
                        presentationType: null,
                        url: '#end-of-term-disclosure',
                        esUrl: null,
                        id: 'notifyRecentRepairsSup',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    royaltyProgramSup: {
                        headerText: 'sup',
                        value: '3',
                        type: null,
                        presentationType: null,
                        url: '/lease-end/return#land-rover-loyalty-program-disclosure',
                        esUrl: null,
                        id: 'royaltyProgramSup',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    boldText: {
                        headerText: 'bold',
                        value: 'If your lease originated in NH/WI:',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'boldText',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                },
            },
            returnYourLandRoverOptional: {
                id: 'returnYourLandRoverOptional',
                tags: ['auto/oem/lrfg/returnyourlandrover'],
                version: '20240606',
                title: {
                    text: null,
                },
                subTitle: {
                    text: null,
                },
                links: {
                    getYourVehicleReady: {
                        headerText: null,
                        value: 'GET YOUR VEHICLE READY TO TURN IN',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'getYourVehicleReady',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    returnYourVehicleToRetailer: {
                        headerText: null,
                        value: 'RETURN YOUR VEHICLE TO THE LAND ROVER RETAILER',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'returnYourVehicleToRetailer',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                },
            },
            returnYourVehicleToRetailer: {
                id: 'returnYourVehicleToRetailer',
                tags: ['auto/oem/lrfg/returnyourlandrover'],
                version: '20240606',
                title: {
                    text: 'RETURN YOUR VEHICLE TO THE LAND ROVER RETAILER',
                },
                subTitle: {
                    text: null,
                },
                links: {
                    returnYourVehicleToRetailerOption1: {
                        headerText: null,
                        value: 'Turn in any items that came with your Land Rover when you first leased it, including keys, manuals and cargo covers. You may be charged for any missing items.',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'returnYourVehicleToRetailerOption1',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    returnYourVehicleToRetailerOption2: {
                        headerText: null,
                        value: 'Complete, sign and date an <1>{{odometerStatement}}</1> and return it to us.',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: 'odometerStatement',
                        id: 'returnYourVehicleToRetailerOption2',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: 'returnYourLandRoverLinkList',
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                },
            },
            schedulePreInspection: {
                id: 'schedulePreInspection',
                tags: ['auto/oem/lrfg/returnyourlandrover'],
                version: '20240606',
                title: {
                    text: 'EVALUATE WEAR AND USE',
                },
                subTitle: {
                    text: null,
                },
                links: {
                    schedulePreInspectionOption1: {
                        headerText: null,
                        value: 'Schedule a <1>{{preinspectionOnline}}</1> or <2>{{scheduleContactUs}}</2> to make the appointment. <3>{{schedulePreInspectionsup}}</3>',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: 'preinspectionOnline,scheduleContactUs,schedulePreInspectionsup',
                        id: 'schedulePreInspectionOption1',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: 'returnYourLandRoverLinkList',
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    schedulePreInspectionOption2: {
                        headerText: null,
                        value: 'Make your appointment at least 45 days before your turn-in date to ensure you can get one that fits your schedule.',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'schedulePreInspectionOption2',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    schedulePreInspectionOption3: {
                        headerText: null,
                        value: 'You can review your online condition report about 24 hours after your appointment.',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'schedulePreInspectionOption3',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    schedulePreInspectionOption4: {
                        headerText: null,
                        value: 'If you don’t get a pre-inspection, we’ll still complete an inspection after you turn-in your vehicle, but you won’t have the ability to make repairs. Any excess wear and use charges will appear on your final bill.',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'schedulePreInspectionOption4',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                },
            },
            scheduleTheReturnOfVehicle: {
                id: 'scheduleTheReturnOfVehicle',
                tags: ['auto/oem/lrfg/returnyourlandrover'],
                version: '20240606',
                title: {
                    text: 'SCHEDULE THE RETURN OF YOUR VEHICLE',
                },
                subTitle: {
                    text: null,
                },
                links: {
                    scheduleTheReturnOfVehicleOption1: {
                        headerText: null,
                        value: "Schedule an appointment to return your vehicle to your Land Rover retailer. If you need help finding a retailer, you can visit <1>{{landRoverUsaDotCom}}</1> to locate one.'",
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: 'landRoverUsaDotCom',
                        id: 'scheduleTheReturnOfVehicleOption1',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: 'returnYourLandRoverLinkList',
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                },
            },
            sixtydaysBeforeYouReturn: {
                id: 'sixtydaysBeforeYouReturn',
                tags: ['auto/oem/lrfg/returnyourlandrover'],
                version: '20240606',
                title: {
                    text: '60 days before you turn in your vehicle',
                },
                subTitle: {
                    text: null,
                },
                links: {
                    evaluateWearAndUse: {
                        headerText: 'collapse',
                        value: 'EVALUATE WEAR AND USE',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'evaluateWearAndUse',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    schedulePreInspection: {
                        headerText: 'collapse',
                        value: 'SCHEDULE A PRE-INSPECTION',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'schedulePreInspection',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    notifyRecentRepairs: {
                        headerText: 'collapse',
                        value: 'NOTIFY US OF ANY RECENT REPAIRS',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'notifyRecentRepairs',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                },
            },
            stepsToReturn: {
                id: 'stepsToReturn',
                tags: ['auto/oem/lrfg/returnyourlandrover'],
                version: '20240606',
                title: {
                    text: 'STEPS TO RETURN',
                },
                subTitle: {
                    text: null,
                },
                links: {
                    stepsToReturnOption1: {
                        headerText: null,
                        value: 'PREPARE',
                        type: null,
                        presentationType: null,
                        url: '#prepare-to-return-your-land-rover',
                        esUrl: null,
                        id: 'stepsToReturnOption1',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: 'pl_le_ql_prepare',
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    stepsToReturnOption2: {
                        headerText: null,
                        value: 'RETURN',
                        type: null,
                        presentationType: null,
                        url: '#return-your-land-rover',
                        esUrl: null,
                        id: 'stepsToReturnOption2',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: 'pl_le_ql_return',
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    stepsToReturnOption3: {
                        headerText: null,
                        value: 'COMPLETE',
                        type: null,
                        presentationType: null,
                        url: '#complete-your-land-rover-return',
                        esUrl: null,
                        id: 'stepsToReturnOption3',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: 'pl_le_ql_complete',
                        trackingType: null,
                        showSpeedBump: false,
                    },
                },
            },
            turnOffAutomaticPayments: {
                id: 'turnOffAutomaticPayments',
                tags: ['auto/oem/lrfg/returnyourlandrover'],
                version: '20240606',
                title: {
                    text: 'TURN OFF AUTOMATIC PAYMENTS',
                },
                subTitle: {
                    text: null,
                },
                links: {
                    turnOffAutomaticPaymentsOption1: {
                        headerText: null,
                        value: 'Make sure that you turn off any automatic payments once your final payment has cleared. We can’t cancel these payments for you, so if you don’t turn them off, you may be overcharged.',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'turnOffAutomaticPaymentsOption1',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                },
            },
        },
        carousel: {
            AdditionalLeaseEndOptionsList: {
                brands: null,
                segments: null,
                tags: ['auto/oem/lrfg/returnyourlandrover'],
                id: 'AdditionalLeaseEndOptionsList',
                title: {
                    html: '<p>ADDITIONAL LEASE-END OPTIONS</p>\n',
                    text: 'ADDITIONAL LEASE-END OPTIONS',
                    markdown: 'ADDITIONAL LEASE-END OPTIONS\n',
                },
                subTitle: {
                    html: null,
                    text: null,
                    markdown: null,
                },
                version: '20240606',
                heroes: [],
                tiles: {
                    AdditionalLeaseEndOption1: {
                        brands: null,
                        segments: null,
                        tags: ['ccb-cf:auto/oem/lrfg/returnyourlandrover'],
                        id: 'AdditionalLeaseEndOption1',
                        variationId: null,
                        title: {
                            html: '<p>EXPLORE A NEW LAND ROVER</p>\n',
                            text: 'EXPLORE A NEW LAND ROVER',
                            markdown: 'EXPLORE A NEW LAND ROVER\n',
                        },
                        subTitle: {
                            html: '<p>Let Land Rover exceed your expectations. Learn what you need to know to purchase or lease a Land Rover.</p>\n',
                            text: 'Let Land Rover exceed your expectations. Learn what you need to know to purchase or lease a Land Rover.',
                            markdown: 'Let Land Rover exceed your expectations. Learn what you need to know to purchase or lease a Land Rover.\n',
                        },
                        version: '20240606',
                        body: {
                            html: null,
                            text: null,
                            markdown: null,
                        },
                        additionalText: {
                            html: null,
                            text: null,
                            markdown: null,
                        },
                        hat: null,
                        iconImage: {
                            altText: null,
                            id: 'ReturnYourCurrentLandRoverTextImage',
                            source: null,
                            url: null,
                        },
                        primaryImage: {
                            altText: 'ReturnYourCurrentLandRoverText',
                            id: null,
                            source: '/content/dam/unified-assets/photography/partner/land-rover/optimized/Get_New_Lease_End_Addl_Options_Card_1.jpg',
                            url: 'https://static2-qa2.chasecdn.com/content/dam/unified-assets/photography/partner/land-rover/optimized/Get_New_Lease_End_Addl_Options_Card_1.jpg',
                        },
                        primaryImageWithRendition: null,
                        secondaryImageWithRendition: null,
                        links: {
                            ReturnYourCurrentLandRoverLink: {
                                value: 'SEE OPTIONS',
                                type: null,
                                languageCode: null,
                                disabled: null,
                                active: null,
                                underLined: null,
                                inversed: null,
                                presentationType: null,
                                url: '/lease-end/new',
                                esUrl: null,
                                id: 'ReturnYourCurrentLandRoverLink',
                                hat: null,
                                navigationKeyId: null,
                                target: null,
                                iconId: null,
                                icon: null,
                                trackingId: 'pl_le_new',
                                trackingType: null,
                                showSpeedBump: null,
                                headerText: null,
                            },
                        },
                    },
                    AdditionalLeaseEndOption2: {
                        brands: null,
                        segments: null,
                        tags: ['ccb-cf:auto/oem/lrfg/returnyourlandrover'],
                        id: 'AdditionalLeaseEndOption2',
                        variationId: null,
                        title: {
                            html: '<p>EXTEND, PURCHASE OR EARLY TURN-IN</p>\n',
                            text: 'EXTEND, PURCHASE OR EARLY TURN-IN',
                            markdown: 'EXTEND, PURCHASE OR EARLY TURN-IN\n',
                        },
                        subTitle: {
                            html: '<p>If you’re not ready to finance a new Land Rover, you have the option to purchase your current Land Rover or extend your lease. If you’re not at the end of your lease, but you’re ready for a new vehicle, make sure you understand the financial impact of early turn-in.</p>\n',
                            text: 'If you’re not ready to finance a new Land Rover, you have the option to purchase your current Land Rover or extend your lease. If you’re not at the end of your lease, but you’re ready for a new vehicle, make sure you understand the financial impact of early turn-in.',
                            markdown: "If you're not ready to finance a new Land Rover, you have the option to purchase your current Land Rover or extend your lease. If you're not at the end of your lease, but you're ready for a new vehicle, make sure you understand the financial impact of early turn-in.\n",
                        },
                        version: '20240606',
                        body: {
                            html: null,
                            text: null,
                            markdown: null,
                        },
                        additionalText: {
                            html: null,
                            text: null,
                            markdown: null,
                        },
                        hat: null,
                        iconImage: null,
                        primaryImage: {
                            altText: null,
                            id: null,
                            source: '/content/dam/unified-assets/photography/partner/land-rover/optimized/Other_Lease_End_Addl_Options_Card_1.jpg',
                            url: 'https://static2-qa2.chasecdn.com/content/dam/unified-assets/photography/partner/land-rover/optimized/Other_Lease_End_Addl_Options_Card_1.jpg',
                        },
                        primaryImageWithRendition: null,
                        secondaryImageWithRendition: null,
                        links: {
                            ExtendPurchaseOrEarlyTurnInLink: {
                                value: 'LEARN MORE',
                                type: null,
                                languageCode: null,
                                disabled: null,
                                active: null,
                                underLined: null,
                                inversed: null,
                                presentationType: null,
                                url: '/lease-end/other',
                                esUrl: null,
                                id: 'ExtendPurchaseOrEarlyTurnInLink',
                                hat: null,
                                navigationKeyId: null,
                                target: null,
                                iconId: null,
                                icon: null,
                                trackingId: 'pl_le_other',
                                trackingType: null,
                                showSpeedBump: null,
                                headerText: null,
                            },
                        },
                    },
                },
            },
        },
        iconList: {
            preApprovedOfferIconList: {
                tags: ['auto/oem/lrfg/returnyourlandrover'],
                id: 'preApprovedOfferIconList',
                title: {
                    html: null,
                    text: null,
                    markdown: null,
                },
                subTitle: {
                    html: null,
                    text: null,
                    markdown: null,
                },
                version: '20240606',
                icons: {
                    findaLandRoverRetailerIcon: {
                        id: 'findaLandRoverRetailerIcon',
                        text: 'Find A Land Rover Retailer',
                        image: {
                            altText: null,
                            id: null,
                            source: '/content/dam/unified-assets/iconography/auto/ico_auto_question_mark_circle.svg',
                            url: 'https://static2-qa2.chasecdn.com/content/dam/unified-assets/iconography/auto/ico_auto_question_mark_circle.svg',
                        },
                        primaryImage: null,
                        link: {
                            value: 'FindaLandRoverImage',
                            type: null,
                            languageCode: null,
                            disabled: null,
                            active: null,
                            underLined: null,
                            inversed: null,
                            presentationType: null,
                            url: 'https://www.landroverusa.com/national-dealer-locator.html',
                            esUrl: null,
                            id: 'FindaLandRoverImage',
                            hat: null,
                            navigationKeyId: null,
                            target: null,
                            iconId: null,
                            icon: null,
                            trackingId: 'pl_find_retailer',
                            trackingType: null,
                            showSpeedBump: false,
                            headerText: null,
                        },
                    },
                    faqIcon: {
                        id: 'faqIcon',
                        text: 'FAQ',
                        image: {
                            altText: null,
                            id: null,
                            source: '/content/dam/unified-assets/iconography/auto/ico_auto_question_mark_circle.svg',
                            url: 'https://static2-qa2.chasecdn.com/content/dam/unified-assets/iconography/auto/ico_auto_question_mark_circle.svg',
                        },
                        primaryImage: null,
                        link: {
                            value: 'FAQ',
                            type: null,
                            languageCode: null,
                            disabled: null,
                            active: null,
                            underLined: null,
                            inversed: null,
                            presentationType: null,
                            url: 'https://lrfg-uat.chase.com/oem/faq',
                            esUrl: null,
                            id: 'FAQImage',
                            hat: null,
                            navigationKeyId: null,
                            target: null,
                            iconId: null,
                            icon: null,
                            trackingId: 'pl_faq',
                            trackingType: null,
                            showSpeedBump: false,
                            headerText: null,
                        },
                    },
                    contactUs: {
                        id: 'contactUs',
                        text: 'CONTACT US',
                        image: {
                            altText: null,
                            id: null,
                            source: '/content/dam/unified-assets/iconography/auto/ico_auto_people_group.svg',
                            url: 'https://static2-qa2.chasecdn.com/content/dam/unified-assets/iconography/auto/ico_auto_people_group.svg',
                        },
                        primaryImage: null,
                        link: {
                            value: 'CONTACT US',
                            type: null,
                            languageCode: null,
                            disabled: null,
                            active: null,
                            underLined: null,
                            inversed: null,
                            presentationType: null,
                            url: 'https://lrfg-uat.chase.com/oem/contact-us',
                            esUrl: null,
                            id: 'contactUsImage',
                            hat: null,
                            navigationKeyId: null,
                            target: null,
                            iconId: null,
                            icon: null,
                            trackingId: 'pl_contact',
                            trackingType: null,
                            showSpeedBump: false,
                            headerText: null,
                        },
                    },
                },
            },
        },
        legalContainer: {
            legalContainer: {
                brands: null,
                segments: null,
                tags: ['auto/oem/lrfg/returnyourlandrover'],
                id: 'legalContainer',
                title: {
                    html: null,
                    text: null,
                    markdown: null,
                },
                subTitle: {
                    html: null,
                    text: null,
                    markdown: null,
                },
                disclaimer: [],
                disclosure: [],
                footNote: [],
                contentLists: [],
                version: '20240502',
            },
        },
        dialog: {},
        contentList: {
            prepareToReturnYourLandRoverList: {
                id: 'prepareToReturnYourLandRoverList',
                title: {
                    html: '<p>PREPARE TO RETURN YOUR LAND ROVER</p>\n',
                    text: 'PREPARE TO RETURN YOUR LAND ROVER',
                    markdown: 'PREPARE TO RETURN YOUR LAND ROVER\n',
                },
                tags: ['auto/oem/lrfg/returnyourlandrover'],
                version: '20240502',
                items: {
                    sixtydaysBeforeYouReturn: {
                        id: 'sixtydaysBeforeYouReturn',
                        value: '60 days before you turn in your vehicle',
                    },
                    evaluateWearAndUse: {
                        id: 'evaluateWearAndUse',
                        value: 'EVALUATE WEAR AND USE',
                    },
                    evaluateWearAndUseOption1: {
                        id: 'evaluateWearAndUseOption1',
                        value: 's you get to the end of your lease, we want to help you make your turn-in as simple as possible. As detailed in your Lease Agreement, we may bill you for the following excess wear and use items:',
                    },
                    evaluateWearAndUseOption2: {
                        id: 'evaluateWearAndUseOption2',
                        value: 'Scratches, chips, dents, pits, rust, holes or cracks in the wheels or exterior surfaces, windshield or other glass surfaces or metal work.',
                    },
                    evaluateWearAndUseOption3: {
                        id: 'evaluateWearAndUseOption3',
                        value: 'Cracked, broken, or missing windows, doors, lights, trim, mirrors, or antennae.',
                    },
                    evaluateWearAndUseOption4: {
                        id: 'evaluateWearAndUseOption4',
                        value: 'Missing, damaged or nonworking mechanical equipment, safety or emissions control equipment, electrical or other parts or accessories.',
                    },
                    evaluateWearAndUseOption5: {
                        id: 'evaluateWearAndUseOption5',
                        value: "Damage to the vehicle's frame or alignment.",
                    },
                    evaluateWearAndUseOption6: {
                        id: 'evaluateWearAndUseOption6',
                        value: 'Damage due to improper, poor quality or incomplete repairs or paint work. You also will remain liable to us for the full cost of any such items resulting from intentional or willful abuse or misuse.',
                    },
                    evaluateWearAndUseOption7: {
                        id: 'evaluateWearAndUseOption7',
                        value: 'Any wear and use related to tires. Tire tread must be at least 1/8” (4/32”) at the lowest point and may not contain any other damages. Make sure that all tires match and are comparable quality to the original equipment. For tires to be considered properly matched, tires on the same axel must match in brand, load rating and speed rating. Additionally, all four tires on the vehicle must match in size (except for vehicles requiring staggered sized from front to rear). Also make sure to remove snow tires prior to your pre-inspection. Other items may be excluded as set forth in the Lease Agreement.',
                    },
                    evaluateWearAndUseOption8: {
                        id: 'evaluateWearAndUseOption8',
                        value: 'Any missing manuals or missing or nonworking keys or remote entry devices.',
                    },
                    schedulePreInspection: {
                        id: 'schedulePreInspection',
                        value: 'SCHEDULE A PRE-INSPECTION',
                    },
                    schedulePreInspectionOption1: {
                        id: 'schedulePreInspectionOption1',
                        value: 'Schedule a {{preinspectionOnline}} or {{contactUs}} to make the appointment. <sup>1</sup>',
                    },
                    schedulePreInspectionOption2: {
                        id: 'schedulePreInspectionOption2',
                        value: 'Make your appointment at least 45 days before your turn-in date to ensure you can get one that fits your schedule.',
                    },
                    schedulePreInspectionOption3: {
                        id: 'schedulePreInspectionOption3',
                        value: 'You can review your online condition report about 24 hours after your appointment.',
                    },
                    schedulePreInspectionOption4: {
                        id: 'schedulePreInspectionOption4',
                        value: 'If you don’t get a pre-inspection, we’ll still complete an inspection after you turn-in your vehicle, but you won’t have the ability to make repairs. Any excess wear and use charges will appear on your final bill.',
                    },
                    notifyRecentRepairs: {
                        id: 'notifyRecentRepairs',
                        value: 'NOTIFY US OF ANY RECENT REPAIRS',
                    },
                    notifyRecentRepairsOption1: {
                        id: 'notifyRecentRepairsOption1',
                        value: 'If you make repairs to reduce your end-of-term liability, 2 let us know before you return your vehicle by sending a {{secureMessage}} at {{chaseDotCom}}.',
                    },
                    notifyRecentRepairsOption2: {
                        id: 'notifyRecentRepairsOption2',
                        value: 'All repairs must be completed by a licensed repair facility. Please include receipts for all repairs.',
                    },
                    daysBeforeYouReturnVehicle: {
                        id: 'daysBeforeYouReturnVehicle',
                        value: '14 DAYS BEFORE YOU TURN IN YOUR VEHICLE',
                    },
                    turnOffAutomaticPayments: {
                        id: 'turnOffAutomaticPayments',
                        value: 'TURN OFF AUTOMATIC PAYMENTS',
                    },
                    turnOffAutomaticPaymentsOption1: {
                        id: 'turnOffAutomaticPaymentsOption1',
                        value: 'Make sure that you turn off any automatic payments once your final payment has cleared. We can’t cancel these payments for you, so if you don’t turn them off, you may be overcharged.',
                    },
                    scheduleTheReturnOfVehicle: {
                        id: 'scheduleTheReturnOfVehicle',
                        value: 'SCHEDULE THE RETURN OF YOUR VEHICLE',
                    },
                    scheduleTheReturnOfVehicleOption1: {
                        id: 'scheduleTheReturnOfVehicleOption1',
                        value: 'Schedule an appointment to return your vehicle to your Land Rover retailer. If you need help finding a retailer, you can visit {{landRoverUsaDotCom}} to locate one.',
                    },
                    findLocalDmvRequirements: {
                        id: 'findLocalDmvRequirements',
                        value: 'FIND OUT LOCAL DMV REQUIREMENTS FOR TURN-IN',
                    },
                    findLocalDmvRequirementsOption1: {
                        id: 'findLocalDmvRequirementsOption1',
                        value: 'Contact your local Department of Motor Vehicles to see if there are specific turn-in requirements for your area. Your retailer may assist during your turn-in appointment, but it’s your responsibility to complete the state requirements.',
                    },
                },
            },
        },
        tile: {
            LoyaltyProgram: {
                tags: ['auto/oem/lrfg/returnyourlandrover'],
                id: 'LoyaltyProgram',
                variationId: null,
                title: {
                    html: '<p>LAND ROVER LOYALTY PROGRAM</p>\n',
                    text: 'LAND ROVER LOYALTY PROGRAM',
                    markdown: 'LAND ROVER LOYALTY PROGRAM\n',
                },
                subTitle: {
                    html: null,
                    text: null,
                    markdown: null,
                },
                version: '20240606',
                body: {
                    html: null,
                    text: null,
                    markdown: null,
                },
                additionalText: {
                    html: null,
                    text: null,
                    markdown: null,
                },
                hat: null,
                iconImage: null,
                primaryImage: {
                    altText: 'ReturnYourCurrentLandRoverText',
                    id: null,
                    source: '/content/dam/unified-assets/photography/partner/land-rover/optimized/LandRoverLoyaltyCard_1.jpg',
                    url: 'https://static2-qa2.chasecdn.com/content/dam/unified-assets/photography/partner/land-rover/optimized/LandRoverLoyaltyCard_1.jpg',
                },
                primaryImageWithRendition: null,
                secondaryImageWithRendition: null,
                links: [],
            },
            returnYourLandRover: {
                tags: ['auto/oem/lrfg/returnyourlandrover'],
                id: 'returnYourLandRover',
                variationId: null,
                title: {
                    html: '<p>RETURN YOUR LAND ROVER</p>\n',
                    text: 'RETURN YOUR LAND ROVER',
                    markdown: 'RETURN YOUR LAND ROVER\n',
                },
                subTitle: {
                    html: '<p>Check out the newest Land Rover</p>\n',
                    text: 'Check out the newest Land Rover',
                    markdown: 'Check out the newest Land Rover\n',
                },
                version: '20240606',
                body: {
                    html: '<p>Experience peerless refinement and luxury</p>\n',
                    text: 'Experience peerless refinement and luxury',
                    markdown: 'Experience peerless refinement and luxury\n',
                },
                additionalText: {
                    html: null,
                    text: null,
                    markdown: null,
                },
                hat: null,
                iconImage: null,
                primaryImage: {
                    altText: null,
                    id: null,
                    source: '/content/dam/unified-assets/photography/partner/land-rover/optimized/Lease_End_Return_Hero_Desktop1.jpg',
                    url: 'https://static2-qa2.chasecdn.com/content/dam/unified-assets/photography/partner/land-rover/optimized/Lease_End_Return_Hero_Desktop1.jpg',
                },
                primaryImageWithRendition: null,
                secondaryImageWithRendition: null,
                links: {
                    exploreInventory: {
                        value: 'Explore inventory',
                        type: null,
                        languageCode: null,
                        disabled: null,
                        active: null,
                        underLined: null,
                        inversed: null,
                        presentationType: null,
                        url: 'https://inventory.landroverusa.com/new/',
                        esUrl: null,
                        id: 'exploreInventory',
                        hat: null,
                        navigationKeyId: 'untimedModal',
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: 'pl_le_explore_inventory',
                        trackingType: null,
                        showSpeedBump: null,
                        headerText: null,
                        visibleIn: null,
                        variant: null,
                    },
                },
            },
        },
        structuredContentList: {},
        header: {},
    },
};
