export const extendYourLease = {
    data: {
        linkList: {
            EarlyTurnInLinks: {
                id: 'EarlyTurnInLinks',
                tags: ['auto/oem/lrfg/extendyourlease'],
                version: '20240502',
                title: {
                    text: null,
                },
                subTitle: {
                    text: null,
                },
                links: {
                    contactus: {
                        headerText: null,
                        value: 'Contact Us',
                        type: null,
                        presentationType: null,
                        url: 'https://lrfg-uat.chase.com/oem/contact-us',
                        esUrl: null,
                        id: 'contactus',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    digitalLeaseTurninForm: {
                        headerText: null,
                        value: 'Digital Lease Turn-in form',
                        type: null,
                        presentationType: null,
                        url: 'https://qac3-secure01ea.chase.com/web/auth/dashboard?navKey=startVastNavigation&jp_cdf=LRFG_LOGIN&context=VAST_LANDROVER&treatment=LANDROVER&financialBrandName=Land+Rover+Financial+Group&thirdParty=true&landingScreen=turnin#/dashboard/index/index',
                        esUrl: null,
                        id: 'digitalLeaseTurninForm',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                },
            },
            ExtendyourLeaseLinkLists: {
                id: 'ExtendyourLeaseLinkLists',
                tags: ['auto/oem/lrfg/extendyourlease'],
                version: '20240502',
                title: {
                    text: null,
                },
                subTitle: {
                    text: null,
                },
                links: {
                    NumberLink: {
                        headerText: null,
                        value: '1-877-369-8455.',
                        type: null,
                        presentationType: null,
                        url: 'https://lrfg-uat.chase.com/oem/lease-end/tel:+18773698455',
                        esUrl: null,
                        id: 'NumberLink',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    secureMessage: {
                        headerText: null,
                        value: 'Secure Message',
                        type: null,
                        presentationType: null,
                        url: 'https://www.chase.com/digital/login-secure-message?jp_cdf=LRFG',
                        esUrl: null,
                        id: 'secureMessage',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    chaseDotCom: {
                        headerText: null,
                        value: 'chase.com',
                        type: null,
                        presentationType: null,
                        url: 'http://chase.com',
                        esUrl: null,
                        id: 'chaseDotCom',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    contactus: {
                        headerText: null,
                        value: 'Contact Us',
                        type: null,
                        presentationType: null,
                        url: 'https://lrfg-uat.chase.com/oem/contact-us',
                        esUrl: null,
                        id: 'contactus',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                },
            },
            FormsList: {
                id: 'FormsList',
                tags: ['auto/oem/lrfg/extendyourlease'],
                version: '20240606',
                title: {
                    text: 'FORMS',
                },
                subTitle: {
                    text: null,
                },
                links: {
                    FormsContent1: {
                        headerText: null,
                        value: 'Digital Lease Turn-in form',
                        type: null,
                        presentationType: null,
                        url: '/lease-end-turnin',
                        esUrl: null,
                        id: 'FormsContent1',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: 'pl_le_ql_turnin',
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    FormsContent2: {
                        headerText: null,
                        value: 'Odometer statement',
                        type: null,
                        presentationType: null,
                        url: 'https://www.chase.com/content/dam/chase-ux/documents/personal/auto-loans/odometer_statement_lease.pdf?jp_cdf=LRFG',
                        esUrl: null,
                        id: 'FormsContent2',
                        hat: null,
                        navigationKeyId: 'Timed Modal',
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: 'pl_le_ql_odo_Stm',
                        trackingType: null,
                        showSpeedBump: true,
                    },
                    FormsContent3: {
                        headerText: null,
                        value: 'Printable Land Rover return checklist',
                        type: null,
                        presentationType: null,
                        url: '/land-rover/Land-Rover-Lease-End-Checklist-2022.pdf',
                        esUrl: null,
                        id: 'FormsContent3',
                        hat: null,
                        navigationKeyId: null,
                        target: '_blank',
                        iconId: null,
                        icon: null,
                        trackingId: 'pl_le_ql_stn_chklst',
                        trackingType: null,
                        showSpeedBump: false,
                    },
                },
            },
            OptionsContentList: {
                id: 'OptionsContentList',
                tags: ['auto/oem/lrfg/extendyourlease'],
                version: '20240606',
                title: {
                    text: 'Options',
                },
                subTitle: {
                    text: null,
                },
                links: {
                    OptionsContent1: {
                        headerText: null,
                        value: 'Extend Lease',
                        type: null,
                        presentationType: null,
                        url: '/lease-end/other#extend-your-lease',
                        esUrl: null,
                        id: 'OptionsContent1',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: 'pl_le_ql_extend',
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    OptionsContent2: {
                        headerText: null,
                        value: 'Purchase Current Land Rover',
                        type: null,
                        presentationType: null,
                        url: '/lease-end/other#purchase-current-land-rover',
                        esUrl: null,
                        id: 'OptionsContent2',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: 'pl_le_ql_purch',
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    OptionsContent3: {
                        headerText: null,
                        value: 'Early Turn-In',
                        type: null,
                        presentationType: null,
                        url: '/lease-end/other#early-turn-in',
                        esUrl: null,
                        id: 'OptionsContent3',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: 'pl_le_ql_early',
                        trackingType: null,
                        showSpeedBump: false,
                    },
                },
            },
            PurchaseCurrentLandRoverLinkLists: {
                id: 'PurchaseCurrentLandRoverLinkLists',
                tags: ['auto/oem/lrfg/extendyourlease'],
                version: '20240502',
                title: {
                    text: null,
                },
                subTitle: {
                    text: null,
                },
                links: {
                    contactus: {
                        headerText: null,
                        value: 'Contact Us',
                        type: null,
                        presentationType: null,
                        url: 'https://lrfg-uat.chase.com/oem/contact-us',
                        esUrl: null,
                        id: 'contactus',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    odometerDisclosureStatement: {
                        headerText: null,
                        value: 'Odometer Disclosure Statement',
                        type: null,
                        presentationType: null,
                        url: 'https://www.chase.com/content/dam/chase-ux/documents/personal/auto-loans/odometer_statement_lease.pdf?jp_cdf=LRFG',
                        esUrl: null,
                        id: 'odometerDisclosureStatement',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    leaseBuyOut: {
                        headerText: null,
                        value: 'chase.com/leasebuyout',
                        type: null,
                        presentationType: null,
                        url: 'https://secure.chase.com/web/oao/application/retail#/origination;cfgCode=060003;offerCode=L2L/index/index',
                        esUrl: null,
                        id: 'leaseBuyOut',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    loanStatus: {
                        headerText: null,
                        value: 'chase.com/loanstatus',
                        type: null,
                        presentationType: null,
                        url: 'https://www.chase.com/personal/auto-loans/checkloanstatus',
                        esUrl: null,
                        id: 'loanStatus',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                },
            },
            QuickLinksList: {
                id: 'QuickLinksList',
                tags: ['auto/oem/lrfg/extendyourlease'],
                version: '20240606',
                title: {
                    text: 'Quick Links',
                },
                subTitle: {
                    text: null,
                },
                links: {
                    QuickLinkContent1: {
                        headerText: null,
                        value: 'Schedule a pre-inspection',
                        type: null,
                        presentationType: null,
                        url: 'https://autovinlive.com/Login/EntryGet',
                        esUrl: null,
                        id: 'QuickLinkContent1',
                        hat: null,
                        navigationKeyId: 'Untimed Modal',
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: 'pl_le_ql_schedule',
                        trackingType: null,
                        showSpeedBump: true,
                    },
                    QuickLinkContent2: {
                        headerText: null,
                        value: 'Review your lease-end options',
                        type: null,
                        presentationType: null,
                        url: '/lease-end',
                        esUrl: null,
                        id: 'QuickLinkContent2',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: 'pl_le_ql_options',
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    QuickLinkContent3: {
                        headerText: null,
                        value: 'FAQs',
                        type: null,
                        presentationType: null,
                        url: '/faq',
                        esUrl: null,
                        id: 'QuickLinkContent3',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: 'pl_le_ql_faq',
                        trackingType: null,
                        showSpeedBump: false,
                    },
                },
            },
            extendLeaseContent: {
                id: 'extendLeaseContent',
                tags: ['auto/oem/lrfg/extendyourlease'],
                version: '20240606',
                title: {
                    text: null,
                },
                subTitle: {
                    text: null,
                },
                links: {
                    extendLeaseContent1: {
                        headerText: null,
                        value: 'EXTEND YOUR LEASE',
                        type: null,
                        presentationType: null,
                        url: 'extend-your-lease',
                        esUrl: null,
                        id: 'extendLeaseContent1',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    extendLeaseContent2: {
                        headerText: null,
                        value: 'PURCHASE CURRENT LAND ROVER',
                        type: null,
                        presentationType: null,
                        url: 'purchase-current-land-rover',
                        esUrl: null,
                        id: 'extendLeaseContent2',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    extendLeaseContent3: {
                        headerText: null,
                        value: 'EARLY TURN-IN',
                        type: null,
                        presentationType: null,
                        url: 'early-turn-in',
                        esUrl: null,
                        id: 'extendLeaseContent3',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                },
            },
            extendLeaseContent1: {
                id: 'extendLeaseContent1',
                tags: ['auto/oem/lrfg/extendyourlease'],
                version: '20240606',
                title: {
                    text: null,
                },
                subTitle: {
                    text: null,
                },
                links: {
                    extendLeaseContent1Item1: {
                        headerText: null,
                        value: 'DO YOU NEED MORE TIME?',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'extendLeaseContent1Item1',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    extendLeaseContent1Item2: {
                        headerText: null,
                        value: 'READY TO EXTEND YOUR LEASE?',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'extendLeaseContent1Item2',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                },
            },
            extendLeaseContent1Item1: {
                id: 'extendLeaseContent1Item1',
                tags: ['auto/oem/lrfg/extendyourlease'],
                version: '20240606',
                title: {
                    text: null,
                },
                subTitle: {
                    text: null,
                },
                links: {
                    extendLeaseContent1Item1List1: {
                        headerText: null,
                        value: 'If you need more time to get the Land Rover vehicle you want, or you’re not sure if you want to purchase or turn-in your current vehicle, you may qualify to extend your lease.',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'extendLeaseContent1Item1List1',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    extendLeaseContent1Item1List2: {
                        headerText: null,
                        value: 'You can only extend your lease for 6 months—if you’re eligible. If you want to turn your vehicle in before that 6 months is up, you can do so without incurring any early termination charges.',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'extendLeaseContent1Item1List2',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    extendLeaseContent1Item1List3: {
                        headerText: null,
                        value: 'Continue making your payments during the extension term. If you have automatic payments set up, please review them as they may not automatically continue. For questions about automatic payments, call us at \u003C1\u003E{{NumberLink}}\u003C/1\u003E.',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: 'NumberLink',
                        id: 'extendLeaseContent1Item1List3',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: 'extendYourLeaseLinks',
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                },
            },
            extendLeaseContent1Item2: {
                id: 'extendLeaseContent1Item2',
                tags: ['auto/oem/lrfg/extendyourlease'],
                version: '20240606',
                title: {
                    text: null,
                },
                subTitle: {
                    text: null,
                },
                links: {
                    extendLeaseContent1Item2List1: {
                        headerText: null,
                        value: 'To request a one-time 6-month extension on your current lease, send us a \u003C1\u003E{{secureMessage}}\u003C/1\u003E at chase.com or \u003C2\u003E{{contactus}}\u003C/2\u003E to see if you’re eligible. \u003C3\u003E{{oneFooter}}\u003C/3\u003E',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: 'secureMessage,contactus,oneFooter',
                        id: 'extendLeaseContent1Item2List1',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: 'extendYourLeaseLinks',
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    extendLeaseContent1Item2List2: {
                        headerText: null,
                        value: 'You must be current on your lease payments and not subject to any bankruptcy or repossession proceedings.',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'extendLeaseContent1Item2List2',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    extendLeaseContent1Item2List3: {
                        headerText: null,
                        value: 'Once the 6-month extension is over, you can either return your vehicle or purchase it. Mileage is pro-rated for each month.',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'extendLeaseContent1Item2List3',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    extendLeaseContent1Item2List4: {
                        headerText: null,
                        value: 'If you purchased any additional products with your lease, like extended warranties on the vehicle, tires, etc., they won’t extend with your lease.',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'extendLeaseContent1Item2List4',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                },
            },
            extendLeaseContent2: {
                id: 'extendLeaseContent2',
                tags: ['auto/oem/lrfg/extendyourlease'],
                version: '20240606',
                title: {
                    text: null,
                },
                subTitle: {
                    text: null,
                },
                links: {
                    extendLeaseContent2Item1: {
                        headerText: null,
                        value: 'WANT TO OWN YOUR CURRENT LAND ROVER?',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'extendLeaseContent2Item1',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    extendLeaseContent2Item2: {
                        headerText: null,
                        value: 'NEED FINANCING FOR YOUR LEASE PURCHASE?',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'extendLeaseContent2Item2',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                },
            },
            extendLeaseContent2Item1: {
                id: 'extendLeaseContent2Item1',
                tags: ['auto/oem/lrfg/extendyourlease'],
                version: '20240606',
                title: {
                    text: null,
                },
                subTitle: {
                    text: null,
                },
                links: {
                    extendLeaseContent2Item1List1: {
                        headerText: null,
                        value: '\u003C1\u003E{{contactus1}}\u003C/1\u003E for forms, instructions and purchase amount.',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: 'contactus1',
                        id: 'extendLeaseContent2Item1List1',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: 'extendYourLeaseLinks',
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    extendLeaseContent2Item1List2: {
                        headerText: null,
                        value: 'extendLeaseContent2Item1List2Content',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: 'odometerDisclosureStatement',
                        id: 'extendLeaseContent2Item1List2',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: 'extendYourLeaseLinks',
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    extendLeaseContent2Item1List3: {
                        headerText: null,
                        value: 'We can’t accept a purchase from a third party, such as a friend or family member.',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'extendLeaseContent2Item1List3',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    extendLeaseContent2Item1List4: {
                        headerText: null,
                        value: 'Keep in mind that even after you terminate your lease, you may be billed for remaining property taxes, parking tickets, toll charges and other state or local fees you still owe on your account.',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'extendLeaseContent2Item1List4',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                },
            },
            extendLeaseContent2Item1List2Content: {
                id: 'extendLeaseContent2Item1List2Content',
                tags: ['auto/oem/lrfg/extendyourlease'],
                version: '20240606',
                title: {
                    text: null,
                },
                subTitle: {
                    text: null,
                },
                links: {
                    extendLeaseContent2Item1List2Content1: {
                        headerText: null,
                        value: 'To release your title, your account must be paid in full with a $0 balance and we must receive a properly completed, federally required \u003C1\u003E{{odometerDisclosureStatement}}\u003C/1\u003E. Following processing of both items,',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'extendLeaseContent2Item1List2Content1',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    extendLeaseContent2Item1List2Content2: {
                        headerText: null,
                        value: 'the title will be sent to the address that appears on your auto account statements generally in 2-10 business days or based on State requirements. Contact us directly if you need to update your information. ',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'extendLeaseContent2Item1List2Content2',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    extendLeaseContent2Item1List2Content3: {
                        headerText: null,
                        value: 'Although we release the title typically within 2-10 business days, please allow up to 30 days to receive your title after the final payment has posted based on mail time and/or state DMV processing.',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'extendLeaseContent2Item1List2Content3',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                },
            },
            extendLeaseContent2Item2: {
                id: 'extendLeaseContent2Item2',
                tags: ['auto/oem/lrfg/extendyourlease'],
                version: '20240606',
                title: {
                    text: null,
                },
                subTitle: {
                    text: null,
                },
                links: {
                    extendLeaseContent2Item2List1: {
                        headerText: null,
                        value: 'Visit \u003C1\u003E{{leaseBuyOut}}\u003C/1\u003E to get started with lease purchase financing.',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: 'leaseBuyOut',
                        id: 'extendLeaseContent2Item2List1',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: 'extendYourLeaseLinks',
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    extendLeaseContent2Item2List2: {
                        headerText: null,
                        value: 'Lease purchase financing is only available for those named on the original lease agreement and can’t be transferred to a third party.',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'extendLeaseContent2Item2List2',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    extendLeaseContent2Item2List3: {
                        headerText: null,
                        value: 'We can’t add or remove anyone from the lease.',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'extendLeaseContent2Item2List3',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    extendLeaseContent2Item2List4: {
                        headerText: null,
                        value: 'You can check the status of your application about 24 hours after submission at \u003C1\u003E{{loanStatus}}\u003C/1\u003E.',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: 'loanStatuloanStatuss',
                        id: 'extendLeaseContent2Item2List4',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: 'extendYourLeaseLinks',
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                },
            },
            extendLeaseContent3: {
                id: 'extendLeaseContent3',
                tags: ['auto/oem/lrfg/extendyourlease'],
                version: '20240606',
                title: {
                    text: null,
                },
                subTitle: {
                    text: null,
                },
                links: {
                    extendLeaseContent3Item1: {
                        headerText: null,
                        value: 'EARLY TERMINATION CHARGE IF YOU TURN IN YOUR VEHICLE MORE THAN 30 DAYS EARLY',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'extendLeaseContent3Item1',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    extendLeaseContent3Item2: {
                        headerText: null,
                        value: 'CONTACT US WITHIN 1 BUSINESS DAY OF YOUR VEHICLE TURN-IN DATE',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'extendLeaseContent3Item2',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    extendLeaseContent3Item3: {
                        headerText: null,
                        value: "IF YOU HAVE LEASE-END CHARGES, YOU'LL RECEIVE YOUR BILL IN THE MAIL",
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'extendLeaseContent3Item3',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                },
            },
            extendLeaseContent3Item1: {
                id: 'extendLeaseContent3Item1',
                tags: ['auto/oem/lrfg/extendyourlease'],
                version: '20240606',
                title: {
                    text: null,
                },
                subTitle: {
                    text: null,
                },
                links: {
                    extendLeaseContent3Item1List1: {
                        headerText: null,
                        value: 'extendLeaseContent3Item1List1Content',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'extendLeaseContent3Item1List1',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    extendLeaseContent3Item1List2: {
                        headerText: null,
                        value: 'Keep in mind that you’ll still owe any other charges due for the lease, such as any past due payments, unpaid late fees and taxes.',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'extendLeaseContent3Item1List2',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    extendLeaseContent3Item1List3: {
                        headerText: null,
                        value: 'Also, if your state charges personal property tax on the vehicle, you’ll remain responsible for any future tax bills for tax periods occurring before you returned the vehicle.',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'extendLeaseContent3Item1List3',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                },
            },
            extendLeaseContent3Item1List1Content: {
                id: 'extendLeaseContent3Item1List1Content',
                tags: ['auto/oem/lrfg/extendyourlease'],
                version: '20240606',
                title: {
                    text: null,
                },
                subTitle: {
                    text: null,
                },
                links: {
                    extendLeaseContent3Item1List1Content1: {
                        headerText: null,
                        value: 'If you return your vehicle more than 30 days before the end of the lease term without purchasing it, you may have to pay a substantial charge which could be several thousand dollars.',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'extendLeaseContent3Item1List1Content1',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    extendLeaseContent3Item1List1Content2: {
                        headerText: null,
                        value: 'The actual charge will depend on when the lease is terminated, but the earlier you end the lease, the greater the charge is likely to be. For additional information, see the Early Termination section of your Lease Agreement.',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'extendLeaseContent3Item1List1Content2',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                },
            },
            extendLeaseContent3Item2: {
                id: 'extendLeaseContent3Item2',
                tags: ['auto/oem/lrfg/extendyourlease'],
                version: '20240606',
                title: {
                    text: null,
                },
                subTitle: {
                    text: null,
                },
                links: {
                    extendLeaseContent3Item2List1: {
                        headerText: null,
                        value: 'Complete the \u003C1\u003E{{digitalLeaseTurninForm}}\u003C/1\u003E or \u003C2\u003E{{contactus2}}\u003C/2\u003E within 1 business day of vehicle turn-in to make sure we have the correct date and information to calculate your lease-end bill, if you have one.',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: 'digitalLeaseTurninForm,contactus2',
                        id: 'extendLeaseContent3Item2List1',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: 'extendYourLeaseLinks',
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                },
            },
            extendLeaseContent3Item3: {
                id: 'extendLeaseContent3Item3',
                tags: ['auto/oem/lrfg/extendyourlease'],
                version: '20240606',
                title: {
                    text: null,
                },
                subTitle: {
                    text: null,
                },
                links: {
                    extendLeaseContent3Item3List1: {
                        headerText: null,
                        value: 'Early termination and other situations where the vehicle must first be sold can expect a bill about 90–120 days after vehicle turn-in.',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'extendLeaseContent3Item3List1',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                },
            },
            extendYourLeaseLinks: {
                id: 'extendYourLeaseLinks',
                tags: ['auto/oem/lrfg/extendyourlease'],
                version: '20240606',
                title: {
                    text: null,
                },
                subTitle: {
                    text: null,
                },
                links: {
                    NumberLink: {
                        headerText: 'anquer',
                        value: '1-877-369-8455.',
                        type: null,
                        presentationType: null,
                        url: 'tel:+18773698455',
                        esUrl: null,
                        id: 'NumberLink',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: 'pl_le_ext_cnt',
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    secureMessage: {
                        headerText: 'anquer',
                        value: 'Secure Message',
                        type: null,
                        presentationType: null,
                        url: 'https://www.chase.com/digital/login-secure-message?jp_cdf=LRFG',
                        esUrl: null,
                        id: 'secureMessage',
                        hat: null,
                        navigationKeyId: 'Timed Modal',
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: 'pl_le_secure',
                        trackingType: null,
                        showSpeedBump: true,
                    },
                    chaseDotCom: {
                        headerText: 'anquer',
                        value: 'chase.com',
                        type: null,
                        presentationType: null,
                        url: 'http://chase.com',
                        esUrl: null,
                        id: 'chaseDotCom',
                        hat: null,
                        navigationKeyId: 'Timed Modal',
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: true,
                    },
                    contactus: {
                        headerText: 'anquer',
                        value: 'Contact Us',
                        type: null,
                        presentationType: null,
                        url: '/contact-us',
                        esUrl: null,
                        id: 'contactus',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: 'pl_le_contactus2',
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    oneFooter: {
                        headerText: 'sup',
                        value: '1',
                        type: null,
                        presentationType: null,
                        url: '#FooterContent',
                        esUrl: null,
                        id: 'oneFooter',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    odometerDisclosureStatement: {
                        headerText: 'anquer',
                        value: 'Odometer Disclosure Statement',
                        type: null,
                        presentationType: null,
                        url: 'https://www.chase.com/content/dam/chase-ux/documents/personal/auto-loans/odometer_statement_lease.pdf?jp_cdf=LRFG',
                        esUrl: null,
                        id: 'odometerDisclosureStatement',
                        hat: null,
                        navigationKeyId: 'Timed Modal',
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: 'pl_le_odo_Stm',
                        trackingType: null,
                        showSpeedBump: true,
                    },
                    leaseBuyOut: {
                        headerText: 'anquer',
                        value: 'chase.com/leasebuyout',
                        type: null,
                        presentationType: null,
                        url: 'https://secure.chase.com/web/oao/application/retail#/origination;cfgCode=060003;offerCode=L2L/index/index',
                        esUrl: null,
                        id: 'leaseBuyOut',
                        hat: null,
                        navigationKeyId: 'Timed Modal',
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: 'pl_le_leasebuy',
                        trackingType: null,
                        showSpeedBump: true,
                    },
                    loanStatus: {
                        headerText: 'anquer',
                        value: 'chase.com/loanstatus',
                        type: null,
                        presentationType: null,
                        url: 'https://www.chase.com/personal/auto-loans/checkloanstatus',
                        esUrl: null,
                        id: 'loanStatus',
                        hat: null,
                        navigationKeyId: 'Timed Modal',
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: 'pl_le_loan_stat',
                        trackingType: null,
                        showSpeedBump: true,
                    },
                    twoFooter: {
                        headerText: 'sup',
                        value: '2',
                        type: null,
                        presentationType: null,
                        url: '#FooterContent',
                        esUrl: null,
                        id: 'twoFooter',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    digitalLeaseTurninForm: {
                        headerText: 'anquer',
                        value: 'Digital Lease Turn-in form',
                        type: null,
                        presentationType: null,
                        url: '/lease-end-turnin',
                        esUrl: null,
                        id: 'digitalLeaseTurninForm',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: 'pl_le_turnin',
                        trackingType: null,
                        showSpeedBump: false,
                    },
                },
            },
            extendYourLeaseLinks2: {
                id: 'extendYourLeaseLinks2',
                tags: ['auto/oem/lrfg/extendyourlease'],
                version: '20240613',
                title: {
                    text: null,
                },
                subTitle: {
                    text: null,
                },
                links: {
                    contactus1: {
                        headerText: 'anquer',
                        value: 'Contact Us',
                        type: null,
                        presentationType: null,
                        url: '/contact-us',
                        esUrl: null,
                        id: 'contactus1',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: 'pl_le_contactus1',
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    contactus2: {
                        headerText: 'anquer',
                        value: 'Contact Us',
                        type: null,
                        presentationType: null,
                        url: '/contact-us',
                        esUrl: null,
                        id: 'contactus2',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                },
            },
        },
        carousel: {
            AdditionalLeaseEndOptionsList: {
                brands: null,
                segments: null,
                tags: ['auto/oem/lrfg/extendyourlease'],
                id: 'AdditionalLeaseEndOptionsList',
                title: {
                    html: '\u003Cp\u003EADDITIONAL LEASE-END OPTIONS\u003C/p\u003E\n',
                    text: 'ADDITIONAL LEASE-END OPTIONS',
                    markdown: 'ADDITIONAL LEASE-END OPTIONS\n',
                },
                subTitle: {
                    html: null,
                    text: null,
                    markdown: null,
                },
                version: '20240606',
                heroes: [],
                tiles: {
                    AdditionalLeaseEndOption1: {
                        brands: null,
                        segments: null,
                        tags: ['ccb-cf:auto/oem/lrfg/extendyourlease'],
                        id: 'AdditionalLeaseEndOption1',
                        variationId: null,
                        title: {
                            html: '\u003Cp\u003EEXPLORE A NEW LAND ROVER\u003C/p\u003E\n',
                            text: 'EXPLORE A NEW LAND ROVER',
                            markdown: 'EXPLORE A NEW LAND ROVER\n',
                        },
                        subTitle: {
                            html: '\u003Cp\u003ELet Land Rover exceed your expectations. Learn what you need to know to purchase or lease a Land Rover.\u003C/p\u003E\n',
                            text: 'Let Land Rover exceed your expectations. Learn what you need to know to purchase or lease a Land Rover.',
                            markdown: 'Let Land Rover exceed your expectations. Learn what you need to know to purchase or lease a Land Rover.\n',
                        },
                        version: '20240606',
                        body: {
                            html: null,
                            text: null,
                            markdown: null,
                        },
                        additionalText: {
                            html: null,
                            text: null,
                            markdown: null,
                        },
                        hat: null,
                        iconImage: {
                            altText: null,
                            id: 'AdditionalLeaseEndOption1Image',
                            source: null,
                            url: null,
                        },
                        primaryImage: {
                            altText: 'ExploreANewLandRover',
                            id: null,
                            source: '/content/dam/unified-assets/photography/partner/land-rover/optimized/Get_New_Lease_End_Addl_Options_Card_1.jpg',
                            url: 'https://static2-qa2.chasecdn.com/content/dam/unified-assets/photography/partner/land-rover/optimized/Get_New_Lease_End_Addl_Options_Card_1.jpg',
                        },
                        primaryImageWithRendition: null,
                        secondaryImageWithRendition: null,
                        links: {
                            AdditionalLeaseEndOption1Link: {
                                value: 'SEE OPTIONS',
                                type: null,
                                languageCode: null,
                                disabled: null,
                                active: null,
                                underLined: null,
                                inversed: null,
                                presentationType: null,
                                url: '/lease-end/new',
                                esUrl: null,
                                id: 'AdditionalLeaseEndOption1Link',
                                hat: null,
                                navigationKeyId: null,
                                target: null,
                                iconId: null,
                                icon: null,
                                trackingId: null,
                                trackingType: null,
                                showSpeedBump: false,
                                headerText: null,
                            },
                        },
                    },
                    AdditionalLeaseEndOption2: {
                        brands: null,
                        segments: null,
                        tags: ['ccb-cf:auto/oem/lrfg/extendyourlease'],
                        id: 'AdditionalLeaseEndOption2',
                        variationId: null,
                        title: {
                            html: '\u003Cp\u003ERETURN YOUR CURRENT LAND ROVER\u003C/p\u003E\n',
                            text: 'RETURN YOUR CURRENT LAND ROVER',
                            markdown: 'RETURN YOUR CURRENT LAND ROVER\n',
                        },
                        subTitle: {
                            html: '\u003Cp\u003EYou’re getting close to the end of your lease, so it’s time to figure out how to return your Land Rover. Review our timeline so you can have a successful turn-in experience.\u003C/p\u003E\n',
                            text: 'You’re getting close to the end of your lease, so it’s time to figure out how to return your Land Rover. Review our timeline so you can have a successful turn-in experience.',
                            markdown: "You're getting close to the end of your lease, so it's time to figure out how to return your Land Rover. Review our timeline so you can have a successful turn-in experience.\n",
                        },
                        version: '20240606',
                        body: {
                            html: null,
                            text: null,
                            markdown: null,
                        },
                        additionalText: {
                            html: null,
                            text: null,
                            markdown: null,
                        },
                        hat: null,
                        iconImage: {
                            altText: null,
                            id: 'AdditionalLeaseEndOption2Image',
                            source: null,
                            url: null,
                        },
                        primaryImage: {
                            altText: 'ReturnYourCurrentLandRoverText',
                            id: null,
                            source: '/content/dam/unified-assets/photography/partner/land-rover/optimized/Return_Lease_End_Addl_Options_Card_1.jpg',
                            url: 'https://static2-qa2.chasecdn.com/content/dam/unified-assets/photography/partner/land-rover/optimized/Return_Lease_End_Addl_Options_Card_1.jpg',
                        },
                        primaryImageWithRendition: null,
                        secondaryImageWithRendition: null,
                        links: {
                            AdditionalLeaseEndOption2Link: {
                                value: 'HOW TO RETURN YOUR LAND ROVER',
                                type: null,
                                languageCode: null,
                                disabled: null,
                                active: null,
                                underLined: null,
                                inversed: null,
                                presentationType: null,
                                url: '/lease-end/return',
                                esUrl: null,
                                id: 'AdditionalLeaseEndOption2Link',
                                hat: null,
                                navigationKeyId: null,
                                target: null,
                                iconId: null,
                                icon: null,
                                trackingId: null,
                                trackingType: null,
                                showSpeedBump: false,
                                headerText: null,
                            },
                        },
                    },
                },
            },
        },
        iconList: {
            FooterList: {
                tags: ['auto/oem/lrfg/extendyourlease'],
                id: 'FooterList',
                title: {
                    html: null,
                    text: null,
                    markdown: null,
                },
                subTitle: {
                    html: null,
                    text: null,
                    markdown: null,
                },
                version: '20240606',
                icons: {
                    findARetailer: {
                        id: 'findARetailer',
                        text: 'Find A Land Rover Retailer',
                        image: {
                            altText: null,
                            id: null,
                            source: '/content/dam/unified-assets/iconography/auto/ico_auto_location.svg',
                            url: 'https://static2-qa2.chasecdn.com/content/dam/unified-assets/iconography/auto/ico_auto_location.svg',
                        },
                        primaryImage: null,
                        link: {
                            value: 'Find A Land Rover Retailer',
                            type: null,
                            languageCode: null,
                            disabled: null,
                            active: null,
                            underLined: null,
                            inversed: null,
                            presentationType: null,
                            url: 'https://www.landroverusa.com/national-dealer-locator.html',
                            esUrl: null,
                            id: 'FindaLandRoverRetailer',
                            hat: null,
                            navigationKeyId: 'Untimed Modal',
                            target: null,
                            iconId: null,
                            icon: null,
                            trackingId: 'pl_find_retailer',
                            trackingType: null,
                            showSpeedBump: true,
                            headerText: null,
                        },
                    },
                    FAQ: {
                        id: 'FAQ',
                        text: 'FAQs',
                        image: {
                            altText: null,
                            id: null,
                            source: '/content/dam/unified-assets/iconography/auto/ico_auto_question_mark_circle.svg',
                            url: 'https://static2-qa2.chasecdn.com/content/dam/unified-assets/iconography/auto/ico_auto_question_mark_circle.svg',
                        },
                        primaryImage: null,
                        link: {
                            value: 'faq',
                            type: null,
                            languageCode: null,
                            disabled: null,
                            active: null,
                            underLined: null,
                            inversed: null,
                            presentationType: null,
                            url: '/faq',
                            esUrl: null,
                            id: 'FAQ',
                            hat: null,
                            navigationKeyId: null,
                            target: null,
                            iconId: null,
                            icon: null,
                            trackingId: 'pl_faq',
                            trackingType: null,
                            showSpeedBump: false,
                            headerText: null,
                        },
                    },
                    contactUs: {
                        id: 'contactUs',
                        text: 'CONTACT US',
                        image: {
                            altText: null,
                            id: null,
                            source: '/content/dam/unified-assets/iconography/auto/ico_auto_people_group.svg',
                            url: 'https://static2-qa2.chasecdn.com/content/dam/unified-assets/iconography/auto/ico_auto_people_group.svg',
                        },
                        primaryImage: null,
                        link: {
                            value: 'contactUsImage',
                            type: null,
                            languageCode: null,
                            disabled: null,
                            active: null,
                            underLined: null,
                            inversed: null,
                            presentationType: null,
                            url: '/contact-us',
                            esUrl: null,
                            id: 'contactUs',
                            hat: null,
                            navigationKeyId: null,
                            target: null,
                            iconId: null,
                            icon: null,
                            trackingId: 'pl_contact',
                            trackingType: null,
                            showSpeedBump: false,
                            headerText: null,
                        },
                    },
                },
            },
        },
        legalContainer: {
            legalContainer: {
                brands: null,
                segments: null,
                tags: ['auto/oem/lrfg/extendyourlease'],
                id: 'legalContainer',
                title: {
                    html: null,
                    text: null,
                    markdown: null,
                },
                subTitle: {
                    html: null,
                    text: null,
                    markdown: null,
                },
                disclaimer: [],
                disclosure: [],
                footNote: {
                    FooterContent1: {
                        id: 'FooterContent1',
                        title: {
                            html: '\u003Cp\u003EChase Mobile® app is available for select mobile devices. Message and data rates may apply.\u003C/p\u003E\n',
                            text: 'Chase Mobile® app is available for select mobile devices. Message and data rates may apply.',
                            markdown: 'Chase Mobile® app is available for select mobile devices. Message and data rates may apply.\n',
                        },
                        description: {
                            html: null,
                            text: null,
                            markdown: null,
                        },
                        links: [],
                    },
                    FooterContent2: {
                        id: 'FooterContent2',
                        title: {
                            html: "\u003Cp\u003EAccount Alerts: There's no charge from Chase, but message and data rates may apply. Delivery of alerts may be delayed for various reasons, including service outages affecting your phone, wireless or internet provider; technology failures; and system capacity limitations.\u003C/p\u003E\n",
                            text: "Account Alerts: There's no charge from Chase, but message and data rates may apply. Delivery of alerts may be delayed for various reasons, including service outages affecting your phone, wireless or internet provider; technology failures; and system capacity limitations.",
                            markdown: "Account Alerts: There's no charge from Chase, but message and data rates may apply. Delivery of alerts may be delayed for various reasons, including service outages affecting your phone, wireless or internet provider; technology failures; and system capacity limitations.\n",
                        },
                        description: {
                            html: null,
                            text: null,
                            markdown: null,
                        },
                        links: [],
                    },
                },
                contentLists: [],
                version: '20240606',
            },
        },
        dialog: {},
        contentList: {
            earlyTurnInOption1: {
                id: 'earlyTurnInOption1',
                title: {
                    html: '\u003Cp\u003EEARLY TERMINATION CHARGE IF YOU TURN IN YOUR VEHICLE MORE THAN 30 DAYS EARLY\u003C/p\u003E\n',
                    text: 'EARLY TERMINATION CHARGE IF YOU TURN IN YOUR VEHICLE MORE THAN 30 DAYS EARLY',
                    markdown: 'EARLY TERMINATION CHARGE IF YOU TURN IN YOUR VEHICLE MORE THAN 30 DAYS EARLY\n',
                },
                tags: ['auto/oem/lrfg/extendyourlease'],
                version: '20240606',
                items: {
                    EarlyTurnInItem1: {
                        id: 'EarlyTurnInItem1',
                        value: 'If you return your vehicle more than 30 days before the end of the lease term without purchasing it, you may have to pay a substantial charge which could be several thousand dollars. The actual charge will depend on when the lease is terminated, but the earlier you end the lease, the greater the charge is likely to be. For additional information, see the Early Termination section of your Lease Agreement.',
                    },
                    EarlyTurnInItem2: {
                        id: 'EarlyTurnInItem2',
                        value: 'Keep in mind that you’ll still owe any other charges due for the lease, such as any past due payments, unpaid late fees and taxes.',
                    },
                    EarlyTurnInItem3: {
                        id: 'EarlyTurnInItem3',
                        value: 'Also, if your state charges personal property tax on the vehicle, you’ll remain responsible for any future tax bills for tax periods occurring before you returned the vehicle.',
                    },
                },
            },
            earlyTurnInOption2: {
                id: 'earlyTurnInOption2',
                title: {
                    html: '\u003Cp\u003ECONTACT US WITHIN 1 BUSINESS DAY OF YOUR VEHICLE TURN-IN DATE\u003C/p\u003E\n',
                    text: 'CONTACT US WITHIN 1 BUSINESS DAY OF YOUR VEHICLE TURN-IN DATE',
                    markdown: 'CONTACT US WITHIN 1 BUSINESS DAY OF YOUR VEHICLE TURN-IN DATE\n',
                },
                tags: ['auto/oem/lrfg/extendyourlease'],
                version: '20240606',
                items: {
                    EarlyTurnInItem1: {
                        id: 'EarlyTurnInItem1',
                        value: 'Early termination and other situations where the vehicle must first be sold can expect a bill about 90–120 days after vehicle turn-in.',
                    },
                    earlyTurnInOption3: {
                        id: 'earlyTurnInOption3',
                        value: "IF YOU HAVE LEASE-END CHARGES, YOU'LL RECEIVE YOUR BILL IN THE MAIL",
                    },
                },
            },
        },
        tile: {
            ExtendyourLease: {
                tags: ['auto/oem/lrfg/extendyourlease'],
                id: 'ExtendyourLease',
                variationId: null,
                title: {
                    html: '\u003Cp\u003EEXTEND YOUR LEASE | PURCHASE CURRENT LAND ROVER | EARLY TURN IN Page\u003C/p\u003E\n',
                    text: 'EXTEND YOUR LEASE | PURCHASE CURRENT LAND ROVER | EARLY TURN IN Page',
                    markdown: 'EXTEND YOUR LEASE \\| PURCHASE CURRENT LAND ROVER \\| EARLY TURN IN Page\n',
                },
                subTitle: {
                    html: '\u003Cp\u003ECHECK OUT THE NEWEST LAND ROVER\u003C/p\u003E\n',
                    text: 'CHECK OUT THE NEWEST LAND ROVER',
                    markdown: 'CHECK OUT THE NEWEST LAND ROVER\n',
                },
                version: '20240606',
                body: {
                    html: '\u003Cp\u003EEXPERIENCE PEERLESS REFINEMENT AND LUXURY\u003C/p\u003E\n',
                    text: 'EXPERIENCE PEERLESS REFINEMENT AND LUXURY',
                    markdown: 'EXPERIENCE PEERLESS REFINEMENT AND LUXURY\n',
                },
                additionalText: {
                    html: null,
                    text: null,
                    markdown: null,
                },
                hat: null,
                iconImage: {
                    altText: null,
                    id: 'extendYourLeaseImage',
                    source: null,
                    url: null,
                },
                primaryImage: {
                    altText: 'extendYourLease',
                    id: null,
                    source: '/content/dam/unified-assets/photography/partner/land-rover/optimized/Lease_End_Other_Hero_Desktop1.jpg',
                    url: 'https://static2-qa2.chasecdn.com/content/dam/unified-assets/photography/partner/land-rover/optimized/Lease_End_Other_Hero_Desktop1.jpg',
                },
                primaryImageWithRendition: null,
                secondaryImageWithRendition: null,
                links: {
                    exploreInventory: {
                        value: 'Explore Inventory',
                        type: null,
                        languageCode: null,
                        disabled: null,
                        active: null,
                        underLined: null,
                        inversed: null,
                        presentationType: null,
                        url: 'https://inventory.landroverusa.com/new/#/',
                        esUrl: null,
                        id: 'exploreInventory',
                        hat: null,
                        navigationKeyId: 'Untimed Modal',
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: 'pl_le_explore_inevntory',
                        trackingType: null,
                        showSpeedBump: true,
                        headerText: null,
                        visibleIn: null,
                        variant: null,
                    },
                },
            },
        },
        structuredContentList: {
            EarlyTurnInList: {
                tags: ['auto/oem/lrfg/extendyourlease'],
                id: 'EarlyTurnInList',
                title: {
                    html: '\u003Cp\u003EEarly Turn In\u003C/p\u003E\n',
                    text: 'Early Turn In',
                    markdown: 'Early Turn In\n',
                },
                subTitle: {
                    html: null,
                    text: null,
                    markdown: null,
                },
                version: '20240606',
                items: {
                    earlyTurnInOption: {
                        id: 'earlyTurnInOption',
                        text: {
                            html: '\u003Cp\u003EEarly Turn In\u003C/p\u003E\n',
                            text: 'Early Turn In',
                            markdown: 'Early Turn In\n',
                        },
                        description: {
                            html: null,
                            text: null,
                            markdown: null,
                        },
                        additionalText: {
                            html: null,
                            text: null,
                            markdown: null,
                        },
                        hat: null,
                        iconId: null,
                        primaryImage: {
                            altText: null,
                            id: '',
                            source: null,
                            url: null,
                        },
                        imageWithRenditions: [],
                    },
                },
                links: [],
                mergeId: null,
            },
        },
        header: {},
    },
};
