export const accounts = {
    data: {
        linkList: {
            accountHeroBannerButton: {
                id: 'accountHeroBannerButton',
                tags: ['auto/oem/lrfg/myaccount'],
                version: '20240606',
                title: {
                    text: null,
                },
                subTitle: {
                    text: null,
                },
                links: {
                    exploreVehicleButtonLabel: {
                        headerText: null,
                        value: 'EXPLORE THIS VEHICLE',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'exploreVehicleButtonLabel',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: true,
                    },
                    makeAPaymentButtonLabel: {
                        headerText: null,
                        value: 'MAKE A PAYMENT',
                        type: null,
                        presentationType: null,
                        url: 'https://secure.chase.com/web/auth/dashboard?navKey=requestOverviewDashboard&jp_cdf=LRFG_MA_PMT',
                        esUrl: null,
                        id: 'makeAPaymentButtonLabel',
                        hat: null,
                        navigationKeyId: 'Timed Modal',
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: 'pl_acct_mke_pmt',
                        trackingType: null,
                        showSpeedBump: true,
                    },
                },
            },
            accountSummaryCommonLabelsList: {
                id: 'accountSummaryCommonLabelsList',
                tags: ['auto/oem/lrfg/myaccount'],
                version: '20240606',
                title: {
                    text: 'LAND ROVER ACCOUNT DETAILS',
                },
                subTitle: {
                    text: null,
                },
                links: {
                    accountDetailsLabel: {
                        headerText: null,
                        value: 'ACCOUNT DETAILS',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'accountDetailsLabel',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: 'chevronBlackDownIcon',
                        icon: null,
                        trackingId: 'pl_acct_details',
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    manageYourAccountLabel: {
                        headerText: null,
                        value: 'MANAGE YOUR ACCOUNT AT CHASE.COM',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'manageYourAccountLabel',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    lookingForSomethingLabel: {
                        headerText: null,
                        value: 'LOOKING FOR SOMETHING ELSE? VISIT <1>{{CHASEDOTCOM}}</1>',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: 'CHASEDOTCOM',
                        id: 'lookingForSomethingLabel',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: 'stringLinksRefList',
                        icon: null,
                        trackingId: 'pl_acct_visit_chase',
                        trackingType: null,
                        showSpeedBump: false,
                    },
                },
            },
            estimatedMilesInfoTextList: {
                id: 'estimatedMilesInfoTextList',
                tags: ['auto/oem/lrfg/myaccount'],
                version: '20240606',
                title: {
                    text: null,
                },
                subTitle: {
                    text: null,
                },
                links: {
                    estimatedMilesInfoTextPart1: {
                        headerText: null,
                        value: 'To determine your estimated miles, we add your mileage allowance to your vehicle’s odometer reading when you lease it, and then divide that total by the number of days on your lease.',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'estimatedMilesInfoTextPart1',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    estimatedMilesInfoTextPart2: {
                        headerText: null,
                        value: 'The estimate you see is based on today’s date. If you extended your lease, we’ll pro-rate your mileage allowance one month at a time.',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'estimatedMilesInfoTextPart2',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                },
            },
            leaseAccountSummaryDetailsList: {
                id: 'leaseAccountSummaryDetailsList',
                tags: ['auto/oem/lrfg/myaccount'],
                version: '20240606',
                title: {
                    text: null,
                },
                subTitle: {
                    text: null,
                },
                links: {
                    leaseStartDateLabel: {
                        headerText: null,
                        value: 'LEASE START DATE',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'leaseStartDateLabel',
                        hat: null,
                        navigationKeyId: 'date',
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    accountNumberLabel: {
                        headerText: null,
                        value: 'ACCOUNT NUMBER',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'accountNumberLabel',
                        hat: null,
                        navigationKeyId: 'accountNumber',
                        target: null,
                        iconId: 'eyeIcon',
                        icon: null,
                        trackingId: 'pl_acct_numb_lse',
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    vinLabel: {
                        headerText: null,
                        value: 'VIN',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'vinLabel',
                        hat: null,
                        navigationKeyId: 'vin',
                        target: null,
                        iconId: 'eyeIcon',
                        icon: null,
                        trackingId: 'pl_acct_vin',
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    accountAddressLabel: {
                        headerText: null,
                        value: 'ACCOUNT MAILING ADDRESS',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'accountAddressLabel',
                        hat: null,
                        navigationKeyId: 'address',
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    lastPaymentDateLabel: {
                        headerText: null,
                        value: 'LAST PAYMENT DATE',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'lastPaymentDateLabel',
                        hat: null,
                        navigationKeyId: 'date',
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    lastPaymentAmountLabel: {
                        headerText: null,
                        value: 'LAST PAYMENT AMOUNT',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'lastPaymentAmountLabel',
                        hat: null,
                        navigationKeyId: 'amount',
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                },
            },
            leaseAccountSummaryList: {
                id: 'leaseAccountSummaryList',
                tags: ['auto/oem/lrfg/myaccount'],
                version: '20240606',
                title: {
                    text: null,
                },
                subTitle: {
                    text: null,
                },
                links: {
                    estimatedMilesLabel: {
                        headerText: 'linklistId-estimatedMilesInfoTextList',
                        value: 'ESTIMATED MILES',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'estimatedMilesLabel',
                        hat: null,
                        navigationKeyId: 'progressbar',
                        target: null,
                        iconId: 'infoIcon',
                        icon: null,
                        trackingId: 'pl_acct_mile_info',
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    completedPaymentsLabel1: {
                        headerText: null,
                        value: 'COMPLETED PAYMENTS',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'completedPaymentsLabel1',
                        hat: null,
                        navigationKeyId: 'amount',
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    leaseEndDateLabel: {
                        headerText: null,
                        value: 'LEASE END DATE',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'leaseEndDateLabel',
                        hat: null,
                        navigationKeyId: 'date',
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    leaseTermLabel: {
                        headerText: null,
                        value: 'LEASE TERM',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'leaseTermLabel',
                        hat: null,
                        navigationKeyId: 'progressbar',
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                },
            },
            loanAccountSummaryDetailsList: {
                id: 'loanAccountSummaryDetailsList',
                tags: ['auto/oem/lrfg/myaccount'],
                version: '20240627',
                title: {
                    text: null,
                },
                subTitle: {
                    text: null,
                },
                links: {
                    originationDateLabel: {
                        headerText: null,
                        value: 'LOAN START DATE',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'originationDateLabel',
                        hat: null,
                        navigationKeyId: 'date',
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    loanTermLabel: {
                        headerText: "This is your original loan term. If you've requested to defer any payments during your loan, your current loan term may be different than what is shown here.",
                        value: 'LOAN TERM',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'loanTermLabel',
                        hat: null,
                        navigationKeyId: 'progressbar',
                        target: null,
                        iconId: 'infoIcon',
                        icon: null,
                        trackingId: 'pl_acct_term_info',
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    vinLabel: {
                        headerText: null,
                        value: 'VIN',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'vinLabel',
                        hat: null,
                        navigationKeyId: 'vin',
                        target: null,
                        iconId: 'eyeIcon',
                        icon: null,
                        trackingId: 'pl_acct_vin',
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    accountAddressLabel: {
                        headerText: null,
                        value: 'ACCOUNT MAILING ADDRESS',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'accountAddressLabel',
                        hat: null,
                        navigationKeyId: 'address',
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    lastPaymentDateLabel: {
                        headerText: null,
                        value: 'LAST PAYMENT DATE',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'lastPaymentDateLabel',
                        hat: null,
                        navigationKeyId: 'date',
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    lastPaymentAmountLabel: {
                        headerText: null,
                        value: 'LAST PAYMENT AMOUNT',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'lastPaymentAmountLabel',
                        hat: null,
                        navigationKeyId: 'amount',
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                },
            },
            loanAccountSummaryList: {
                id: 'loanAccountSummaryList',
                tags: ['auto/oem/lrfg/myaccount'],
                version: '20240606',
                title: {
                    text: null,
                },
                subTitle: {
                    text: null,
                },
                links: {
                    accountNumberLabel: {
                        headerText: null,
                        value: 'ACCOUNT NUMBER',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'accountNumberLabel',
                        hat: null,
                        navigationKeyId: 'accountNumber',
                        target: null,
                        iconId: 'eyeIcon',
                        icon: null,
                        trackingId: 'pl_acct_numb',
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    interestRateLabel: {
                        headerText: null,
                        value: 'INTEREST RATE',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'interestRateLabel',
                        hat: null,
                        navigationKeyId: 'percentage',
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    remainingBalanceLabel: {
                        headerText: 'Your remaining balance is not the same as your payoff amount. Go to <1>{{chase.com}}</1> to get your payoff quote.',
                        value: 'REMAINING BALANCE',
                        type: null,
                        presentationType: null,
                        url: 'payoffLabel',
                        esUrl: 'remainingBalanceInfoChase',
                        id: 'remainingBalanceLabel',
                        hat: null,
                        navigationKeyId: 'amount',
                        target: null,
                        iconId: 'infoIcon',
                        icon: null,
                        trackingId: 'pl_acct_rmn_bal_info',
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    completedPaymentsLabel: {
                        headerText: 'This is your original loan term. If you’ve requested to defer any payments during your loan, your completed and total payments required to meet the terms of your agreement may be different than what is shown here.',
                        value: 'COMPLETED PAYMENTS',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'completedPaymentsLabel',
                        hat: null,
                        navigationKeyId: 'progressbar',
                        target: null,
                        iconId: 'infoIcon',
                        icon: null,
                        trackingId: 'pl_acct_comp_pmt_info',
                        trackingType: null,
                        showSpeedBump: false,
                    },
                },
            },
            mobileLeaseAccountSummaryDetailsList: {
                id: 'mobileLeaseAccountSummaryDetailsList',
                tags: ['auto/oem/lrfg/myaccount'],
                version: '20240627',
                title: {
                    text: null,
                },
                subTitle: {
                    text: null,
                },
                links: {
                    leaseStartDateLabel: {
                        headerText: null,
                        value: 'LEASE START DATE',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'leaseStartDateLabel',
                        hat: null,
                        navigationKeyId: 'date',
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    loanTermLabel: {
                        headerText: null,
                        value: 'LAST PAYMENT DATE',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'loanTermLabel',
                        hat: null,
                        navigationKeyId: 'date',
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    accountNumberLabel: {
                        headerText: null,
                        value: 'ACCOUNT NUMBER',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'accountNumberLabel',
                        hat: null,
                        navigationKeyId: 'accountNumber',
                        target: null,
                        iconId: 'eyeIcon',
                        icon: null,
                        trackingId: 'pl_acct_numb_lse',
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    lastPaymentAmountLabel: {
                        headerText: null,
                        value: 'LAST PAYMENT AMOUNT',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'lastPaymentAmountLabel',
                        hat: null,
                        navigationKeyId: 'amount',
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    lastPaymentDateLabel: {
                        headerText: null,
                        value: 'VIN',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'lastPaymentDateLabel',
                        hat: null,
                        navigationKeyId: 'vin',
                        target: null,
                        iconId: 'eyeIcon',
                        icon: null,
                        trackingId: 'pl_acct_vin',
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    accountAddressLabel: {
                        headerText: null,
                        value: 'ACCOUNT MAILING ADDRESS',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'accountAddressLabel',
                        hat: null,
                        navigationKeyId: 'address',
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                },
            },
            mobileLoanAccountSummaryDetailsList: {
                id: 'mobileLoanAccountSummaryDetailsList',
                tags: ['auto/oem/lrfg/myaccount'],
                version: '20240627',
                title: {
                    text: null,
                },
                subTitle: {
                    text: null,
                },
                links: {
                    originationDateLabel: {
                        headerText: null,
                        value: 'LOAN START DATE',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'originationDateLabel',
                        hat: null,
                        navigationKeyId: 'date',
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    lastPaymentDateLabel: {
                        headerText: null,
                        value: 'LAST PAYMENT DATE',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'lastPaymentDateLabel',
                        hat: null,
                        navigationKeyId: 'date',
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    loanTermLabel: {
                        headerText: "This is your original loan term. If you've requested to defer any payments during your loan, your current loan term may be different than what is shown here.",
                        value: 'LOAN TERM',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'loanTermLabel',
                        hat: null,
                        navigationKeyId: 'progressbar',
                        target: null,
                        iconId: 'infoIcon',
                        icon: null,
                        trackingId: 'pl_acct_term_info',
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    lastPaymentAmountLabel: {
                        headerText: null,
                        value: 'LAST PAYMENT AMOUNT',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'lastPaymentAmountLabel',
                        hat: null,
                        navigationKeyId: 'amount',
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    vinLabel: {
                        headerText: null,
                        value: 'VIN',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'vinLabel',
                        hat: null,
                        navigationKeyId: 'vin',
                        target: null,
                        iconId: 'eyeIcon',
                        icon: null,
                        trackingId: 'pl_acct_vin',
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    accountAddressLabel: {
                        headerText: null,
                        value: 'ACCOUNT MAILING ADDRESS',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'accountAddressLabel',
                        hat: null,
                        navigationKeyId: 'address',
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                },
            },
            payoffLabel: {
                id: 'payoffLabel',
                tags: ['auto/oem/lrfg/myaccount'],
                version: '20240606',
                title: {
                    text: null,
                },
                subTitle: {
                    text: null,
                },
                links: {
                    requestPayoffLabel: {
                        headerText: null,
                        value: 'REQUEST PAYOFF',
                        type: null,
                        presentationType: null,
                        url: 'https://secure.chase.com/web/auth/dashboard?navKey=requestOverviewDashboard&jp_cdf=LRFG_MA_PYOFF',
                        esUrl: null,
                        id: 'requestPayoffLabel',
                        hat: null,
                        navigationKeyId: 'Timed Modal',
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: 'pl_acct_rqst_poff',
                        trackingType: null,
                        showSpeedBump: false,
                    },
                },
            },
            stringLinksRefList: {
                id: 'stringLinksRefList',
                tags: ['auto/oem/lrfg/myaccount'],
                version: '20240606',
                title: {
                    text: null,
                },
                subTitle: {
                    text: null,
                },
                links: {
                    CHASEDOTCOM: {
                        headerText: 'anquer',
                        value: 'chase.com',
                        type: null,
                        presentationType: null,
                        url: 'https://secure.chase.com/web/auth/dashboard?navKey=requestOverviewDashboard&jp_cdf=LRFG_MA_VISIT',
                        esUrl: null,
                        id: 'CHASEDOTCOM',
                        hat: null,
                        navigationKeyId: 'Timed Modal',
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: 'pl_acct_visit_chase',
                        trackingType: null,
                        showSpeedBump: true,
                    },
                    remainingBalanceInfoChase: {
                        headerText: 'anquer',
                        value: 'chase.com',
                        type: null,
                        presentationType: null,
                        url: 'https://secure.chase.com/web/auth/dashboard?navKey=requestOverviewDashboard&jp_cdf=LRFG_MA_PYOFF',
                        esUrl: null,
                        id: 'remainingBalanceInfoChase',
                        hat: null,
                        navigationKeyId: 'Timed Modal',
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: 'pl_acct_rmn_bal_info_chse',
                        trackingType: null,
                        showSpeedBump: true,
                    },
                },
            },
        },
        carousel: {
            LeaseEndExploreMoreList: {
                brands: null,
                segments: null,
                tags: ['auto/oem/lrfg/myaccount'],
                id: 'LeaseEndExploreMoreList',
                title: {
                    html: '<p>EXPLORE MORE WITH LAND ROVER</p>\n',
                    text: 'EXPLORE MORE WITH LAND ROVER',
                    markdown: 'EXPLORE MORE WITH LAND ROVER\n',
                },
                subTitle: {
                    html: null,
                    text: null,
                    markdown: null,
                },
                version: '20240606',
                heroes: [],
                tiles: {
                    leaseLoyaltyProgram: {
                        brands: null,
                        segments: null,
                        tags: ['ccb-cf:auto/oem/lrfg/myaccount'],
                        id: 'leaseLoyaltyProgram',
                        variationId: null,
                        title: {
                            html: '<p>LEASE LOYALTY PROGRAM</p>\n',
                            text: 'LEASE LOYALTY PROGRAM',
                            markdown: 'LEASE LOYALTY PROGRAM\n',
                        },
                        subTitle: {
                            html: null,
                            text: null,
                            markdown: null,
                        },
                        version: '20240606',
                        body: {
                            html: null,
                            text: null,
                            markdown: null,
                        },
                        additionalText: {
                            html: null,
                            text: null,
                            markdown: null,
                        },
                        hat: null,
                        iconImage: {
                            altText: null,
                            id: 'leaseLoyaltyProgramImage',
                            source: null,
                            url: null,
                        },
                        primaryImage: {
                            altText: 'leaseLoyaltyProgram',
                            id: null,
                            source: '/content/dam/unified-assets/photography/partner/land-rover/optimized/MyAccount_LoyaltyProgram_Tan1.jpg',
                            url: 'https://static2-qa2.chasecdn.com/content/dam/unified-assets/photography/partner/land-rover/optimized/MyAccount_LoyaltyProgram_Tan1.jpg',
                        },
                        primaryImageWithRendition: null,
                        secondaryImageWithRendition: null,
                        links: {
                            leaseLoyaltyProgramLink: {
                                value: null,
                                type: null,
                                languageCode: null,
                                disabled: null,
                                active: null,
                                underLined: null,
                                inversed: null,
                                presentationType: null,
                                url: 'https://landroverfinancialgroup.com/oem/financing',
                                esUrl: null,
                                id: 'leaseLoyaltyProgramLink',
                                hat: null,
                                navigationKeyId: null,
                                target: null,
                                iconId: null,
                                icon: null,
                                trackingId: 'pl_loyalty',
                                trackingType: null,
                                showSpeedBump: false,
                                headerText: null,
                            },
                        },
                    },
                    financingAndOffers: {
                        brands: null,
                        segments: null,
                        tags: ['ccb-cf:auto/oem/lrfg/myaccount'],
                        id: 'financingAndOffers',
                        variationId: null,
                        title: {
                            html: '<p>FINANCING &amp; OFFERS</p>\n',
                            text: 'FINANCING & OFFERS',
                            markdown: 'FINANCING \\& OFFERS\n',
                        },
                        subTitle: {
                            html: null,
                            text: null,
                            markdown: null,
                        },
                        version: '20240606',
                        body: {
                            html: null,
                            text: null,
                            markdown: null,
                        },
                        additionalText: {
                            html: null,
                            text: null,
                            markdown: null,
                        },
                        hat: null,
                        iconImage: {
                            altText: null,
                            id: 'financingAndOffersImage',
                            source: null,
                            url: null,
                        },
                        primaryImage: {
                            altText: 'financingAndOffers',
                            id: null,
                            source: '/content/dam/unified-assets/photography/partner/land-rover/optimized/MyAccount_FinancingOffer_Red1.jpg',
                            url: 'https://static2-qa2.chasecdn.com/content/dam/unified-assets/photography/partner/land-rover/optimized/MyAccount_FinancingOffer_Red1.jpg',
                        },
                        primaryImageWithRendition: null,
                        secondaryImageWithRendition: null,
                        links: {
                            financingAndOffersLink: {
                                value: null,
                                type: null,
                                languageCode: null,
                                disabled: null,
                                active: null,
                                underLined: null,
                                inversed: null,
                                presentationType: null,
                                url: 'https://www.landroverusa.com/current-offers/index.html',
                                esUrl: null,
                                id: 'financingAndOffersLink',
                                hat: null,
                                navigationKeyId: 'Untimed Modal',
                                target: null,
                                iconId: null,
                                icon: null,
                                trackingId: 'pl_offers_1',
                                trackingType: null,
                                showSpeedBump: true,
                                headerText: null,
                            },
                        },
                    },
                    extendYourLease: {
                        brands: null,
                        segments: null,
                        tags: ['ccb-cf:auto/oem/lrfg/myaccount'],
                        id: 'extendYourLease',
                        variationId: null,
                        title: {
                            html: '<p>EXTEND YOUR LEASE</p>\n',
                            text: 'EXTEND YOUR LEASE',
                            markdown: 'EXTEND YOUR LEASE\n',
                        },
                        subTitle: {
                            html: null,
                            text: null,
                            markdown: null,
                        },
                        version: '20240606',
                        body: {
                            html: null,
                            text: null,
                            markdown: null,
                        },
                        additionalText: {
                            html: null,
                            text: null,
                            markdown: null,
                        },
                        hat: null,
                        iconImage: {
                            altText: null,
                            id: 'extendYourLeaseImage',
                            source: null,
                            url: null,
                        },
                        primaryImage: {
                            altText: 'extendYourLease',
                            id: null,
                            source: '/content/dam/unified-assets/photography/partner/land-rover/optimized/MyAccount_ExtendYourLease_Tan1.jpg',
                            url: 'https://static2-qa2.chasecdn.com/content/dam/unified-assets/photography/partner/land-rover/optimized/MyAccount_ExtendYourLease_Tan1.jpg',
                        },
                        primaryImageWithRendition: null,
                        secondaryImageWithRendition: null,
                        links: {
                            extendYourLeaseLink: {
                                value: null,
                                type: null,
                                languageCode: null,
                                disabled: null,
                                active: null,
                                underLined: null,
                                inversed: null,
                                presentationType: null,
                                url: 'https://www.landroverusa.com/our-story/news/index.html',
                                esUrl: null,
                                id: 'extendYourLeaseLink',
                                hat: null,
                                navigationKeyId: 'Untimed Modal',
                                target: null,
                                iconId: null,
                                icon: null,
                                trackingId: 'pl_le_extend',
                                trackingType: null,
                                showSpeedBump: false,
                                headerText: null,
                            },
                        },
                    },
                },
            },
            LeaseStartExploreMoreList: {
                brands: null,
                segments: null,
                tags: ['auto/oem/lrfg/myaccount'],
                id: 'LeaseStartExploreMoreList',
                title: {
                    html: '<p>EXPLORE MORE WITH LAND ROVER</p>\n',
                    text: 'EXPLORE MORE WITH LAND ROVER',
                    markdown: 'EXPLORE MORE WITH LAND ROVER\n',
                },
                subTitle: {
                    html: null,
                    text: null,
                    markdown: null,
                },
                version: '20240606',
                heroes: [],
                tiles: {
                    experiences: {
                        brands: null,
                        segments: null,
                        tags: ['ccb-cf:auto/oem/lrfg/myaccount'],
                        id: 'experiences',
                        variationId: null,
                        title: {
                            html: '<p>LANDROVER EXPERIENCES</p>\n',
                            text: 'LANDROVER EXPERIENCES',
                            markdown: 'LANDROVER EXPERIENCES\n',
                        },
                        subTitle: {
                            html: null,
                            text: null,
                            markdown: null,
                        },
                        version: '20240606',
                        body: {
                            html: null,
                            text: null,
                            markdown: null,
                        },
                        additionalText: {
                            html: null,
                            text: null,
                            markdown: null,
                        },
                        hat: null,
                        iconImage: {
                            altText: null,
                            id: 'experiencesImage',
                            source: null,
                            url: null,
                        },
                        primaryImage: {
                            altText: 'experiences',
                            id: null,
                            source: '/content/dam/unified-assets/photography/partner/land-rover/optimized/MyAccount_LandRoverExperience_Red1.jpg',
                            url: 'https://static2-qa2.chasecdn.com/content/dam/unified-assets/photography/partner/land-rover/optimized/MyAccount_LandRoverExperience_Red1.jpg',
                        },
                        primaryImageWithRendition: null,
                        secondaryImageWithRendition: null,
                        links: {
                            experiencesLink: {
                                value: null,
                                type: null,
                                languageCode: null,
                                disabled: null,
                                active: null,
                                underLined: null,
                                inversed: null,
                                presentationType: null,
                                url: 'https://experience.landroverusa.com/',
                                esUrl: null,
                                id: 'experiencesLink',
                                hat: null,
                                navigationKeyId: 'Untimed Modal',
                                target: null,
                                iconId: null,
                                icon: null,
                                trackingId: 'pl_experience',
                                trackingType: null,
                                showSpeedBump: false,
                                headerText: null,
                            },
                        },
                    },
                    ownership: {
                        brands: null,
                        segments: null,
                        tags: ['ccb-cf:auto/oem/lrfg/myaccount'],
                        id: 'ownership',
                        variationId: null,
                        title: {
                            html: '<p>OWNERSHIP</p>\n',
                            text: 'OWNERSHIP',
                            markdown: 'OWNERSHIP\n',
                        },
                        subTitle: {
                            html: null,
                            text: null,
                            markdown: null,
                        },
                        version: '20240606',
                        body: {
                            html: null,
                            text: null,
                            markdown: null,
                        },
                        additionalText: {
                            html: null,
                            text: null,
                            markdown: null,
                        },
                        hat: null,
                        iconImage: {
                            altText: null,
                            id: 'ownershipImage',
                            source: null,
                            url: null,
                        },
                        primaryImage: {
                            altText: 'ownership',
                            id: null,
                            source: '/content/dam/unified-assets/photography/partner/land-rover/optimized/MyAccount_Ownership_Tanfront1.jpg',
                            url: 'https://static2-qa2.chasecdn.com/content/dam/unified-assets/photography/partner/land-rover/optimized/MyAccount_Ownership_Tanfront1.jpg',
                        },
                        primaryImageWithRendition: null,
                        secondaryImageWithRendition: null,
                        links: {
                            ownershipLink: {
                                value: null,
                                type: null,
                                languageCode: null,
                                disabled: null,
                                active: null,
                                underLined: null,
                                inversed: null,
                                presentationType: null,
                                url: 'https://www.landroverusa.com/ownership/index.html',
                                esUrl: null,
                                id: 'ownershipLink',
                                hat: null,
                                navigationKeyId: 'Untimed Modal',
                                target: null,
                                iconId: null,
                                icon: null,
                                trackingId: 'pl_ownership',
                                trackingType: null,
                                showSpeedBump: true,
                                headerText: null,
                            },
                        },
                    },
                    news: {
                        brands: null,
                        segments: null,
                        tags: ['ccb-cf:auto/oem/lrfg/myaccount'],
                        id: 'news',
                        variationId: null,
                        title: {
                            html: '<p>NEWS</p>\n',
                            text: 'NEWS',
                            markdown: 'NEWS\n',
                        },
                        subTitle: {
                            html: null,
                            text: null,
                            markdown: null,
                        },
                        version: '20240606',
                        body: {
                            html: null,
                            text: null,
                            markdown: null,
                        },
                        additionalText: {
                            html: null,
                            text: null,
                            markdown: null,
                        },
                        hat: null,
                        iconImage: {
                            altText: null,
                            id: 'newsImage',
                            source: null,
                            url: null,
                        },
                        primaryImage: {
                            altText: 'news',
                            id: null,
                            source: '/content/dam/unified-assets/photography/partner/land-rover/optimized/MyAccount_News_LightGray1.jpg',
                            url: 'https://static2-qa2.chasecdn.com/content/dam/unified-assets/photography/partner/land-rover/optimized/MyAccount_News_LightGray1.jpg',
                        },
                        primaryImageWithRendition: null,
                        secondaryImageWithRendition: null,
                        links: {
                            newsLink: {
                                value: null,
                                type: null,
                                languageCode: null,
                                disabled: null,
                                active: null,
                                underLined: null,
                                inversed: null,
                                presentationType: null,
                                url: 'https://www.landroverusa.com/our-story/news/index.html',
                                esUrl: null,
                                id: 'newsLink',
                                hat: null,
                                navigationKeyId: 'Untimed Modal',
                                target: null,
                                iconId: null,
                                icon: null,
                                trackingId: 'pl_news',
                                trackingType: null,
                                showSpeedBump: false,
                                headerText: null,
                            },
                        },
                    },
                },
            },
            LoanExploreMoreList: {
                brands: null,
                segments: null,
                tags: ['auto/oem/lrfg/myaccount'],
                id: 'LoanExploreMoreList',
                title: {
                    html: '<p>EXPLORE MORE WITH LAND ROVER</p>\n',
                    text: 'EXPLORE MORE WITH LAND ROVER',
                    markdown: 'EXPLORE MORE WITH LAND ROVER\n',
                },
                subTitle: {
                    html: null,
                    text: null,
                    markdown: null,
                },
                version: '20240606',
                heroes: [],
                tiles: {
                    loanExperiences: {
                        brands: null,
                        segments: null,
                        tags: ['ccb-cf:auto/oem/lrfg/myaccount'],
                        id: 'loanExperiences',
                        variationId: null,
                        title: {
                            html: '<p>LAND ROVER EXPERIENCES</p>\n',
                            text: 'LAND ROVER EXPERIENCES',
                            markdown: 'LAND ROVER EXPERIENCES\n',
                        },
                        subTitle: {
                            html: null,
                            text: null,
                            markdown: null,
                        },
                        version: '20240606',
                        body: {
                            html: null,
                            text: null,
                            markdown: null,
                        },
                        additionalText: {
                            html: null,
                            text: null,
                            markdown: null,
                        },
                        hat: null,
                        iconImage: {
                            altText: null,
                            id: 'loanExperiencesImage',
                            source: null,
                            url: null,
                        },
                        primaryImage: {
                            altText: 'experiences',
                            id: null,
                            source: '/content/dam/unified-assets/photography/partner/land-rover/optimized/MyAccount_LandRoverExperiences_DarkGray1.jpg',
                            url: 'https://static2-qa2.chasecdn.com/content/dam/unified-assets/photography/partner/land-rover/optimized/MyAccount_LandRoverExperiences_DarkGray1.jpg',
                        },
                        primaryImageWithRendition: null,
                        secondaryImageWithRendition: null,
                        links: {
                            loanExperiencesLink: {
                                value: null,
                                type: null,
                                languageCode: null,
                                disabled: null,
                                active: null,
                                underLined: null,
                                inversed: null,
                                presentationType: null,
                                url: 'https://experience.landroverusa.com/',
                                esUrl: null,
                                id: 'loanExperiencesLink',
                                hat: null,
                                navigationKeyId: 'Untimed Modal',
                                target: null,
                                iconId: null,
                                icon: null,
                                trackingId: 'pl_experience',
                                trackingType: null,
                                showSpeedBump: true,
                                headerText: null,
                            },
                        },
                    },
                    loanOwnership: {
                        brands: null,
                        segments: null,
                        tags: ['ccb-cf:auto/oem/lrfg/myaccount'],
                        id: 'loanOwnership',
                        variationId: null,
                        title: {
                            html: '<p>OWNERSHIP</p>\n',
                            text: 'OWNERSHIP',
                            markdown: 'OWNERSHIP\n',
                        },
                        subTitle: {
                            html: null,
                            text: null,
                            markdown: null,
                        },
                        version: '20240606',
                        body: {
                            html: null,
                            text: null,
                            markdown: null,
                        },
                        additionalText: {
                            html: null,
                            text: null,
                            markdown: null,
                        },
                        hat: null,
                        iconImage: {
                            altText: null,
                            id: 'loanOwnershipImage',
                            source: null,
                            url: null,
                        },
                        primaryImage: {
                            altText: 'ownership',
                            id: null,
                            source: '/content/dam/unified-assets/photography/partner/land-rover/optimized/MyAccount_Ownership_Yellow1.jpg',
                            url: 'https://static2-qa2.chasecdn.com/content/dam/unified-assets/photography/partner/land-rover/optimized/MyAccount_Ownership_Yellow1.jpg',
                        },
                        primaryImageWithRendition: null,
                        secondaryImageWithRendition: null,
                        links: {
                            loanOwnershipLink: {
                                value: null,
                                type: null,
                                languageCode: null,
                                disabled: null,
                                active: null,
                                underLined: null,
                                inversed: null,
                                presentationType: null,
                                url: 'https://www.landroverusa.com/ownership/index.html',
                                esUrl: null,
                                id: 'loanOwnershipLink',
                                hat: null,
                                navigationKeyId: 'Untimed Modal',
                                target: null,
                                iconId: null,
                                icon: null,
                                trackingId: 'pl_ownership',
                                trackingType: null,
                                showSpeedBump: true,
                                headerText: null,
                            },
                        },
                    },
                    loanNews: {
                        brands: null,
                        segments: null,
                        tags: ['ccb-cf:auto/oem/lrfg/myaccount'],
                        id: 'loanNews',
                        variationId: null,
                        title: {
                            html: '<p>NEWS</p>\n',
                            text: 'NEWS',
                            markdown: 'NEWS\n',
                        },
                        subTitle: {
                            html: null,
                            text: null,
                            markdown: null,
                        },
                        version: '20240606',
                        body: {
                            html: null,
                            text: null,
                            markdown: null,
                        },
                        additionalText: {
                            html: null,
                            text: null,
                            markdown: null,
                        },
                        hat: null,
                        iconImage: {
                            altText: null,
                            id: 'loanNewsImage',
                            source: null,
                            url: null,
                        },
                        primaryImage: {
                            altText: 'news',
                            id: null,
                            source: '/content/dam/unified-assets/photography/partner/land-rover/optimized/MyAccount_News_GraySlate1.jpg',
                            url: 'https://static2-qa2.chasecdn.com/content/dam/unified-assets/photography/partner/land-rover/optimized/MyAccount_News_GraySlate1.jpg',
                        },
                        primaryImageWithRendition: null,
                        secondaryImageWithRendition: null,
                        links: {
                            loanNewsLink: {
                                value: null,
                                type: null,
                                languageCode: null,
                                disabled: null,
                                active: null,
                                underLined: null,
                                inversed: null,
                                presentationType: null,
                                url: 'https://www.landroverusa.com/our-story/news/index.html',
                                esUrl: null,
                                id: 'loanNewsLink',
                                hat: null,
                                navigationKeyId: 'Untimed Modal',
                                target: null,
                                iconId: null,
                                icon: null,
                                trackingId: 'pl_news',
                                trackingType: null,
                                showSpeedBump: true,
                                headerText: null,
                            },
                        },
                    },
                },
            },
            leaseEndOptionsList: {
                brands: null,
                segments: null,
                tags: ['auto/oem/lrfg/myaccount'],
                id: 'leaseEndOptionsList',
                title: {
                    html: '<p>YOUR LEASE-END OPTIONS</p>\n',
                    text: 'YOUR LEASE-END OPTIONS',
                    markdown: 'YOUR LEASE-END OPTIONS\n',
                },
                subTitle: {
                    html: null,
                    text: null,
                    markdown: null,
                },
                version: '20240606',
                heroes: [],
                tiles: {
                    exploreOption: {
                        brands: null,
                        segments: null,
                        tags: ['ccb-cf:auto/oem/lrfg/myaccount'],
                        id: 'exploreOption',
                        variationId: null,
                        title: {
                            html: '<p>Explore a new Land Rover</p>\n',
                            text: 'Explore a new Land Rover',
                            markdown: 'Explore a new Land Rover\n',
                        },
                        subTitle: {
                            html: null,
                            text: null,
                            markdown: null,
                        },
                        version: '20240606',
                        body: {
                            html: null,
                            text: null,
                            markdown: null,
                        },
                        additionalText: {
                            html: null,
                            text: null,
                            markdown: null,
                        },
                        hat: null,
                        iconImage: {
                            altText: null,
                            id: 'exploreANewLandRoverImage',
                            source: null,
                            url: null,
                        },
                        primaryImage: {
                            altText: 'exploreANewLandRover',
                            id: null,
                            source: null,
                            url: null,
                        },
                        primaryImageWithRendition: null,
                        secondaryImageWithRendition: null,
                        links: {
                            exploreOptionLink: {
                                value: null,
                                type: null,
                                languageCode: null,
                                disabled: null,
                                active: null,
                                underLined: null,
                                inversed: null,
                                presentationType: null,
                                url: 'https://landroverfinancialgroup.com/oem/lease-end/new',
                                esUrl: null,
                                id: 'exploreOptionLink',
                                hat: null,
                                navigationKeyId: null,
                                target: null,
                                iconId: null,
                                icon: null,
                                trackingId: 'pl_le_new',
                                trackingType: null,
                                showSpeedBump: false,
                                headerText: null,
                            },
                        },
                    },
                    extendPurchaseOption: {
                        brands: null,
                        segments: null,
                        tags: ['ccb-cf:auto/oem/lrfg/myaccount'],
                        id: 'extendPurchaseOption',
                        variationId: null,
                        title: {
                            html: '<p>Extend, purchase or early turn-in</p>\n',
                            text: 'Extend, purchase or early turn-in',
                            markdown: 'Extend, purchase or early turn-in\n',
                        },
                        subTitle: {
                            html: null,
                            text: null,
                            markdown: null,
                        },
                        version: '20240606',
                        body: {
                            html: null,
                            text: null,
                            markdown: null,
                        },
                        additionalText: {
                            html: null,
                            text: null,
                            markdown: null,
                        },
                        hat: null,
                        iconImage: {
                            altText: null,
                            id: 'ExtendPurchaseOrEarlyTurnInImage',
                            source: null,
                            url: null,
                        },
                        primaryImage: {
                            altText: 'ExtendPurchaseOrEarlyTurnIn',
                            id: null,
                            source: null,
                            url: null,
                        },
                        primaryImageWithRendition: null,
                        secondaryImageWithRendition: null,
                        links: {
                            extendPurchaseOptionLink: {
                                value: null,
                                type: null,
                                languageCode: null,
                                disabled: null,
                                active: null,
                                underLined: null,
                                inversed: null,
                                presentationType: null,
                                url: 'https://landroverfinancialgroup.com/oem/lease-end/other',
                                esUrl: null,
                                id: 'extendPurchaseOptionLink',
                                hat: null,
                                navigationKeyId: null,
                                target: null,
                                iconId: null,
                                icon: null,
                                trackingId: 'pl_le_other',
                                trackingType: null,
                                showSpeedBump: false,
                                headerText: null,
                            },
                        },
                    },
                    returnOption: {
                        brands: null,
                        segments: null,
                        tags: ['ccb-cf:auto/oem/lrfg/myaccount'],
                        id: 'returnOption',
                        variationId: null,
                        title: {
                            html: '<p>Return your Land Rover</p>\n',
                            text: 'Return your Land Rover',
                            markdown: 'Return your Land Rover\n',
                        },
                        subTitle: {
                            html: null,
                            text: null,
                            markdown: null,
                        },
                        version: '20240606',
                        body: {
                            html: null,
                            text: null,
                            markdown: null,
                        },
                        additionalText: {
                            html: null,
                            text: null,
                            markdown: null,
                        },
                        hat: null,
                        iconImage: {
                            altText: null,
                            id: 'returnYourLandRoverImage',
                            source: null,
                            url: null,
                        },
                        primaryImage: {
                            altText: 'returnYourLandRover',
                            id: null,
                            source: null,
                            url: null,
                        },
                        primaryImageWithRendition: null,
                        secondaryImageWithRendition: null,
                        links: {
                            returnOptionLink: {
                                value: null,
                                type: null,
                                languageCode: null,
                                disabled: null,
                                active: null,
                                underLined: null,
                                inversed: null,
                                presentationType: null,
                                url: 'https://landroverfinancialgroup.com/oem/lease-end/return',
                                esUrl: null,
                                id: 'returnOptionLink',
                                hat: null,
                                navigationKeyId: null,
                                target: null,
                                iconId: null,
                                icon: null,
                                trackingId: 'pl_le_return',
                                trackingType: null,
                                showSpeedBump: false,
                                headerText: null,
                            },
                        },
                    },
                },
            },
            leaseStartMidOptionsList: {
                brands: null,
                segments: null,
                tags: ['auto/oem/lrfg/myaccount'],
                id: 'leaseStartMidOptionsList',
                title: {
                    html: '<p>YOUR AUTO TOOLS</p>\n',
                    text: 'YOUR AUTO TOOLS',
                    markdown: 'YOUR AUTO TOOLS\n',
                },
                subTitle: {
                    html: null,
                    text: null,
                    markdown: null,
                },
                version: '20240627',
                heroes: [],
                tiles: {
                    guidesAndDocumentationOption1: {
                        brands: null,
                        segments: null,
                        tags: ['ccb-cf:auto/oem/lrfg/myaccount'],
                        id: 'guidesAndDocumentationOption1',
                        variationId: null,
                        title: {
                            html: '<p>Guides and documentation</p>\n',
                            text: 'Guides and documentation',
                            markdown: 'Guides and documentation\n',
                        },
                        subTitle: {
                            html: null,
                            text: null,
                            markdown: null,
                        },
                        version: '20240627',
                        body: {
                            html: null,
                            text: null,
                            markdown: null,
                        },
                        additionalText: {
                            html: null,
                            text: null,
                            markdown: null,
                        },
                        hat: null,
                        iconImage: {
                            altText: null,
                            id: 'guidesAndDocumentationImage',
                            source: null,
                            url: null,
                        },
                        primaryImage: {
                            altText: 'guidesAndDocumentation',
                            id: null,
                            source: '/content/dam/unified-assets/photography/partner/land-rover/optimized/MyAccount_MidLease_Guides_1.jpg',
                            url: 'https://static2-qa2.chasecdn.com/content/dam/unified-assets/photography/partner/land-rover/optimized/MyAccount_MidLease_Guides_1.jpg',
                        },
                        primaryImageWithRendition: null,
                        secondaryImageWithRendition: null,
                        links: {
                            guidesAndDocumentationOptionLink: {
                                value: null,
                                type: null,
                                languageCode: null,
                                disabled: null,
                                active: null,
                                underLined: null,
                                inversed: null,
                                presentationType: null,
                                url: 'http://www.ownerinfo.landrover.com/ ',
                                esUrl: null,
                                id: 'guidesAndDocumentationOptionLink',
                                hat: null,
                                navigationKeyId: 'Untimed Modal',
                                target: null,
                                iconId: null,
                                icon: null,
                                trackingId: 'pl_guide_doc',
                                trackingType: null,
                                showSpeedBump: true,
                                headerText: null,
                            },
                        },
                    },
                    serviceAndWarrantiesOption1: {
                        brands: null,
                        segments: null,
                        tags: ['ccb-cf:auto/oem/lrfg/myaccount'],
                        id: 'serviceAndWarrantiesOption1',
                        variationId: null,
                        title: {
                            html: '<p>Service and warranties</p>\n',
                            text: 'Service and warranties',
                            markdown: 'Service and warranties\n',
                        },
                        subTitle: {
                            html: null,
                            text: null,
                            markdown: null,
                        },
                        version: '20240627',
                        body: {
                            html: null,
                            text: null,
                            markdown: null,
                        },
                        additionalText: {
                            html: null,
                            text: null,
                            markdown: null,
                        },
                        hat: null,
                        iconImage: {
                            altText: null,
                            id: 'serviceAndWarrantiesImage',
                            source: null,
                            url: null,
                        },
                        primaryImage: {
                            altText: 'serviceAndWarranties',
                            id: null,
                            source: '/content/dam/unified-assets/photography/partner/land-rover/optimized/MyAccount_NewLease_Services_1.jpg',
                            url: 'https://static2-qa2.chasecdn.com/content/dam/unified-assets/photography/partner/land-rover/optimized/MyAccount_NewLease_Services_1.jpg',
                        },
                        primaryImageWithRendition: null,
                        secondaryImageWithRendition: null,
                        links: {
                            serviceAndWarrantiesOptionLink: {
                                value: null,
                                type: null,
                                languageCode: null,
                                disabled: null,
                                active: null,
                                underLined: null,
                                inversed: null,
                                presentationType: null,
                                url: 'https://www.landroverusa.com/ownership/warranty.html',
                                esUrl: null,
                                id: 'serviceAndWarrantiesOptionLink',
                                hat: null,
                                navigationKeyId: 'Untimed Modal',
                                target: null,
                                iconId: null,
                                icon: null,
                                trackingId: 'pl_svc_warrnties',
                                trackingType: null,
                                showSpeedBump: true,
                                headerText: null,
                            },
                        },
                    },
                    easilyManageYourAccountOption: {
                        brands: null,
                        segments: null,
                        tags: ['ccb-cf:auto/oem/lrfg/myaccount'],
                        id: 'easilyManageYourAccountOption',
                        variationId: null,
                        title: {
                            html: '<p>Easily manage your account</p>\n',
                            text: 'Easily manage your account',
                            markdown: 'Easily manage your account\n',
                        },
                        subTitle: {
                            html: null,
                            text: null,
                            markdown: null,
                        },
                        version: '20240627',
                        body: {
                            html: null,
                            text: null,
                            markdown: null,
                        },
                        additionalText: {
                            html: null,
                            text: null,
                            markdown: null,
                        },
                        hat: null,
                        iconImage: {
                            altText: null,
                            id: 'easilyManageYourAccountImage',
                            source: null,
                            url: null,
                        },
                        primaryImage: {
                            altText: 'easilyManageYourAccount',
                            id: null,
                            source: '/content/dam/unified-assets/photography/partner/land-rover/optimized/MyAccount_NewLease_Branded_1.jpg',
                            url: 'https://static2-qa2.chasecdn.com/content/dam/unified-assets/photography/partner/land-rover/optimized/MyAccount_NewLease_Branded_1.jpg',
                        },
                        primaryImageWithRendition: null,
                        secondaryImageWithRendition: null,
                        links: {
                            easilyManageYourAccountOptionLink: {
                                value: null,
                                type: null,
                                languageCode: null,
                                disabled: null,
                                active: null,
                                underLined: null,
                                inversed: null,
                                presentationType: null,
                                url: 'https://landroverfinancialgroup.com/oem/welcome ',
                                esUrl: null,
                                id: 'easilyManageYourAccountOptionLink',
                                hat: null,
                                navigationKeyId: null,
                                target: null,
                                iconId: null,
                                icon: null,
                                trackingId: 'pl_welcom',
                                trackingType: null,
                                showSpeedBump: false,
                                headerText: null,
                            },
                        },
                    },
                },
            },
            loanEndOptionsList: {
                brands: null,
                segments: null,
                tags: ['auto/oem/lrfg/myaccount'],
                id: 'loanEndOptionsList',
                title: {
                    html: '<p>VEHICLE OPTIONS</p>\n',
                    text: 'VEHICLE OPTIONS',
                    markdown: 'VEHICLE OPTIONS\n',
                },
                subTitle: {
                    html: null,
                    text: null,
                    markdown: null,
                },
                version: '20240606',
                heroes: [],
                tiles: {
                    stepsForEarlyPayOffOption: {
                        brands: null,
                        segments: null,
                        tags: ['ccb-cf:auto/oem/lrfg/myaccount'],
                        id: 'stepsForEarlyPayOffOption',
                        variationId: null,
                        title: {
                            html: '<p>Steps for end of loan or early payoff</p>\n',
                            text: 'Steps for end of loan or early payoff',
                            markdown: 'Steps for end of loan or early payoff\n',
                        },
                        subTitle: {
                            html: null,
                            text: null,
                            markdown: null,
                        },
                        version: '20240606',
                        body: {
                            html: null,
                            text: null,
                            markdown: null,
                        },
                        additionalText: {
                            html: null,
                            text: null,
                            markdown: null,
                        },
                        hat: null,
                        iconImage: {
                            altText: null,
                            id: 'stepsForEarlyPayOffImage',
                            source: null,
                            url: null,
                        },
                        primaryImage: {
                            altText: 'stepsForEarlyPayOff',
                            id: null,
                            source: null,
                            url: null,
                        },
                        primaryImageWithRendition: null,
                        secondaryImageWithRendition: null,
                        links: {
                            stepsForEarlyPayOffLink: {
                                value: null,
                                type: null,
                                languageCode: null,
                                disabled: null,
                                active: null,
                                underLined: null,
                                inversed: null,
                                presentationType: null,
                                url: 'https://landroverfinancialgroup.com/oem/loan-end',
                                esUrl: null,
                                id: 'stepsForEarlyPayOffLink',
                                hat: null,
                                navigationKeyId: null,
                                target: null,
                                iconId: null,
                                icon: null,
                                trackingId: 'pl_steps_payoff',
                                trackingType: null,
                                showSpeedBump: false,
                                headerText: null,
                            },
                        },
                    },
                    checkOutOffersOption: {
                        brands: null,
                        segments: null,
                        tags: ['ccb-cf:auto/oem/lrfg/myaccount'],
                        id: 'checkOutOffersOption',
                        variationId: null,
                        title: {
                            html: '<p>Check out Land Rover offers</p>\n',
                            text: 'Check out Land Rover offers',
                            markdown: 'Check out Land Rover offers\n',
                        },
                        subTitle: {
                            html: null,
                            text: null,
                            markdown: null,
                        },
                        version: '20240606',
                        body: {
                            html: null,
                            text: null,
                            markdown: null,
                        },
                        additionalText: {
                            html: null,
                            text: null,
                            markdown: null,
                        },
                        hat: null,
                        iconImage: {
                            altText: null,
                            id: 'checkOutLandRoverOffersImage',
                            source: null,
                            url: null,
                        },
                        primaryImage: {
                            altText: 'checkOutLandRoverOffers',
                            id: null,
                            source: null,
                            url: null,
                        },
                        primaryImageWithRendition: null,
                        secondaryImageWithRendition: null,
                        links: {
                            checkOutOffersOptionLink: {
                                value: null,
                                type: null,
                                languageCode: null,
                                disabled: null,
                                active: null,
                                underLined: null,
                                inversed: null,
                                presentationType: null,
                                url: 'https://www.landroverusa.com/current-offers/index.html ',
                                esUrl: null,
                                id: 'checkOutOffersOptionLink',
                                hat: null,
                                navigationKeyId: 'Untimed Modal',
                                target: null,
                                iconId: null,
                                icon: null,
                                trackingId: 'pl_offers',
                                trackingType: null,
                                showSpeedBump: true,
                                headerText: null,
                            },
                        },
                    },
                    shopForNewOption: {
                        brands: null,
                        segments: null,
                        tags: ['ccb-cf:auto/oem/lrfg/myaccount'],
                        id: 'shopForNewOption',
                        variationId: null,
                        title: {
                            html: '<p>Shop for a new Land Rover</p>\n',
                            text: 'Shop for a new Land Rover',
                            markdown: 'Shop for a new Land Rover\n',
                        },
                        subTitle: {
                            html: null,
                            text: null,
                            markdown: null,
                        },
                        version: '20240606',
                        body: {
                            html: null,
                            text: null,
                            markdown: null,
                        },
                        additionalText: {
                            html: null,
                            text: null,
                            markdown: null,
                        },
                        hat: null,
                        iconImage: {
                            altText: null,
                            id: 'shopForNewLandRoverImage',
                            source: null,
                            url: null,
                        },
                        primaryImage: {
                            altText: 'shopForNewLandRover',
                            id: null,
                            source: null,
                            url: null,
                        },
                        primaryImageWithRendition: null,
                        secondaryImageWithRendition: null,
                        links: {
                            shopForNewOptionLink: {
                                value: null,
                                type: null,
                                languageCode: null,
                                disabled: null,
                                active: null,
                                underLined: null,
                                inversed: null,
                                presentationType: null,
                                url: 'http://new.landroverusa.com/',
                                esUrl: null,
                                id: 'shopForNewOptionLink',
                                hat: null,
                                navigationKeyId: 'Untimed Modal',
                                target: null,
                                iconId: null,
                                icon: null,
                                trackingId: 'pl_shop_new',
                                trackingType: null,
                                showSpeedBump: true,
                                headerText: null,
                            },
                        },
                    },
                },
            },
            loanStartMidOptionsList: {
                brands: null,
                segments: null,
                tags: ['auto/oem/lrfg/myaccount'],
                id: 'loanStartMidOptionsList',
                title: {
                    html: '<p>YOUR AUTO TOOLS</p>\n',
                    text: 'YOUR AUTO TOOLS',
                    markdown: 'YOUR AUTO TOOLS\n',
                },
                subTitle: {
                    html: null,
                    text: null,
                    markdown: null,
                },
                version: '20240627',
                heroes: [],
                tiles: {
                    guidesAndDocumentationOption: {
                        brands: null,
                        segments: null,
                        tags: ['ccb-cf:auto/oem/lrfg/myaccount'],
                        id: 'guidesAndDocumentationOption',
                        variationId: null,
                        title: {
                            html: '<p>Guides and documentation</p>\n',
                            text: 'Guides and documentation',
                            markdown: 'Guides and documentation\n',
                        },
                        subTitle: {
                            html: null,
                            text: null,
                            markdown: null,
                        },
                        version: '20240627',
                        body: {
                            html: null,
                            text: null,
                            markdown: null,
                        },
                        additionalText: {
                            html: null,
                            text: null,
                            markdown: null,
                        },
                        hat: null,
                        iconImage: {
                            altText: null,
                            id: 'guidesAndDocumentationImage',
                            source: null,
                            url: null,
                        },
                        primaryImage: {
                            altText: 'guidesAndDocumentation',
                            id: null,
                            source: '/content/dam/unified-assets/photography/partner/land-rover/optimized/MyAccount_NewLoan_Guides_1.jpg',
                            url: 'https://static2-qa2.chasecdn.com/content/dam/unified-assets/photography/partner/land-rover/optimized/MyAccount_NewLoan_Guides_1.jpg',
                        },
                        primaryImageWithRendition: null,
                        secondaryImageWithRendition: null,
                        links: {
                            guidesAndDocumentationOptionLink: {
                                value: null,
                                type: null,
                                languageCode: null,
                                disabled: null,
                                active: null,
                                underLined: null,
                                inversed: null,
                                presentationType: null,
                                url: 'http://www.ownerinfo.landrover.com/ ',
                                esUrl: null,
                                id: 'guidesAndDocumentationOptionLink',
                                hat: null,
                                navigationKeyId: 'Untimed Modal',
                                target: null,
                                iconId: null,
                                icon: null,
                                trackingId: 'pl_guide_doc',
                                trackingType: null,
                                showSpeedBump: true,
                                headerText: null,
                            },
                        },
                    },
                    serviceAndWarrantiesOption: {
                        brands: null,
                        segments: null,
                        tags: ['ccb-cf:auto/oem/lrfg/myaccount'],
                        id: 'serviceAndWarrantiesOption',
                        variationId: null,
                        title: {
                            html: '<p>Service and warranties</p>\n',
                            text: 'Service and warranties',
                            markdown: 'Service and warranties\n',
                        },
                        subTitle: {
                            html: null,
                            text: null,
                            markdown: null,
                        },
                        version: '20240627',
                        body: {
                            html: null,
                            text: null,
                            markdown: null,
                        },
                        additionalText: {
                            html: null,
                            text: null,
                            markdown: null,
                        },
                        hat: null,
                        iconImage: {
                            altText: null,
                            id: 'serviceAndWarrantiesImage',
                            source: null,
                            url: null,
                        },
                        primaryImage: {
                            altText: 'serviceAndWarranties',
                            id: null,
                            source: '/content/dam/unified-assets/photography/partner/land-rover/optimized/MyAccount_NewLoan_Service_1.jpg',
                            url: 'https://static2-qa2.chasecdn.com/content/dam/unified-assets/photography/partner/land-rover/optimized/MyAccount_NewLoan_Service_1.jpg',
                        },
                        primaryImageWithRendition: null,
                        secondaryImageWithRendition: null,
                        links: {
                            serviceAndWarrantiesOptionLink: {
                                value: null,
                                type: null,
                                languageCode: null,
                                disabled: null,
                                active: null,
                                underLined: null,
                                inversed: null,
                                presentationType: null,
                                url: 'https://www.landroverusa.com/ownership/warranty.html',
                                esUrl: null,
                                id: 'serviceAndWarrantiesOptionLink',
                                hat: null,
                                navigationKeyId: 'Untimed Modal',
                                target: null,
                                iconId: null,
                                icon: null,
                                trackingId: 'pl_svc_warrnties',
                                trackingType: null,
                                showSpeedBump: true,
                                headerText: null,
                            },
                        },
                    },
                    brandedGoodsOption: {
                        brands: null,
                        segments: null,
                        tags: ['ccb-cf:auto/oem/lrfg/myaccount'],
                        id: 'brandedGoodsOption',
                        variationId: null,
                        title: {
                            html: '<p>Branded Goods</p>\n',
                            text: 'Branded Goods',
                            markdown: 'Branded Goods\n',
                        },
                        subTitle: {
                            html: null,
                            text: null,
                            markdown: null,
                        },
                        version: '20240627',
                        body: {
                            html: null,
                            text: null,
                            markdown: null,
                        },
                        additionalText: {
                            html: null,
                            text: null,
                            markdown: null,
                        },
                        hat: null,
                        iconImage: {
                            altText: null,
                            id: 'brandedGoodsImage',
                            source: null,
                            url: null,
                        },
                        primaryImage: {
                            altText: 'brandedGoods',
                            id: null,
                            source: '/content/dam/unified-assets/photography/partner/land-rover/optimized/MyAccount_NewLoan_BrandedGoods_1.jpg',
                            url: 'https://static2-qa2.chasecdn.com/content/dam/unified-assets/photography/partner/land-rover/optimized/MyAccount_NewLoan_BrandedGoods_1.jpg',
                        },
                        primaryImageWithRendition: null,
                        secondaryImageWithRendition: null,
                        links: {
                            brandedGoodsOptionLink: {
                                value: null,
                                type: null,
                                languageCode: null,
                                disabled: null,
                                active: null,
                                underLined: null,
                                inversed: null,
                                presentationType: null,
                                url: 'https://www.landroverusa.com/branded-goods/index.html',
                                esUrl: null,
                                id: 'brandedGoodsOptionLink',
                                hat: null,
                                navigationKeyId: 'Untimed Modal',
                                target: null,
                                iconId: null,
                                icon: null,
                                trackingId: 'pl_brand_goods',
                                trackingType: null,
                                showSpeedBump: true,
                                headerText: null,
                            },
                        },
                    },
                },
            },
        },
        iconList: {
            footerIconList: {
                tags: ['auto/oem/lrfg/myaccount'],
                id: 'footerIconList',
                title: {
                    html: null,
                    text: null,
                    markdown: null,
                },
                subTitle: {
                    html: null,
                    text: null,
                    markdown: null,
                },
                version: '20240627',
                icons: {
                    FindaLandRoverRetailerIcon: {
                        id: 'FindaLandRoverRetailerIcon',
                        text: 'FIND A LAND ROVER RETAILER',
                        image: {
                            altText: null,
                            id: null,
                            source: '/content/dam/unified-assets/iconography/auto/ico_auto_location.svg',
                            url: 'https://static2-qa2.chasecdn.com/content/dam/unified-assets/iconography/auto/ico_auto_location.svg',
                        },
                        primaryImage: null,
                        link: {
                            value: 'FIND A LAND ROVER RETAILER',
                            type: null,
                            languageCode: null,
                            disabled: null,
                            active: null,
                            underLined: null,
                            inversed: null,
                            presentationType: null,
                            url: 'https://www.landroverusa.com/national-dealer-locator.html',
                            esUrl: null,
                            id: 'FindaLandRoverRetailerIconLink',
                            hat: null,
                            navigationKeyId: 'Untimed Modal',
                            target: null,
                            iconId: null,
                            icon: null,
                            trackingId: 'pl_find_retailer',
                            trackingType: null,
                            showSpeedBump: true,
                            headerText: null,
                        },
                    },
                    faqIcon: {
                        id: 'faqIcon',
                        text: 'FAQS',
                        image: {
                            altText: null,
                            id: null,
                            source: '/content/dam/unified-assets/iconography/auto/ico_auto_question_mark_circle.svg',
                            url: 'https://static2-qa2.chasecdn.com/content/dam/unified-assets/iconography/auto/ico_auto_question_mark_circle.svg',
                        },
                        primaryImage: null,
                        link: {
                            value: 'FAQS',
                            type: null,
                            languageCode: null,
                            disabled: null,
                            active: null,
                            underLined: null,
                            inversed: null,
                            presentationType: null,
                            url: '/faq',
                            esUrl: null,
                            id: 'faqIconLink',
                            hat: null,
                            navigationKeyId: null,
                            target: null,
                            iconId: null,
                            icon: null,
                            trackingId: 'pl_faq',
                            trackingType: null,
                            showSpeedBump: true,
                            headerText: null,
                        },
                    },
                    contactUsIcon: {
                        id: 'contactUsIcon',
                        text: 'CONTACT US',
                        image: {
                            altText: null,
                            id: null,
                            source: '/content/dam/unified-assets/iconography/auto/ico_auto_people_group.svg',
                            url: 'https://static2-qa2.chasecdn.com/content/dam/unified-assets/iconography/auto/ico_auto_people_group.svg',
                        },
                        primaryImage: null,
                        link: {
                            value: 'CONTACT US',
                            type: null,
                            languageCode: null,
                            disabled: null,
                            active: null,
                            underLined: null,
                            inversed: null,
                            presentationType: null,
                            url: '/contact-us',
                            esUrl: null,
                            id: 'contactUsIconLink',
                            hat: null,
                            navigationKeyId: null,
                            target: null,
                            iconId: null,
                            icon: null,
                            trackingId: 'pl_contact',
                            trackingType: null,
                            showSpeedBump: false,
                            headerText: null,
                        },
                    },
                },
            },
            manageAccountList: {
                tags: ['auto/oem/lrfg/myaccount'],
                id: 'manageAccountList',
                title: {
                    html: null,
                    text: null,
                    markdown: null,
                },
                subTitle: {
                    html: null,
                    text: null,
                    markdown: null,
                },
                version: '20240606',
                icons: {
                    manageAutomaticPaymentsIcon: {
                        id: 'manageAutomaticPaymentsIcon',
                        text: 'MANAGE AUTOMATIC PAYMENTS',
                        image: {
                            altText: null,
                            id: null,
                            source: '/content/dam/unified-assets/iconography/auto/myaccount/icon_auto_myaccount_manage_autopay.svg',
                            url: 'https://static2-qa2.chasecdn.com/content/dam/unified-assets/iconography/auto/myaccount/icon_auto_myaccount_manage_autopay.svg',
                        },
                        primaryImage: null,
                        link: {
                            value: null,
                            type: null,
                            languageCode: null,
                            disabled: null,
                            active: null,
                            underLined: null,
                            inversed: null,
                            presentationType: null,
                            url: 'https://secure.chase.com/web/auth/nav?navKey=AutoPayBills&jp_cdf=SMF_AUTOPAY',
                            esUrl: null,
                            id: 'manageAutomaticPaymentsLink',
                            hat: null,
                            navigationKeyId: 'Timed Modal',
                            target: null,
                            iconId: null,
                            icon: null,
                            trackingId: 'pl_acct_chase_autopay',
                            trackingType: null,
                            showSpeedBump: true,
                            headerText: null,
                        },
                    },
                    reviewPayFromAccountsIcon: {
                        id: 'reviewPayFromAccountsIcon',
                        text: 'REVIEW PAY FROM ACCOUNTS',
                        image: {
                            altText: null,
                            id: null,
                            source: '/content/dam/unified-assets/iconography/auto/myaccount/icon_auto_myaccount_manage_external.svg',
                            url: 'https://static2-qa2.chasecdn.com/content/dam/unified-assets/iconography/auto/myaccount/icon_auto_myaccount_manage_external.svg',
                        },
                        primaryImage: null,
                        link: {
                            value: null,
                            type: null,
                            languageCode: null,
                            disabled: null,
                            active: null,
                            underLined: null,
                            inversed: null,
                            presentationType: null,
                            url: 'https://secure.chase.com/web/auth/nav?navKey=externalAccountsTransfer&jp_cdf=SMF_EXTERNAL_ACCOUNT',
                            esUrl: null,
                            id: 'reviewPayFromAccountsLink',
                            hat: null,
                            navigationKeyId: 'Timed Modal',
                            target: null,
                            iconId: null,
                            icon: null,
                            trackingId: 'pl_acct_chase_external_account',
                            trackingType: null,
                            showSpeedBump: true,
                            headerText: null,
                        },
                    },
                    viewStatementAndDocumentIcon: {
                        id: 'viewStatementAndDocumentIcon',
                        text: 'VIEW STATEMENT AND DOCUMENTS',
                        image: {
                            altText: null,
                            id: null,
                            source: '/content/dam/unified-assets/iconography/auto/myaccount/icon_auto_myaccount_view_statement_doc.svg',
                            url: 'https://static2-qa2.chasecdn.com/content/dam/unified-assets/iconography/auto/myaccount/icon_auto_myaccount_view_statement_doc.svg',
                        },
                        primaryImage: null,
                        link: {
                            value: null,
                            type: null,
                            languageCode: null,
                            disabled: null,
                            active: null,
                            underLined: null,
                            inversed: null,
                            presentationType: null,
                            url: 'https://secure.chase.com/web/auth/nav?navKey=requestAccountStatements&jp_cdf=SMF_STATEMENTS',
                            esUrl: null,
                            id: 'viewStatementAndDocumentLink',
                            hat: null,
                            navigationKeyId: 'Timed Modal',
                            target: null,
                            iconId: null,
                            icon: null,
                            trackingId: 'pl_acct_chase_statements',
                            trackingType: null,
                            showSpeedBump: true,
                            headerText: null,
                        },
                    },
                    managePaperlessIcon: {
                        id: 'managePaperlessIcon',
                        text: 'MANAGE PAPERLESS',
                        image: {
                            altText: null,
                            id: null,
                            source: '/content/dam/unified-assets/iconography/auto/myaccount/icon_auto_myaccount_manage_paperless.svg',
                            url: 'https://static2-qa2.chasecdn.com/content/dam/unified-assets/iconography/auto/myaccount/icon_auto_myaccount_manage_paperless.svg',
                        },
                        primaryImage: null,
                        link: {
                            value: null,
                            type: null,
                            languageCode: null,
                            disabled: null,
                            active: null,
                            underLined: null,
                            inversed: null,
                            presentationType: null,
                            url: 'https://secure.chase.com/web/auth/nav?navKey=manageMyPaperlessPreferences&jp_cdf=SMF_PAPERLESS',
                            esUrl: null,
                            id: 'managePaperlessLink',
                            hat: null,
                            navigationKeyId: 'Timed Modal',
                            target: null,
                            iconId: null,
                            icon: null,
                            trackingId: 'pl_acct_chase_paperless',
                            trackingType: null,
                            showSpeedBump: true,
                            headerText: null,
                        },
                    },
                    sendASecureMessageIcon: {
                        id: 'sendASecureMessageIcon',
                        text: 'SEND A SECURE MESSAGE',
                        image: {
                            altText: null,
                            id: null,
                            source: '/content/dam/unified-assets/iconography/auto/myaccount/icon_auto_myaccount_send_message.svg',
                            url: 'https://static2-qa2.chasecdn.com/content/dam/unified-assets/iconography/auto/myaccount/icon_auto_myaccount_send_message.svg',
                        },
                        primaryImage: null,
                        link: {
                            value: null,
                            type: null,
                            languageCode: null,
                            disabled: null,
                            active: null,
                            underLined: null,
                            inversed: null,
                            presentationType: null,
                            url: 'https://secure.chase.com/web/auth/nav?navKey=requestSecureMessages&jp_cdf_SMF_SMC',
                            esUrl: null,
                            id: 'sendASecureMessageLink',
                            hat: null,
                            navigationKeyId: 'Timed Modal',
                            target: null,
                            iconId: null,
                            icon: null,
                            trackingId: 'pl_acct_chase_smc',
                            trackingType: null,
                            showSpeedBump: true,
                            headerText: null,
                        },
                    },
                    reviewProfileAndSettingsIcon: {
                        id: 'reviewProfileAndSettingsIcon',
                        text: 'REVIEW PROFILE AND SETTINGS',
                        image: {
                            altText: null,
                            id: null,
                            source: '/content/dam/unified-assets/iconography/auto/myaccount/icon_auto_myaccount_manage_profile.svg',
                            url: 'https://static2-qa2.chasecdn.com/content/dam/unified-assets/iconography/auto/myaccount/icon_auto_myaccount_manage_profile.svg',
                        },
                        primaryImage: null,
                        link: {
                            value: null,
                            type: null,
                            languageCode: null,
                            disabled: null,
                            active: null,
                            underLined: null,
                            inversed: null,
                            presentationType: null,
                            url: 'https://secure.chase.com/web/auth/nav?navKey=requestUserProfileAndSettings&jp_cdf=SMF_PROFILE_SETTING',
                            esUrl: null,
                            id: 'reviewProfileAndSettingsLink',
                            hat: null,
                            navigationKeyId: 'Timed Modal',
                            target: null,
                            iconId: null,
                            icon: null,
                            trackingId: 'pl_acct_chase_profile_settings',
                            trackingType: null,
                            showSpeedBump: true,
                            headerText: null,
                        },
                    },
                },
            },
            myAccountCommonIconsList: {
                tags: ['auto/oem/lrfg/myaccount'],
                id: 'myAccountCommonIconsList',
                title: {
                    html: null,
                    text: null,
                    markdown: null,
                },
                subTitle: {
                    html: null,
                    text: null,
                    markdown: null,
                },
                version: '20240606',
                icons: {
                    editIcon: {
                        id: 'editIcon',
                        text: 'EDIT',
                        image: {
                            altText: null,
                            id: null,
                            source: '/content/dam/unified-assets/iconography/auto/ico_auto_pencil.svg',
                            url: 'https://static2-qa2.chasecdn.com/content/dam/unified-assets/iconography/auto/ico_auto_pencil.svg',
                        },
                        primaryImage: null,
                        link: {
                            value: null,
                            type: null,
                            languageCode: null,
                            disabled: null,
                            active: null,
                            underLined: null,
                            inversed: null,
                            presentationType: null,
                            url: null,
                            esUrl: null,
                            id: null,
                            hat: null,
                            navigationKeyId: null,
                            target: null,
                            iconId: null,
                            icon: null,
                            trackingId: null,
                            trackingType: null,
                            showSpeedBump: null,
                            headerText: null,
                        },
                    },
                    chevronBlackRightIcon: {
                        id: 'chevronBlackRightIcon',
                        text: 'chevronBlackRight',
                        image: {
                            altText: null,
                            id: null,
                            source: '/content/dam/unified-assets/iconography/auto/chevron/icon_auto_chevron_black_right.svg',
                            url: 'https://static2-qa2.chasecdn.com/content/dam/unified-assets/iconography/auto/chevron/icon_auto_chevron_black_right.svg',
                        },
                        primaryImage: null,
                        link: {
                            value: null,
                            type: null,
                            languageCode: null,
                            disabled: null,
                            active: null,
                            underLined: null,
                            inversed: null,
                            presentationType: null,
                            url: null,
                            esUrl: null,
                            id: null,
                            hat: null,
                            navigationKeyId: null,
                            target: null,
                            iconId: null,
                            icon: null,
                            trackingId: null,
                            trackingType: null,
                            showSpeedBump: null,
                            headerText: null,
                        },
                    },
                    chevronBlackDownIcon: {
                        id: 'chevronBlackDownIcon',
                        text: 'chevronBlackDown',
                        image: {
                            altText: null,
                            id: null,
                            source: '/content/dam/unified-assets/iconography/auto/chevron/icon_auto_chevron_black_down.svg',
                            url: 'https://static2-qa2.chasecdn.com/content/dam/unified-assets/iconography/auto/chevron/icon_auto_chevron_black_down.svg',
                        },
                        primaryImage: null,
                        link: {
                            value: null,
                            type: null,
                            languageCode: null,
                            disabled: null,
                            active: null,
                            underLined: null,
                            inversed: null,
                            presentationType: null,
                            url: null,
                            esUrl: null,
                            id: null,
                            hat: null,
                            navigationKeyId: null,
                            target: null,
                            iconId: null,
                            icon: null,
                            trackingId: null,
                            trackingType: null,
                            showSpeedBump: null,
                            headerText: null,
                        },
                    },
                    infoIcon: {
                        id: 'infoIcon',
                        text: '/content/dam/unified-assets/iconography/auto/ico_auto_info.svg',
                        image: null,
                        primaryImage: null,
                        link: {
                            value: null,
                            type: null,
                            languageCode: null,
                            disabled: null,
                            active: null,
                            underLined: null,
                            inversed: null,
                            presentationType: null,
                            url: null,
                            esUrl: null,
                            id: null,
                            hat: null,
                            navigationKeyId: null,
                            target: null,
                            iconId: null,
                            icon: null,
                            trackingId: null,
                            trackingType: null,
                            showSpeedBump: null,
                            headerText: null,
                        },
                    },
                    eyeIcon: {
                        id: 'eyeIcon',
                        text: 'eye',
                        image: {
                            altText: null,
                            id: null,
                            source: '/content/dam/unified-assets/iconography/auto/myaccount/ico_auto_myaccount_eye.svg',
                            url: 'https://static2-qa2.chasecdn.com/content/dam/unified-assets/iconography/auto/myaccount/ico_auto_myaccount_eye.svg',
                        },
                        primaryImage: null,
                        link: {
                            value: null,
                            type: null,
                            languageCode: null,
                            disabled: null,
                            active: null,
                            underLined: null,
                            inversed: null,
                            presentationType: null,
                            url: null,
                            esUrl: null,
                            id: null,
                            hat: null,
                            navigationKeyId: null,
                            target: null,
                            iconId: null,
                            icon: null,
                            trackingId: null,
                            trackingType: null,
                            showSpeedBump: null,
                            headerText: null,
                        },
                    },
                    chevronRightWithBoxIcon: {
                        id: 'chevronRightWithBoxIcon',
                        text: 'chevronRightWithBox',
                        image: {
                            altText: null,
                            id: null,
                            source: '/content/dam/unified-assets/iconography/auto/arrow/icon_auto_bw_arrow_in_box.svg',
                            url: 'https://static2-qa2.chasecdn.com/content/dam/unified-assets/iconography/auto/arrow/icon_auto_bw_arrow_in_box.svg',
                        },
                        primaryImage: null,
                        link: {
                            value: null,
                            type: null,
                            languageCode: null,
                            disabled: null,
                            active: null,
                            underLined: null,
                            inversed: null,
                            presentationType: null,
                            url: null,
                            esUrl: null,
                            id: null,
                            hat: null,
                            navigationKeyId: null,
                            target: null,
                            iconId: null,
                            icon: null,
                            trackingId: null,
                            trackingType: null,
                            showSpeedBump: null,
                            headerText: null,
                        },
                    },
                    chevronGreenRightIcon: {
                        id: 'chevronGreenRightIcon',
                        text: 'chevronGreenRight',
                        image: {
                            altText: null,
                            id: null,
                            source: '/content/dam/unified-assets/iconography/auto/chevron/icon_auto_chevron_green_right.svg',
                            url: 'https://static2-qa2.chasecdn.com/content/dam/unified-assets/iconography/auto/chevron/icon_auto_chevron_green_right.svg',
                        },
                        primaryImage: null,
                        link: {
                            value: null,
                            type: null,
                            languageCode: null,
                            disabled: null,
                            active: null,
                            underLined: null,
                            inversed: null,
                            presentationType: null,
                            url: null,
                            esUrl: null,
                            id: null,
                            hat: null,
                            navigationKeyId: null,
                            target: null,
                            iconId: null,
                            icon: null,
                            trackingId: null,
                            trackingType: null,
                            showSpeedBump: null,
                            headerText: null,
                        },
                    },
                },
            },
            retailerInfo: {
                tags: ['auto/oem/lrfg/myaccount'],
                id: 'retailerInfo',
                title: {
                    html: null,
                    text: null,
                    markdown: null,
                },
                subTitle: {
                    html: null,
                    text: null,
                    markdown: null,
                },
                version: '20240606',
                icons: {
                    retailerInfoIcon: {
                        id: 'retailerInfoIcon',
                        text: 'YOUR LAND ROVER RETAILER',
                        image: {
                            altText: null,
                            id: null,
                            source: '/content/dam/unified-assets/iconography/auto/ico_auto_people_group.svg',
                            url: 'https://static2-qa2.chasecdn.com/content/dam/unified-assets/iconography/auto/ico_auto_people_group.svg',
                        },
                        primaryImage: null,
                        link: {
                            value: null,
                            type: null,
                            languageCode: null,
                            disabled: null,
                            active: null,
                            underLined: null,
                            inversed: null,
                            presentationType: null,
                            url: null,
                            esUrl: null,
                            id: null,
                            hat: null,
                            navigationKeyId: null,
                            target: null,
                            iconId: null,
                            icon: null,
                            trackingId: null,
                            trackingType: null,
                            showSpeedBump: null,
                            headerText: null,
                        },
                    },
                },
            },
        },
        legalContainer: {},
        dialog: {},
        contentList: {
            accountHeroBannerList: {
                id: 'accountHeroBannerList',
                title: {
                    html: null,
                    text: null,
                    markdown: null,
                },
                tags: ['auto/oem/lrfg/myaccount'],
                version: '20240606',
                items: {
                    amountDueLabel: {
                        id: 'amountDueLabel',
                        value: 'AMOUNT DUE',
                    },
                    dueDateLabel: {
                        id: 'dueDateLabel',
                        value: 'DUE DATE',
                    },
                    seePaymentDetailsList: {
                        id: 'seePaymentDetailsList',
                        value: 'SEE PAYMENT DETAILS',
                    },
                    monthlyPaymentLabel: {
                        id: 'monthlyPaymentLabel',
                        value: 'Monthly payment',
                    },
                    amountPastDueLabel: {
                        id: 'amountPastDueLabel',
                        value: 'Amount past due',
                    },
                    currentPaymentDueLabel: {
                        id: 'currentPaymentDueLabel',
                        value: 'Current payment due',
                    },
                    lateChargesLabel: {
                        id: 'lateChargesLabel',
                        value: 'Late charges',
                    },
                    otherFeesLabel: {
                        id: 'otherFeesLabel',
                        value: 'Other Fees/Charges',
                    },
                    totalAmountDueLabel: {
                        id: 'totalAmountDueLabel',
                        value: 'Total amount due',
                    },
                    deferredPaymentLabel: {
                        id: 'deferredPaymentLabel',
                        value: 'Deferred Payment',
                    },
                },
            },
            landRoverAccountDetailsList: {
                id: 'landRoverAccountDetailsList',
                title: {
                    html: '<p>Land Rover Account Details</p>\n',
                    text: 'Land Rover Account Details',
                    markdown: 'Land Rover Account Details\n',
                },
                tags: ['auto/oem/lrfg/myaccount'],
                version: '20240502',
                items: {
                    landRoverAccountDetailsOption1: {
                        id: 'landRoverAccountDetailsOption1',
                        value: 'LOAN START DATE',
                    },
                    landRoverAccountDetailsOption2: {
                        id: 'landRoverAccountDetailsOption2',
                        value: 'DEC 21, 2022',
                    },
                    landRoverAccountDetailsOption3: {
                        id: 'landRoverAccountDetailsOption3',
                        value: 'Loan Term',
                    },
                    landRoverAccountDetailsOption4: {
                        id: 'landRoverAccountDetailsOption4',
                        value: '11 /OUT OF 60',
                    },
                    landRoverAccountDetailsOption5: {
                        id: 'landRoverAccountDetailsOption5',
                        value: 'VIN',
                    },
                    landRoverAccountDetailsOption6: {
                        id: 'landRoverAccountDetailsOption6',
                        value: '*********PH919584',
                    },
                    landRoverAccountDetailsOption7: {
                        id: 'landRoverAccountDetailsOption7',
                        value: 'ACCOUNT MAILING ADDRESS',
                    },
                    landRoverAccountDetailsOption8: {
                        id: 'landRoverAccountDetailsOption8',
                        value: '1317 TILLAMACK ST LOAN MID-1 CYCLE BILLINGS, MT 59102-1022',
                    },
                    landRoverAccountDetailsOption9: {
                        id: 'landRoverAccountDetailsOption9',
                        value: 'LAST PAYMENT DATE',
                    },
                    landRoverAccountDetailsOption10: {
                        id: 'landRoverAccountDetailsOption10',
                        value: 'JUN 2, 2021',
                    },
                    landRoverAccountDetailsOption11: {
                        id: 'landRoverAccountDetailsOption11',
                        value: 'LAST PAYMENT AMOUNT',
                    },
                    landRoverAccountDetailsOption12: {
                        id: 'landRoverAccountDetailsOption12',
                        value: '$310.00',
                    },
                },
            },
        },
        tile: {
            accountHeroBannerDetails: {
                tags: ['auto/oem/lrfg/myaccount'],
                id: 'accountHeroBannerDetails',
                variationId: null,
                title: {
                    html: '<p>Welcome Back</p>\n',
                    text: 'Welcome Back',
                    markdown: 'Welcome Back\n',
                },
                subTitle: {
                    html: null,
                    text: null,
                    markdown: null,
                },
                version: '20240606',
                body: {
                    html: null,
                    text: null,
                    markdown: null,
                },
                additionalText: {
                    html: null,
                    text: null,
                    markdown: null,
                },
                hat: null,
                iconImage: {
                    altText: null,
                    id: 'defaultVehicleImage',
                    source: null,
                    url: null,
                },
                primaryImage: {
                    altText: 'mainCarImage',
                    id: null,
                    source: '/content/dam/unified-assets/photography/partner/land-rover/optimized/MyAccount_NoImageAvailable_Inter1.jpg',
                    url: 'https://static2-qa2.chasecdn.com/content/dam/unified-assets/photography/partner/land-rover/optimized/MyAccount_NoImageAvailable_Inter1.jpg',
                },
                primaryImageWithRendition: null,
                secondaryImageWithRendition: null,
                links: [],
            },
            specialOfferBanner: {
                tags: ['auto/oem/lrfg/myaccount'],
                id: 'specialOfferBanner',
                variationId: null,
                title: {
                    html: '<p>YOUR LAND ROVER SPECIAL OFFER</p>\n',
                    text: 'YOUR LAND ROVER SPECIAL OFFER',
                    markdown: 'YOUR LAND ROVER SPECIAL OFFER\n',
                },
                subTitle: {
                    html: "<p>You're Pre-approved</p>\n",
                    text: "You're Pre-approved",
                    markdown: "You're Pre-approved\n",
                },
                version: '20240606',
                body: {
                    html: '<p>To Lease or purchase</p>\n',
                    text: 'To Lease or purchase',
                    markdown: 'To Lease or purchase\n',
                },
                additionalText: {
                    html: null,
                    text: null,
                    markdown: null,
                },
                hat: null,
                iconImage: {
                    altText: null,
                    id: 'LandRoverSpecialOfferImage',
                    source: null,
                    url: null,
                },
                primaryImage: {
                    altText: 'LandRoverSpecialOffer',
                    id: null,
                    source: '/content/dam/unified-assets/photography/partner/land-rover/optimized/MyAccount_PreApproval.jpg',
                    url: 'https://static2-qa2.chasecdn.com/content/dam/unified-assets/photography/partner/land-rover/optimized/MyAccount_PreApproval.jpg',
                },
                primaryImageWithRendition: null,
                secondaryImageWithRendition: null,
                links: {
                    specialOfferBannerLink: {
                        value: null,
                        type: null,
                        languageCode: null,
                        disabled: null,
                        active: null,
                        underLined: null,
                        inversed: null,
                        presentationType: null,
                        url: '/oem/myoffers',
                        esUrl: null,
                        id: 'specialOfferBannerLink',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: 'pl_pm_my_offers',
                        trackingType: null,
                        showSpeedBump: false,
                        headerText: null,
                        visibleIn: null,
                        variant: null,
                    },
                },
            },
        },
        structuredContentList: {
            reviewYourAlerts: {
                tags: ['auto/oem/lrfg/myaccount'],
                id: 'reviewYourAlerts',
                title: {
                    html: '<p>Review Your Alerts</p>\n',
                    text: 'Review Your Alerts',
                    markdown: 'Review Your Alerts\n',
                },
                subTitle: {
                    html: null,
                    text: null,
                    markdown: null,
                },
                version: '20240606',
                items: {},
                links: [],
                mergeId: null,
            },
        },
        header: {},
    },
};
