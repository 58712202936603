export const financingOffersPage = {
    data: {
        linkList: {
            financingStepsLink: {
                id: 'financingStepsLink',
                tags: ['auto/oem/lrfg/financingandoffers'],
                version: '20240606',
                title: {
                    text: null,
                },
                subTitle: {
                    text: null,
                },
                links: {
                    getStartedLinkText: {
                        headerText: null,
                        value: 'Get started',
                        type: null,
                        presentationType: null,
                        url: 'https://www.landroverusa.com/national-dealer-locator.html',
                        esUrl: null,
                        id: 'getStartedLinkText',
                        hat: null,
                        navigationKeyId: 'Untimed Modal',
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: 'pl_getstarted',
                        trackingType: null,
                        showSpeedBump: true,
                    },
                },
            },
            loyalityProgramList: {
                id: 'loyalityProgramList',
                tags: ['auto/oem/lrfg/financingandoffers'],
                version: '20240606',
                title: {
                    text: 'LAND ROVER LOYALTY PROGRAM',
                },
                subTitle: {
                    text: null,
                },
                links: {
                    loyaltyProgramContent1: {
                        headerText: null,
                        value: 'If you currently lease your Land Rover, you may qualify for the Loyalty Disposition Fee Waiver Program.<1>{{loyaltyDispositionProgramSup}}</1> With this program, any disposition fee you may owe under your current lease may be waived.',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: 'loyaltyDispositionProgramSup',
                        id: 'loyaltyProgramContent1',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: 'loyaltyProgramSupLink',
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    loyaltyProgramContent2: {
                        headerText: null,
                        value: 'To take part of the program, you’ll need to:',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'loyaltyProgramContent2',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                },
            },
            loyaltyProgramContent2: {
                id: 'loyaltyProgramContent2',
                tags: ['auto/oem/lrfg/financingandoffers'],
                version: '20240606',
                title: {
                    text: null,
                },
                subTitle: {
                    text: null,
                },
                links: {
                    loyaltyProgramOption1: {
                        headerText: null,
                        value: 'Return your current leased vehicle',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'loyaltyProgramOption1',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    loyaltyProgramOption2: {
                        headerText: null,
                        value: 'Finance or lease a new vehicle through Land Rover Financial Group within 30 days of turning in your leased vehicle',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'loyaltyProgramOption2',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                },
            },
            loyaltyProgramSupLink: {
                id: 'loyaltyProgramSupLink',
                tags: ['auto/oem/lrfg/financingandoffers'],
                version: '20240606',
                title: {
                    text: null,
                },
                subTitle: {
                    text: null,
                },
                links: {
                    loyaltyDispositionProgramSup: {
                        headerText: 'sup',
                        value: '1',
                        type: null,
                        presentationType: null,
                        url: '/financing#loyalty-program-disclosure',
                        esUrl: null,
                        id: 'loyaltyDispositionProgramSup',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    loyaltyDisclosureSup: {
                        headerText: 'sup',
                        value: '1',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'loyaltyDisclosureSup',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                },
            },
        },
        carousel: {
            leasePurchaseCarouselLinkList: {
                brands: null,
                segments: null,
                tags: ['auto/oem/lrfg/financingandoffers'],
                id: 'leasePurchaseCarouselLinkList',
                title: {
                    html: null,
                    text: null,
                    markdown: null,
                },
                subTitle: {
                    html: null,
                    text: null,
                    markdown: null,
                },
                version: '20240606',
                heroes: [],
                tiles: {
                    leasePurchaseLinks: {
                        brands: null,
                        segments: null,
                        tags: ['ccb-cf:auto/oem/lrfg/financingandoffers'],
                        id: 'leasePurchaseLinks',
                        variationId: null,
                        title: {
                            html: null,
                            text: null,
                            markdown: null,
                        },
                        subTitle: {
                            html: null,
                            text: null,
                            markdown: null,
                        },
                        version: '20240606',
                        body: {
                            html: null,
                            text: null,
                            markdown: null,
                        },
                        additionalText: {
                            html: null,
                            text: null,
                            markdown: null,
                        },
                        hat: null,
                        iconImage: null,
                        primaryImage: null,
                        primaryImageWithRendition: null,
                        secondaryImageWithRendition: null,
                        links: {
                            ConvenientLeaseEndOptions: {
                                value: 'Convenient lease-end options',
                                type: null,
                                languageCode: null,
                                disabled: null,
                                active: null,
                                underLined: null,
                                inversed: null,
                                presentationType: null,
                                url: '/lease-end',
                                esUrl: null,
                                id: 'ConvenientLeaseEndOptions',
                                hat: null,
                                navigationKeyId: null,
                                target: null,
                                iconId: null,
                                icon: null,
                                trackingId: 'pl_fin_le_options',
                                trackingType: null,
                                showSpeedBump: null,
                                headerText: 'anquer',
                            },
                        },
                    },
                },
            },
            leasePurchaseCarouselList: {
                brands: null,
                segments: null,
                tags: ['auto/oem/lrfg/financingandoffers'],
                id: 'leasePurchaseCarouselList',
                title: {
                    html: '<p>LEASE vs PURCHASE</p>\n',
                    text: 'LEASE vs PURCHASE',
                    markdown: 'LEASE vs PURCHASE\n',
                },
                subTitle: {
                    html: null,
                    text: null,
                    markdown: null,
                },
                version: '20240606',
                heroes: [],
                tiles: {
                    lease: {
                        brands: null,
                        segments: null,
                        tags: ['ccb-cf:auto/oem/lrfg/financingandoffers'],
                        id: 'lease',
                        variationId: null,
                        title: {
                            html: '<p>LEASE</p>\n',
                            text: 'LEASE',
                            markdown: 'LEASE\n',
                        },
                        subTitle: {
                            html: null,
                            text: null,
                            markdown: null,
                        },
                        version: '20240606',
                        body: {
                            html: null,
                            text: null,
                            markdown: null,
                        },
                        additionalText: {
                            html: null,
                            text: null,
                            markdown: null,
                        },
                        hat: null,
                        iconImage: {
                            altText: null,
                            id: 'leaseTab',
                            source: null,
                            url: null,
                        },
                        primaryImage: {
                            altText: 'leaseTab',
                            id: null,
                            source: '/content/dam/unified-assets/photography/partner/land-rover/optimized/Finance_LeaseTab_Desktop1.jpg',
                            url: 'https://static2-qa2.chasecdn.com/content/dam/unified-assets/photography/partner/land-rover/optimized/Finance_LeaseTab_Desktop1.jpg',
                        },
                        primaryImageWithRendition: null,
                        secondaryImageWithRendition: null,
                        links: {
                            leaseTileContentList1: {
                                value: 'With a lease, you can choose a new vehicle with the latest technology and features every 12 to 48 months.',
                                type: null,
                                languageCode: null,
                                disabled: null,
                                active: null,
                                underLined: null,
                                inversed: null,
                                presentationType: null,
                                url: null,
                                esUrl: null,
                                id: 'leaseTileContentList1',
                                hat: null,
                                navigationKeyId: null,
                                target: null,
                                iconId: null,
                                icon: null,
                                trackingId: null,
                                trackingType: null,
                                showSpeedBump: null,
                                headerText: null,
                            },
                            leaseTileContentList2: {
                                value: 'You may enjoy lower monthly payments and flexible mileage options.',
                                type: null,
                                languageCode: null,
                                disabled: null,
                                active: null,
                                underLined: null,
                                inversed: null,
                                presentationType: null,
                                url: null,
                                esUrl: null,
                                id: 'leaseTileContentList2',
                                hat: null,
                                navigationKeyId: null,
                                target: null,
                                iconId: null,
                                icon: null,
                                trackingId: null,
                                trackingType: null,
                                showSpeedBump: null,
                                headerText: null,
                            },
                            leaseTileContentList3: {
                                value: '<1>{{ConvenientLeaseEndOptions}}</1>: at lease maturity, trade in your Land Rover for a new one, buy it or select a different vehicle.',
                                type: null,
                                languageCode: null,
                                disabled: null,
                                active: null,
                                underLined: null,
                                inversed: null,
                                presentationType: null,
                                url: null,
                                esUrl: 'ConvenientLeaseEndOptions',
                                id: 'leaseTileContentList3',
                                hat: null,
                                navigationKeyId: null,
                                target: null,
                                iconId: 'leasePurchaseLinks',
                                icon: null,
                                trackingId: null,
                                trackingType: null,
                                showSpeedBump: null,
                                headerText: null,
                            },
                        },
                    },
                    purchase: {
                        brands: null,
                        segments: null,
                        tags: ['ccb-cf:auto/oem/lrfg/financingandoffers'],
                        id: 'purchase',
                        variationId: null,
                        title: {
                            html: '<p>PURCHASE</p>\n',
                            text: 'PURCHASE',
                            markdown: 'PURCHASE\n',
                        },
                        subTitle: {
                            html: null,
                            text: null,
                            markdown: null,
                        },
                        version: '20240606',
                        body: {
                            html: null,
                            text: null,
                            markdown: null,
                        },
                        additionalText: {
                            html: null,
                            text: null,
                            markdown: null,
                        },
                        hat: null,
                        iconImage: {
                            altText: null,
                            id: 'purchaseTab',
                            source: null,
                            url: null,
                        },
                        primaryImage: {
                            altText: 'purchaseTab',
                            id: null,
                            source: '/content/dam/unified-assets/photography/partner/land-rover/optimized/Finance_PurchaseTab_Desktop1.jpg',
                            url: 'https://static2-qa2.chasecdn.com/content/dam/unified-assets/photography/partner/land-rover/optimized/Finance_PurchaseTab_Desktop1.jpg',
                        },
                        primaryImageWithRendition: null,
                        secondaryImageWithRendition: null,
                        links: {
                            purchaseTileContentList1: {
                                value: 'Each payment brings you closer to the pride of ownership. The creditor usually holds a security interest on the vehicle until you make your final payment.',
                                type: null,
                                languageCode: null,
                                disabled: null,
                                active: null,
                                underLined: null,
                                inversed: null,
                                presentationType: null,
                                url: null,
                                esUrl: null,
                                id: 'purchaseTileContentList1',
                                hat: null,
                                navigationKeyId: null,
                                target: null,
                                iconId: null,
                                icon: null,
                                trackingId: null,
                                trackingType: null,
                                showSpeedBump: null,
                                headerText: null,
                            },
                            purchaseTileContentList2: {
                                value: 'Unlimited mileage lets you drive where you want without counting miles.',
                                type: null,
                                languageCode: null,
                                disabled: null,
                                active: null,
                                underLined: null,
                                inversed: null,
                                presentationType: null,
                                url: null,
                                esUrl: null,
                                id: 'purchaseTileContentList2',
                                hat: null,
                                navigationKeyId: null,
                                target: null,
                                iconId: null,
                                icon: null,
                                trackingId: null,
                                trackingType: null,
                                showSpeedBump: null,
                                headerText: null,
                            },
                            purchaseTileContentList3: {
                                value: 'Enjoy the full benefit of the trade-in or resale value.',
                                type: null,
                                languageCode: null,
                                disabled: null,
                                active: null,
                                underLined: null,
                                inversed: null,
                                presentationType: null,
                                url: null,
                                esUrl: null,
                                id: 'purchaseTileContentList3',
                                hat: null,
                                navigationKeyId: null,
                                target: null,
                                iconId: null,
                                icon: null,
                                trackingId: null,
                                trackingType: null,
                                showSpeedBump: null,
                                headerText: null,
                            },
                        },
                    },
                },
            },
        },
        iconList: {
            buttonIcons: {
                tags: ['auto/oem/lrfg/financingandoffers'],
                id: 'buttonIcons',
                title: {
                    html: null,
                    text: null,
                    markdown: null,
                },
                subTitle: {
                    html: null,
                    text: null,
                    markdown: null,
                },
                version: '20240606',
                icons: {
                    rightArrowIconWhite: {
                        id: 'rightArrowIconWhite',
                        text: 'rightArrowIcon',
                        image: {
                            altText: null,
                            id: null,
                            source: '/content/dam/unified-assets/iconography/auto/chevron/icon_auto_chevron_white_pointing_right.svg',
                            url: 'https://static2-qa2.chasecdn.com/content/dam/unified-assets/iconography/auto/chevron/icon_auto_chevron_white_pointing_right.svg',
                        },
                        primaryImage: null,
                        link: {
                            value: null,
                            type: null,
                            languageCode: null,
                            disabled: null,
                            active: null,
                            underLined: null,
                            inversed: null,
                            presentationType: null,
                            url: null,
                            esUrl: null,
                            id: null,
                            hat: null,
                            navigationKeyId: null,
                            target: null,
                            iconId: null,
                            icon: null,
                            trackingId: null,
                            trackingType: null,
                            showSpeedBump: null,
                            headerText: null,
                        },
                    },
                    rightArrowIconBlack: {
                        id: 'rightArrowIconBlack',
                        text: 'rightArrowIcon',
                        image: {
                            altText: null,
                            id: null,
                            source: 'rightArrowIconBlackImage',
                            url: 'https://static2-qa2.chasecdn.comrightArrowIconBlackImage',
                        },
                        primaryImage: null,
                        link: {
                            value: null,
                            type: null,
                            languageCode: null,
                            disabled: null,
                            active: null,
                            underLined: null,
                            inversed: null,
                            presentationType: null,
                            url: null,
                            esUrl: null,
                            id: null,
                            hat: null,
                            navigationKeyId: null,
                            target: null,
                            iconId: null,
                            icon: null,
                            trackingId: null,
                            trackingType: null,
                            showSpeedBump: null,
                            headerText: null,
                        },
                    },
                },
            },
        },
        legalContainer: {
            legalNote: {
                brands: null,
                segments: null,
                tags: ['auto/oem/lrfg/financingandoffers'],
                id: 'legalNote',
                title: {
                    html: null,
                    text: null,
                    markdown: null,
                },
                subTitle: {
                    html: null,
                    text: null,
                    markdown: null,
                },
                disclaimer: [],
                disclosure: [],
                footNote: {
                    footNote: {
                        id: 'footNote',
                        title: {
                            html: '<p>FootNote</p>\n',
                            text: 'FootNote',
                            markdown: 'FootNote\n',
                        },
                        description: {
                            html: null,
                            text: null,
                            markdown: null,
                        },
                        links: {
                            legalContainerLink1: {
                                value: '<1>{{loyaltyDisclosureSup}}</1>  Offer is valid for all current Land Rover Financial Group (LRFG) lessees who:',
                                type: null,
                                languageCode: null,
                                disabled: null,
                                active: null,
                                underLined: null,
                                inversed: null,
                                presentationType: null,
                                url: null,
                                esUrl: 'loyaltyDisclosureSup',
                                id: 'legalContainerLink1',
                                hat: null,
                                navigationKeyId: null,
                                target: null,
                                iconId: 'loyaltyProgramSupLink',
                                icon: null,
                                trackingId: null,
                                trackingType: null,
                                showSpeedBump: false,
                                headerText: null,
                            },
                            legalContainerLink2: {
                                value: '(a) Return the leased vehicle no earlier than 180 days prior to the originally contracted maturity date and no later than the originally contracted maturity date, or if extended, no later than the extended maturity date, and',
                                type: null,
                                languageCode: null,
                                disabled: null,
                                active: null,
                                underLined: null,
                                inversed: null,
                                presentationType: null,
                                url: null,
                                esUrl: null,
                                id: 'legalContainerLink2',
                                hat: null,
                                navigationKeyId: null,
                                target: null,
                                iconId: null,
                                icon: null,
                                trackingId: null,
                                trackingType: null,
                                showSpeedBump: false,
                                headerText: null,
                            },
                            legalContainerLink3: {
                                value: '(b) Within 30 days of vehicle return, finance or lease a new Land Rover vehicle through LRFG. Verification of current LRFG lease may be required. This is an ongoing offer, but the program may be discontinued by LRFG at any time without notice.',
                                type: null,
                                languageCode: null,
                                disabled: null,
                                active: null,
                                underLined: null,
                                inversed: null,
                                presentationType: null,
                                url: null,
                                esUrl: null,
                                id: 'legalContainerLink3',
                                hat: null,
                                navigationKeyId: null,
                                target: null,
                                iconId: null,
                                icon: null,
                                trackingId: null,
                                trackingType: null,
                                showSpeedBump: false,
                                headerText: null,
                            },
                            legalContainerLink4: {
                                value: 'Disposition fees are not charged on leases originated in Wisconsin. By contacting your Land Rover retailer about this offer, they will know that you are a valued Chase customer.',
                                type: null,
                                languageCode: null,
                                disabled: null,
                                active: null,
                                underLined: null,
                                inversed: null,
                                presentationType: null,
                                url: null,
                                esUrl: null,
                                id: 'legalContainerLink4',
                                hat: null,
                                navigationKeyId: null,
                                target: null,
                                iconId: null,
                                icon: null,
                                trackingId: null,
                                trackingType: null,
                                showSpeedBump: false,
                                headerText: null,
                            },
                            legalContainerLink5: {
                                value: 'Please contact your financial advisor for tax implications. New leases and financing through LRFG are subject to credit approval. Additional terms and conditions apply.',
                                type: null,
                                languageCode: null,
                                disabled: false,
                                active: false,
                                underLined: false,
                                inversed: false,
                                presentationType: null,
                                url: null,
                                esUrl: null,
                                id: 'legalContainerLink5',
                                hat: null,
                                navigationKeyId: null,
                                target: null,
                                iconId: null,
                                icon: null,
                                trackingId: null,
                                trackingType: null,
                                showSpeedBump: false,
                                headerText: null,
                            },
                        },
                    },
                },
                contentLists: [],
                version: '20240606',
            },
        },
        dialog: {},
        contentList: {},
        tile: {
            financingOptions: {
                tags: ['auto/oem/lrfg/financingandoffers'],
                id: 'financingOptions',
                variationId: null,
                title: {
                    html: '<p>FINANCING OPTIONS</p>\n',
                    text: 'FINANCING OPTIONS',
                    markdown: 'FINANCING OPTIONS\n',
                },
                subTitle: {
                    html: null,
                    text: null,
                    markdown: null,
                },
                version: '20240606',
                body: {
                    html: null,
                    text: null,
                    markdown: null,
                },
                additionalText: {
                    html: null,
                    text: null,
                    markdown: null,
                },
                hat: null,
                iconImage: {
                    altText: null,
                    id: 'FinancingOptions',
                    source: null,
                    url: null,
                },
                primaryImage: {
                    altText: 'FinancingOptions',
                    id: null,
                    source: '/content/dam/unified-assets/photography/partner/land-rover/optimized/Financing_Hero_Desktop1.jpg',
                    url: 'https://static2-qa2.chasecdn.com/content/dam/unified-assets/photography/partner/land-rover/optimized/Financing_Hero_Desktop1.jpg',
                },
                primaryImageWithRendition: null,
                secondaryImageWithRendition: null,
                links: {
                    getStartedLinkText: {
                        value: 'Get started',
                        type: null,
                        languageCode: null,
                        disabled: null,
                        active: null,
                        underLined: null,
                        inversed: null,
                        presentationType: null,
                        url: 'https://www.landroverusa.com/current-offers/finance-calculator.html#/models',
                        esUrl: null,
                        id: 'getStartedLinkText',
                        hat: null,
                        navigationKeyId: 'Untimed Modal',
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: 'pl_hero_getstarted',
                        trackingType: null,
                        showSpeedBump: null,
                        headerText: null,
                        visibleIn: null,
                        variant: null,
                    },
                },
            },
        },
        structuredContentList: {
            selectAndFinanceList: {
                tags: ['auto/oem/lrfg/financingandoffers'],
                id: 'selectAndFinanceList',
                title: {
                    html: '<p>SELECT AND FINANCE YOUR LAND ROVER IN 3 STEPS</p>\n',
                    text: 'SELECT AND FINANCE YOUR LAND ROVER IN 3 STEPS',
                    markdown: 'SELECT AND FINANCE YOUR LAND ROVER IN 3 STEPS\n',
                },
                subTitle: {
                    html: null,
                    text: null,
                    markdown: null,
                },
                version: '20240606',
                items: {
                    selectAndFinanceOption1: {
                        id: 'selectAndFinanceOption1',
                        text: {
                            html: '<p>1.&nbsp;BUILD AND PRICE</p>\n',
                            text: '1. BUILD AND PRICE',
                            markdown: '1. BUILD AND PRICE\n',
                        },
                        description: {
                            html: "<p>Build the Land Rover that's perfectly suited to your lifestyle.</p>\n",
                            text: "Build the Land Rover that's perfectly suited to your lifestyle.",
                            markdown: "Build the Land Rover that's perfectly suited to your lifestyle.\n",
                        },
                        additionalText: {
                            html: null,
                            text: null,
                            markdown: null,
                        },
                        hat: null,
                        iconId: 'BuildAndPriceIcon',
                        primaryImage: {
                            altText: null,
                            id: '',
                            source: '/content/dam/unified-assets/iconography/auto/ico_auto_car_front_view_two_tone.svg',
                            url: 'https://static2-qa2.chasecdn.com/content/dam/unified-assets/iconography/auto/ico_auto_car_front_view_two_tone.svg',
                        },
                        imageWithRenditions: [],
                    },
                    selectAndFinanceOption2: {
                        id: 'selectAndFinanceOption2',
                        text: {
                            html: '<p>2. REQUEST A QUOTE</p>\n',
                            text: '2. REQUEST A QUOTE',
                            markdown: '2. REQUEST A QUOTE\n',
                        },
                        description: {
                            html: "<p>Submit your information to the retailer closest and they'll be in touch to discuss next steps.</p>\n",
                            text: "Submit your information to the retailer closest and they'll be in touch to discuss next steps.",
                            markdown: "Submit your information to the retailer closest and they'll be in touch to discuss next steps.\n",
                        },
                        additionalText: {
                            html: null,
                            text: null,
                            markdown: null,
                        },
                        hat: null,
                        iconId: 'RequestQuoteIcon',
                        primaryImage: {
                            altText: null,
                            id: '',
                            source: '/content/dam/unified-assets/iconography/auto/ico_auto_calculator_money.svg',
                            url: 'https://static2-qa2.chasecdn.com/content/dam/unified-assets/iconography/auto/ico_auto_calculator_money.svg',
                        },
                        imageWithRenditions: [],
                    },
                    selectAndFinanceOption3: {
                        id: 'selectAndFinanceOption3',
                        text: {
                            html: '<p>3. COMPLETE THE APPLICATION</p>\n',
                            text: '3. COMPLETE THE APPLICATION',
                            markdown: '3. COMPLETE THE APPLICATION\n',
                        },
                        description: {
                            html: '<p>Contact your retailer to complete a credit application.</p>\n',
                            text: 'Contact your retailer to complete a credit application.',
                            markdown: 'Contact your retailer to complete a credit application.\n',
                        },
                        additionalText: {
                            html: null,
                            text: null,
                            markdown: null,
                        },
                        hat: null,
                        iconId: 'CompleteIcon',
                        primaryImage: {
                            altText: null,
                            id: '',
                            source: '/content/dam/unified-assets/iconography/auto/ico_auto_document_accept.svg',
                            url: 'https://static2-qa2.chasecdn.com/content/dam/unified-assets/iconography/auto/ico_auto_document_accept.svg',
                        },
                        imageWithRenditions: [],
                    },
                },
                links: [],
                mergeId: null,
            },
        },
    },
};
