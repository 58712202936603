export const leaseExplore = {
    data: {
        linkList: {
            ExploreANewVehicleList: {
                id: 'ExploreANewVehicleList',
                tags: ['auto/oem/lrfg/exploreanewlandrover'],
                version: '20240606',
                title: {
                    text: null,
                },
                subTitle: {
                    text: null,
                },
                links: {
                    ExploreANewVehicleSubcontent1: {
                        headerText: null,
                        value: 'FIND YOUR NEW LAND ROVER',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'ExploreANewVehicleSubcontent1',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    ExploreANewVehicleSubcontent2: {
                        headerText: null,
                        value: 'PREPARE TO RETURN YOUR CURRENT LAND ROVER',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'ExploreANewVehicleSubcontent2',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    ExploreANewVehicleSubcontent3: {
                        headerText: null,
                        value: 'FINANCE YOUR NEW LAND ROVER',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'ExploreANewVehicleSubcontent3',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                },
            },
            ExploreANewVehicleSubcontent1: {
                id: 'ExploreANewVehicleSubcontent1',
                tags: ['auto/oem/lrfg/exploreanewlandrover'],
                version: '20240606',
                title: {
                    text: null,
                },
                subTitle: {
                    text: null,
                },
                links: {
                    findVehicleLink1: {
                        headerText: null,
                        value: 'Whether you want to stay in the same model you have now, or try something new, search <1>{{localInventory}}</1> to find your new high-performance vehicle.',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: 'localInventory',
                        id: 'findVehicleLink1',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: 'exploreANewVehicleLinkList',
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    findVehicleLink2: {
                        headerText: null,
                        value: 'Sign in to your <1>{{landRoverFinGroup}}</1> account to see if you have any offers.',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: 'landRoverFinGroup',
                        id: 'findVehicleLink2',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: 'exploreANewVehicleLinkList',
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                },
            },
            ExploreANewVehicleSubcontent2: {
                id: 'ExploreANewVehicleSubcontent2',
                tags: ['auto/oem/lrfg/exploreanewlandrover'],
                version: '20240606',
                title: {
                    text: null,
                },
                subTitle: {
                    text: null,
                },
                links: {
                    returnVehicleLink1: {
                        headerText: null,
                        value: 'If you’re looking for your next Land Rover, it’s time to get your current one ready for turn-in. Go to our <1>{{printableLandRover}}</1> for steps to help you successfully turn in your vehicle.',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: 'printableLandRover',
                        id: 'returnVehicleLink1',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: 'exploreANewVehicleLinkList',
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    returnVehicleLink2: {
                        headerText: null,
                        value: "Your Land Rover retailer can provide you with an estimate of your current vehicle's worth and help you determine what works for you.",
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'returnVehicleLink2',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                },
            },
            ExploreANewVehicleSubcontent3: {
                id: 'ExploreANewVehicleSubcontent3',
                tags: ['auto/oem/lrfg/exploreanewlandrover'],
                version: '20240606',
                title: {
                    text: null,
                },
                subTitle: {
                    text: null,
                },
                links: {
                    financeVehicleLink1: {
                        headerText: null,
                        value: "Once you've found the new Land Rover that best fits your lifestyle, go to your Land Rover retailer to start the process of leasing or purchasing your next vehicle. If you need help finding a retailer, visit <1>{{landRoverUsa}}</1> to locate one.",
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: 'landRoverUsa',
                        id: 'financeVehicleLink1',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: 'exploreANewVehicleLinkList',
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                },
            },
            FormsList: {
                id: 'FormsList',
                tags: ['auto/oem/lrfg/exploreanewlandrover'],
                version: '20240606',
                title: {
                    text: 'FORMS',
                },
                subTitle: {
                    text: null,
                },
                links: {
                    FormsContent1: {
                        headerText: null,
                        value: 'Digital Lease Turn-in form',
                        type: null,
                        presentationType: null,
                        url: '/lease-end-turnin',
                        esUrl: null,
                        id: 'FormsContent1',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: 'pl_le_ql_turnin',
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    FormsContent2: {
                        headerText: null,
                        value: 'Odometer statement',
                        type: null,
                        presentationType: null,
                        url: 'https://www.chase.com/content/dam/chase-ux/documents/personal/auto-loans/odometer_statement_lease.pdf?jp_cdf=LRFG',
                        esUrl: null,
                        id: 'FormsContent2',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: 'pl_le_ql_odo_Stm',
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    FormsContent3: {
                        headerText: null,
                        value: 'Printable Land Rover return checklist',
                        type: null,
                        presentationType: null,
                        url: '/land-rover/Land-Rover-Lease-End-Checklist-2022.pdf',
                        esUrl: null,
                        id: 'FormsContent3',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: 'pl_le_ql_stn_chklst',
                        trackingType: null,
                        showSpeedBump: false,
                    },
                },
            },
            LoyaltyProgramContent3: {
                id: 'LoyaltyProgramContent3',
                tags: ['auto/oem/lrfg/exploreanewlandrover'],
                version: '20240606',
                title: {
                    text: null,
                },
                subTitle: {
                    text: null,
                },
                links: {
                    loyaltyProgramOption1: {
                        headerText: null,
                        value: 'Return your current leased vehicle.',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'loyaltyProgramOption1',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    loyaltyProgramOption2: {
                        headerText: null,
                        value: 'Finance or lease a new vehicle through Land Rover Financial Group within 30 days of turning in your leased vehicle.',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'loyaltyProgramOption2',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                },
            },
            LoyaltyProgramList: {
                id: 'LoyaltyProgramList',
                tags: ['auto/oem/lrfg/exploreanewlandrover'],
                version: '20240606',
                title: {
                    text: null,
                },
                subTitle: {
                    text: null,
                },
                links: {
                    LoyaltyProgramContent1: {
                        headerText: null,
                        value: 'If you currently lease your Land Rover, you may qualify for the Loyalty Disposition Fee Waiver Program.<1>{{loyaltyProgramSup}}</1>',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: 'loyaltyProgramSup',
                        id: 'LoyaltyProgramContent1',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: 'exploreANewVehicleLinkList',
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    LoyaltyProgramContent2: {
                        headerText: null,
                        value: "The disposition fee is commonly charged if you don't purchase the vehicle—it covers the vehicle’s transport and sale costs. With this program, any disposition fee you may owe under your current lease may be waived.",
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: '1',
                        id: 'LoyaltyProgramContent2',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    LoyaltyProgramContent3: {
                        headerText: null,
                        value: 'To take part of the program, you’ll need to:',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: '1',
                        id: 'LoyaltyProgramContent3',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                },
            },
            QuickLinksList: {
                id: 'QuickLinksList',
                tags: ['auto/oem/lrfg/exploreanewlandrover'],
                version: '20240606',
                title: {
                    text: 'QUICK LINKS',
                },
                subTitle: {
                    text: null,
                },
                links: {
                    QuickLinkContent1: {
                        headerText: null,
                        value: 'Schedule a pre-inspection',
                        type: null,
                        presentationType: null,
                        url: 'https://autovinlive.com/Login/EntryGet',
                        esUrl: null,
                        id: 'QuickLinkContent1',
                        hat: null,
                        navigationKeyId: 'Untimed Modal',
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: 'pl_le_ql_schedule',
                        trackingType: null,
                        showSpeedBump: true,
                    },
                    QuickLinkContent2: {
                        headerText: null,
                        value: 'Review your lease-end options',
                        type: null,
                        presentationType: null,
                        url: '/lease-end',
                        esUrl: null,
                        id: 'QuickLinkContent2',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: 'pl_le_ql_options',
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    QuickLinkContent3: {
                        headerText: null,
                        value: 'FAQs',
                        type: null,
                        presentationType: null,
                        url: '/faq',
                        esUrl: null,
                        id: 'QuickLinkContent3',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: 'pl_le_ql_faq',
                        trackingType: null,
                        showSpeedBump: false,
                    },
                },
            },
            ReturnAndFinanceList: {
                id: 'ReturnAndFinanceList',
                tags: ['auto/oem/lrfg/exploreanewlandrover'],
                version: '20240606',
                title: {
                    text: 'To take part of the program, you’ll need to:',
                },
                subTitle: {
                    text: null,
                },
                links: {
                    content1: {
                        headerText: null,
                        value: 'Return your current leased vehicle.',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'content1',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    content2: {
                        headerText: null,
                        value: 'Finance or lease a new vehicle through Land Rover Financial Group within 30 days of turning in your leased vehicle.',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'content2',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                },
            },
            exploreANewVehicleLinkList: {
                id: 'exploreANewVehicleLinkList',
                tags: ['auto/oem/lrfg/exploreanewlandrover'],
                version: '20240606',
                title: {
                    text: null,
                },
                subTitle: {
                    text: null,
                },
                links: {
                    localInventory: {
                        headerText: 'anquer',
                        value: 'local inventory ',
                        type: null,
                        presentationType: null,
                        url: 'https://inventory.landroverusa.com/new/',
                        esUrl: 'localInventory',
                        id: 'localInventory',
                        hat: null,
                        navigationKeyId: 'Untimed Modal',
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: 'pl_le_local_inevntory',
                        trackingType: null,
                        showSpeedBump: true,
                    },
                    landRoverFinGroup: {
                        headerText: 'anquer',
                        value: 'Land Rover Financial Group',
                        type: null,
                        presentationType: null,
                        url: '/myaccount',
                        esUrl: null,
                        id: 'landRoverFinGroup',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: 'pl_le_login',
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    printableLandRover: {
                        headerText: 'anquer',
                        value: 'Printable Land Rover Return checklist',
                        type: null,
                        presentationType: null,
                        url: 'https://lrfg-uat.chase.com/oem/land-rover/Land-Rover-Lease-End-Checklist-2022.pdf',
                        esUrl: null,
                        id: 'printableLandRover',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: 'pl_le_new_stn_chklst',
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    landRoverUsa: {
                        headerText: 'anquer',
                        value: 'LandRoverUSA.com',
                        type: null,
                        presentationType: null,
                        url: 'https://www.landroverusa.com/index.html',
                        esUrl: null,
                        id: 'landRoverUsa',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: 'pl_le_find_retailer',
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    loyaltyProgramSup: {
                        headerText: 'sup',
                        value: '1',
                        type: null,
                        presentationType: null,
                        url: '/lease-end/new#land-rover-loyalty-program-disclosure',
                        esUrl: null,
                        id: 'loyaltyProgramSup',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    legalContainerSup: {
                        headerText: 'sup',
                        value: '1',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'legalContainerSup',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                },
            },
        },
        carousel: {
            AdditionalLeaseEndOptionsList: {
                brands: null,
                segments: null,
                tags: ['auto/oem/lrfg/exploreanewlandrover'],
                id: 'AdditionalLeaseEndOptionsList',
                title: {
                    html: '<p>ADDITIONAL LEASE-END OPTIONS</p>\n',
                    text: 'ADDITIONAL LEASE-END OPTIONS',
                    markdown: 'ADDITIONAL LEASE-END OPTIONS\n',
                },
                subTitle: {
                    html: null,
                    text: null,
                    markdown: null,
                },
                version: '20240606',
                heroes: [],
                tiles: {
                    AdditionalLeaseEndOption1: {
                        brands: null,
                        segments: null,
                        tags: ['ccb-cf:auto/oem/lrfg/exploreanewlandrover'],
                        id: 'AdditionalLeaseEndOption1',
                        variationId: null,
                        title: {
                            html: '<p>Return your current Land Rover</p>\n',
                            text: 'Return your current Land Rover',
                            markdown: 'Return your current Land Rover\n',
                        },
                        subTitle: {
                            html: '<p>You’re getting close to the end of your lease, so it’s time to figure out how to return your Land Rover. Review our timeline so you can have a successful turn-in experience.</p>\n',
                            text: 'You’re getting close to the end of your lease, so it’s time to figure out how to return your Land Rover. Review our timeline so you can have a successful turn-in experience.',
                            markdown: "You're getting close to the end of your lease, so it's time to figure out how to return your Land Rover. Review our timeline so you can have a successful turn-in experience.\n",
                        },
                        version: '20240606',
                        body: {
                            html: null,
                            text: null,
                            markdown: null,
                        },
                        additionalText: {
                            html: null,
                            text: null,
                            markdown: null,
                        },
                        hat: null,
                        iconImage: {
                            altText: null,
                            id: 'ReturnYourCurrentVehicleTextImage',
                            source: null,
                            url: null,
                        },
                        primaryImage: {
                            altText: 'ReturnYourCurrentVehicleText',
                            id: null,
                            source: '/content/dam/unified-assets/photography/partner/land-rover/optimized/Return_Lease_End_Addl_Options_Card_1.jpg',
                            url: 'https://static2-qa2.chasecdn.com/content/dam/unified-assets/photography/partner/land-rover/optimized/Return_Lease_End_Addl_Options_Card_1.jpg',
                        },
                        primaryImageWithRendition: null,
                        secondaryImageWithRendition: null,
                        links: {
                            ReturnYourCurrentVehicleLink: {
                                value: 'HOW TO RETURN YOUR LAND ROVER',
                                type: null,
                                languageCode: null,
                                disabled: null,
                                active: null,
                                underLined: null,
                                inversed: null,
                                presentationType: null,
                                url: '/lease-end/return',
                                esUrl: null,
                                id: 'ReturnYourCurrentVehicleLink',
                                hat: null,
                                navigationKeyId: null,
                                target: null,
                                iconId: null,
                                icon: null,
                                trackingId: 'pl_le_return',
                                trackingType: null,
                                showSpeedBump: null,
                                headerText: null,
                            },
                        },
                    },
                    AdditionalLeaseEndOption2: {
                        brands: null,
                        segments: null,
                        tags: ['ccb-cf:auto/oem/lrfg/exploreanewlandrover'],
                        id: 'AdditionalLeaseEndOption2',
                        variationId: null,
                        title: {
                            html: '<p>EXTEND, PURCHASE OR EARLY TURN-IN</p>\n',
                            text: 'EXTEND, PURCHASE OR EARLY TURN-IN',
                            markdown: 'EXTEND, PURCHASE OR EARLY TURN-IN\n',
                        },
                        subTitle: {
                            html: '<p>If you’re not ready to finance a new Land Rover, you have the option to purchase your current Land Rover or extend your lease. If you’re not at the end of your lease, but you’re ready for a new vehicle, make sure you understand the financial impact of early turn-in.</p>\n',
                            text: 'If you’re not ready to finance a new Land Rover, you have the option to purchase your current Land Rover or extend your lease. If you’re not at the end of your lease, but you’re ready for a new vehicle, make sure you understand the financial impact of early turn-in.',
                            markdown: "If you're not ready to finance a new Land Rover, you have the option to purchase your current Land Rover or extend your lease. If you're not at the end of your lease, but you're ready for a new vehicle, make sure you understand the financial impact of early turn-in.\n",
                        },
                        version: '20240606',
                        body: {
                            html: null,
                            text: null,
                            markdown: null,
                        },
                        additionalText: {
                            html: null,
                            text: null,
                            markdown: null,
                        },
                        hat: null,
                        iconImage: {
                            altText: null,
                            id: 'ReturnYourCurrentVehicleImage',
                            source: null,
                            url: null,
                        },
                        primaryImage: {
                            altText: 'ReturnYourCurrentVehicleText',
                            id: null,
                            source: '/content/dam/unified-assets/photography/partner/land-rover/optimized/Other_Lease_End_Addl_Options_Card_1.jpg',
                            url: 'https://static2-qa2.chasecdn.com/content/dam/unified-assets/photography/partner/land-rover/optimized/Other_Lease_End_Addl_Options_Card_1.jpg',
                        },
                        primaryImageWithRendition: null,
                        secondaryImageWithRendition: null,
                        links: {
                            ExtendPurchaseOrEarlyTurnInLink: {
                                value: 'LEARN MORE',
                                type: null,
                                languageCode: null,
                                disabled: null,
                                active: null,
                                underLined: null,
                                inversed: null,
                                presentationType: null,
                                url: '/lease-end/other',
                                esUrl: null,
                                id: 'ExtendPurchaseOrEarlyTurnInLink',
                                hat: null,
                                navigationKeyId: null,
                                target: null,
                                iconId: null,
                                icon: null,
                                trackingId: 'pl_le_other',
                                trackingType: null,
                                showSpeedBump: null,
                                headerText: null,
                            },
                        },
                    },
                },
            },
        },
        iconList: {
            bellyBandIcons: {
                tags: ['auto/oem/lrfg/exploreanewlandrover'],
                id: 'bellyBandIcons',
                title: {
                    html: null,
                    text: null,
                    markdown: null,
                },
                subTitle: {
                    html: null,
                    text: null,
                    markdown: null,
                },
                version: '20240606',
                icons: {
                    navigationIcon: {
                        id: 'navigationIcon',
                        text: 'Find A Land Rover Retailer',
                        image: {
                            altText: null,
                            id: null,
                            source: '/content/dam/unified-assets/iconography/auto/ico_auto_location.svg',
                            url: 'https://static2-qa2.chasecdn.com/content/dam/unified-assets/iconography/auto/ico_auto_location.svg',
                        },
                        primaryImage: null,
                        link: {
                            value: 'FindaLandRoverImage',
                            type: null,
                            languageCode: null,
                            disabled: null,
                            active: null,
                            underLined: null,
                            inversed: null,
                            presentationType: null,
                            url: 'https://www.landroverusa.com/national-dealer-locator.html',
                            esUrl: null,
                            id: 'navigationIcon',
                            hat: null,
                            navigationKeyId: 'Untimed Modal',
                            target: null,
                            iconId: null,
                            icon: null,
                            trackingId: 'pl_find_retailer',
                            trackingType: null,
                            showSpeedBump: true,
                            headerText: null,
                        },
                    },
                    faqIcon: {
                        id: 'faqIcon',
                        text: 'faq',
                        image: {
                            altText: null,
                            id: null,
                            source: '/content/dam/unified-assets/iconography/auto/ico_auto_question_mark_circle.svg',
                            url: 'https://static2-qa2.chasecdn.com/content/dam/unified-assets/iconography/auto/ico_auto_question_mark_circle.svg',
                        },
                        primaryImage: null,
                        link: {
                            value: 'FAQImage',
                            type: null,
                            languageCode: null,
                            disabled: null,
                            active: null,
                            underLined: null,
                            inversed: null,
                            presentationType: null,
                            url: '/faq',
                            esUrl: null,
                            id: 'faqIcon',
                            hat: null,
                            navigationKeyId: null,
                            target: null,
                            iconId: null,
                            icon: null,
                            trackingId: 'pl_faq',
                            trackingType: null,
                            showSpeedBump: false,
                            headerText: null,
                        },
                    },
                    helpIcon: {
                        id: 'helpIcon',
                        text: 'CONTACT US',
                        image: {
                            altText: null,
                            id: null,
                            source: '/content/dam/unified-assets/iconography/auto/ico_auto_people_group.svg',
                            url: 'https://static2-qa2.chasecdn.com/content/dam/unified-assets/iconography/auto/ico_auto_people_group.svg',
                        },
                        primaryImage: null,
                        link: {
                            value: 'FAQImage',
                            type: null,
                            languageCode: null,
                            disabled: null,
                            active: null,
                            underLined: null,
                            inversed: null,
                            presentationType: null,
                            url: '/contact-us',
                            esUrl: null,
                            id: 'helpIcon',
                            hat: null,
                            navigationKeyId: null,
                            target: null,
                            iconId: null,
                            icon: null,
                            trackingId: 'pl-contact',
                            trackingType: null,
                            showSpeedBump: false,
                            headerText: null,
                        },
                    },
                },
            },
            otherIcons: {
                tags: ['auto/oem/lrfg/exploreanewlandrover'],
                id: 'otherIcons',
                title: {
                    html: null,
                    text: null,
                    markdown: null,
                },
                subTitle: {
                    html: null,
                    text: null,
                    markdown: null,
                },
                version: '20240606',
                icons: {
                    chevronIcon: {
                        id: 'chevronIcon',
                        text: 'chevronIcon',
                        image: {
                            altText: null,
                            id: null,
                            source: '/content/dam/unified-assets/iconography/auto/chevron/ico_auto_chevron_left_carousel.svg',
                            url: 'https://static2-qa2.chasecdn.com/content/dam/unified-assets/iconography/auto/chevron/ico_auto_chevron_left_carousel.svg',
                        },
                        primaryImage: null,
                        link: {
                            value: null,
                            type: null,
                            languageCode: null,
                            disabled: null,
                            active: null,
                            underLined: null,
                            inversed: null,
                            presentationType: null,
                            url: null,
                            esUrl: null,
                            id: 'chevronIcon',
                            hat: null,
                            navigationKeyId: null,
                            target: null,
                            iconId: null,
                            icon: null,
                            trackingId: 'pl_le_offers',
                            trackingType: null,
                            showSpeedBump: false,
                            headerText: null,
                        },
                    },
                },
            },
        },
        legalContainer: {
            'land-rover-loyalty-program-disclosure': {
                brands: null,
                segments: null,
                tags: ['auto/oem/lrfg/exploreanewlandrover'],
                id: 'land-rover-loyalty-program-disclosure',
                title: {
                    html: null,
                    text: null,
                    markdown: null,
                },
                subTitle: {
                    html: null,
                    text: null,
                    markdown: null,
                },
                disclaimer: [],
                disclosure: [],
                footNote: {
                    footNote: {
                        id: 'footNote',
                        title: {
                            html: '<p>FootNote</p>\n',
                            text: 'FootNote',
                            markdown: 'FootNote\n',
                        },
                        description: {
                            html: null,
                            text: null,
                            markdown: null,
                        },
                        links: {
                            legalContainerLink1: {
                                value: '<1>{{legalContainerSup}}</1> Offer is valid for all current Land Rover Financial Group (LRFG) lessees who:',
                                type: null,
                                languageCode: null,
                                disabled: null,
                                active: null,
                                underLined: null,
                                inversed: null,
                                presentationType: null,
                                url: null,
                                esUrl: 'legalContainerSup',
                                id: 'legalContainerLink1',
                                hat: null,
                                navigationKeyId: null,
                                target: null,
                                iconId: 'exploreANewVehicleLinkList',
                                icon: null,
                                trackingId: null,
                                trackingType: null,
                                showSpeedBump: false,
                                headerText: null,
                            },
                            legalContainerLink2: {
                                value: '(a) Return the leased vehicle no earlier than 180 days prior to the originally contracted maturity date and no later than the originally contracted maturity date, or if extended, no later than the extended maturity date, and',
                                type: null,
                                languageCode: null,
                                disabled: null,
                                active: null,
                                underLined: null,
                                inversed: null,
                                presentationType: null,
                                url: null,
                                esUrl: null,
                                id: 'legalContainerLink2',
                                hat: null,
                                navigationKeyId: null,
                                target: null,
                                iconId: null,
                                icon: null,
                                trackingId: null,
                                trackingType: null,
                                showSpeedBump: false,
                                headerText: null,
                            },
                            legalContainerLink3: {
                                value: '(b) Within 30 days of vehicle return, finance or lease a new Land Rover vehicle through LRFG. Verification of current LRFG lease may be required. This is an ongoing offer, but the program may be discontinued by LRFG at any time without notice.',
                                type: null,
                                languageCode: null,
                                disabled: null,
                                active: null,
                                underLined: null,
                                inversed: null,
                                presentationType: null,
                                url: null,
                                esUrl: null,
                                id: 'legalContainerLink3',
                                hat: null,
                                navigationKeyId: null,
                                target: null,
                                iconId: null,
                                icon: null,
                                trackingId: null,
                                trackingType: null,
                                showSpeedBump: false,
                                headerText: null,
                            },
                            legalContainerLink4: {
                                value: 'Disposition fees are not charged on leases originated in Wisconsin. By contacting your Land Rover retailer about this offer, they will know that you are a valued Chase customer.',
                                type: null,
                                languageCode: null,
                                disabled: null,
                                active: null,
                                underLined: null,
                                inversed: null,
                                presentationType: null,
                                url: null,
                                esUrl: null,
                                id: 'legalContainerLink4',
                                hat: null,
                                navigationKeyId: null,
                                target: null,
                                iconId: null,
                                icon: null,
                                trackingId: null,
                                trackingType: null,
                                showSpeedBump: false,
                                headerText: null,
                            },
                            legalContainerLink5: {
                                value: 'Please contact your financial advisor for tax implications. New leases and financing through LRFG are subject to credit approval. Additional terms and conditions apply.',
                                type: null,
                                languageCode: null,
                                disabled: false,
                                active: false,
                                underLined: false,
                                inversed: false,
                                presentationType: null,
                                url: null,
                                esUrl: null,
                                id: 'legalContainerLink5',
                                hat: null,
                                navigationKeyId: null,
                                target: null,
                                iconId: null,
                                icon: null,
                                trackingId: null,
                                trackingType: null,
                                showSpeedBump: false,
                                headerText: null,
                            },
                        },
                    },
                },
                contentLists: [],
                version: '20240606',
            },
        },
        dialog: {},
        contentList: {},
        tile: {
            ExploreANewVehicle: {
                tags: ['auto/oem/lrfg/exploreanewlandrover'],
                id: 'ExploreANewVehicle',
                variationId: null,
                title: {
                    html: '<p>EXPLORE A NEW LAND ROVER</p>\n',
                    text: 'EXPLORE A NEW LAND ROVER',
                    markdown: 'EXPLORE A NEW LAND ROVER\n',
                },
                subTitle: {
                    html: '<p>CHECK OUT THE NEWEST LAND ROVER</p>\n',
                    text: 'CHECK OUT THE NEWEST LAND ROVER',
                    markdown: 'CHECK OUT THE NEWEST LAND ROVER\n',
                },
                version: '20240606',
                body: {
                    html: '<p>EXPERIENCE PEERLESS REFINEMENT AND LUXURY</p>\n',
                    text: 'EXPERIENCE PEERLESS REFINEMENT AND LUXURY',
                    markdown: 'EXPERIENCE PEERLESS REFINEMENT AND LUXURY\n',
                },
                additionalText: {
                    html: null,
                    text: null,
                    markdown: null,
                },
                hat: null,
                iconImage: {
                    altText: null,
                    id: 'exploreANewVehicleImage',
                    source: null,
                    url: null,
                },
                primaryImage: {
                    altText: 'exploreANewVehicle',
                    id: null,
                    source: '/content/dam/unified-assets/photography/partner/land-rover/optimized/Lease_End_New_Hero_Desktop1.jpg',
                    url: 'https://static2-qa2.chasecdn.com/content/dam/unified-assets/photography/partner/land-rover/optimized/Lease_End_New_Hero_Desktop1.jpg',
                },
                primaryImageWithRendition: null,
                secondaryImageWithRendition: null,
                links: {
                    exploreInventory: {
                        value: 'Explore Inventory',
                        type: null,
                        languageCode: null,
                        disabled: null,
                        active: null,
                        underLined: null,
                        inversed: null,
                        presentationType: null,
                        url: 'https://inventory.landroverusa.com/new/#/',
                        esUrl: null,
                        id: 'exploreInventory',
                        hat: null,
                        navigationKeyId: 'Untimed Modal',
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: 'pl_le_explore_inevntory',
                        trackingType: null,
                        showSpeedBump: null,
                        headerText: null,
                        visibleIn: null,
                        variant: null,
                    },
                },
            },
            LoyaltyProgram: {
                tags: ['auto/oem/lrfg/exploreanewlandrover'],
                id: 'LoyaltyProgram',
                variationId: null,
                title: {
                    html: '<p>Land Rover Loyalty Program</p>\n',
                    text: 'Land Rover Loyalty Program',
                    markdown: 'Land Rover Loyalty Program\n',
                },
                subTitle: {
                    html: null,
                    text: null,
                    markdown: null,
                },
                version: '20240606',
                body: {
                    html: null,
                    text: null,
                    markdown: null,
                },
                additionalText: {
                    html: null,
                    text: null,
                    markdown: null,
                },
                hat: null,
                iconImage: {
                    altText: null,
                    id: 'LoyaltyProgramImage',
                    source: null,
                    url: null,
                },
                primaryImage: {
                    altText: 'Land Rover Loyalty Program',
                    id: null,
                    source: '/content/dam/unified-assets/photography/partner/land-rover/optimized/LandRoverLoyaltyCard_1.jpg',
                    url: 'https://static2-qa2.chasecdn.com/content/dam/unified-assets/photography/partner/land-rover/optimized/LandRoverLoyaltyCard_1.jpg',
                },
                primaryImageWithRendition: null,
                secondaryImageWithRendition: null,
                links: [],
            },
            SpecialoffersList: {
                tags: ['auto/oem/lrfg/exploreanewlandrover'],
                id: 'SpecialoffersList',
                variationId: null,
                title: {
                    html: '<p>Land Rover Special offers</p>\n',
                    text: 'Land Rover Special offers',
                    markdown: 'Land Rover Special offers\n',
                },
                subTitle: {
                    html: "<p>Find the Land Rover vehicle that's perfect for you with our latest special offers.</p>\n",
                    text: "Find the Land Rover vehicle that's perfect for you with our latest special offers.",
                    markdown: "Find the Land Rover vehicle that's perfect for you with our latest special offers.\n",
                },
                version: '20240606',
                body: {
                    html: '<p>Learn more</p>\n',
                    text: 'Learn more',
                    markdown: 'Learn more\n',
                },
                additionalText: {
                    html: null,
                    text: null,
                    markdown: null,
                },
                hat: null,
                iconImage: {
                    altText: 'chevronLeftIcon',
                    id: 'chevronLeftIcon',
                    source: '/content/dam/unified-assets/iconography/auto/chevron/ico_auto_chevron_left_carousel.svg',
                    url: 'https://static2-qa2.chasecdn.com/content/dam/unified-assets/iconography/auto/chevron/ico_auto_chevron_left_carousel.svg',
                },
                primaryImage: {
                    altText: 'specialOffersImage',
                    id: null,
                    source: '/content/dam/unified-assets/photography/partner/land-rover/optimized/Land_Rover_Special_Offers_1.jpg',
                    url: 'https://static2-qa2.chasecdn.com/content/dam/unified-assets/photography/partner/land-rover/optimized/Land_Rover_Special_Offers_1.jpg',
                },
                primaryImageWithRendition: null,
                secondaryImageWithRendition: null,
                links: {
                    SpecialoffersImage: {
                        value: 'Land Rover Special offers',
                        type: null,
                        languageCode: null,
                        disabled: null,
                        active: null,
                        underLined: null,
                        inversed: null,
                        presentationType: null,
                        url: 'https://www.landroverusa.com/current-offers/index.html',
                        esUrl: null,
                        id: 'SpecialoffersImage',
                        hat: null,
                        navigationKeyId: 'Untimed Modal',
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: 'pl_le_offers',
                        trackingType: null,
                        showSpeedBump: null,
                        headerText: null,
                        visibleIn: null,
                        variant: null,
                    },
                },
            },
        },
        structuredContentList: {},
        header: {},
    },
};
