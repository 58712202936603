export const commonPage = {
    data: {
        linkList: {
            customerSupportNavLink: {
                id: 'customerSupportNavLink',
                tags: ['auto/oem/lrfg/common'],
                version: '20240606',
                title: {
                    text: null,
                },
                subTitle: {
                    text: null,
                },
                links: {
                    contact: {
                        headerText: null,
                        value: 'Contact us',
                        type: null,
                        presentationType: null,
                        url: '/contact-us',
                        esUrl: null,
                        id: 'contact',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: 'pl_nav_contact_us,pl_hm_contact_us',
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    faqs: {
                        headerText: null,
                        value: 'FAQs',
                        type: null,
                        presentationType: null,
                        url: '/faq',
                        esUrl: null,
                        id: 'faqs',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: 'pl_nav_faqs,pl_hm_faqs',
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    giveFeedback: {
                        headerText: null,
                        value: 'Give feedback',
                        type: null,
                        presentationType: null,
                        url: 'https://jpmc.surveymonkey.com/r/YC7JSHP',
                        esUrl: null,
                        id: 'giveFeedback',
                        hat: null,
                        navigationKeyId: 'Untimed Modal',
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: 'pl_nav_give_feedback,pl_hm_give_feedback',
                        trackingType: null,
                        showSpeedBump: true,
                    },
                },
            },
            financingAndOffersFooter: {
                id: 'financingAndOffersFooter',
                tags: ['auto/oem/lrfg/common'],
                version: '20240606',
                title: {
                    text: 'FINANCING & OFFERS',
                },
                subTitle: {
                    text: null,
                },
                links: {
                    financingOptions: {
                        headerText: null,
                        value: 'FINANCING OPTIONS',
                        type: null,
                        presentationType: null,
                        url: '/financing',
                        esUrl: null,
                        id: 'financingOptions',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: 'pl_ft_financing',
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    leaseVsPurchase: {
                        headerText: null,
                        value: 'LEASE VS PURCHASE',
                        type: null,
                        presentationType: null,
                        url: '/financing#lease-vs-purchase',
                        esUrl: null,
                        id: 'leaseVsPurchase',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: 'pl_ft_lease_purchase',
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    currentVehicleOffers: {
                        headerText: null,
                        value: 'CURRENT LAND ROVER OFFERS',
                        type: null,
                        presentationType: null,
                        url: 'https://www.landroverusa.com/current-offers/index.html',
                        esUrl: null,
                        id: 'currentVehicleOffers',
                        hat: null,
                        navigationKeyId: 'Untimed Modal',
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: 'pl_ft_offers',
                        trackingType: null,
                        showSpeedBump: true,
                    },
                },
            },
            financingAndOffersHeader: {
                id: 'financingAndOffersHeader',
                tags: ['auto/oem/lrfg/common'],
                version: '20240606',
                title: {
                    text: 'FINANCING & OFFERS',
                },
                subTitle: {
                    text: null,
                },
                links: {
                    financingOptions: {
                        headerText: null,
                        value: 'FINANCING OPTIONS',
                        type: null,
                        presentationType: null,
                        url: '/financing',
                        esUrl: null,
                        id: 'financingOptions',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: 'pl_nav_financing,pl_hm_financing',
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    leaseVsPurchase: {
                        headerText: null,
                        value: 'LEASE VS PURCHASE',
                        type: null,
                        presentationType: null,
                        url: '/financing#lease-vs-purchase',
                        esUrl: null,
                        id: 'leaseVsPurchase',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: 'pl_nav_lease_purchase,pl_hm_lease_purchase',
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    currentOffers: {
                        headerText: null,
                        value: 'CURRENT OFFERS',
                        type: null,
                        presentationType: null,
                        url: 'https://www.landroverusa.com/current-offers/index.html',
                        esUrl: null,
                        id: 'currentOffers',
                        hat: null,
                        navigationKeyId: 'Untimed Modal',
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: 'pl_nav_offers,pl_hm_offers',
                        trackingType: null,
                        showSpeedBump: true,
                    },
                },
            },
            footerLinksList: {
                id: 'footerLinksList',
                tags: ['auto/oem/lrfg/common'],
                version: '20240606',
                title: {
                    text: null,
                },
                subTitle: {
                    text: null,
                },
                links: {
                    privacy: {
                        headerText: null,
                        value: 'Privacy',
                        type: null,
                        presentationType: null,
                        url: 'https://www.chase.com/digital/resources/privacy-security',
                        esUrl: null,
                        id: 'privacy',
                        hat: null,
                        navigationKeyId: 'Timed Modal',
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: 'pl_ft_privacy',
                        trackingType: null,
                        showSpeedBump: true,
                    },
                    security: {
                        headerText: null,
                        value: 'Security',
                        type: null,
                        presentationType: null,
                        url: 'https://www.chase.com/digital/resources/privacy-security',
                        esUrl: null,
                        id: 'security',
                        hat: null,
                        navigationKeyId: 'Timed Modal',
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: 'pl_ft_security',
                        trackingType: null,
                        showSpeedBump: true,
                    },
                    termsOfUse: {
                        headerText: null,
                        value: 'Terms of Use',
                        type: null,
                        presentationType: null,
                        url: 'https://www.chase.com/digital/resources/terms-of-use?jp_cdf=LRFG',
                        esUrl: null,
                        id: 'termsOfUse',
                        hat: null,
                        navigationKeyId: 'Timed Modal',
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: 'pl_ft_terms_of_use',
                        trackingType: null,
                        showSpeedBump: true,
                    },
                    siteMap: {
                        headerText: null,
                        value: 'Site map',
                        type: null,
                        presentationType: null,
                        url: '/sitemap',
                        esUrl: null,
                        id: 'siteMap',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: 'pl_ft_site_map',
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    accessibility: {
                        headerText: null,
                        value: 'Accessibility',
                        type: null,
                        presentationType: null,
                        url: 'https://www.chase.com/digital/resources/accessibility?jp_cdf=LRFG',
                        esUrl: null,
                        id: 'accessibility',
                        hat: null,
                        navigationKeyId: 'Timed Modal',
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: 'pl_ft_accessibility',
                        trackingType: null,
                        showSpeedBump: true,
                    },
                    adChoices: {
                        headerText: null,
                        value: 'AdChoices',
                        type: null,
                        presentationType: null,
                        url: 'https://optout.aboutads.info/?c=2&lang=EN',
                        esUrl: null,
                        id: 'adChoices',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: 'pl_ft_adchoices',
                        trackingType: null,
                        showSpeedBump: false,
                    },
                },
            },
            footerMainNavList: {
                id: 'footerMainNavList',
                tags: ['auto/oem/lrfg/common'],
                version: '20240606',
                title: {
                    text: null,
                },
                subTitle: {
                    text: null,
                },
                links: {
                    payAndManageFooter: {
                        headerText: null,
                        value: 'PAY & MANAGE',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'payAndManageFooter',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    financingAndOffersFooter: {
                        headerText: null,
                        value: 'FINANCING & OFFERS',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'financingAndOffersFooter',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    shoppingToolsFooter: {
                        headerText: null,
                        value: 'SHOPPING TOOLS',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'shoppingToolsFooter',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    vehiclesList: {
                        headerText: null,
                        value: 'LAND ROVER VEHICLES',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'vehiclesList',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    socialMediaFollowList: {
                        headerText: null,
                        value: 'FOLLOW LAND ROVER',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'socialMediaFollowList',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                },
            },
            leaseEndOptionsNavLink: {
                id: 'leaseEndOptionsNavLink',
                tags: ['auto/oem/lrfg/common'],
                version: '20240606',
                title: {
                    text: null,
                },
                subTitle: {
                    text: null,
                },
                links: {
                    exploreANewVehicle: {
                        headerText: null,
                        value: 'Explore a new Land Rover',
                        type: null,
                        presentationType: null,
                        url: '/lease-end/new',
                        esUrl: null,
                        id: 'exploreANewVehicle',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: 'pl_nav_le_new,pl_hm_le_new',
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    entendPurchaseOrTurnIn: {
                        headerText: null,
                        value: 'Extend, purchase, or early turn-in',
                        type: null,
                        presentationType: null,
                        url: '/lease-end/other',
                        esUrl: null,
                        id: 'entendPurchaseOrTurnIn',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: 'pl_nav_le_other,pl_hm_le_other',
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    returnYourVehicle: {
                        headerText: null,
                        value: 'Return your Land Rover',
                        type: null,
                        presentationType: null,
                        url: '/lease-end/return',
                        esUrl: null,
                        id: 'returnYourVehicle',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: 'pl_nav_le_return,pl_hm_le_return',
                        trackingType: null,
                        showSpeedBump: false,
                    },
                },
            },
            mainNavList: {
                id: 'mainNavList',
                tags: ['auto/oem/lrfg/common'],
                version: '20240606',
                title: {
                    text: null,
                },
                subTitle: {
                    text: null,
                },
                links: {
                    payAndManageHeader: {
                        headerText: null,
                        value: 'PAY & MANAGE',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'payAndManageHeader',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: 'pl_hm_pay_manage',
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    financingAndOffersHeader: {
                        headerText: null,
                        value: 'FINANCING & OFFERS',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'financingAndOffersHeader',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: 'pl_hm_fin_offers',
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    shoppingToolsHeader: {
                        headerText: null,
                        value: 'SHOPPING TOOLS',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'shoppingToolsHeader',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: 'pl_hm_shop_build',
                        trackingType: null,
                        showSpeedBump: false,
                    },
                },
            },
            payAndManageFooter: {
                id: 'payAndManageFooter',
                tags: ['auto/oem/lrfg/common'],
                version: '20240606',
                title: {
                    text: 'PAY & MANAGE',
                },
                subTitle: {
                    text: null,
                },
                links: {
                    myAccountNavLink: {
                        headerText: null,
                        value: 'MY ACCOUNT',
                        type: null,
                        presentationType: null,
                        url: '/myaccount',
                        esUrl: null,
                        id: 'myAccountNavLink',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: 'pl_ft_my_account',
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    makeAPaymentNavLink: {
                        headerText: null,
                        value: 'MAKE A PAYMENT',
                        type: null,
                        presentationType: null,
                        url: '/myaccount',
                        esUrl: null,
                        id: 'makeAPaymentNavLink',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: 'pl_ft_pay',
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    newCustomerNavLink: {
                        headerText: null,
                        value: 'NEW CUSTOMER',
                        type: null,
                        presentationType: null,
                        url: '/welcome',
                        esUrl: null,
                        id: 'newCustomerNavLink',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: 'pl_ft_welcome',
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    leaseEndNavLink: {
                        headerText: null,
                        value: 'LEASE END',
                        type: null,
                        presentationType: null,
                        url: '/lease-end',
                        esUrl: null,
                        id: 'leaseEndNavLink',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: 'pl_ft_lease_end',
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    loanEndNavLink: {
                        headerText: null,
                        value: 'LOAN END',
                        type: null,
                        presentationType: null,
                        url: '/loan-end',
                        esUrl: null,
                        id: 'loanEndNavLink',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: 'pl_ft_loan_end',
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    contact: {
                        headerText: null,
                        value: 'CONTACT US',
                        type: null,
                        presentationType: null,
                        url: '/contact-us',
                        esUrl: null,
                        id: 'contact',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: 'pl_ft_contact_us',
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    faqs: {
                        headerText: null,
                        value: 'FAQs',
                        type: null,
                        presentationType: null,
                        url: '/faq',
                        esUrl: null,
                        id: 'faqs',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: 'pl_ft_faqs',
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    giveFeedback: {
                        headerText: null,
                        value: 'GIVE FEEDBACK',
                        type: null,
                        presentationType: null,
                        url: 'https://jpmc.surveymonkey.com/r/YC7JSHP',
                        esUrl: null,
                        id: 'giveFeedback',
                        hat: null,
                        navigationKeyId: 'Untimed Modal',
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: 'pl_ft_give_feedback',
                        trackingType: null,
                        showSpeedBump: true,
                    },
                },
            },
            payAndManageHeader: {
                id: 'payAndManageHeader',
                tags: ['auto/oem/lrfg/common'],
                version: '20240606',
                title: {
                    text: 'PAY & MANAGE',
                },
                subTitle: {
                    text: null,
                },
                links: {
                    myAccountNavLink: {
                        headerText: null,
                        value: 'MY ACCOUNT',
                        type: null,
                        presentationType: null,
                        url: '/myaccount',
                        esUrl: null,
                        id: 'myAccountNavLink',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: 'pl_nav_my_account',
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    makeAPaymentNavLink: {
                        headerText: null,
                        value: 'MAKE A PAYMENT',
                        type: null,
                        presentationType: null,
                        url: '/myaccount',
                        esUrl: null,
                        id: 'makeAPaymentNavLink',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: 'pl_nav_pay,pl_hm_pay',
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    newCustomerNavLink: {
                        headerText: null,
                        value: 'NEW CUSTOMER',
                        type: null,
                        presentationType: null,
                        url: '/welcome',
                        esUrl: null,
                        id: 'newCustomerNavLink',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: 'pl_nav_welcome,pl_hm_welcome',
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    leaseEndOptionsNavLink: {
                        headerText: null,
                        value: 'LEASE-END OPTIONS',
                        type: null,
                        presentationType: null,
                        url: '/lease-end',
                        esUrl: null,
                        id: 'leaseEndOptionsNavLink',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: 'pl_nav_lease_end,pl_hm_lease_end',
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    loanEndNavLink: {
                        headerText: null,
                        value: 'LOAN END',
                        type: null,
                        presentationType: null,
                        url: '/loan-end',
                        esUrl: null,
                        id: 'loanEndNavLink',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: 'pl_nav_loan_end,pl_hm_loan_end',
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    customerSupportNavLink: {
                        headerText: null,
                        value: 'CUSTOMER SUPPORT',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'customerSupportNavLink',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                },
            },
            restrictionModalLinkList: {
                id: 'restrictionModalLinkList',
                tags: ['auto/oem/lrfg/common'],
                version: '20240613',
                title: {
                    text: null,
                },
                subTitle: {
                    text: null,
                },
                links: {
                    secureMessage: {
                        headerText: 'anquer',
                        value: 'secure message',
                        type: null,
                        presentationType: null,
                        url: 'https://www.chase.com/digital/login-secure-message?jp_cdf=LRFG',
                        esUrl: null,
                        id: 'secureMessage',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    contactUs: {
                        headerText: 'anquer',
                        value: 'contact us',
                        type: null,
                        presentationType: null,
                        url: '/contact-us',
                        esUrl: null,
                        id: 'contactUs',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                },
            },
            shoppingToolsFooter: {
                id: 'shoppingToolsFooter',
                tags: ['auto/oem/lrfg/common'],
                version: '20240606',
                title: {
                    text: 'SHOPPING TOOLS',
                },
                subTitle: {
                    text: null,
                },
                links: {
                    buildAndPrice: {
                        headerText: null,
                        value: 'BUILD AND PRICE',
                        type: null,
                        presentationType: null,
                        url: 'https://www.landroverusa.com/build-your-own/index.html',
                        esUrl: null,
                        id: 'buildAndPrice',
                        hat: null,
                        navigationKeyId: 'Untimed Modal',
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: 'pl_ft_build_price',
                        trackingType: null,
                        showSpeedBump: true,
                    },
                    findARetailer: {
                        headerText: null,
                        value: 'FIND A RETAILER',
                        type: null,
                        presentationType: null,
                        url: 'https://www.landroverusa.com/national-dealer-locator.html',
                        esUrl: null,
                        id: 'findARetailer',
                        hat: null,
                        navigationKeyId: 'Untimed Modal',
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: 'pl_ft_find_retailer',
                        trackingType: null,
                        showSpeedBump: true,
                    },
                    searchInventory: {
                        headerText: null,
                        value: 'SEARCH INVENTORY',
                        type: null,
                        presentationType: null,
                        url: 'https://inventory.landroverusa.com/new',
                        esUrl: null,
                        id: 'searchInventory',
                        hat: null,
                        navigationKeyId: 'Untimed Modal',
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: 'pl_ft_shop_new',
                        trackingType: null,
                        showSpeedBump: true,
                    },
                    certifiedPreOwned: {
                        headerText: null,
                        value: 'CERTIFIED PRE-OWNED',
                        type: null,
                        presentationType: null,
                        url: 'https://www.landroverusa.com/certified-pre-owned/index.html',
                        esUrl: null,
                        id: 'certifiedPreOwned',
                        hat: null,
                        navigationKeyId: 'Untimed Modal',
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: 'pl_ft_shop_cpo',
                        trackingType: null,
                        showSpeedBump: true,
                    },
                    estimateTradeInValue: {
                        headerText: null,
                        value: 'ESTIMATE TRADE-IN VALUE',
                        type: null,
                        presentationType: null,
                        url: 'https://www.landroverusa.com/shopping-tools/calculate-trade-in-value.html',
                        esUrl: null,
                        id: 'estimateTradeInValue',
                        hat: null,
                        navigationKeyId: 'Untimed Modal',
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: 'pl_ft_trade_in',
                        trackingType: null,
                        showSpeedBump: true,
                    },
                },
            },
            shoppingToolsHeader: {
                id: 'shoppingToolsHeader',
                tags: ['auto/oem/lrfg/common'],
                version: '20240606',
                title: {
                    text: 'SHOPPING TOOLS',
                },
                subTitle: {
                    text: null,
                },
                links: {
                    buildAndPrice: {
                        headerText: null,
                        value: 'BUILD AND PRICE',
                        type: null,
                        presentationType: null,
                        url: 'https://www.landroverusa.com/build-your-own/index.html',
                        esUrl: null,
                        id: 'buildAndPrice',
                        hat: null,
                        navigationKeyId: 'Untimed Modal',
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: 'pl_nav_build_price,pl_hm_build_price',
                        trackingType: null,
                        showSpeedBump: true,
                    },
                    searchInventory: {
                        headerText: null,
                        value: 'SEARCH INVENTORY',
                        type: null,
                        presentationType: null,
                        url: 'https://inventory.landroverusa.com/new',
                        esUrl: null,
                        id: 'searchInventory',
                        hat: null,
                        navigationKeyId: 'Untimed Modal',
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: 'pl_nav_shop,pl_hm_shop',
                        trackingType: null,
                        showSpeedBump: true,
                    },
                    preOwned: {
                        headerText: null,
                        value: 'PRE-OWNED',
                        type: null,
                        presentationType: null,
                        url: 'https://www.landroverusa.com/certified-pre-owned/index.html',
                        esUrl: null,
                        id: 'preOwned',
                        hat: null,
                        navigationKeyId: 'Untimed Modal',
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: 'pl_nav_shop_cpo,pl_hm_shop_cpo',
                        trackingType: null,
                        showSpeedBump: true,
                    },
                    findARetailer: {
                        headerText: null,
                        value: 'FIND A RETAILER',
                        type: null,
                        presentationType: null,
                        url: 'https://www.landroverusa.com/national-dealer-locator.html',
                        esUrl: null,
                        id: 'findARetailer',
                        hat: null,
                        navigationKeyId: 'Untimed Modal',
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: 'pl_nav_st_find_retailer1,pl_hm_st_find_retailer',
                        trackingType: null,
                        showSpeedBump: true,
                    },
                    estimateTradeInValue: {
                        headerText: null,
                        value: 'ESTIMATE TRADE-IN VALUE',
                        type: null,
                        presentationType: null,
                        url: 'https://www.landroverusa.com/shopping-tools/calculate-trade-in-value.html',
                        esUrl: null,
                        id: 'estimateTradeInValue',
                        hat: null,
                        navigationKeyId: 'Untimed Modal',
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: 'pl_nav_trade_in,pl_hm_tradein_appraisal',
                        trackingType: null,
                        showSpeedBump: true,
                    },
                },
            },
            vehiclesList: {
                id: 'vehiclesList',
                tags: ['auto/oem/lrfg/common'],
                version: '20240606',
                title: {
                    text: 'LAND ROVER VEHICLES',
                },
                subTitle: {
                    text: null,
                },
                links: {
                    usaSiteLink: {
                        headerText: null,
                        value: 'LANDROVERUSA.COM',
                        type: null,
                        presentationType: null,
                        url: 'https://www.landroverusa.com/index.html',
                        esUrl: null,
                        id: 'usaSiteLink',
                        hat: null,
                        navigationKeyId: 'Untimed Modal',
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: 'pl_ft_visit_oem',
                        trackingType: null,
                        showSpeedBump: true,
                    },
                    exploreVehicles: {
                        headerText: null,
                        value: 'EXPLORE VEHICLES',
                        type: null,
                        presentationType: null,
                        url: 'https://www.landroverusa.com/index.html',
                        esUrl: null,
                        id: 'exploreVehicles',
                        hat: null,
                        navigationKeyId: 'Untimed Modal',
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: 'pl_ft_all_vehicles',
                        trackingType: null,
                        showSpeedBump: true,
                    },
                    carBrandFamily: {
                        headerText: null,
                        value: 'RANGE ROVER FAMILY',
                        type: null,
                        presentationType: null,
                        url: 'https://www.landroverusa.com/range-rover/index.html',
                        esUrl: null,
                        id: 'carBrandFamily',
                        hat: null,
                        navigationKeyId: 'Untimed Modal',
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: 'pl_ft_veh_elect',
                        trackingType: null,
                        showSpeedBump: true,
                    },
                    discoveryFamily: {
                        headerText: null,
                        value: 'DISCOVERY FAMILY',
                        type: null,
                        presentationType: null,
                        url: 'https://www.landroverusa.com/discovery/index.html',
                        esUrl: null,
                        id: 'discoveryFamily',
                        hat: null,
                        navigationKeyId: 'Untimed Modal',
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: 'pl_ft_veh_sports',
                        trackingType: null,
                        showSpeedBump: true,
                    },
                    fleetAndBusiness: {
                        headerText: null,
                        value: 'FLEET & BUSINESS',
                        type: null,
                        presentationType: null,
                        url: 'https://www.landroverusa.com/corporate-sales/fleet-and-business-sales.html',
                        esUrl: null,
                        id: 'fleetAndBusiness',
                        hat: null,
                        navigationKeyId: 'Untimed Modal',
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: 'pl_ft_veh_sedans',
                        trackingType: null,
                        showSpeedBump: true,
                    },
                },
            },
        },
        carousel: {},
        iconList: {
            secNavAfterAuth: {
                tags: ['auto/oem/lrfg/common'],
                id: 'secNavAfterAuth',
                title: {
                    html: '<p>HOME</p>\n',
                    text: 'HOME',
                    markdown: 'HOME\n',
                },
                subTitle: {
                    html: null,
                    text: null,
                    markdown: null,
                },
                version: '20240613',
                icons: {
                    secNavAfterAuthItem1: {
                        id: 'secNavAfterAuthItem1',
                        text: 'VISIT LANDROVERUSA.COM',
                        image: {
                            altText: null,
                            id: null,
                            source: '/content/dam/unified-assets/logo/other-logos/land-rover/logo_landrover_financialgroup_H_asterisk_transparent.png',
                            url: 'https://static2-qa2.chasecdn.com/content/dam/unified-assets/logo/other-logos/land-rover/logo_landrover_financialgroup_H_asterisk_transparent.png',
                        },
                        primaryImage: null,
                        link: {
                            value: 'VISIT LANDROVERUSA.COM',
                            type: null,
                            languageCode: null,
                            disabled: null,
                            active: null,
                            underLined: null,
                            inversed: null,
                            presentationType: null,
                            url: 'https://www.landroverusa.com/index.html',
                            esUrl: null,
                            id: 'secNavAfterAuthLink1',
                            hat: null,
                            navigationKeyId: 'Untimed Modal',
                            target: null,
                            iconId: null,
                            icon: null,
                            trackingId: 'pl_nav_visit_oem',
                            trackingType: null,
                            showSpeedBump: true,
                            headerText: null,
                        },
                    },
                    secNavAfterAuthItem2: {
                        id: 'secNavAfterAuthItem2',
                        text: null,
                        image: null,
                        primaryImage: null,
                        link: {
                            value: 'CONTACT MY RETAILER',
                            type: null,
                            languageCode: null,
                            disabled: null,
                            active: null,
                            underLined: null,
                            inversed: null,
                            presentationType: null,
                            url: 'https://www.landroverusa.com/national-dealer-locator.html',
                            esUrl: null,
                            id: 'secNavAfterAuthLink2',
                            hat: null,
                            navigationKeyId: null,
                            target: null,
                            iconId: null,
                            icon: null,
                            trackingId: null,
                            trackingType: null,
                            showSpeedBump: false,
                            headerText: null,
                        },
                    },
                    secNavAfterAuthItem3: {
                        id: 'secNavAfterAuthItem3',
                        text: null,
                        image: null,
                        primaryImage: null,
                        link: {
                            value: 'SIGN OUT',
                            type: null,
                            languageCode: null,
                            disabled: null,
                            active: null,
                            underLined: null,
                            inversed: null,
                            presentationType: null,
                            url: '/logout',
                            esUrl: null,
                            id: 'secNavAfterAuthLink3',
                            hat: null,
                            navigationKeyId: null,
                            target: null,
                            iconId: null,
                            icon: null,
                            trackingId: null,
                            trackingType: null,
                            showSpeedBump: false,
                            headerText: null,
                        },
                    },
                },
            },
            secNavBeforeAuth: {
                tags: ['auto/oem/lrfg/common'],
                id: 'secNavBeforeAuth',
                title: {
                    html: '<p>HOME</p>\n',
                    text: 'HOME',
                    markdown: 'HOME\n',
                },
                subTitle: {
                    html: null,
                    text: null,
                    markdown: null,
                },
                version: '20240606',
                icons: {
                    secNavBeforeAuthItem1: {
                        id: 'secNavBeforeAuthItem1',
                        text: 'VISIT LANDROVERUSA.COM',
                        image: {
                            altText: null,
                            id: null,
                            source: '/content/dam/unified-assets/logo/other-logos/land-rover/logo_landrover_financialgroup_H_asterisk_transparent.png',
                            url: 'https://static2-qa2.chasecdn.com/content/dam/unified-assets/logo/other-logos/land-rover/logo_landrover_financialgroup_H_asterisk_transparent.png',
                        },
                        primaryImage: null,
                        link: {
                            value: 'VISIT LANDROVERUSA.COM',
                            type: null,
                            languageCode: null,
                            disabled: null,
                            active: null,
                            underLined: null,
                            inversed: null,
                            presentationType: null,
                            url: 'https://www.jaguarusa.com/index.html',
                            esUrl: null,
                            id: 'secNavBeforeAuthLink1',
                            hat: null,
                            navigationKeyId: 'Untimed Modal',
                            target: null,
                            iconId: null,
                            icon: null,
                            trackingId: 'pl_nav_visit_oem,pl_hm_visit_oem',
                            trackingType: null,
                            showSpeedBump: true,
                            headerText: null,
                        },
                    },
                    secNavBeforeAuthItem2: {
                        id: 'secNavBeforeAuthItem2',
                        text: null,
                        image: null,
                        primaryImage: null,
                        link: {
                            value: 'FIND A RETAILER',
                            type: null,
                            languageCode: null,
                            disabled: null,
                            active: null,
                            underLined: null,
                            inversed: null,
                            presentationType: null,
                            url: 'https://www.landroverusa.com/national-dealer-locator.html',
                            esUrl: null,
                            id: 'secNavBeforeAuthLink2',
                            hat: null,
                            navigationKeyId: 'Untimed Modal',
                            target: null,
                            iconId: null,
                            icon: null,
                            trackingId: 'pl_nav_find_retailer,pl_hm_find_retailer',
                            trackingType: null,
                            showSpeedBump: true,
                            headerText: null,
                        },
                    },
                    secNavBeforeAuthItem3: {
                        id: 'secNavBeforeAuthItem3',
                        text: null,
                        image: null,
                        primaryImage: null,
                        link: {
                            value: 'SIGN IN',
                            type: null,
                            languageCode: null,
                            disabled: null,
                            active: null,
                            underLined: null,
                            inversed: null,
                            presentationType: null,
                            url: 'https://qac3-secure01ea.chase.com/web/auth/dashboard?navKey=oemNavLandRover&jp_cdf=LRFG_LOGIN&context=OEM_LANDROVER&treatment=LANDROVER&financialBrandName=Land+Rover+Financial+Group&landingScreen=myaccount\t',
                            esUrl: null,
                            id: 'secNavBeforeAuthLink3',
                            hat: null,
                            navigationKeyId: null,
                            target: null,
                            iconId: null,
                            icon: null,
                            trackingId: 'pl_nav_sign_in,pl_hm_sign_in',
                            trackingType: null,
                            showSpeedBump: false,
                            headerText: null,
                        },
                    },
                },
            },
            socialMediaFollowList: {
                tags: ['auto/oem/lrfg/common'],
                id: 'socialMediaFollowList',
                title: {
                    html: '<p>FOLLOW LAND ROVER</p>\n',
                    text: 'FOLLOW LAND ROVER',
                    markdown: 'FOLLOW LAND ROVER\n',
                },
                subTitle: {
                    html: null,
                    text: null,
                    markdown: null,
                },
                version: '20240606',
                icons: {
                    socialMediaFollowList1: {
                        id: 'socialMediaFollowList1',
                        text: 'FACEBOOK',
                        image: {
                            altText: null,
                            id: null,
                            source: '/content/dam/unified-assets/iconography/auto/ico_auto_facebook.svg',
                            url: 'https://static2-qa2.chasecdn.com/content/dam/unified-assets/iconography/auto/ico_auto_facebook.svg',
                        },
                        primaryImage: null,
                        link: {
                            value: 'FACEBOOK',
                            type: null,
                            languageCode: null,
                            disabled: null,
                            active: null,
                            underLined: null,
                            inversed: null,
                            presentationType: null,
                            url: 'https://www.facebook.com/landroverUSA',
                            esUrl: null,
                            id: 'socialMediaFollowLink1',
                            hat: null,
                            navigationKeyId: 'Untimed Modal',
                            target: null,
                            iconId: null,
                            icon: null,
                            trackingId: 'pl_ft_fb',
                            trackingType: null,
                            showSpeedBump: true,
                            headerText: null,
                        },
                    },
                    socialMediaFollowList2: {
                        id: 'socialMediaFollowList2',
                        text: 'X',
                        image: {
                            altText: null,
                            id: null,
                            source: '/content/dam/unified-assets/iconography/auto/ico_auto_X.svg',
                            url: 'https://static2-qa2.chasecdn.com/content/dam/unified-assets/iconography/auto/ico_auto_X.svg',
                        },
                        primaryImage: null,
                        link: {
                            value: 'X',
                            type: null,
                            languageCode: null,
                            disabled: null,
                            active: null,
                            underLined: null,
                            inversed: null,
                            presentationType: null,
                            url: 'https://www.x.com/landroverusa',
                            esUrl: null,
                            id: 'socialMediaFollowLink2',
                            hat: null,
                            navigationKeyId: 'Untimed Modal',
                            target: null,
                            iconId: null,
                            icon: null,
                            trackingId: 'pl_ft_twit',
                            trackingType: null,
                            showSpeedBump: true,
                            headerText: null,
                        },
                    },
                    socialMediaFollowList3: {
                        id: 'socialMediaFollowList3',
                        text: 'YOUTUBE',
                        image: {
                            altText: null,
                            id: null,
                            source: '/content/dam/unified-assets/iconography/auto/ico_auto_youtube.svg',
                            url: 'https://static2-qa2.chasecdn.com/content/dam/unified-assets/iconography/auto/ico_auto_youtube.svg',
                        },
                        primaryImage: null,
                        link: {
                            value: 'YOUTUBE',
                            type: null,
                            languageCode: null,
                            disabled: null,
                            active: null,
                            underLined: null,
                            inversed: null,
                            presentationType: null,
                            url: 'https://www.youtube.com/user/landroverusa/',
                            esUrl: null,
                            id: 'socialMediaFollowLink3',
                            hat: null,
                            navigationKeyId: 'Untimed Modal',
                            target: null,
                            iconId: null,
                            icon: null,
                            trackingId: 'pl_ft_youtube',
                            trackingType: null,
                            showSpeedBump: true,
                            headerText: null,
                        },
                    },
                    socialMediaFollowList4: {
                        id: 'socialMediaFollowList4',
                        text: 'INSTAGRAM',
                        image: {
                            altText: null,
                            id: null,
                            source: '/content/dam/unified-assets/iconography/auto/ico_auto_instagram.svg',
                            url: 'https://static2-qa2.chasecdn.com/content/dam/unified-assets/iconography/auto/ico_auto_instagram.svg',
                        },
                        primaryImage: null,
                        link: {
                            value: 'INSTAGRAM',
                            type: null,
                            languageCode: null,
                            disabled: null,
                            active: null,
                            underLined: null,
                            inversed: null,
                            presentationType: null,
                            url: 'https://www.instagram.com/landoverusa/',
                            esUrl: null,
                            id: 'socialMediaFollowLink4',
                            hat: null,
                            navigationKeyId: 'Untimed Modal',
                            target: null,
                            iconId: null,
                            icon: null,
                            trackingId: 'pl_ft_ig',
                            trackingType: null,
                            showSpeedBump: true,
                            headerText: null,
                        },
                    },
                    socialMediaFollowList5: {
                        id: 'socialMediaFollowList5',
                        text: 'LINKEDIN',
                        image: {
                            altText: null,
                            id: null,
                            source: '/content/dam/unified-assets/iconography/auto/ico_auto_linkedin.svg',
                            url: 'https://static2-qa2.chasecdn.com/content/dam/unified-assets/iconography/auto/ico_auto_linkedin.svg',
                        },
                        primaryImage: null,
                        link: {
                            value: 'LINKEDIN',
                            type: null,
                            languageCode: null,
                            disabled: null,
                            active: null,
                            underLined: null,
                            inversed: null,
                            presentationType: null,
                            url: 'https://www.linkedin.com/company/jaguar-land-rover-north-america/',
                            esUrl: null,
                            id: 'socialMediaFollowLink5',
                            hat: null,
                            navigationKeyId: 'Untimed Modal',
                            target: null,
                            iconId: null,
                            icon: null,
                            trackingId: 'pl_ft_linkedin',
                            trackingType: null,
                            showSpeedBump: true,
                            headerText: null,
                        },
                    },
                },
            },
        },
        legalContainer: {
            legalContainer: {
                brands: null,
                segments: null,
                tags: ['auto/oem/lrfg/common'],
                id: 'legalContainer',
                title: {
                    html: null,
                    text: null,
                    markdown: null,
                },
                subTitle: {
                    html: null,
                    text: null,
                    markdown: null,
                },
                disclaimer: [],
                disclosure: [],
                footNote: {
                    footNoteText1: {
                        id: 'footNoteText1',
                        title: {
                            html: '<p>* The tradename Land Rover Financial Group and the Land Rover logo are owned by Jaguar Land Rover North America, LLC (JLR) or its affiliates and are licensed to JPMorgan Chase Bank, N.A. (Chase). JLR is solely responsible for its products and services and for promotional statements about them, and is not affiliated with Chase or its affiliates. Auto finance accounts are owned by Chase and are subject to credit approval, terms and conditions.</p>\n',
                            text: '* The tradename Land Rover Financial Group and the Land Rover logo are owned by Jaguar Land Rover North America, LLC (JLR) or its affiliates and are licensed to JPMorgan Chase Bank, N.A. (Chase). JLR is solely responsible for its products and services and for promotional statements about them, and is not affiliated with Chase or its affiliates. Auto finance accounts are owned by Chase and are subject to credit approval, terms and conditions.',
                            markdown: '\\* The tradename Land Rover Financial Group and the Land Rover logo are owned by Jaguar Land Rover North America, LLC (JLR) or its affiliates and are licensed to JPMorgan Chase Bank, N.A. (Chase). JLR is solely responsible for its products and services and for promotional statements about them, and is not affiliated with Chase or its affiliates. Auto finance accounts are owned by Chase and are subject to credit approval, terms and conditions.\n',
                        },
                        description: {
                            html: null,
                            text: null,
                            markdown: null,
                        },
                        links: [],
                    },
                    footNoteText2: {
                        id: 'footNoteText2',
                        title: {
                            html: '<p>JPMorgan Chase Bank, N.A. Member FDIC</p>\n',
                            text: 'JPMorgan Chase Bank, N.A. Member FDIC',
                            markdown: 'JPMorgan Chase Bank, N.A. Member FDIC\n',
                        },
                        description: {
                            html: null,
                            text: null,
                            markdown: null,
                        },
                        links: [],
                    },
                },
                contentLists: [],
                version: '20240613',
            },
        },
        dialog: {
            carouselTileDialog: {
                brands: null,
                segments: null,
                tags: ['auto/oem/lrfg/common'],
                id: 'carouselTileDialog',
                title: {
                    html: '<p>YOU’RE NOW LEAVING LAND ROVER FINANCIAL GROUP</p>\n',
                    text: 'YOU’RE NOW LEAVING LAND ROVER FINANCIAL GROUP',
                    markdown: "YOU'RE NOW LEAVING LAND ROVER FINANCIAL GROUP\n",
                },
                subTitle: {
                    html: '<p>Land Rover Financial Group website and/or mobile terms, privacy and security policies don’t apply to the site or app you’re about to visit. Please review its terms, privacy and security policies to see how they apply to you. Land Rover Financial Group isn’t responsible for (and doesn’t provide) any products or content at this third-party site or app, except for products and services that explicitly carry the Land Rover Financial Group name.</p>\n',
                    text: 'Land Rover Financial Group website and/or mobile terms, privacy and security policies don’t apply to the site or app you’re about to visit. Please review its terms, privacy and security policies to see how they apply to you. Land Rover Financial Group isn’t responsible for (and doesn’t provide) any products or content at this third-party site or app, except for products and services that explicitly carry the Land Rover Financial Group name.',
                    markdown: "Land Rover Financial Group website and/or mobile terms, privacy and security policies don't apply to the site or app you're about to visit. Please review its terms, privacy and security policies to see how they apply to you. Land Rover Financial Group isn't responsible for (and doesn't provide) any products or content at this third-party site or app, except for products and services that explicitly carry the Land Rover Financial Group name.\n",
                },
                version: '20240606',
                message: {
                    html: null,
                    text: null,
                    markdown: null,
                },
                messageType: null,
                hat: null,
                primaryIconImage: null,
                secondaryIconImage: null,
                links: {
                    dialogCancelButton: {
                        value: 'CANCEL',
                        type: null,
                        languageCode: null,
                        disabled: null,
                        active: null,
                        underLined: null,
                        inversed: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'dialogCancelButton',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: 'pl_leave_fin_cxl',
                        trackingType: null,
                        showSpeedBump: null,
                        headerText: null,
                    },
                    dialogProceedButton: {
                        value: 'PROCEED',
                        type: null,
                        languageCode: null,
                        disabled: null,
                        active: null,
                        underLined: null,
                        inversed: null,
                        presentationType: null,
                        url: 'https://www.landroverusa.com/range-rover/range-rover-sport/index.html',
                        esUrl: null,
                        id: 'dialogProceedButton',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: 'pl_leave_fin_prcd',
                        trackingType: null,
                        showSpeedBump: null,
                        headerText: null,
                    },
                },
            },
            inActiveSpeedBumpDialog: {
                brands: null,
                segments: null,
                tags: ['auto/oem/lrfg/common'],
                id: 'inActiveSpeedBumpDialog',
                title: {
                    html: '<p>YOUR SESSION IS ABOUT TO END</p>\n',
                    text: 'YOUR SESSION IS ABOUT TO END',
                    markdown: 'YOUR SESSION IS ABOUT TO END\n',
                },
                subTitle: {
                    html: "<p>You've been inactive for a while. For your security, we'll automatically sign you out in approximately 1 minute. You may choose &quot;Stay Signed in&quot; to continue or sign out if you're done.</p>\n",
                    text: "You've been inactive for a while. For your security, we'll automatically sign you out in approximately 1 minute. You may choose \"Stay Signed in\" to continue or sign out if you're done.",
                    markdown: "You've been inactive for a while. For your security, we'll automatically sign you out in approximately 1 minute. You may choose \"Stay Signed in\" to continue or sign out if you're done.\n",
                },
                version: '20240613',
                message: {
                    html: null,
                    text: null,
                    markdown: null,
                },
                messageType: null,
                hat: null,
                primaryIconImage: null,
                secondaryIconImage: null,
                links: {
                    inActivePrimaryActionButton: {
                        value: 'SIGN OUT',
                        type: null,
                        languageCode: null,
                        disabled: null,
                        active: null,
                        underLined: null,
                        inversed: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'inActivePrimaryActionButton',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: null,
                        headerText: null,
                    },
                    inActiveSecondaryActionButton: {
                        value: 'STAY SIGNED IN',
                        type: null,
                        languageCode: null,
                        disabled: null,
                        active: null,
                        underLined: null,
                        inversed: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'inActiveSecondaryActionButton',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: null,
                        headerText: null,
                    },
                },
            },
            restrictionModalDialog: {
                brands: null,
                segments: null,
                tags: ['auto/oem/lrfg/common'],
                id: 'restrictionModalDialog',
                title: {
                    html: null,
                    text: null,
                    markdown: null,
                },
                subTitle: {
                    html: null,
                    text: null,
                    markdown: null,
                },
                version: '20240613',
                message: {
                    html: null,
                    text: null,
                    markdown: null,
                },
                messageType: null,
                hat: null,
                primaryIconImage: null,
                secondaryIconImage: null,
                links: {
                    restrictionModal: {
                        value: 'You must be a lease customer within the last 6 months of your lease to use this form. Please send a <1>{{secureMessage}}</1> on chase.com or <2>{{contactUs}}</2> to provide your turn-in information.',
                        type: null,
                        languageCode: null,
                        disabled: null,
                        active: null,
                        underLined: null,
                        inversed: null,
                        presentationType: null,
                        url: null,
                        esUrl: 'secureMessage,contactUs',
                        id: 'restrictionModal',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: 'restrictionModalLinkList',
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: null,
                        headerText: null,
                    },
                    dialogCloseButton: {
                        value: 'CLOSE',
                        type: null,
                        languageCode: null,
                        disabled: null,
                        active: null,
                        underLined: null,
                        inversed: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'dialogCloseButton',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: 'pl_le_grnd_cls',
                        trackingType: null,
                        showSpeedBump: null,
                        headerText: null,
                    },
                },
            },
            timedModalDialog: {
                brands: null,
                segments: null,
                tags: ['auto/oem/lrfg/common'],
                id: 'timedModalDialog',
                title: {
                    html: '<p>WE’RE CONNECTING YOU TO CHASE.COM</p>\n',
                    text: 'WE’RE CONNECTING YOU TO CHASE.COM',
                    markdown: "WE'RE CONNECTING YOU TO CHASE.COM\n",
                },
                subTitle: {
                    html: "<p>Don't show this message again</p>\n",
                    text: "Don't show this message again",
                    markdown: "Don't show this message again\n",
                },
                version: '20240606',
                message: {
                    html: null,
                    text: null,
                    markdown: null,
                },
                messageType: null,
                hat: null,
                primaryIconImage: null,
                secondaryIconImage: null,
                links: {
                    dialogCancelButton: {
                        value: 'CANCEL',
                        type: null,
                        languageCode: null,
                        disabled: null,
                        active: null,
                        underLined: null,
                        inversed: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'dialogCancelButton',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: 'pl_connect_chse_cxl',
                        trackingType: null,
                        showSpeedBump: null,
                        headerText: null,
                    },
                },
            },
        },
        contentList: {
            phoneTypeDropdownList: {
                id: 'phoneTypeDropdownList',
                title: {
                    html: null,
                    text: null,
                    markdown: null,
                },
                tags: ['auto/oem/lrfg/common'],
                version: '20240606',
                items: {
                    phoneTypeOption1: {
                        id: 'phoneTypeOption1',
                        value: 'Cell',
                    },
                    phoneTypeOption2: {
                        id: 'phoneTypeOption2',
                        value: 'Work',
                    },
                    phoneTypeOption3: {
                        id: 'phoneTypeOption3',
                        value: 'Home',
                    },
                },
            },
            stateDropdownList: {
                id: 'stateDropdownList',
                title: {
                    html: null,
                    text: null,
                    markdown: null,
                },
                tags: ['auto/oem/lrfg/common'],
                version: '20240606',
                items: {
                    stateOption1: {
                        id: 'stateOption1',
                        value: 'AK',
                    },
                    stateOption2: {
                        id: 'stateOption2',
                        value: 'AL',
                    },
                    stateOption3: {
                        id: 'stateOption3',
                        value: 'AR',
                    },
                    stateOption4: {
                        id: 'stateOption4',
                        value: 'AZ',
                    },
                    stateOption5: {
                        id: 'stateOption5',
                        value: 'CA',
                    },
                    stateOption6: {
                        id: 'stateOption6',
                        value: 'CO',
                    },
                    stateOption7: {
                        id: 'stateOption7',
                        value: 'DC',
                    },
                    stateOption8: {
                        id: 'stateOption8',
                        value: 'DE',
                    },
                    stateOption9: {
                        id: 'stateOption9',
                        value: 'FL',
                    },
                    stateOption10: {
                        id: 'stateOption10',
                        value: 'GA',
                    },
                    stateOption11: {
                        id: 'stateOption11',
                        value: 'HI',
                    },
                    stateOption12: {
                        id: 'stateOption12',
                        value: 'IA',
                    },
                    stateOption13: {
                        id: 'stateOption13',
                        value: 'ID',
                    },
                    stateOption14: {
                        id: 'stateOption14',
                        value: 'IL',
                    },
                    stateOption15: {
                        id: 'stateOption15',
                        value: 'IN',
                    },
                    stateOption16: {
                        id: 'stateOption16',
                        value: 'KS',
                    },
                    stateOption17: {
                        id: 'stateOption17',
                        value: 'KY',
                    },
                    stateOption18: {
                        id: 'stateOption18',
                        value: 'LA',
                    },
                    stateOption19: {
                        id: 'stateOption19',
                        value: 'MA',
                    },
                    stateOption20: {
                        id: 'stateOption20',
                        value: 'MD',
                    },
                    stateOption21: {
                        id: 'stateOption21',
                        value: 'ME',
                    },
                    stateOption22: {
                        id: 'stateOption22',
                        value: 'MI',
                    },
                    stateOption23: {
                        id: 'stateOption23',
                        value: 'MN',
                    },
                    stateOption24: {
                        id: 'stateOption24',
                        value: 'MO',
                    },
                    stateOption25: {
                        id: 'stateOption25',
                        value: 'MS',
                    },
                    stateOption26: {
                        id: 'stateOption26',
                        value: 'MT',
                    },
                    stateOption27: {
                        id: 'stateOption27',
                        value: 'NC',
                    },
                    stateOption28: {
                        id: 'stateOption28',
                        value: 'ND',
                    },
                    stateOption29: {
                        id: 'stateOption29',
                        value: 'NE',
                    },
                    stateOption30: {
                        id: 'stateOption30',
                        value: 'NH',
                    },
                    stateOption31: {
                        id: 'stateOption31',
                        value: 'NJ',
                    },
                    stateOption32: {
                        id: 'stateOption32',
                        value: 'NM',
                    },
                    stateOption33: {
                        id: 'stateOption33',
                        value: 'NV',
                    },
                    stateOption34: {
                        id: 'stateOption34',
                        value: 'NY',
                    },
                    stateOption35: {
                        id: 'stateOption35',
                        value: 'OH',
                    },
                    stateOption36: {
                        id: 'stateOption36',
                        value: 'OK',
                    },
                    stateOption37: {
                        id: 'stateOption37',
                        value: 'OR',
                    },
                    stateOption38: {
                        id: 'stateOption38',
                        value: 'PA',
                    },
                    stateOption39: {
                        id: 'stateOption39',
                        value: 'RI',
                    },
                    stateOption40: {
                        id: 'stateOption40',
                        value: 'SC',
                    },
                    stateOption41: {
                        id: 'stateOption41',
                        value: 'SD',
                    },
                    stateOption42: {
                        id: 'stateOption42',
                        value: 'TN',
                    },
                    stateOption43: {
                        id: 'stateOption43',
                        value: 'TX',
                    },
                    stateOption44: {
                        id: 'stateOption44',
                        value: 'UT',
                    },
                    stateOption45: {
                        id: 'stateOption45',
                        value: 'VT',
                    },
                    stateOption46: {
                        id: 'stateOption46',
                        value: 'VA',
                    },
                    stateOption47: {
                        id: 'stateOption47',
                        value: 'WA',
                    },
                    stateOption48: {
                        id: 'stateOption48',
                        value: 'WI',
                    },
                    stateOption49: {
                        id: 'stateOption49',
                        value: 'WV',
                    },
                    stateOption50: {
                        id: 'stateOption50',
                        value: 'WY',
                    },
                },
            },
        },
        tile: {},
        structuredContentList: {},
        header: {},
    },
};
