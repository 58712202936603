export const errorPage = {
    data: {
        linkList: {
            errorPageLinks: {
                id: 'errorPageLinks',
                tags: ['auto/oem/lrfg/error-page'],
                version: '20240606',
                title: {
                    text: null,
                },
                subTitle: {
                    text: null,
                },
                links: {
                    contactUs: {
                        headerText: 'anquer',
                        value: 'contact us',
                        type: null,
                        presentationType: null,
                        url: '/contact-us',
                        esUrl: null,
                        id: 'contactUs',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    FindARetailerButton: {
                        headerText: 'anquer',
                        value: 'Find A Retailer',
                        type: null,
                        presentationType: null,
                        url: 'https://www.landroverusa.com/national-dealer-locator.html',
                        esUrl: null,
                        id: 'FindARetailerButton',
                        hat: null,
                        navigationKeyId: 'Untimed Modal',
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: true,
                    },
                    oopsSignInLink: {
                        headerText: null,
                        value: 'SIGN IN TO CHASE.COM',
                        type: null,
                        presentationType: null,
                        url: 'https://www.chase.com/?jp_cdf=LRFG',
                        esUrl: null,
                        id: 'oopsSignInLink',
                        hat: null,
                        navigationKeyId: 'Timed Modal',
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: true,
                    },
                    noActiveAccountSignInLink: {
                        headerText: null,
                        value: 'SIGN IN TO CHASE.COM',
                        type: null,
                        presentationType: null,
                        url: 'https://qac3-secure01ea.chase.com/web/auth/dashboard?navKey=requestOverviewDashboard&jp_cdf=LRFG_PM_VISIT',
                        esUrl: null,
                        id: 'noActiveAccountSignInLink',
                        hat: null,
                        navigationKeyId: 'Timed Modal',
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: true,
                    },
                },
            },
            errorPageList: {
                id: 'errorPageList',
                tags: ['auto/oem/lrfg/error-page'],
                version: '20240606',
                title: {
                    text: "IT LOOKS LIKE THIS PART OF OUR SITE ISN'T WORKING RIGHT NOW",
                },
                subTitle: {
                    text: null,
                },
                links: {
                    errorPageList1: {
                        headerText: null,
                        value: 'Please try again later.',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'errorPageList1',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    errorPageList2: {
                        headerText: null,
                        value: 'Thanks for your patience.',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'errorPageList2',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                },
            },
            noActiveAccountList: {
                id: 'noActiveAccountList',
                tags: ['auto/oem/lrfg/error-page'],
                version: '20240606',
                title: {
                    text: "YOU DON'T HAVE AN ACTIVE LAND ROVER ACCOUNT AT THIS TIME",
                },
                subTitle: {
                    text: null,
                },
                links: {
                    noActiveAccountList1: {
                        headerText: null,
                        value: 'If your account is new, it should be active around 2 weeks before your first payment is due.',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'noActiveAccountList1',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    noActiveAccountList2: {
                        headerText: null,
                        value: 'If your account recently closed or has special circumstances (including collections), <1>{{contactUs}}</1>.',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: 'contactUs',
                        id: 'noActiveAccountList2',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: 'errorPageLinks',
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                },
            },
            oopsPageList: {
                id: 'oopsPageList',
                tags: ['auto/oem/lrfg/error-page'],
                version: '20240606',
                title: {
                    text: "THE PAGE YOU'RE LOOKING FOR DOESN'T EXIST",
                },
                subTitle: {
                    text: null,
                },
                links: {
                    oopsPageList1: {
                        headerText: null,
                        value: "Let's get you to one that does.",
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'oopsPageList1',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    oopsPageList2: {
                        headerText: null,
                        value: 'Looking for a new vehicle? <1>{{FindARetailerButton}}</1>.',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: 'FindARetailerButton',
                        id: 'oopsPageList2',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: 'errorPageLinks',
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                },
            },
        },
        carousel: {},
        iconList: {
            errorPageIcons: {
                tags: ['auto/oem/lrfg/error-page'],
                id: 'errorPageIcons',
                title: {
                    html: null,
                    text: null,
                    markdown: null,
                },
                subTitle: {
                    html: null,
                    text: null,
                    markdown: null,
                },
                version: '20240606',
                icons: {
                    rightArrowIconWhite: {
                        id: 'rightArrowIconWhite',
                        text: 'rightArrowIcon',
                        image: {
                            altText: null,
                            id: null,
                            source: '/content/dam/unified-assets/iconography/auto/chevron/icon_auto_chevron_white_pointing_right.svg',
                            url: 'https://static2-qa2.chasecdn.com/content/dam/unified-assets/iconography/auto/chevron/icon_auto_chevron_white_pointing_right.svg',
                        },
                        primaryImage: null,
                        link: {
                            value: null,
                            type: null,
                            languageCode: null,
                            disabled: null,
                            active: null,
                            underLined: null,
                            inversed: null,
                            presentationType: null,
                            url: null,
                            esUrl: null,
                            id: null,
                            hat: null,
                            navigationKeyId: null,
                            target: null,
                            iconId: null,
                            icon: null,
                            trackingId: null,
                            trackingType: null,
                            showSpeedBump: null,
                            headerText: null,
                        },
                    },
                    rightArrowIconBlack: {
                        id: 'rightArrowIconBlack',
                        text: 'rightArrowIcon',
                        image: {
                            altText: null,
                            id: null,
                            source: 'rightArrowIconBlackImage',
                            url: 'https://static2-qa2.chasecdn.comrightArrowIconBlackImage',
                        },
                        primaryImage: null,
                        link: {
                            value: null,
                            type: null,
                            languageCode: null,
                            disabled: null,
                            active: null,
                            underLined: null,
                            inversed: null,
                            presentationType: null,
                            url: null,
                            esUrl: null,
                            id: null,
                            hat: null,
                            navigationKeyId: null,
                            target: null,
                            iconId: null,
                            icon: null,
                            trackingId: null,
                            trackingType: null,
                            showSpeedBump: null,
                            headerText: null,
                        },
                    },
                },
            },
        },
        legalContainer: {},
        dialog: {},
        contentList: {},
        tile: {
            errorPage: {
                tags: ['auto/oem/lrfg/error-page'],
                id: 'errorPage',
                variationId: null,
                title: {
                    html: null,
                    text: null,
                    markdown: null,
                },
                subTitle: {
                    html: null,
                    text: null,
                    markdown: null,
                },
                version: '20240606',
                body: {
                    html: null,
                    text: null,
                    markdown: null,
                },
                additionalText: {
                    html: null,
                    text: null,
                    markdown: null,
                },
                hat: null,
                iconImage: {
                    altText: null,
                    id: 'noActiveAccountImage',
                    source: null,
                    url: null,
                },
                primaryImage: {
                    altText: 'noActiveAccount',
                    id: null,
                    source: '/content/dam/unified-assets/photography/partner/land-rover/optimized/No_Active_Account_Hero_Desktop1.jpg',
                    url: 'https://static2-qa2.chasecdn.com/content/dam/unified-assets/photography/partner/land-rover/optimized/No_Active_Account_Hero_Desktop1.jpg',
                },
                primaryImageWithRendition: null,
                secondaryImageWithRendition: null,
                links: [],
            },
        },
        structuredContentList: {},
    },
};
