export const newCustomer = {
    data: {
        linkList: {
            QuickLinksList: {
                id: 'QuickLinksList',
                tags: ['auto/oem/lrfg/newcustomer'],
                version: '20240606',
                title: {
                    text: 'Quick Links',
                },
                subTitle: {
                    text: null,
                },
                links: {
                    QuickLinkContent1: {
                        headerText: null,
                        value: 'MANAGE YOUR ACCOUNT',
                        type: null,
                        presentationType: null,
                        url: '/myaccount',
                        esUrl: null,
                        id: 'QuickLinkContent1',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: 'pl_ql_lnk_manage',
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    QuickLinkContent2: {
                        headerText: null,
                        value: 'FAQs',
                        type: null,
                        presentationType: null,
                        url: '/faq',
                        esUrl: null,
                        id: 'QuickLinkContent2',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: 'pl_ql_lnk_faq',
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    QuickLinkContent3: {
                        headerText: null,
                        value: 'Contact Us',
                        type: null,
                        presentationType: null,
                        url: '/contact-us',
                        esUrl: null,
                        id: 'QuickLinkContent3',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: 'pl_ql_lnk_contact',
                        trackingType: null,
                        showSpeedBump: false,
                    },
                },
            },
            automaticPaymentsIcon: {
                id: 'automaticPaymentsIcon',
                tags: ['auto/oem/lrfg/newcustomer'],
                version: '20240606',
                title: {
                    text: null,
                },
                subTitle: {
                    text: null,
                },
                links: {
                    automaticPaymentsLink1: {
                        headerText: null,
                        value: 'Go to <1>{{chaseAutoPay}}</1>',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: 'chaseAutoPay',
                        id: 'automaticPaymentsLink1',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: 'chaseAppLinkList',
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    automaticPaymentsLink2: {
                        headerText: null,
                        value: 'Sign in to your account',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'automaticPaymentsLink2',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    automaticPaymentsLink3: {
                        headerText: null,
                        value: 'Choose “Pay” on your auto account',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'automaticPaymentsLink3',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    automaticPaymentsLink4: {
                        headerText: null,
                        value: 'Follow the steps to finish setting up your automatic payments',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'automaticPaymentsLink4',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                },
            },
            chaseAppLinkList: {
                id: 'chaseAppLinkList',
                tags: ['auto/oem/lrfg/newcustomer'],
                version: '20240606',
                title: {
                    text: null,
                },
                subTitle: {
                    text: null,
                },
                links: {
                    chaseAutoPay: {
                        headerText: 'anquer',
                        value: 'chase.com/autopay',
                        type: null,
                        presentationType: null,
                        url: 'https://www.chase.com/autopay?jp_cdf=LRFG',
                        esUrl: null,
                        id: 'chaseAutoPay',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: 'pl_auto_pmt_chase',
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    chasePaperless: {
                        headerText: 'anquer',
                        value: 'chase.com/paperless',
                        type: null,
                        presentationType: null,
                        url: 'https://www.chase.com/paperless?jp_cdf=LRFG',
                        esUrl: null,
                        id: 'chasePaperless',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: 'pl_papr_chase',
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    chaseAlerts: {
                        headerText: 'anquer',
                        value: 'chase.com/alerts',
                        type: null,
                        presentationType: null,
                        url: 'https://www.chase.com/alerts?jp_cdf=LRFG',
                        esUrl: null,
                        id: 'chaseAlerts',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: 'pl_alerts_chase',
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    chaseDotComEnroll: {
                        headerText: 'anquer',
                        value: 'chase.com/enroll',
                        type: null,
                        presentationType: null,
                        url: 'https://chase.com/enroll?jp_cdf=LRFG',
                        esUrl: null,
                        id: 'chaseDotComEnroll',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: 'pl_chase_app_chase',
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    chaseDotCom: {
                        headerText: 'anquer',
                        value: 'chase.com',
                        type: null,
                        presentationType: null,
                        url: 'https://www.chase.com/',
                        esUrl: null,
                        id: 'chaseDotCom',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: 'pl_chase_1',
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    oneFooter: {
                        headerText: 'sup',
                        value: '1',
                        type: null,
                        presentationType: null,
                        url: '#footerContent',
                        esUrl: null,
                        id: 'oneFooter',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    twoFooter: {
                        headerText: 'sup',
                        value: '1',
                        type: null,
                        presentationType: null,
                        url: '#footerContent',
                        esUrl: null,
                        id: 'twoFooter',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                },
            },
            downloadTheChaseMobileAppIcon: {
                id: 'downloadTheChaseMobileAppIcon',
                tags: ['auto/oem/lrfg/newcustomer'],
                version: '20240606',
                title: {
                    text: null,
                },
                subTitle: {
                    text: null,
                },
                links: {
                    downloadTheChaseMobileAppIconLink1: {
                        headerText: 'paragraph',
                        value: 'Get everything you need to manage your auto loan or lease from almost anywhere. Just download the Chase Mobile app on your mobile device and use the same username and password you use at <1>{{chaseDotCom}}</1>',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: 'chaseDotCom',
                        id: 'downloadTheChaseMobileAppIconLink1',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: 'chaseAppLinkList',
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                },
            },
            enrollInPaperlessBillingIcon: {
                id: 'enrollInPaperlessBillingIcon',
                tags: ['auto/oem/lrfg/newcustomer'],
                version: '20240606',
                title: {
                    text: null,
                },
                subTitle: {
                    text: null,
                },
                links: {
                    enrollInPaperlessBillingList1: {
                        headerText: 'title',
                        value: 'On the Chase Mobile app:',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'enrollInPaperlessBillingList1',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    enrollInPaperlessBillingList2: {
                        headerText: 'title',
                        value: 'On your desktop:',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'enrollInPaperlessBillingList2',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                },
            },
            enrollInPaperlessBillingList1: {
                id: 'enrollInPaperlessBillingList1',
                tags: ['auto/oem/lrfg/newcustomer'],
                version: '20240606',
                title: {
                    text: null,
                },
                subTitle: {
                    text: null,
                },
                links: {
                    enrollInPaperlessBillingLis1SubList1: {
                        headerText: null,
                        value: 'Sign in to your account',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'enrollInPaperlessBillingLis1SubList1',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    enrollInPaperlessBillingList1SubList2: {
                        headerText: null,
                        value: 'Go to “Account management” and then “Paperless”',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'enrollInPaperlessBillingList1SubList2',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    enrollInPaperlessBillingLis1SubList3: {
                        headerText: null,
                        value: 'Turn on paperless billing for your auto account',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'enrollInPaperlessBillingLis1SubList3',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                },
            },
            enrollInPaperlessBillingList2: {
                id: 'enrollInPaperlessBillingList2',
                tags: ['auto/oem/lrfg/newcustomer'],
                version: '20240606',
                title: {
                    text: null,
                },
                subTitle: {
                    text: null,
                },
                links: {
                    enrollInPaperlessBillingList2SubList1: {
                        headerText: null,
                        value: 'Go to <1>{{chasePaperless}}</1>',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: 'chasePaperless',
                        id: 'enrollInPaperlessBillingList2SubList1',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: 'chaseAppLinkList',
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    enrollInPaperlessBillingList2SubList2: {
                        headerText: null,
                        value: 'Sign in to your account',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'enrollInPaperlessBillingList2SubList2',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    enrollInPaperlessBillingList2SubList3: {
                        headerText: null,
                        value: 'Choose your auto account and then choose “I consent” to turn on paperless billing',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'enrollInPaperlessBillingList2SubList3',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    enrollInPaperlessBillingList2SubList4: {
                        headerText: 'paragraph',
                        value: 'Keep in mind: It can take up to a month to stop receiving paper statements in the mail.',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'enrollInPaperlessBillingList2SubList4',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                },
            },
            getAccountsIcon: {
                id: 'getAccountsIcon',
                tags: ['auto/oem/lrfg/newcustomer'],
                version: '20240606',
                title: {
                    text: null,
                },
                subTitle: {
                    text: null,
                },
                links: {
                    getAccountsIconList1: {
                        headerText: 'title',
                        value: 'On the Chase Mobile app:',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'getAccountsIconList1',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    getAccountsIconList2: {
                        headerText: 'title',
                        value: 'On your desktop:',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'getAccountsIconList2',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                },
            },
            getAccountsIconList1: {
                id: 'getAccountsIconList1',
                tags: ['auto/oem/lrfg/newcustomer'],
                version: '20240606',
                title: {
                    text: null,
                },
                subTitle: {
                    text: null,
                },
                links: {
                    getAccountsIconList1SubList1: {
                        headerText: null,
                        value: 'Sign in to your account',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'getAccountsIconList1SubList1',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    getAccountsIconList1SubList2: {
                        headerText: null,
                        value: 'Choose “Profile & settings”',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'getAccountsIconList1SubList2',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    getAccountsIconList1SubList3: {
                        headerText: null,
                        value: 'Go to “Manage alerts”',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'getAccountsIconList1SubList3',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    getAccountsIconList1SubList4: {
                        headerText: null,
                        value: 'Choose your auto account and set up your alerts',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'getAccountsIconList1SubList4',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                },
            },
            getAccountsIconList2: {
                id: 'getAccountsIconList2',
                tags: ['auto/oem/lrfg/newcustomer'],
                version: '20240606',
                title: {
                    text: null,
                },
                subTitle: {
                    text: null,
                },
                links: {
                    getAccountsIconList2SubList1: {
                        headerText: null,
                        value: 'Go to <1>{{chaseAlerts}}</1>',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: 'chaseAlerts',
                        id: 'getAccountsIconList2SubList1',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: 'chaseAppLinkList',
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    getAccountsIconList2SubList2: {
                        headerText: null,
                        value: 'Sign in to your account',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'getAccountsIconList2SubList2',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    getAccountsIconList2SubList3: {
                        headerText: null,
                        value: 'Choose your auto account and set up your alerts',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'getAccountsIconList2SubList3',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                },
            },
        },
        carousel: {},
        iconList: {
            downloadTheChaseMobileAppIconLink1: {
                tags: ['auto/oem/lrfg/newcustomer'],
                id: 'downloadTheChaseMobileAppIconLink1',
                title: {
                    html: null,
                    text: null,
                    markdown: null,
                },
                subTitle: {
                    html: null,
                    text: null,
                    markdown: null,
                },
                version: '20240606',
                icons: {
                    icon1: {
                        id: 'icon1',
                        text: 'Download the Chase mobile app from the Apple app store for your iOS device',
                        image: {
                            altText: null,
                            id: null,
                            source: '/content/dam/unified-assets/logo/other-logos/apple-app-store/logo_apple_store_download.png',
                            url: 'https://static2-qa2.chasecdn.com/content/dam/unified-assets/logo/other-logos/apple-app-store/logo_apple_store_download.png',
                        },
                        primaryImage: null,
                        link: {
                            value: 'Download the Chase mobile app from the Apple app store for your iOS device',
                            type: null,
                            languageCode: null,
                            disabled: null,
                            active: null,
                            underLined: null,
                            inversed: null,
                            presentationType: null,
                            url: 'https://apps.apple.com/us/app/chase-mobile-bank-invest/id298867247',
                            esUrl: null,
                            id: 'appStore',
                            hat: null,
                            navigationKeyId: 'Untimed Modal',
                            target: null,
                            iconId: null,
                            icon: null,
                            trackingId: 'pl_app_store',
                            trackingType: null,
                            showSpeedBump: true,
                            headerText: null,
                        },
                    },
                    icon2: {
                        id: 'icon2',
                        text: 'Download the Chase mobile app from the Google play store for your android device',
                        image: {
                            altText: null,
                            id: null,
                            source: '/content/dam/unified-assets/logo/other-logos/google-play/logo_google_play272x78.png',
                            url: 'https://static2-qa2.chasecdn.com/content/dam/unified-assets/logo/other-logos/google-play/logo_google_play272x78.png',
                        },
                        primaryImage: null,
                        link: {
                            value: 'Download the Chase mobile app from the Google play store for your android device',
                            type: null,
                            languageCode: null,
                            disabled: null,
                            active: null,
                            underLined: null,
                            inversed: null,
                            presentationType: null,
                            url: 'https://play.google.com/store/apps/details?id=com.chase.sig.android&hl=en',
                            esUrl: null,
                            id: 'googlePlay',
                            hat: null,
                            navigationKeyId: 'Untimed Modal',
                            target: null,
                            iconId: null,
                            icon: null,
                            trackingId: 'pl_play_store',
                            trackingType: null,
                            showSpeedBump: true,
                            headerText: null,
                        },
                    },
                },
            },
            newCustomerIconList: {
                tags: ['auto/oem/lrfg/newcustomer'],
                id: 'newCustomerIconList',
                title: {
                    html: null,
                    text: null,
                    markdown: null,
                },
                subTitle: {
                    html: null,
                    text: null,
                    markdown: null,
                },
                version: '20240606',
                icons: {
                    downloadTheChaseMobileAppIcon: {
                        id: 'downloadTheChaseMobileAppIcon',
                        text: 'DOWNLOAD THE CHASE MOBILE® APP',
                        image: {
                            altText: null,
                            id: null,
                            source: '/content/dam/unified-assets/iconography/auto/ico_auto_phone_with_card_md.svg',
                            url: 'https://static2-qa2.chasecdn.com/content/dam/unified-assets/iconography/auto/ico_auto_phone_with_card_md.svg',
                        },
                        primaryImage: null,
                        link: {
                            value: 'DOWNLOAD THE CHASE MOBILE® APP <1>{{oneFooter}}</1>',
                            type: null,
                            languageCode: null,
                            disabled: null,
                            active: null,
                            underLined: null,
                            inversed: null,
                            presentationType: null,
                            url: null,
                            esUrl: 'oneFooter',
                            id: 'downloadTheChaseMobileAppLink',
                            hat: null,
                            navigationKeyId: null,
                            target: null,
                            iconId: 'chaseAppLinkList',
                            icon: null,
                            trackingId: null,
                            trackingType: null,
                            showSpeedBump: false,
                            headerText: null,
                        },
                    },
                    automaticPaymentsIcon: {
                        id: 'automaticPaymentsIcon',
                        text: null,
                        image: {
                            altText: null,
                            id: null,
                            source: '/content/dam/unified-assets/iconography/auto/ico_auto_lending_md.svg',
                            url: 'https://static2-qa2.chasecdn.com/content/dam/unified-assets/iconography/auto/ico_auto_lending_md.svg',
                        },
                        primaryImage: null,
                        link: {
                            value: 'SET UP AUTOMATIC PAYMENTS',
                            type: null,
                            languageCode: null,
                            disabled: null,
                            active: null,
                            underLined: null,
                            inversed: null,
                            presentationType: null,
                            url: null,
                            esUrl: null,
                            id: 'automaticPaymentsLink',
                            hat: null,
                            navigationKeyId: null,
                            target: null,
                            iconId: null,
                            icon: null,
                            trackingId: null,
                            trackingType: null,
                            showSpeedBump: false,
                            headerText: null,
                        },
                    },
                    enrollInPaperlessBillingIcon: {
                        id: 'enrollInPaperlessBillingIcon',
                        text: null,
                        image: {
                            altText: null,
                            id: null,
                            source: '/content/dam/unified-assets/iconography/auto/ico_auto_paperless_md.svg',
                            url: 'https://static2-qa2.chasecdn.com/content/dam/unified-assets/iconography/auto/ico_auto_paperless_md.svg',
                        },
                        primaryImage: null,
                        link: {
                            value: 'ENROLL IN PAPERLESS BILLING',
                            type: null,
                            languageCode: null,
                            disabled: null,
                            active: null,
                            underLined: null,
                            inversed: null,
                            presentationType: null,
                            url: null,
                            esUrl: null,
                            id: 'enrollInPaperlessBillingLink',
                            hat: null,
                            navigationKeyId: null,
                            target: null,
                            iconId: null,
                            icon: null,
                            trackingId: null,
                            trackingType: null,
                            showSpeedBump: false,
                            headerText: null,
                        },
                    },
                    getAccountsIcon: {
                        id: 'getAccountsIcon',
                        text: 'GET ACCOUNT ALERTS',
                        image: {
                            altText: null,
                            id: null,
                            source: '/content/dam/unified-assets/iconography/auto/ico_auto_newspaper_alert_md.svg',
                            url: 'https://static2-qa2.chasecdn.com/content/dam/unified-assets/iconography/auto/ico_auto_newspaper_alert_md.svg',
                        },
                        primaryImage: null,
                        link: {
                            value: 'GET ACCOUNT ALERTS <1>{{twoFooter}}</1>',
                            type: null,
                            languageCode: null,
                            disabled: null,
                            active: null,
                            underLined: null,
                            inversed: null,
                            presentationType: null,
                            url: null,
                            esUrl: 'twoFooter',
                            id: 'getAccountsIconLink',
                            hat: null,
                            navigationKeyId: null,
                            target: null,
                            iconId: 'chaseAppLinkList',
                            icon: null,
                            trackingId: null,
                            trackingType: null,
                            showSpeedBump: false,
                            headerText: null,
                        },
                    },
                },
            },
        },
        legalContainer: {
            FooterContentList: {
                brands: null,
                segments: null,
                tags: ['auto/oem/lrfg/newcustomer'],
                id: 'FooterContentList',
                title: {
                    html: null,
                    text: null,
                    markdown: null,
                },
                subTitle: {
                    html: null,
                    text: null,
                    markdown: null,
                },
                disclaimer: [],
                disclosure: [],
                footNote: {
                    FooterContent1: {
                        id: 'FooterContent1',
                        title: {
                            html: '<p>Chase Mobile® app is available for select mobile devices. Message and data rates may apply.</p>\n',
                            text: 'Chase Mobile® app is available for select mobile devices. Message and data rates may apply.',
                            markdown: 'Chase Mobile® app is available for select mobile devices. Message and data rates may apply.\n',
                        },
                        description: {
                            html: null,
                            text: null,
                            markdown: null,
                        },
                        links: [],
                    },
                    FooterContent2: {
                        id: 'FooterContent2',
                        title: {
                            html: "<p>Account Alerts: There's no charge from Chase, but message and data rates may apply. Delivery of alerts may be delayed for various reasons, including service outages affecting your phone, wireless or internet provider; technology failures; and system capacity limitations.</p>\n",
                            text: "Account Alerts: There's no charge from Chase, but message and data rates may apply. Delivery of alerts may be delayed for various reasons, including service outages affecting your phone, wireless or internet provider; technology failures; and system capacity limitations.",
                            markdown: "Account Alerts: There's no charge from Chase, but message and data rates may apply. Delivery of alerts may be delayed for various reasons, including service outages affecting your phone, wireless or internet provider; technology failures; and system capacity limitations.\n",
                        },
                        description: {
                            html: null,
                            text: null,
                            markdown: null,
                        },
                        links: [],
                    },
                    FooterContent3: {
                        id: 'FooterContent3',
                        title: {
                            html: '<p>Apple, the Apple logo and Face ID are trademarks of Apple Inc., registered in the U.S. and other countries. App Store is a service mark of Apple Inc.</p>\n',
                            text: 'Apple, the Apple logo and Face ID are trademarks of Apple Inc., registered in the U.S. and other countries. App Store is a service mark of Apple Inc.',
                            markdown: 'Apple, the Apple logo and Face ID are trademarks of Apple Inc., registered in the U.S. and other countries. App Store is a service mark of Apple Inc.\n',
                        },
                        description: {
                            html: null,
                            text: null,
                            markdown: null,
                        },
                        links: [],
                    },
                    FooterContent4: {
                        id: 'FooterContent4',
                        title: {
                            html: '<p>Google Play and the Google Play logo are trademarks of Google Inc.</p>\n',
                            text: 'Google Play and the Google Play logo are trademarks of Google Inc.',
                            markdown: 'Google Play and the Google Play logo are trademarks of Google Inc.\n',
                        },
                        description: {
                            html: null,
                            text: null,
                            markdown: null,
                        },
                        links: [],
                    },
                },
                contentLists: [],
                version: '20240502',
            },
        },
        dialog: {},
        contentList: {},
        tile: {
            newCustomer: {
                tags: ['auto/oem/lrfg/newcustomer'],
                id: 'newCustomer',
                variationId: null,
                title: {
                    html: '<p>New Customer</p>\n',
                    text: 'New Customer',
                    markdown: 'New Customer\n',
                },
                subTitle: {
                    html: '<p>Your Land Rover Financial Group and Chase accounts are linked, so you can make payments, set up automatic payments and see your account activity.</p>\n',
                    text: 'Your Land Rover Financial Group and Chase accounts are linked, so you can make payments, set up automatic payments and see your account activity.',
                    markdown: 'Your Land Rover Financial Group and Chase accounts are linked, so you can make payments, set up automatic payments and see your account activity.\n',
                },
                version: '20240606',
                body: {
                    html: '<p>If you’re not a Chase customer, go to&lt;1&gt;{{chaseDotComEnroll}}&lt;/1&gt;&nbsp;to create your Land Rover Financial Group account. You’ll need your account number, which will be on your first statement. (If you’re already a Chase customer, you’re automatically enrolled and should see your new auto account with your other Chase accounts when you sign in.)</p>\n',
                    text: 'If you’re not a Chase customer, go to<1>{{chaseDotComEnroll}}</1> to create your Land Rover Financial Group account. You’ll need your account number, which will be on your first statement. (If you’re already a Chase customer, you’re automatically enrolled and should see your new auto account with your other Chase accounts when you sign in.)',
                    markdown: "If you're not a Chase customer, go to\\<1\\>{{chaseDotComEnroll}}\\</1\\> to create your Land Rover Financial Group account. You'll need your account number, which will be on your first statement. (If you're already a Chase customer, you're automatically enrolled and should see your new auto account with your other Chase accounts when you sign in.)\n",
                },
                additionalText: {
                    html: '<p>Use the same username and password for Land Rover Financial Group and Chase.</p>\n',
                    text: 'Use the same username and password for Land Rover Financial Group and Chase.',
                    markdown: 'Use the same username and password for Land Rover Financial Group and Chase.\n',
                },
                hat: null,
                iconImage: {
                    altText: null,
                    id: 'newCustomerImage',
                    source: null,
                    url: null,
                },
                primaryImage: {
                    altText: 'newCustomer',
                    id: null,
                    source: '/content/dam/unified-assets/photography/partner/land-rover/optimized/NewCustomer_Hero_Desktop2.webp',
                    url: 'https://static2-qa2.chasecdn.com/content/dam/unified-assets/photography/partner/land-rover/optimized/NewCustomer_Hero_Desktop2.webp',
                },
                primaryImageWithRendition: null,
                secondaryImageWithRendition: null,
                links: {
                    chaseDotComEnroll: {
                        value: 'chase.com/enroll',
                        type: null,
                        languageCode: null,
                        disabled: null,
                        active: null,
                        underLined: null,
                        inversed: null,
                        presentationType: null,
                        url: 'https://chase.com/enroll?jp_cdf=LRFG',
                        esUrl: null,
                        id: 'chaseDotComEnroll',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: 'pl_chase_1',
                        trackingType: null,
                        showSpeedBump: null,
                        headerText: 'anquer',
                        visibleIn: null,
                        variant: null,
                    },
                },
            },
        },
        structuredContentList: {},
        header: {},
    },
};
