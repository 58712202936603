export const digitalTurnIn = {
    data: {
        linkList: {
            customerInformationLinkList: {
                id: 'customerInformationLinkList',
                tags: ['auto/oem/lrfg/digital-turn-in'],
                version: '20240606',
                title: {
                    text: null,
                },
                subTitle: {
                    text: null,
                },
                links: {
                    changeContactLinkText: {
                        headerText: null,
                        value: 'Change contact information',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'changeContactLinkText',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    addAdditionalNumberLinkText: {
                        headerText: null,
                        value: '+Add additional number',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'addAdditionalNumberLinkText',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                },
            },
            leaseTurnInReturnHomelist: {
                id: 'leaseTurnInReturnHomelist',
                tags: ['auto/oem/lrfg/digital-turn-in'],
                version: '20240606',
                title: {
                    text: null,
                },
                subTitle: {
                    text: null,
                },
                links: {
                    returnHomeLabel: {
                        headerText: null,
                        value: 'RETURN HOME',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'returnHomeLabel',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                },
            },
            leaseTurnInSubmitList: {
                id: 'leaseTurnInSubmitList',
                tags: ['auto/oem/lrfg/digital-turn-in'],
                version: '20240606',
                title: {
                    text: null,
                },
                subTitle: {
                    text: null,
                },
                links: {
                    submitButtonLabel: {
                        headerText: null,
                        value: 'SUBMIT',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'submitButtonLabel',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                },
            },
            turninDealerInformationLinkList: {
                id: 'turninDealerInformationLinkList',
                tags: ['auto/oem/lrfg/digital-turn-in'],
                version: '20240606',
                title: {
                    text: null,
                },
                subTitle: {
                    text: null,
                },
                links: {
                    cancelButtonLabel: {
                        headerText: null,
                        value: 'cancel',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'cancelButtonLabel',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    saveButtonLabel: {
                        headerText: null,
                        value: 'save',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'saveButtonLabel',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    changeDealerLinkText: {
                        headerText: null,
                        value: 'Change dealer',
                        type: null,
                        presentationType: null,
                        url: null,
                        esUrl: null,
                        id: 'changeDealerLinkText',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                },
            },
        },
        carousel: {},
        iconList: {
            turninIconList: {
                tags: ['auto/oem/lrfg/digital-turn-in'],
                id: 'turninIconList',
                title: {
                    html: null,
                    text: null,
                    markdown: null,
                },
                subTitle: {
                    html: null,
                    text: null,
                    markdown: null,
                },
                version: '20240606',
                icons: {
                    cancelButonIcon: {
                        id: 'cancelButonIcon',
                        text: 'cancelButonIcon',
                        image: {
                            altText: null,
                            id: null,
                            source: '/content/dam/unified-assets/iconography/auto/chevron/icon_auto_chevron_black_right.svg',
                            url: 'https://static2-qa2.chasecdn.com/content/dam/unified-assets/iconography/auto/chevron/icon_auto_chevron_black_right.svg',
                        },
                        primaryImage: null,
                        link: {
                            value: null,
                            type: null,
                            languageCode: null,
                            disabled: null,
                            active: null,
                            underLined: null,
                            inversed: null,
                            presentationType: null,
                            url: null,
                            esUrl: null,
                            id: null,
                            hat: null,
                            navigationKeyId: null,
                            target: null,
                            iconId: null,
                            icon: null,
                            trackingId: null,
                            trackingType: null,
                            showSpeedBump: null,
                            headerText: null,
                        },
                    },
                },
            },
        },
        legalContainer: {},
        dialog: {},
        contentList: {
            customerInformationList: {
                id: 'customerInformationList',
                title: {
                    html: '<p>CUSTOMER INFORMATION</p>\n',
                    text: 'CUSTOMER INFORMATION',
                    markdown: 'CUSTOMER INFORMATION\n',
                },
                tags: ['auto/oem/lrfg/digital-turn-in'],
                version: '20240606',
                items: {
                    methodToContactYouQuestion: {
                        id: 'methodToContactYouQuestion',
                        value: 'WHAT IS THE BEST METHOD TO CONTACT YOU? REQUIRED*',
                    },
                    contactPhoneLabel: {
                        id: 'contactPhoneLabel',
                        value: 'PHONE',
                    },
                    contactTypeLabel: {
                        id: 'contactTypeLabel',
                        value: 'TYPE',
                    },
                },
            },
            dealerOptionsList: {
                id: 'dealerOptionsList',
                title: {
                    html: null,
                    text: null,
                    markdown: null,
                },
                tags: ['auto/oem/lrfg/digital-turn-in'],
                version: '20240606',
                items: {
                    vehicleTurnInAtTheDealerQuestion: {
                        id: 'vehicleTurnInAtTheDealerQuestion',
                        value: 'WHO ASSISTED YOU WITH THE VEHICLE TURN-IN AT THE DEALER?',
                    },
                    firstNameLabel: {
                        id: 'firstNameLabel',
                        value: 'FIRST NAME',
                    },
                    lastNameLabel: {
                        id: 'lastNameLabel',
                        value: 'LAST NAME',
                    },
                    notSureLabel: {
                        id: 'notSureLabel',
                        value: 'NOT SURE',
                    },
                    jobTitleQuestion: {
                        id: 'jobTitleQuestion',
                        value: 'WHAT IS THEIR JOB TITLE?',
                    },
                    salesManagerLabel: {
                        id: 'salesManagerLabel',
                        value: 'SALES MANAGER',
                    },
                    financeManagerLabel: {
                        id: 'financeManagerLabel',
                        value: 'FINANCE MANAGER',
                    },
                    usedCarManagerLabel: {
                        id: 'usedCarManagerLabel',
                        value: 'USED CAR MANAGER',
                    },
                },
            },
            informationList: {
                id: 'informationList',
                title: {
                    html: '<p>INFORMATION</p>\n',
                    text: 'INFORMATION',
                    markdown: 'INFORMATION\n',
                },
                tags: ['auto/oem/lrfg/digital-turn-in'],
                version: '20240606',
                items: {
                    informationSubHeading: {
                        id: 'informationSubHeading',
                        value: 'Please indicate your new vehicle details. The VIN is required for loyalty offer eligibility.',
                    },
                    leaseOrPurchaseYourNewVehicleQuestion: {
                        id: 'leaseOrPurchaseYourNewVehicleQuestion',
                        value: 'DID YOU LEASE OR PURCHASE YOUR NEW VEHICLE?',
                    },
                    leaseLabel: {
                        id: 'leaseLabel',
                        value: 'LEASE',
                    },
                    purchaseLabel: {
                        id: 'purchaseLabel',
                        value: 'PURCHASE',
                    },
                    newVehicleQuestion: {
                        id: 'newVehicleQuestion',
                        value: 'WHAT IS THE MAKE OF YOUR NEW VEHICLE?',
                    },
                    landRoverLabel: {
                        id: 'landRoverLabel',
                        value: 'LAND ROVER',
                    },
                    otherLabel: {
                        id: 'otherLabel',
                        value: 'OTHER',
                    },
                    vinOfLandRoverQuestion: {
                        id: 'vinOfLandRoverQuestion',
                        value: 'WHAT IS THE VIN OF YOUR LAND ROVER?',
                    },
                    yearAndModelOfYourNewVehicle: {
                        id: 'yearAndModelOfYourNewVehicle',
                        value: 'WHAT IS THE YEAR AND MODEL OF YOUR NEW VEHICLE?',
                    },
                    yearLabel: {
                        id: 'yearLabel',
                        value: 'YEAR',
                    },
                    modelLabel: {
                        id: 'modelLabel',
                        value: 'MODEL',
                    },
                },
            },
            leaseTurnInConfirmationList: {
                id: 'leaseTurnInConfirmationList',
                title: {
                    html: '<p>THANK YOU</p>\n',
                    text: 'THANK YOU',
                    markdown: 'THANK YOU\n',
                },
                tags: ['auto/oem/lrfg/digital-turn-in'],
                version: '20240606',
                items: {
                    thankyouMessage: {
                        id: 'thankyouMessage',
                        value: 'Your form was successfully submitted. Please allow 2 business days for processing.',
                    },
                },
            },
            leaseTurnInInformationList: {
                id: 'leaseTurnInInformationList',
                title: {
                    html: '<p>LEASE TURN-IN INFORMATION</p>\n',
                    text: 'LEASE TURN-IN INFORMATION',
                    markdown: 'LEASE TURN-IN INFORMATION\n',
                },
                tags: ['auto/oem/lrfg/digital-turn-in'],
                version: '20240606',
                items: {
                    formSubHeading: {
                        id: 'formSubHeading',
                        value: 'LEASE TURN-IN INFORMATION',
                    },
                    maturityDateLabel: {
                        id: 'maturityDateLabel',
                        value: 'MATURITY DATE',
                    },
                    returnYourLeaseToTheDealerQuestion: {
                        id: 'returnYourLeaseToTheDealerQuestion',
                        value: 'WHEN DID YOU RETURN YOUR LEASE TO THE DEALER? REQUIRED*',
                    },
                    endingMileageQuestion: {
                        id: 'endingMileageQuestion',
                        value: 'WHAT IS YOUR ENDING MILEAGE? REQUIRED*',
                    },
                },
            },
            turnInFormTrackingIdList: {
                id: 'turnInFormTrackingIdList',
                title: {
                    html: '<p>pl_turnin_form</p>\n',
                    text: 'pl_turnin_form',
                    markdown: 'pl_turnin_form\n',
                },
                tags: ['auto/oem/lrfg/digital-turn-in'],
                version: '20240606',
                items: {
                    inputReturnDateTrackingId: {
                        id: 'inputReturnDateTrackingId',
                        value: 'pl_turnin_return_date',
                    },
                    inputMileageTrackingId: {
                        id: 'inputMileageTrackingId',
                        value: 'pl_turnin_mileage',
                    },
                    inputRetailerNameTrackingId: {
                        id: 'inputRetailerNameTrackingId',
                        value: 'pl_turnin_retailer_nm',
                    },
                    inputRetailerAddressTrackingId: {
                        id: 'inputRetailerAddressTrackingId',
                        value: 'pl_turnin_retailer_adrs',
                    },
                    inputRetailerCityTrackingId: {
                        id: 'inputRetailerCityTrackingId',
                        value: 'pl_turnin_retailer_city',
                    },
                    inputRetailerStateTrackingId: {
                        id: 'inputRetailerStateTrackingId',
                        value: 'pl_turnin_retailer_st',
                    },
                    inputRetailerZipTrackingId: {
                        id: 'inputRetailerZipTrackingId',
                        value: 'pl_turnin_retailer_zip',
                    },
                    inputRetailerPhoneTrackingId: {
                        id: 'inputRetailerPhoneTrackingId',
                        value: 'pl_turnin_retailer_phone',
                    },
                    inputRetailerFnameTrackingId: {
                        id: 'inputRetailerFnameTrackingId',
                        value: 'pl_turnin_retailer_fname',
                    },
                    inputRetailerLnameTrackingId: {
                        id: 'inputRetailerLnameTrackingId',
                        value: 'pl_turnin_retailer_lname',
                    },
                    inputNotSureTrackingId: {
                        id: 'inputNotSureTrackingId',
                        value: 'pl_turnin_retailer_ntsure',
                    },
                    inputCustomerAddressTrackingId: {
                        id: 'inputCustomerAddressTrackingId',
                        value: 'pl_turnin_cust_addrs',
                    },
                    inputCustomerCityTrackingId: {
                        id: 'inputCustomerCityTrackingId',
                        value: 'pl_turnin_cust_city',
                    },
                    inputCustomerStateTrackingId: {
                        id: 'inputCustomerStateTrackingId',
                        value: 'pl_turnin_cust_st',
                    },
                    inputCustomerZipTrackingId: {
                        id: 'inputCustomerZipTrackingId',
                        value: 'pl_turnin_cust_zip',
                    },
                    inputCustomerPhoneTrackingId: {
                        id: 'inputCustomerPhoneTrackingId',
                        value: 'pl_turnin_cust_phone',
                    },
                    inputCustomerPhoneTypeTrackingId: {
                        id: 'inputCustomerPhoneTypeTrackingId',
                        value: 'pl_turnin_cust_phn_typ',
                    },
                    inputVehicleLeaseTrackingId: {
                        id: 'inputVehicleLeaseTrackingId',
                        value: 'pl_turnin_veh_lease',
                    },
                    inputVehiclePurchaseTrackingId: {
                        id: 'inputVehiclePurchaseTrackingId',
                        value: 'pl_turnin_veh_purch',
                    },
                    inputVehicleMakeTrackingId: {
                        id: 'inputVehicleMakeTrackingId',
                        value: 'pl_turnin_veh_make',
                    },
                    inputVehicleOtherTrackingId: {
                        id: 'inputVehicleOtherTrackingId',
                        value: 'pl_turnin_veh_othr',
                    },
                    inputVehicleOtherTextTrackingId: {
                        id: 'inputVehicleOtherTextTrackingId',
                        value: 'pl_turnin_veh_othr_tx',
                    },
                    inputVehicleYearTrackingId: {
                        id: 'inputVehicleYearTrackingId',
                        value: 'pl_turnin_veh_yr',
                    },
                    inputVehicleModelTrackingId: {
                        id: 'inputVehicleModelTrackingId',
                        value: 'pl_turnin_veh_model',
                    },
                },
            },
            turninDealerInformation: {
                id: 'turninDealerInformation',
                title: {
                    html: '<p>TURN-IN DEALER INFORMATION</p>\n',
                    text: 'TURN-IN DEALER INFORMATION',
                    markdown: 'TURN-IN DEALER INFORMATION\n',
                },
                tags: ['auto/oem/lrfg/digital-turn-in'],
                version: '20240606',
                items: {
                    retailerNameLabel: {
                        id: 'retailerNameLabel',
                        value: 'RETAILER NAME',
                    },
                    addressLabel: {
                        id: 'addressLabel',
                        value: 'ADDRESS',
                    },
                    addressLabel2: {
                        id: 'addressLabel2',
                        value: 'ADDRESS 2',
                    },
                    cityLabel: {
                        id: 'cityLabel',
                        value: 'CITY',
                    },
                    stateLabel: {
                        id: 'stateLabel',
                        value: 'STATE',
                    },
                    zipLabel: {
                        id: 'zipLabel',
                        value: 'ZIP',
                    },
                    phoneLabel: {
                        id: 'phoneLabel',
                        value: 'PHONE',
                    },
                },
            },
            phoneTypeDropdownList: {
                id: 'phoneTypeDropdownList',
                title: {
                    html: null,
                    text: null,
                    markdown: null,
                },
                tags: ['auto/oem/lrfg/common'],
                version: '20240606',
                items: {
                    phoneTypeOption1: {
                        id: 'phoneTypeOption1',
                        value: 'Cell',
                    },
                    phoneTypeOption2: {
                        id: 'phoneTypeOption2',
                        value: 'Work',
                    },
                    phoneTypeOption3: {
                        id: 'phoneTypeOption3',
                        value: 'Home',
                    },
                },
            },
            stateDropdownList: {
                id: 'stateDropdownList',
                title: {
                    html: null,
                    text: null,
                    markdown: null,
                },
                tags: ['auto/oem/lrfg/common'],
                version: '20240606',
                items: {
                    stateOption1: {
                        id: 'stateOption1',
                        value: 'AK',
                    },
                    stateOption2: {
                        id: 'stateOption2',
                        value: 'AL',
                    },
                    stateOption3: {
                        id: 'stateOption3',
                        value: 'AR',
                    },
                    stateOption4: {
                        id: 'stateOption4',
                        value: 'AZ',
                    },
                    stateOption5: {
                        id: 'stateOption5',
                        value: 'CA',
                    },
                    stateOption6: {
                        id: 'stateOption6',
                        value: 'CO',
                    },
                    stateOption7: {
                        id: 'stateOption7',
                        value: 'DC',
                    },
                    stateOption8: {
                        id: 'stateOption8',
                        value: 'DE',
                    },
                    stateOption9: {
                        id: 'stateOption9',
                        value: 'FL',
                    },
                    stateOption10: {
                        id: 'stateOption10',
                        value: 'GA',
                    },
                    stateOption11: {
                        id: 'stateOption11',
                        value: 'HI',
                    },
                    stateOption12: {
                        id: 'stateOption12',
                        value: 'IA',
                    },
                    stateOption13: {
                        id: 'stateOption13',
                        value: 'ID',
                    },
                    stateOption14: {
                        id: 'stateOption14',
                        value: 'IL',
                    },
                    stateOption15: {
                        id: 'stateOption15',
                        value: 'IN',
                    },
                    stateOption16: {
                        id: 'stateOption16',
                        value: 'KS',
                    },
                    stateOption17: {
                        id: 'stateOption17',
                        value: 'KY',
                    },
                    stateOption18: {
                        id: 'stateOption18',
                        value: 'LA',
                    },
                    stateOption19: {
                        id: 'stateOption19',
                        value: 'MA',
                    },
                    stateOption20: {
                        id: 'stateOption20',
                        value: 'MD',
                    },
                    stateOption21: {
                        id: 'stateOption21',
                        value: 'ME',
                    },
                    stateOption22: {
                        id: 'stateOption22',
                        value: 'MI',
                    },
                    stateOption23: {
                        id: 'stateOption23',
                        value: 'MN',
                    },
                    stateOption24: {
                        id: 'stateOption24',
                        value: 'MO',
                    },
                    stateOption25: {
                        id: 'stateOption25',
                        value: 'MS',
                    },
                    stateOption26: {
                        id: 'stateOption26',
                        value: 'MT',
                    },
                    stateOption27: {
                        id: 'stateOption27',
                        value: 'NC',
                    },
                    stateOption28: {
                        id: 'stateOption28',
                        value: 'ND',
                    },
                    stateOption29: {
                        id: 'stateOption29',
                        value: 'NE',
                    },
                    stateOption30: {
                        id: 'stateOption30',
                        value: 'NH',
                    },
                    stateOption31: {
                        id: 'stateOption31',
                        value: 'NJ',
                    },
                    stateOption32: {
                        id: 'stateOption32',
                        value: 'NM',
                    },
                    stateOption33: {
                        id: 'stateOption33',
                        value: 'NV',
                    },
                    stateOption34: {
                        id: 'stateOption34',
                        value: 'NY',
                    },
                    stateOption35: {
                        id: 'stateOption35',
                        value: 'OH',
                    },
                    stateOption36: {
                        id: 'stateOption36',
                        value: 'OK',
                    },
                    stateOption37: {
                        id: 'stateOption37',
                        value: 'OR',
                    },
                    stateOption38: {
                        id: 'stateOption38',
                        value: 'PA',
                    },
                    stateOption39: {
                        id: 'stateOption39',
                        value: 'RI',
                    },
                    stateOption40: {
                        id: 'stateOption40',
                        value: 'SC',
                    },
                    stateOption41: {
                        id: 'stateOption41',
                        value: 'SD',
                    },
                    stateOption42: {
                        id: 'stateOption42',
                        value: 'TN',
                    },
                    stateOption43: {
                        id: 'stateOption43',
                        value: 'TX',
                    },
                    stateOption44: {
                        id: 'stateOption44',
                        value: 'UT',
                    },
                    stateOption45: {
                        id: 'stateOption45',
                        value: 'VT',
                    },
                    stateOption46: {
                        id: 'stateOption46',
                        value: 'VA',
                    },
                    stateOption47: {
                        id: 'stateOption47',
                        value: 'WA',
                    },
                    stateOption48: {
                        id: 'stateOption48',
                        value: 'WI',
                    },
                    stateOption49: {
                        id: 'stateOption49',
                        value: 'WV',
                    },
                    stateOption50: {
                        id: 'stateOption50',
                        value: 'WY',
                    },
                },
            },
        },
        tile: {
            turnInFormIntroText: {
                tags: ['auto/oem/lrfg/digital-turn-in'],
                id: 'turnInFormIntroText',
                variationId: null,
                title: {
                    html: '<p>LAND ROVER FINANCIAL GROUP</p>\n',
                    text: 'LAND ROVER FINANCIAL GROUP',
                    markdown: 'LAND ROVER FINANCIAL GROUP\n',
                },
                subTitle: {
                    html: '<p>DIGITAL LEASE TURN-IN FORM</p>\n',
                    text: 'DIGITAL LEASE TURN-IN FORM',
                    markdown: 'DIGITAL LEASE TURN-IN FORM\n',
                },
                version: '20240606',
                body: {
                    html: '<p>Complete this form within 1 business day of returning your vehicle to the dealer. That way, we can ensure that we have the correct date and information to calculate your lease-end bill, if you have one.<br>\n<br>\nIf you return your vehicle more than 30 days before the end of the lease term without purchasing it, you may have to pay a substantial charge which could be several thousand dollars. The actual charge will depend on when the lease is terminated, but the earlier you end the lease, the greater the charge is likely to be. For additional information, see the Early Termination section of your Lease Agreement. Please note that even after you terminate your lease, you may be billed for remaining property taxes, parking tickets, toll charges and other state or local fees due on your account.</p>\n',
                    text: 'Complete this form within 1 business day of returning your vehicle to the dealer. That way, we can ensure that we have the correct date and information to calculate your lease-end bill, if you have one.\n\nIf you return your vehicle more than 30 days before the end of the lease term without purchasing it, you may have to pay a substantial charge which could be several thousand dollars. The actual charge will depend on when the lease is terminated, but the earlier you end the lease, the greater the charge is likely to be. For additional information, see the Early Termination section of your Lease Agreement. Please note that even after you terminate your lease, you may be billed for remaining property taxes, parking tickets, toll charges and other state or local fees due on your account.',
                    markdown: 'Complete this form within 1 business day of returning your vehicle to the dealer. That way, we can ensure that we have the correct date and information to calculate your lease-end bill, if you have one.  \n\n<br />\n\n\nIf you return your vehicle more than 30 days before the end of the lease term without purchasing it, you may have to pay a substantial charge which could be several thousand dollars. The actual charge will depend on when the lease is terminated, but the earlier you end the lease, the greater the charge is likely to be. For additional information, see the Early Termination section of your Lease Agreement. Please note that even after you terminate your lease, you may be billed for remaining property taxes, parking tickets, toll charges and other state or local fees due on your account.\n',
                },
                additionalText: {
                    html: null,
                    text: null,
                    markdown: null,
                },
                hat: null,
                iconImage: {
                    altText: null,
                    id: 'logoImage',
                    source: null,
                    url: null,
                },
                primaryImage: {
                    altText: 'Land Rover',
                    id: null,
                    source: '/content/dam/unified-assets/logo/other-logos/land-rover/logo_landrover_financialgroup_H_asterisk_transparent.png',
                    url: 'https://static2-qa2.chasecdn.com/content/dam/unified-assets/logo/other-logos/land-rover/logo_landrover_financialgroup_H_asterisk_transparent.png',
                },
                primaryImageWithRendition: null,
                secondaryImageWithRendition: null,
                links: [],
            },
        },
        structuredContentList: {},
    },
};
