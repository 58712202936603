export const leaseEnd = {
    data: {
        linkList: {
            QuickLinksList: {
                id: 'QuickLinksList',
                tags: ['auto/oem/lrfg/leaseend'],
                version: '20240606',
                title: {
                    text: 'QUICK LINKS',
                },
                subTitle: {
                    text: null,
                },
                links: {
                    QuickLinkContent1: {
                        headerText: null,
                        value: 'WEAR AND USE',
                        type: null,
                        presentationType: null,
                        url: '/lease-end/return#wearanduse',
                        esUrl: null,
                        id: 'QuickLinkContent1',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: 'pl_le_wear_use',
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    QuickLinkContent2: {
                        headerText: null,
                        value: 'LOYALTY PROGRAM',
                        type: null,
                        presentationType: null,
                        url: '/lease-end/return#loyaltyprogram',
                        esUrl: null,
                        id: 'QuickLinkContent2',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: 'pl_le_loyalty',
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    QuickLinkContent3: {
                        headerText: null,
                        value: 'FAQs',
                        type: null,
                        presentationType: null,
                        url: '/faq',
                        esUrl: null,
                        id: 'QuickLinkContent3',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: 'pl_le_faq',
                        trackingType: null,
                        showSpeedBump: false,
                    },
                },
            },
        },
        carousel: {
            leaseEndExploreMoreTiles: {
                brands: null,
                segments: null,
                tags: ['auto/oem/lrfg/leaseend'],
                id: 'leaseEndExploreMoreTiles',
                title: {
                    html: '<p>EXPLORE MORE WITH LAND ROVER</p>\n',
                    text: 'EXPLORE MORE WITH LAND ROVER',
                    markdown: 'EXPLORE MORE WITH LAND ROVER\n',
                },
                subTitle: {
                    html: null,
                    text: null,
                    markdown: null,
                },
                version: '20240606',
                heroes: [],
                tiles: {
                    exploreMoreTile1: {
                        brands: null,
                        segments: null,
                        tags: ['ccb-cf:auto/oem/lrfg/leaseend'],
                        id: 'exploreMoreTile1',
                        variationId: null,
                        title: {
                            html: '<p>EXPLORE A NEW LAND ROVER</p>\n',
                            text: 'EXPLORE A NEW LAND ROVER',
                            markdown: 'EXPLORE A NEW LAND ROVER\n',
                        },
                        subTitle: {
                            html: null,
                            text: null,
                            markdown: null,
                        },
                        version: '20240606',
                        body: {
                            html: null,
                            text: null,
                            markdown: null,
                        },
                        additionalText: {
                            html: null,
                            text: null,
                            markdown: null,
                        },
                        hat: null,
                        iconImage: {
                            altText: null,
                            id: 'exploreMoreTile1Image',
                            source: null,
                            url: null,
                        },
                        primaryImage: {
                            altText: 'exploreANewLandRover',
                            id: null,
                            source: '/content/dam/unified-assets/photography/partner/land-rover/optimized/LeaseEnd_NewCard_Shading.webp',
                            url: 'https://static2-qa2.chasecdn.com/content/dam/unified-assets/photography/partner/land-rover/optimized/LeaseEnd_NewCard_Shading.webp',
                        },
                        primaryImageWithRendition: null,
                        secondaryImageWithRendition: null,
                        links: {
                            exploreMoreLink: {
                                value: null,
                                type: null,
                                languageCode: null,
                                disabled: null,
                                active: null,
                                underLined: null,
                                inversed: null,
                                presentationType: null,
                                url: '/lease-end/new',
                                esUrl: null,
                                id: 'exploreMoreLink',
                                hat: null,
                                navigationKeyId: null,
                                target: null,
                                iconId: null,
                                icon: null,
                                trackingId: 'pl_le_get_new',
                                trackingType: null,
                                showSpeedBump: null,
                                headerText: null,
                            },
                        },
                    },
                    exploreMoreTile2: {
                        brands: null,
                        segments: null,
                        tags: ['ccb-cf:auto/oem/lrfg/leaseend'],
                        id: 'exploreMoreTile2',
                        variationId: null,
                        title: {
                            html: '<p>EXTEND, PURCHASE OR EARLY TURN-IN</p>\n',
                            text: 'EXTEND, PURCHASE OR EARLY TURN-IN',
                            markdown: 'EXTEND, PURCHASE OR EARLY TURN-IN\n',
                        },
                        subTitle: {
                            html: null,
                            text: null,
                            markdown: null,
                        },
                        version: '20240606',
                        body: {
                            html: null,
                            text: null,
                            markdown: null,
                        },
                        additionalText: {
                            html: null,
                            text: null,
                            markdown: null,
                        },
                        hat: null,
                        iconImage: {
                            altText: null,
                            id: 'exploreMoreTile2Image',
                            source: null,
                            url: null,
                        },
                        primaryImage: {
                            altText: 'extendPurchaseorEarlyTurnIn',
                            id: null,
                            source: '/content/dam/unified-assets/photography/partner/land-rover/optimized/LeaseEnd_OtherCard_Shading.webp',
                            url: 'https://static2-qa2.chasecdn.com/content/dam/unified-assets/photography/partner/land-rover/optimized/LeaseEnd_OtherCard_Shading.webp',
                        },
                        primaryImageWithRendition: null,
                        secondaryImageWithRendition: null,
                        links: {
                            exploreMoreLink: {
                                value: null,
                                type: null,
                                languageCode: null,
                                disabled: null,
                                active: null,
                                underLined: null,
                                inversed: null,
                                presentationType: null,
                                url: '/lease-end/other',
                                esUrl: null,
                                id: 'exploreMoreLink',
                                hat: null,
                                navigationKeyId: null,
                                target: null,
                                iconId: null,
                                icon: null,
                                trackingId: 'pl_le_other',
                                trackingType: null,
                                showSpeedBump: null,
                                headerText: null,
                            },
                        },
                    },
                    exploreMoreTile3: {
                        brands: null,
                        segments: null,
                        tags: ['ccb-cf:auto/oem/lrfg/leaseend'],
                        id: 'exploreMoreTile3',
                        variationId: null,
                        title: {
                            html: '<p>RETURN YOUR LAND ROVER</p>\n',
                            text: 'RETURN YOUR LAND ROVER',
                            markdown: 'RETURN YOUR LAND ROVER\n',
                        },
                        subTitle: {
                            html: null,
                            text: null,
                            markdown: null,
                        },
                        version: '20240606',
                        body: {
                            html: null,
                            text: null,
                            markdown: null,
                        },
                        additionalText: {
                            html: null,
                            text: null,
                            markdown: null,
                        },
                        hat: null,
                        iconImage: {
                            altText: null,
                            id: 'exploreMoreTile3Image',
                            source: null,
                            url: null,
                        },
                        primaryImage: {
                            altText: 'returnYourLandRover',
                            id: null,
                            source: '/content/dam/unified-assets/photography/partner/land-rover/optimized/LeaseEnd_ReturnCard_Shading.webp',
                            url: 'https://static2-qa2.chasecdn.com/content/dam/unified-assets/photography/partner/land-rover/optimized/LeaseEnd_ReturnCard_Shading.webp',
                        },
                        primaryImageWithRendition: null,
                        secondaryImageWithRendition: null,
                        links: {
                            exploreMoreLink: {
                                value: null,
                                type: null,
                                languageCode: null,
                                disabled: null,
                                active: null,
                                underLined: null,
                                inversed: null,
                                presentationType: null,
                                url: '/lease-end/return',
                                esUrl: null,
                                id: 'exploreMoreLink',
                                hat: null,
                                navigationKeyId: null,
                                target: null,
                                iconId: null,
                                icon: null,
                                trackingId: 'pl_le_return',
                                trackingType: null,
                                showSpeedBump: null,
                                headerText: null,
                            },
                        },
                    },
                },
            },
        },
        iconList: {
            leasePageIcons: {
                tags: ['auto/oem/lrfg/leaseend'],
                id: 'leasePageIcons',
                title: {
                    html: null,
                    text: null,
                    markdown: null,
                },
                subTitle: {
                    html: null,
                    text: null,
                    markdown: null,
                },
                version: '20240606',
                icons: {
                    rightArrowWhiteIcon: {
                        id: 'rightArrowWhiteIcon',
                        text: 'rightArrowWhiteIcon',
                        image: {
                            altText: null,
                            id: null,
                            source: '/content/dam/unified-assets/iconography/auto/chevron/icon_auto_chevron_white_pointing_right.svg',
                            url: 'https://static2-qa2.chasecdn.com/content/dam/unified-assets/iconography/auto/chevron/icon_auto_chevron_white_pointing_right.svg',
                        },
                        primaryImage: null,
                        link: {
                            value: null,
                            type: null,
                            languageCode: null,
                            disabled: null,
                            active: null,
                            underLined: null,
                            inversed: null,
                            presentationType: null,
                            url: null,
                            esUrl: null,
                            id: null,
                            hat: null,
                            navigationKeyId: null,
                            target: null,
                            iconId: null,
                            icon: null,
                            trackingId: null,
                            trackingType: null,
                            showSpeedBump: null,
                            headerText: null,
                        },
                    },
                    rightArrowBlackIcon: {
                        id: 'rightArrowBlackIcon',
                        text: 'rightArrowBlackIcon',
                        image: {
                            altText: null,
                            id: null,
                            source: 'rightArrowBlackIconImage',
                            url: 'https://static2-qa2.chasecdn.comrightArrowBlackIconImage',
                        },
                        primaryImage: null,
                        link: {
                            value: null,
                            type: null,
                            languageCode: null,
                            disabled: null,
                            active: null,
                            underLined: null,
                            inversed: null,
                            presentationType: null,
                            url: null,
                            esUrl: null,
                            id: null,
                            hat: null,
                            navigationKeyId: null,
                            target: null,
                            iconId: null,
                            icon: null,
                            trackingId: null,
                            trackingType: null,
                            showSpeedBump: null,
                            headerText: null,
                        },
                    },
                },
            },
        },
        legalContainer: {},
        dialog: {},
        contentList: {},
        tile: {
            leaseEndOptions: {
                tags: ['auto/oem/lrfg/leaseend'],
                id: 'leaseEndOptions',
                variationId: null,
                title: {
                    html: '<p>LEASE END OPTIONS</p>\n',
                    text: 'LEASE END OPTIONS',
                    markdown: 'LEASE END OPTIONS\n',
                },
                subTitle: {
                    html: null,
                    text: null,
                    markdown: null,
                },
                version: '20240502',
                body: {
                    html: null,
                    text: null,
                    markdown: null,
                },
                additionalText: {
                    html: null,
                    text: null,
                    markdown: null,
                },
                hat: null,
                iconImage: {
                    altText: null,
                    id: 'leaseendOptionsImage',
                    source: null,
                    url: null,
                },
                primaryImage: {
                    altText: 'Leaseend Options',
                    id: null,
                    source: null,
                    url: null,
                },
                primaryImageWithRendition: null,
                secondaryImageWithRendition: null,
                links: {
                    lrfgCarouselLinkText: {
                        value: 'MANAGE YOUR ACCOUNT',
                        type: null,
                        languageCode: null,
                        disabled: null,
                        active: null,
                        underLined: null,
                        inversed: null,
                        presentationType: null,
                        url: 'https://qac3-secure01ea.chase.com/web/auth/dashboard?navKey=startVastNavigation&jp_cdf=LRFG_LOGIN&context=VAST_LANDROVER&treatment=LANDROVER&financialBrandName=Land+Rover+Financial+Group&thirdParty=true&landingScreen=VAST_DEFAULT',
                        esUrl: null,
                        id: 'lrfgCarouselLinkText',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: null,
                        headerText: null,
                        visibleIn: null,
                        variant: null,
                    },
                },
            },
            timeForNewLease: {
                tags: ['auto/oem/lrfg/leaseend'],
                id: 'timeForNewLease',
                variationId: null,
                title: {
                    html: '<p>TIME FOR A NEW LEASE?</p>\n',
                    text: 'TIME FOR A NEW LEASE?',
                    markdown: 'TIME FOR A NEW LEASE?\n',
                },
                subTitle: {
                    html: '<p>REVIEW YOUR ACCOUNT DETAILS AND NEXT STEPS FOR YOUR LAND ROVER</p>\n',
                    text: 'REVIEW YOUR ACCOUNT DETAILS AND NEXT STEPS FOR YOUR LAND ROVER',
                    markdown: 'REVIEW YOUR ACCOUNT DETAILS AND NEXT STEPS FOR YOUR LAND ROVER\n',
                },
                version: '20240606',
                body: {
                    html: null,
                    text: null,
                    markdown: null,
                },
                additionalText: {
                    html: null,
                    text: null,
                    markdown: null,
                },
                hat: null,
                iconImage: {
                    altText: null,
                    id: 'leaseendOptionsImage',
                    source: null,
                    url: null,
                },
                primaryImage: {
                    altText: 'Leaseend Options',
                    id: null,
                    source: '/content/dam/unified-assets/photography/partner/land-rover/optimized/LeaseEnd_hero_Desktop1.jpg',
                    url: 'https://static2-qa2.chasecdn.com/content/dam/unified-assets/photography/partner/land-rover/optimized/LeaseEnd_hero_Desktop1.jpg',
                },
                primaryImageWithRendition: null,
                secondaryImageWithRendition: null,
                links: {
                    carouselLinkText: {
                        value: 'MANAGE YOUR ACCOUNT',
                        type: null,
                        languageCode: null,
                        disabled: null,
                        active: null,
                        underLined: null,
                        inversed: null,
                        presentationType: null,
                        url: '/myaccount',
                        esUrl: null,
                        id: 'lrfgCarouselLinkText',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: 'pl_le_manage',
                        trackingType: null,
                        showSpeedBump: null,
                        headerText: null,
                        visibleIn: null,
                        variant: null,
                    },
                },
            },
        },
        structuredContentList: {},
    },
};
