export const homePage = {
    data: {
        linkList: {},
        carousel: {
            exploreMoreTiles: {
                brands: null,
                segments: null,
                tags: ['auto/oem/lrfg/homepage'],
                id: 'exploreMoreTiles',
                title: {
                    html: '<p>EXPLORE MORE WITH LAND ROVER</p>\n',
                    text: 'EXPLORE MORE WITH LAND ROVER',
                    markdown: 'EXPLORE MORE WITH LAND ROVER\n',
                },
                subTitle: {
                    html: null,
                    text: null,
                    markdown: null,
                },
                version: '20240606',
                heroes: [],
                tiles: {
                    payAndManageTile: {
                        brands: null,
                        segments: null,
                        tags: [
                            'ccb-cf:auto/finance-and-drive/oem/lrfg',
                            'ccb-cf:auto/oem/lrfg/homepage',
                        ],
                        id: 'payAndManageTile',
                        variationId: null,
                        title: {
                            html: '<p>PAY &amp; MANAGE</p>\n',
                            text: 'PAY & MANAGE',
                            markdown: 'PAY \\& MANAGE\n',
                        },
                        subTitle: {
                            html: '<p>Manage your vehicle and get account details.</p>\n',
                            text: 'Manage your vehicle and get account details.',
                            markdown: 'Manage your vehicle and get account details.\n',
                        },
                        version: '20240606',
                        body: {
                            html: null,
                            text: null,
                            markdown: null,
                        },
                        additionalText: {
                            html: null,
                            text: null,
                            markdown: null,
                        },
                        hat: null,
                        iconImage: {
                            altText: null,
                            id: 'payAndManageImage',
                            source: null,
                            url: null,
                        },
                        primaryImage: {
                            altText: 'payAndManage',
                            id: null,
                            source: '/content/dam/unified-assets/photography/partner/land-rover/optimized/Pay_manage_desktop.jpg',
                            url: 'https://static2-qa2.chasecdn.com/content/dam/unified-assets/photography/partner/land-rover/optimized/Pay_manage_desktop.jpg',
                        },
                        primaryImageWithRendition: null,
                        secondaryImageWithRendition: null,
                        links: {
                            exploreMoreLink: {
                                value: 'MANAGE MY ACCOUNT',
                                type: null,
                                languageCode: null,
                                disabled: null,
                                active: null,
                                underLined: null,
                                inversed: null,
                                presentationType: null,
                                url: 'https://secure.chase.com/web/auth/dashboard?navKey=requestOverviewDashboard&jp_cdf=LRFG_MA_PMT',
                                esUrl: null,
                                id: 'exploreMoreLink',
                                hat: null,
                                navigationKeyId: null,
                                target: null,
                                iconId: null,
                                icon: null,
                                trackingId: null,
                                trackingType: null,
                                showSpeedBump: null,
                                headerText: null,
                            },
                        },
                    },
                    financeAndOfferTile: {
                        brands: null,
                        segments: null,
                        tags: [
                            'ccb-cf:auto/finance-and-drive/oem/lrfg',
                            'ccb-cf:auto/oem/lrfg/homepage',
                        ],
                        id: 'financeAndOfferTile',
                        variationId: null,
                        title: {
                            html: '<p>FINANCING &amp; OFFERS</p>\n',
                            text: 'FINANCING & OFFERS',
                            markdown: 'FINANCING \\& OFFERS\n',
                        },
                        subTitle: {
                            html: '<p>Apply online with Land Rover Financial Group—it’s quick, convenient, and secure.</p>\n',
                            text: 'Apply online with Land Rover Financial Group—it’s quick, convenient, and secure.',
                            markdown: "Apply online with Land Rover Financial Group---it's quick, convenient, and secure.\n",
                        },
                        version: '20240606',
                        body: {
                            html: null,
                            text: null,
                            markdown: null,
                        },
                        additionalText: {
                            html: null,
                            text: null,
                            markdown: null,
                        },
                        hat: null,
                        iconImage: {
                            altText: null,
                            id: 'financeAndOfferTileImage',
                            source: null,
                            url: null,
                        },
                        primaryImage: {
                            altText: 'financeAndOffer',
                            id: null,
                            source: '/content/dam/unified-assets/photography/partner/land-rover/optimized/Financing_desktop.jpg',
                            url: 'https://static2-qa2.chasecdn.com/content/dam/unified-assets/photography/partner/land-rover/optimized/Financing_desktop.jpg',
                        },
                        primaryImageWithRendition: null,
                        secondaryImageWithRendition: null,
                        links: {
                            exploreMoreLink: {
                                value: 'GET STARTED',
                                type: null,
                                languageCode: null,
                                disabled: null,
                                active: null,
                                underLined: null,
                                inversed: null,
                                presentationType: null,
                                url: '/financing ',
                                esUrl: null,
                                id: 'exploreMoreLink',
                                hat: null,
                                navigationKeyId: null,
                                target: null,
                                iconId: null,
                                icon: null,
                                trackingId: null,
                                trackingType: null,
                                showSpeedBump: null,
                                headerText: null,
                            },
                        },
                    },
                    extendYourLeaseTile: {
                        brands: null,
                        segments: null,
                        tags: [
                            'ccb-cf:auto/finance-and-drive/oem/lrfg',
                            'ccb-cf:auto/oem/lrfg/homepage',
                        ],
                        id: 'extendYourLeaseTile',
                        variationId: null,
                        title: {
                            html: '<p>EXTEND YOUR LEASE</p>\n',
                            text: 'EXTEND YOUR LEASE',
                            markdown: 'EXTEND YOUR LEASE\n',
                        },
                        subTitle: {
                            html: '<p>You may be eligible to extend your current lease.</p>\n',
                            text: 'You may be eligible to extend your current lease.',
                            markdown: 'You may be eligible to extend your current lease.\n',
                        },
                        version: '20240606',
                        body: {
                            html: null,
                            text: null,
                            markdown: null,
                        },
                        additionalText: {
                            html: null,
                            text: null,
                            markdown: null,
                        },
                        hat: null,
                        iconImage: {
                            altText: null,
                            id: 'extendYourLeaseTileImage',
                            source: null,
                            url: null,
                        },
                        primaryImage: {
                            altText: 'extendYourLease',
                            id: null,
                            source: '/content/dam/unified-assets/photography/partner/land-rover/optimized/Extend_desktop.jpg',
                            url: 'https://static2-qa2.chasecdn.com/content/dam/unified-assets/photography/partner/land-rover/optimized/Extend_desktop.jpg',
                        },
                        primaryImageWithRendition: null,
                        secondaryImageWithRendition: null,
                        links: {
                            exploreMoreLink: {
                                value: 'LEARN MORE',
                                type: null,
                                languageCode: null,
                                disabled: null,
                                active: null,
                                underLined: null,
                                inversed: null,
                                presentationType: null,
                                url: '/lease-end/other#extend-your-lease',
                                esUrl: null,
                                id: 'exploreMoreLink',
                                hat: null,
                                navigationKeyId: null,
                                target: null,
                                iconId: null,
                                icon: null,
                                trackingId: null,
                                trackingType: null,
                                showSpeedBump: null,
                                headerText: null,
                            },
                        },
                    },
                },
            },
            homePageCarouselList: {
                brands: null,
                segments: null,
                tags: ['auto/oem/lrfg/homepage'],
                id: 'homePageCarouselList',
                title: {
                    html: null,
                    text: null,
                    markdown: null,
                },
                subTitle: {
                    html: null,
                    text: null,
                    markdown: null,
                },
                version: '20240606',
                heroes: [],
                tiles: {
                    rangeRoverSport: {
                        brands: null,
                        segments: null,
                        tags: ['ccb-cf:auto/oem/lrfg/homepage'],
                        id: 'rangeRoverSport',
                        variationId: null,
                        title: {
                            html: '<p>RANGE ROVER SPORT</p>\n',
                            text: 'RANGE ROVER SPORT',
                            markdown: 'RANGE ROVER SPORT\n',
                        },
                        subTitle: {
                            html: null,
                            text: null,
                            markdown: null,
                        },
                        version: '20240606',
                        body: {
                            html: null,
                            text: null,
                            markdown: null,
                        },
                        additionalText: {
                            html: null,
                            text: null,
                            markdown: null,
                        },
                        hat: null,
                        iconImage: {
                            altText: 'rangeRoverSport',
                            id: 'rangeRoverSportImage',
                            source: null,
                            url: null,
                        },
                        primaryImage: {
                            altText: null,
                            id: null,
                            source: '/content/dam/unified-assets/photography/partner/land-rover/optimized/L663_23_5MY_105_US_130_FE_ALT_DX_Desktop1.jpg',
                            url: 'https://static2-qa2.chasecdn.com/content/dam/unified-assets/photography/partner/land-rover/optimized/L663_23_5MY_105_US_130_FE_ALT_DX_Desktop1.jpg',
                        },
                        primaryImageWithRendition: null,
                        secondaryImageWithRendition: null,
                        links: {
                            homePageCarouselLinkText: {
                                value: 'EXPLORE THIS VEHICLE',
                                type: null,
                                languageCode: null,
                                disabled: null,
                                active: null,
                                underLined: null,
                                inversed: null,
                                presentationType: null,
                                url: 'https://www.landroverusa.com/range-rover/range-rover-sport/index.html',
                                esUrl: null,
                                id: 'homePageCarouselLinkText',
                                hat: null,
                                navigationKeyId: 'abc1',
                                target: null,
                                iconId: null,
                                icon: null,
                                trackingId: 'pl_exp_rr_sport',
                                trackingType: null,
                                showSpeedBump: null,
                                headerText: null,
                            },
                        },
                    },
                    defender: {
                        brands: null,
                        segments: null,
                        tags: ['ccb-cf:auto/oem/lrfg/homepage'],
                        id: 'defender',
                        variationId: null,
                        title: {
                            html: '<p>DEFENDER</p>\n',
                            text: 'DEFENDER',
                            markdown: 'DEFENDER\n',
                        },
                        subTitle: {
                            html: null,
                            text: null,
                            markdown: null,
                        },
                        version: '20240606',
                        body: {
                            html: null,
                            text: null,
                            markdown: null,
                        },
                        additionalText: {
                            html: null,
                            text: null,
                            markdown: null,
                        },
                        hat: null,
                        iconImage: {
                            altText: null,
                            id: 'defenderImage',
                            source: null,
                            url: null,
                        },
                        primaryImage: {
                            altText: 'defender',
                            id: null,
                            source: '/content/dam/unified-assets/photography/partner/land-rover/optimized/L663_23_5MY_097_US_1_Desktop1.jpg',
                            url: 'https://static2-qa2.chasecdn.com/content/dam/unified-assets/photography/partner/land-rover/optimized/L663_23_5MY_097_US_1_Desktop1.jpg',
                        },
                        primaryImageWithRendition: null,
                        secondaryImageWithRendition: null,
                        links: {
                            homePageCarouselLinkText: {
                                value: 'EXPLORE DEFENDER',
                                type: null,
                                languageCode: null,
                                disabled: null,
                                active: null,
                                underLined: null,
                                inversed: null,
                                presentationType: null,
                                url: 'https://www.landroverusa.com/defender/index.html',
                                esUrl: null,
                                id: 'homePageCarouselLinkText',
                                hat: null,
                                navigationKeyId: 'abc2',
                                target: null,
                                iconId: null,
                                icon: null,
                                trackingId: 'pl_exp_def',
                                trackingType: null,
                                showSpeedBump: null,
                                headerText: null,
                            },
                        },
                    },
                    defender130: {
                        brands: null,
                        segments: null,
                        tags: ['ccb-cf:auto/oem/lrfg/homepage'],
                        id: 'defender130',
                        variationId: null,
                        title: {
                            html: '<p>DEFENDER 130</p>\n',
                            text: 'DEFENDER 130',
                            markdown: 'DEFENDER 130\n',
                        },
                        subTitle: {
                            html: null,
                            text: null,
                            markdown: null,
                        },
                        version: '20240606',
                        body: {
                            html: null,
                            text: null,
                            markdown: null,
                        },
                        additionalText: {
                            html: null,
                            text: null,
                            markdown: null,
                        },
                        hat: null,
                        iconImage: {
                            altText: null,
                            id: 'defender130Image',
                            source: null,
                            url: null,
                        },
                        primaryImage: {
                            altText: 'defender130',
                            id: null,
                            source: '/content/dam/unified-assets/photography/partner/land-rover/optimized/L663_23_5MY_105_US_130_FE_ALT_DX_Desktop1.jpg',
                            url: 'https://static2-qa2.chasecdn.com/content/dam/unified-assets/photography/partner/land-rover/optimized/L663_23_5MY_105_US_130_FE_ALT_DX_Desktop1.jpg',
                        },
                        primaryImageWithRendition: null,
                        secondaryImageWithRendition: null,
                        links: {
                            homePageCarouselLinkText: {
                                value: 'EXPLORE DEFENDER 130',
                                type: null,
                                languageCode: null,
                                disabled: null,
                                active: null,
                                underLined: null,
                                inversed: null,
                                presentationType: null,
                                url: 'https://www.landroverusa.com/defender/defender-130/index.html',
                                esUrl: null,
                                id: 'homePageCarouselLinkText',
                                hat: null,
                                navigationKeyId: 'abc3',
                                target: null,
                                iconId: null,
                                icon: null,
                                trackingId: 'pl_exp_def_130',
                                trackingType: null,
                                showSpeedBump: null,
                                headerText: null,
                            },
                        },
                    },
                    rangeRover: {
                        brands: null,
                        segments: null,
                        tags: [
                            'ccb-cf:auto/finance-and-drive/oem/lrfg',
                            'ccb-cf:auto/oem/lrfg/homepage',
                        ],
                        id: 'rangeRover',
                        variationId: null,
                        title: {
                            html: '<p>RANGE ROVER</p>\n',
                            text: 'RANGE ROVER',
                            markdown: 'RANGE ROVER\n',
                        },
                        subTitle: {
                            html: null,
                            text: null,
                            markdown: null,
                        },
                        version: '20240606',
                        body: {
                            html: null,
                            text: null,
                            markdown: null,
                        },
                        additionalText: {
                            html: null,
                            text: null,
                            markdown: null,
                        },
                        hat: null,
                        iconImage: {
                            altText: null,
                            id: 'rangeRoverImage',
                            source: null,
                            url: null,
                        },
                        primaryImage: {
                            altText: 'rangeRover',
                            id: null,
                            source: '/content/dam/unified-assets/photography/partner/land-rover/optimized/L460_22MY_104_US_DX_Desktop1.jpg',
                            url: 'https://static2-qa2.chasecdn.com/content/dam/unified-assets/photography/partner/land-rover/optimized/L460_22MY_104_US_DX_Desktop1.jpg',
                        },
                        primaryImageWithRendition: null,
                        secondaryImageWithRendition: null,
                        links: {
                            homePageCarouselLinkText: {
                                value: 'EXPLORE RANGE ROVER',
                                type: null,
                                languageCode: null,
                                disabled: null,
                                active: null,
                                underLined: null,
                                inversed: null,
                                presentationType: null,
                                url: 'https://www.landroverusa.com/range-rover/range-rover/index.html',
                                esUrl: null,
                                id: 'homePageCarouselLinkText',
                                hat: null,
                                navigationKeyId: null,
                                target: null,
                                iconId: null,
                                icon: null,
                                trackingId: 'pl_exp_rr',
                                trackingType: null,
                                showSpeedBump: null,
                                headerText: null,
                            },
                        },
                    },
                },
            },
        },
        iconList: {
            arrowIcons: {
                tags: ['auto/oem/lrfg/homepage'],
                id: 'arrowIcons',
                title: {
                    html: null,
                    text: null,
                    markdown: null,
                },
                subTitle: {
                    html: null,
                    text: null,
                    markdown: null,
                },
                version: '20240502',
                icons: {
                    leftArrowIcon: {
                        id: 'leftArrowIcon',
                        text: 'leftArrowIcon',
                        image: {
                            altText: null,
                            id: null,
                            source: '/content/dam/unified-assets/iconography/auto/chevron/ico_auto_chevron_left_carousel.svg',
                            url: 'https://static2-qa2.chasecdn.com/content/dam/unified-assets/iconography/auto/chevron/ico_auto_chevron_left_carousel.svg',
                        },
                        primaryImage: null,
                        link: {
                            value: null,
                            type: null,
                            languageCode: null,
                            disabled: null,
                            active: null,
                            underLined: null,
                            inversed: null,
                            presentationType: null,
                            url: null,
                            esUrl: null,
                            id: null,
                            hat: null,
                            navigationKeyId: null,
                            target: null,
                            iconId: null,
                            icon: null,
                            trackingId: null,
                            trackingType: null,
                            showSpeedBump: null,
                            headerText: null,
                        },
                    },
                    rightArrowIcon: {
                        id: 'rightArrowIcon',
                        text: 'rightArrowIcon',
                        image: {
                            altText: null,
                            id: null,
                            source: '/content/dam/unified-assets/iconography/auto/chevron/ico_auto_chevron_right_carousel.svg',
                            url: 'https://static2-qa2.chasecdn.com/content/dam/unified-assets/iconography/auto/chevron/ico_auto_chevron_right_carousel.svg',
                        },
                        primaryImage: null,
                        link: {
                            value: null,
                            type: null,
                            languageCode: null,
                            disabled: null,
                            active: null,
                            underLined: null,
                            inversed: null,
                            presentationType: null,
                            url: null,
                            esUrl: null,
                            id: null,
                            hat: null,
                            navigationKeyId: null,
                            target: null,
                            iconId: null,
                            icon: null,
                            trackingId: null,
                            trackingType: null,
                            showSpeedBump: null,
                            headerText: null,
                        },
                    },
                    greenArrowIcon: {
                        id: 'greenArrowIcon',
                        text: 'greenArrowIcon',
                        image: {
                            altText: null,
                            id: null,
                            source: '/content/dam/unified-assets/iconography/auto/chevron/icon_auto_chevron_green_right.svg',
                            url: 'https://static2-qa2.chasecdn.com/content/dam/unified-assets/iconography/auto/chevron/icon_auto_chevron_green_right.svg',
                        },
                        primaryImage: null,
                        link: {
                            value: null,
                            type: null,
                            languageCode: null,
                            disabled: null,
                            active: null,
                            underLined: null,
                            inversed: null,
                            presentationType: null,
                            url: null,
                            esUrl: null,
                            id: null,
                            hat: null,
                            navigationKeyId: null,
                            target: null,
                            iconId: null,
                            icon: null,
                            trackingId: null,
                            trackingType: null,
                            showSpeedBump: null,
                            headerText: null,
                        },
                    },
                    arrowIconWhiteDown: {
                        id: 'arrowIconWhiteDown',
                        text: 'arrowIconWhiteDown',
                        image: {
                            altText: null,
                            id: null,
                            source: '/content/dam/unified-assets/iconography/auto/chevron/icon_auto_chevron_white_down.svg',
                            url: 'https://static2-qa2.chasecdn.com/content/dam/unified-assets/iconography/auto/chevron/icon_auto_chevron_white_down.svg',
                        },
                        primaryImage: null,
                        link: {
                            value: null,
                            type: null,
                            languageCode: null,
                            disabled: null,
                            active: null,
                            underLined: null,
                            inversed: null,
                            presentationType: null,
                            url: null,
                            esUrl: null,
                            id: null,
                            hat: null,
                            navigationKeyId: null,
                            target: null,
                            iconId: null,
                            icon: null,
                            trackingId: null,
                            trackingType: null,
                            showSpeedBump: null,
                            headerText: null,
                        },
                    },
                    arrowWhitePointingRight: {
                        id: 'arrowWhitePointingRight',
                        text: 'arrowWhitePointingRight',
                        image: {
                            altText: null,
                            id: null,
                            source: '/content/dam/unified-assets/iconography/auto/chevron/icon_auto_chevron_white_pointing_right.svg',
                            url: 'https://static2-qa2.chasecdn.com/content/dam/unified-assets/iconography/auto/chevron/icon_auto_chevron_white_pointing_right.svg',
                        },
                        primaryImage: null,
                        link: {
                            value: null,
                            type: null,
                            languageCode: null,
                            disabled: null,
                            active: null,
                            underLined: null,
                            inversed: null,
                            presentationType: null,
                            url: null,
                            esUrl: null,
                            id: null,
                            hat: null,
                            navigationKeyId: null,
                            target: null,
                            iconId: null,
                            icon: null,
                            trackingId: null,
                            trackingType: null,
                            showSpeedBump: null,
                            headerText: null,
                        },
                    },
                },
            },
        },
        legalContainer: {},
        dialog: {},
        contentList: {},
        tile: {},
        structuredContentList: {},
    },
};
