export const preApprovalData = {
    data: {
        linkList: {
            preApprovedOfferList: {
                id: 'preApprovedOfferList',
                tags: ['auto/oem/lrfg/preapprovalpage'],
                version: '20240606',
                title: {
                    text: 'TO TAKE ADVANTAGE OF YOUR LAND ROVER FINANCIAL GROUP PRE-APPROVED OFFER:',
                },
                subTitle: {
                    text: 'Eligibility subject to change after <1>{{date}}</1>.',
                },
                links: {
                    preApprovedOfferList1: {
                        headerText: null,
                        value: 'Choose a new Land Rover online or at a retailer.',
                        type: null,
                        presentationType: null,
                        url: '#termsAndConditionsOption1',
                        esUrl: null,
                        id: 'preApprovedOfferList1',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    contactRetailer: {
                        headerText: 'sup',
                        value: 'Contact your retailer for a test drive and tell them your application is pre-approved by Land Rover Financial Group. <1>{{contactRetailer}}</1>',
                        type: null,
                        presentationType: null,
                        url: '#termsAndConditionsOption2',
                        esUrl: 'contactRetailer',
                        id: 'contactRetailer',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: 'preOfferPageLinks',
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                },
            },
            preOfferPageLinks: {
                id: 'preOfferPageLinks',
                tags: ['auto/oem/lrfg/preapprovalpage'],
                version: '20240606',
                title: {
                    text: null,
                },
                subTitle: {
                    text: null,
                },
                links: {
                    newLandRover: {
                        headerText: 'sup',
                        value: '1',
                        type: null,
                        presentationType: null,
                        url: '#termsAndConditionsOption1',
                        esUrl: null,
                        id: 'newLandRover',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    contactRetailer: {
                        headerText: 'sup',
                        value: '2',
                        type: null,
                        presentationType: null,
                        url: '#termsAndConditionsOption2',
                        esUrl: null,
                        id: 'contactRetailer',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                },
            },
            quickLinksList: {
                id: 'quickLinksList',
                tags: ['auto/oem/lrfg/preapprovalpage'],
                version: '20240502',
                title: {
                    text: 'QUICK LINKS',
                },
                subTitle: {
                    text: null,
                },
                links: {
                    shopForNewLandRover: {
                        headerText: null,
                        value: 'SHOP FOR A NEW LAND ROVER',
                        type: null,
                        presentationType: null,
                        url: 'https://inventory.landroverusa.com/new/?_ga=2.148564215.192554163.1658508583-96110766.1658508583',
                        esUrl: null,
                        id: 'shopForNewLandRover',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                    manageYourAccountLink: {
                        headerText: null,
                        value: 'MANAGE YOUR ACCOUNT',
                        type: null,
                        presentationType: null,
                        url: 'https://lrfg-uat.chase.com/oem/myaccount',
                        esUrl: null,
                        id: 'manageYourAccountLink',
                        hat: null,
                        navigationKeyId: null,
                        target: null,
                        iconId: null,
                        icon: null,
                        trackingId: null,
                        trackingType: null,
                        showSpeedBump: false,
                    },
                },
            },
        },
        carousel: {},
        iconList: {
            preApprovedOfferIconList: {
                tags: ['auto/oem/lrfg/preapprovalpage'],
                id: 'preApprovedOfferIconList',
                title: {
                    html: null,
                    text: null,
                    markdown: null,
                },
                subTitle: {
                    html: null,
                    text: null,
                    markdown: null,
                },
                version: '20240606',
                icons: {
                    findaLandRoverRetailerIcon: {
                        id: 'findaLandRoverRetailerIcon',
                        text: 'Find A Land Rover Retailer',
                        image: {
                            altText: null,
                            id: null,
                            source: '/content/dam/unified-assets/iconography/auto/ico_auto_location.svg',
                            url: 'https://static2-qa2.chasecdn.com/content/dam/unified-assets/iconography/auto/ico_auto_location.svg',
                        },
                        primaryImage: null,
                        link: {
                            value: 'Find A Land Rover Retailer',
                            type: null,
                            languageCode: null,
                            disabled: null,
                            active: null,
                            underLined: null,
                            inversed: null,
                            presentationType: null,
                            url: null,
                            esUrl: null,
                            id: null,
                            hat: null,
                            navigationKeyId: null,
                            target: null,
                            iconId: null,
                            icon: null,
                            trackingId: null,
                            trackingType: null,
                            showSpeedBump: null,
                            headerText: null,
                        },
                    },
                    faqIcon: {
                        id: 'faqIcon',
                        text: 'FAQ',
                        image: {
                            altText: null,
                            id: null,
                            source: '/content/dam/unified-assets/iconography/auto/ico_auto_question_mark_circle.svg',
                            url: 'https://static2-qa2.chasecdn.com/content/dam/unified-assets/iconography/auto/ico_auto_question_mark_circle.svg',
                        },
                        primaryImage: null,
                        link: {
                            value: 'FAQ',
                            type: null,
                            languageCode: null,
                            disabled: null,
                            active: null,
                            underLined: null,
                            inversed: null,
                            presentationType: null,
                            url: null,
                            esUrl: null,
                            id: null,
                            hat: null,
                            navigationKeyId: null,
                            target: null,
                            iconId: null,
                            icon: null,
                            trackingId: null,
                            trackingType: null,
                            showSpeedBump: null,
                            headerText: null,
                        },
                    },
                    contactUs: {
                        id: 'contactUs',
                        text: 'CONTACT US',
                        image: {
                            altText: null,
                            id: null,
                            source: '/content/dam/unified-assets/iconography/auto/ico_auto_people_group.svg',
                            url: 'https://static2-qa2.chasecdn.com/content/dam/unified-assets/iconography/auto/ico_auto_people_group.svg',
                        },
                        primaryImage: null,
                        link: {
                            value: 'CONTACT US',
                            type: null,
                            languageCode: null,
                            disabled: null,
                            active: null,
                            underLined: null,
                            inversed: null,
                            presentationType: null,
                            url: null,
                            esUrl: null,
                            id: null,
                            hat: null,
                            navigationKeyId: null,
                            target: null,
                            iconId: null,
                            icon: null,
                            trackingId: null,
                            trackingType: null,
                            showSpeedBump: null,
                            headerText: null,
                        },
                    },
                },
            },
        },
        legalContainer: {
            termsAndConditions: {
                brands: null,
                segments: null,
                tags: ['auto/oem/lrfg/preapprovalpage'],
                id: 'termsAndConditions',
                title: {
                    html: null,
                    text: null,
                    markdown: null,
                },
                subTitle: {
                    html: null,
                    text: null,
                    markdown: null,
                },
                disclaimer: [],
                disclosure: [],
                footNote: {
                    termsAndConditionsOption1: {
                        id: 'termsAndConditionsOption1',
                        title: {
                            html: "<p><sup>1</sup>&nbsp;Information from a credit bureau was not used in connection with this preapproved offer. You were selected for this preapproval offer based on your current Land Rover Financial Group (LRFG) account(s) being in good standing. When you apply through your retailer for LRFG financing, LRFG will honor these terms provided your financing meets LRFG's guidelines, and you and your co-applicant, if applicable, also meet LRFG's credit criteria. LRFG will use a credit report to make a final decision on your application. You must be a U.S. resident. Used and certified pre owned vehicles excluded. Your new financing will be subject to minimum and maximum dollar amounts and payment periods. Eligibility subject to change. The offer period ends March 27, 2024, but may be renewed if preapproval criteria continue to be met. The dollar amount shown is the maximum amount that LRFG will finance toward the lease or purchase.</p>\n",
                            text: "1 Information from a credit bureau was not used in connection with this preapproved offer. You were selected for this preapproval offer based on your current Land Rover Financial Group (LRFG) account(s) being in good standing. When you apply through your retailer for LRFG financing, LRFG will honor these terms provided your financing meets LRFG's guidelines, and you and your co-applicant, if applicable, also meet LRFG's credit criteria. LRFG will use a credit report to make a final decision on your application. You must be a U.S. resident. Used and certified pre owned vehicles excluded. Your new financing will be subject to minimum and maximum dollar amounts and payment periods. Eligibility subject to change. The offer period ends March 27, 2024, but may be renewed if preapproval criteria continue to be met. The dollar amount shown is the maximum amount that LRFG will finance toward the lease or purchase.",
                            markdown: "^1^ Information from a credit bureau was not used in connection with this preapproved offer. You were selected for this preapproval offer based on your current Land Rover Financial Group (LRFG) account(s) being in good standing. When you apply through your retailer for LRFG financing, LRFG will honor these terms provided your financing meets LRFG's guidelines, and you and your co-applicant, if applicable, also meet LRFG's credit criteria. LRFG will use a credit report to make a final decision on your application. You must be a U.S. resident. Used and certified pre owned vehicles excluded. Your new financing will be subject to minimum and maximum dollar amounts and payment periods. Eligibility subject to change. The offer period ends March 27, 2024, but may be renewed if preapproval criteria continue to be met. The dollar amount shown is the maximum amount that LRFG will finance toward the lease or purchase.\n",
                        },
                        description: {
                            html: null,
                            text: null,
                            markdown: null,
                        },
                        links: [],
                    },
                    termsAndConditionsOption2: {
                        id: 'termsAndConditionsOption2',
                        title: {
                            html: '<p><sup>2</sup>&nbsp;By contacting your Land Rover retailer about this offer, they will know that you are a valued Chase customer and that you may meet other eligibility requirements. This offer may be discontinued at any time without notice.</p>\n',
                            text: '2 By contacting your Land Rover retailer about this offer, they will know that you are a valued Chase customer and that you may meet other eligibility requirements. This offer may be discontinued at any time without notice.',
                            markdown: '^2^ By contacting your Land Rover retailer about this offer, they will know that you are a valued Chase customer and that you may meet other eligibility requirements. This offer may be discontinued at any time without notice.\n',
                        },
                        description: {
                            html: null,
                            text: null,
                            markdown: null,
                        },
                        links: [],
                    },
                    termsAndConditionsOption3: {
                        id: 'termsAndConditionsOption3',
                        title: {
                            html: '<p>Lease customers: If you return your leased vehicle early, you may owe LRFG an early termination liability, as due under your lease agreement. This may be a substantial charge depending on when you terminate during the lease term. Contact LRFG to make sure you understand the financial details regarding early turn in.</p>\n',
                            text: 'Lease customers: If you return your leased vehicle early, you may owe LRFG an early termination liability, as due under your lease agreement. This may be a substantial charge depending on when you terminate during the lease term. Contact LRFG to make sure you understand the financial details regarding early turn in.',
                            markdown: 'Lease customers: If you return your leased vehicle early, you may owe LRFG an early termination liability, as due under your lease agreement. This may be a substantial charge depending on when you terminate during the lease term. Contact LRFG to make sure you understand the financial details regarding early turn in.\n',
                        },
                        description: {
                            html: null,
                            text: null,
                            markdown: null,
                        },
                        links: [],
                    },
                    termsAndConditionsOption4: {
                        id: 'termsAndConditionsOption4',
                        title: {
                            html: '<p>Some offers and discounts may not be eligible to be combined. Ask your retailer about what offers and discounts may be combined.</p>\n',
                            text: 'Some offers and discounts may not be eligible to be combined. Ask your retailer about what offers and discounts may be combined.',
                            markdown: 'Some offers and discounts may not be eligible to be combined. Ask your retailer about what offers and discounts may be combined.\n',
                        },
                        description: {
                            html: null,
                            text: null,
                            markdown: null,
                        },
                        links: [],
                    },
                },
                contentLists: [],
                version: '20240502',
            },
        },
        dialog: {},
        contentList: {},
        tile: {
            yourPreApprovalOffer: {
                tags: ['auto/oem/lrfg/preapprovalpage'],
                id: 'yourPreApprovalOffer',
                variationId: null,
                title: {
                    html: '<p>YOUR PRE-APPROVAL OFFER</p>\n',
                    text: 'YOUR PRE-APPROVAL OFFER',
                    markdown: 'YOUR PRE-APPROVAL OFFER\n',
                },
                subTitle: {
                    html: "<p>YOU'RE PRE-APPROVED UP TO</p>\n",
                    text: "YOU'RE PRE-APPROVED UP TO",
                    markdown: "YOU'RE PRE-APPROVED UP TO\n",
                },
                version: '20240606',
                body: {
                    html: '<p>toward the purchase or lease of a new Land Rover&lt;1&gt;{{newLandRover}}&lt;/1&gt;</p>\n',
                    text: 'toward the purchase or lease of a new Land Rover<1>{{newLandRover}}</1>',
                    markdown: 'toward the purchase or lease of a new Land Rover\\<1\\>{{newLandRover}}\\</1\\>\n',
                },
                additionalText: {
                    html: null,
                    text: null,
                    markdown: null,
                },
                hat: null,
                iconImage: null,
                primaryImage: {
                    altText: null,
                    id: null,
                    source: '/content/dam/unified-assets/photography/partner/land-rover/optimized/PreApproval_Hero_Desktop1.jpg',
                    url: 'https://static2-qa2.chasecdn.com/content/dam/unified-assets/photography/partner/land-rover/optimized/PreApproval_Hero_Desktop1.jpg',
                },
                primaryImageWithRendition: null,
                secondaryImageWithRendition: null,
                links: [],
            },
        },
        structuredContentList: {},
        header: {},
    },
};
